<template>
  <div class="h-10 w-10 rounded-full bg-semantic-text-custom flex items-center justify-center">
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.56513 0C5.14664 0 4.76507 0.239514 4.58316 0.616396L3.41191 3.04293C1.48159 3.32734 0 4.99065 0 7V11C0 11.8406 0.259316 12.6207 0.702318 13.2646C0.262348 13.8253 0 14.532 0 15.3C0 17.1225 1.47746 18.6 3.3 18.6H3.4C4.22311 18.6 4.97584 18.2986 5.55387 17.8002C6.25571 17.1951 6.7 16.2994 6.7 15.3C6.7 16.2994 7.14428 17.1951 7.84612 17.8003C8.42413 18.2986 9.17684 18.6 9.99995 18.6C10.8231 18.6 11.5758 18.2986 12.1538 17.8002C12.8557 17.1951 13.3 16.2994 13.3 15.3C13.3 16.2994 13.7443 17.195 14.4461 17.8002C15.0242 18.2986 15.7769 18.6 16.6001 18.6H16.7C18.5226 18.6 20 17.1225 20 15.3C20 14.532 19.7377 13.8253 19.2977 13.2645C19.7407 12.6207 20 11.8406 20 11V8.9225C20 7.70736 19.4476 6.55811 18.4988 5.79902L16.3696 4.09566C15.483 3.3864 14.3815 3 13.2461 3H7.02339V0H5.56513ZM13.3 15.3C13.3 15.2778 13.2997 15.2556 13.2993 15.2335L13.3006 15.2331C13.3002 15.2553 13.3 15.2776 13.3 15.3ZM7.50497 4.8L9.12805 7.5322C9.66864 8.44221 10.6488 9 11.7073 9L18 8.99996C18.0685 8.99996 18.1354 9.00685 18.2 9.01997V8.9225C18.2 8.25418 17.8962 7.62209 17.3743 7.20459L15.2451 5.50122C14.6777 5.0473 13.9727 4.8 13.2461 4.8H7.50497ZM1.8 15.3C1.8 14.4716 2.47157 13.8 3.3 13.8H3.4C4.22843 13.8 4.9 14.4716 4.9 15.3C4.9 16.1284 4.22843 16.8 3.4 16.8H3.3C2.47157 16.8 1.8 16.1284 1.8 15.3ZM9.99995 16.8C9.17156 16.8 8.5 16.1285 8.5 15.3C8.5 14.4715 9.17156 13.8 9.99995 13.8C10.8284 13.8 11.5 14.4716 11.5 15.3C11.5 16.1284 10.8284 16.8 9.99995 16.8ZM16.6001 16.8C15.7716 16.8 15.1 16.1284 15.1 15.3C15.1 14.4716 15.7716 13.8 16.6 13.8H16.7C17.5285 13.8 18.2001 14.4716 18.2001 15.3C18.2001 16.1284 17.5285 16.8 16.7 16.8H16.6001Z"
        fill="white"
      />
    </svg>
  </div>
</template>
