<template>
  <div :data-test="type" class="rounded-[20px] pt-4 p-3" :class="bgColor">
    <div class="pb-4 flex flex-col justify-center">
      <Body1 :color="titleColor" class="text-center font-medium" data-test="order title">
        {{ title }}
      </Body1>
      <Caption1 v-if="actions" data-test="order-card actions" :color="textColor" class="text-center mt-2">
        {{ actions }}
      </Caption1>
      <Caption1 v-if="number" :color="textColor" class="text-center mt-2">
        {{ number }}
      </Caption1>
    </div>
    <div class="relative flex gap-2">
      <template v-if="status === 'request'">
        <UiButton
          v-if="needWorkBtn"
          :is-disabled="workBtnDisabled"
          :background-color="needViewBtn ? 'secondary' : 'primary'"
          data-test="order open btn В работу"
          @click="emits('work-btn-click')"
        >
          {{ $gettext('В работу') }}
          <template #icon>
            <OrderStatusIcons :icons="icons" />
          </template>
        </UiButton>
        <UiButton
          v-if="needViewBtn"
          background-color="primary"
          data-test="order view btn Посмотреть"
          @click="emits('view-btn-click')"
        >
          {{ $gettext('Посмотреть') }}
        </UiButton>
      </template>
      <template v-if="status === 'processing'">
        <UiButton
          background-color="secondary"
          data-test="order open btn Продолжить"
          @click="emits('continue-btn-click')"
        >
          <template v-if="isPaused"> {{ $gettext('Обрабатывается оператором') }}</template>
          <template v-else> {{ $gettext('Продолжить') }}</template>
          <template #icon>
            <OrderStatusIcons :icons="icons" />
          </template>
        </UiButton>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import OrderStatusIcons from '@/fsd/entities/order/ui/order-card/order-status-icons.vue';
import { CardType, IconType } from '@/fsd/entities/order/ui/order-card/types';
import { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, toRefs } from 'vue';

interface Props {
  title: string;
  actions?: string;
  number?: string;
  type: CardType;
  status: OrderWorkStatusEnum;
  needViewBtn: boolean;
  needWorkBtn: boolean;
  workBtnDisabled: boolean;
  isPaused?: boolean;

  icons: IconType[];
}

const props = defineProps<Props>();
const { title, actions, number, type, status, needViewBtn, needWorkBtn, workBtnDisabled, isPaused, icons } =
  toRefs(props);

const emits = defineEmits<{
  (e: 'view-btn-click'): void;
  (e: 'work-btn-click'): void;
  (e: 'continue-btn-click'): void;
}>();

const bgColor = computed(() => {
  if (type.value === 'pause') {
    return 'bg-cardBackground-pause';
  }
  if (type.value === 'rover') {
    return 'bg-coldGray-500';
  }
  if (status.value === 'processing') {
    return 'bg-amber-high';
  }
  if (status.value === 'request') {
    return 'bg-warmGray-50';
  }
  return '';
});

const titleColor = computed(() => {
  if (type.value === 'rover') {
    return 'day-textInvert';
  }
  return 'warmGray-600';
});
const textColor = computed(() => {
  if (type.value === 'rover') {
    return 'day-textInvert';
  }
  return 'warmGray-500';
});
</script>
