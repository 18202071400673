import { defineStore } from 'pinia';

export const useScanner = defineStore('scanner', {
  state: () => ({
    cameraActive: false,
  }),
  getters: {
    isActiveCamera(state) {
      return state.cameraActive;
    },
  },
  actions: {
    setCameraActive(isActive: boolean) {
      this.cameraActive = isActive;
    },
  },
});
