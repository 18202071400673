import { useRum } from '@/fsd/shared/metrics';
import { Ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export const useRumPage = (loading: Ref<boolean, boolean>) => {
  const route = useRoute();
  const pageName = route.meta.metrics?.pageName;

  if (pageName) {
    const { startLoading, finishLoading, startDataRendering } = useRum({
      pageName,
      pageLifecycleHooks: true,
    });

    startLoading();
    if (!loading.value) {
      finishLoading(true);
      startDataRendering();
    } else {
      const unWatch = watch(loading, val => {
        if (!val) {
          unWatch();
          finishLoading();
          startDataRendering();
        }
      });
    }
  }
};
