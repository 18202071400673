import { getSuggestsByStatus } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { SuggestStatusEnum } from '@/models/Suggest';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { ControlModeEnum, isControlOrder } from '@/models/orders/ControlOrder';
import { isOrderOrder } from '@/models/orders/OrderOrder';
import { isSaleStowageOrder } from '@/models/orders/SaleStowageOrder';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { shelfTagsInStowage } from '@/temp/constants/translations';
import { $gettext } from '@/temp/plugins/gettext';
import { computed } from 'vue';

export const getOrderTitle = (order_id: BaseOrder['order_id']) => {
  const order = computed(() => {
    return useOrders().orderById(order_id);
  });

  const waitingRover = computed(() => {
    if (!order.value) return false;
    return Boolean(
      isOrderOrder(order.value) &&
        order.value.attr.maybe_rover &&
        !order.value.courier &&
        order.value.status === OrderWorkStatusEnum.processing &&
        getSuggestsByStatus(order.value.suggests, SuggestStatusEnum.request).length === 0,
    );
  });

  return computed(() => {
    if (!order.value) return '';
    const titles = {
      acceptance: $gettext('Приёмка'),
      writeoff_prepare_day: $gettext('Контроль однодневных товаров'),
      check_valid_short: $gettext('Контроль краткосрочных товаров'),
      check_valid_regular: $gettext('Контроль долгосрочных товаров'),
      order: $gettext('Заказ'),
      stowage: $gettext('Размещение'),
      sale_stowage: $gettext('Размещение'),
      weight_stowage: $gettext('Размещение'),
      writeoff: $gettext('Списание'),
      check: $gettext('Локальная инвентаризация'),
      check_more: $gettext('Инвентаризация'),
      inventory_check_more: $gettext('Инвентаризация'),
      check_product_on_shelf: $gettext('Пересчет'),
      inventory_check_product_on_shelf: $gettext('Контрольная проверка (ПИ)'),
      refund: $gettext('Возврат'),
      part_refund: $gettext('Возврат'),
      shipment: $gettext('Отгрузка'),
      shipment_rollback: $gettext('Возврат отгрузки'),
      hand_move: $gettext('Ручное перемещение'),
      visual_control: $gettext('Контроль внешнего вида'),
      move: $gettext('Перемещение'),
      repacking: $gettext('Перефасовка'),
      check_final: $gettext('Контрольная проверка (ЛИ)'),
    };

    switch (order.value.type) {
      case OrderTypeEnum.acceptance_market:
      case OrderTypeEnum.acceptance:
        return `${$gettext('Приёмка')} ${order.value.contractor}`;
      case OrderTypeEnum.check_more:
      case OrderTypeEnum.inventory_check_more:
        return order.value.rack || $gettext('Инвентаризация');
      case OrderTypeEnum.inventory_check_product_on_shelf:
        return order.value.rack || $gettext('Контрольный проход');
      case OrderTypeEnum.writeoff_prepare_day:
      case OrderTypeEnum.check_valid_regular:
      case OrderTypeEnum.check_valid_short:
        if (isControlOrder(order.value)) {
          if (order.value.vars.mode === ControlModeEnum.store2markdown) {
            return $gettext('Контроль товаров для распродажи');
          }
          if (order.value.vars.mode === ControlModeEnum.eatToday2markdown) {
            return $gettext('КСГ "Годен сегодня"');
          }
        }

        return titles[order.value.type];
      case OrderTypeEnum.hand_move:
        return `${$gettext('Ручное перемещение')} №${order.value.orderNumberForView}`;
      case OrderTypeEnum.kitchen_provision:
        return $gettext('Снабжение');
      case OrderTypeEnum.order:
      case OrderTypeEnum.order_retail:
        if (waitingRover.value) {
          return $gettext('Определяем тип доставки');
        }
        if (order.value.target === 'canceled') {
          return `${$gettext('Возврат товара на полки')} ${order.value.orderNumberForView}`;
        }
        if (order.value.courier && order.value.courier.related_orders.length >= 2) {
          const order_ids = [...order.value.courier.related_orders, order_id].sort();
          const idx = order_ids.findIndex(id => id === order_id);
          const part = idx + 1;

          return $gettext('%{part}-ая часть мультизаказа %{number}', {
            part: String(part),
            number: order.value.orderNumberForView,
          });
        }

        return `${$gettext('Заказ')} ${order.value.orderNumberForView}`;

      case OrderTypeEnum.sale_stowage:
        if (order.value.target === 'canceled') {
          return $gettext('Отмена размещения');
        }
        if (order.value.vars.tag === 'parcel') {
          return $gettext('Размещение посылки');
        }
        //если размещение на разные типы полок
        if (order.value.vars.tag === 'mixed' || !order.value.vars.tag) {
          return $gettext('Размещение');
        }
        if (isSaleStowageOrder(order.value) && order.value.vars.addition_tag === 'perishable') {
          return $gettext('Скоропортящаяся еда');
        }
        return $gettext('%{orderType} в %{shelfType}', {
          orderType: $gettext('Размещение'),
          shelfType: shelfTagsInStowage[order.value.vars.tag],
        });
      case OrderTypeEnum.shipment:
        if ((order.value as ShipmentOrder).isMarketShipment) return $gettext('Возврат Маркета');
        return order.value.contractor;
      case OrderTypeEnum.stowage_market:
        if (order.value.target === 'canceled') {
          return $gettext('Отмена размещения');
        }
        return $gettext('Размещение посылки');
      default:
        return titles[order.value.type];
    }
  });
};
