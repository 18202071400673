<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48113 10.4135C5.69552 10.1353 5.79216 9.77962 5.71656 9.43657L4.86129 5.55567C4.34607 3.21776 6.11032 1 8.48539 1H15.5146C17.8897 1 19.654 3.21776 19.1387 5.55567L18.2835 9.43659C18.2079 9.77964 18.3045 10.1353 18.5189 10.4135C19.6039 11.8219 20.25 13.5913 20.25 15.5129C20.25 18.5326 18.6544 21.1765 16.2671 22.6342C15.8397 22.8951 15.3408 23 14.8412 23H9.15875C8.65917 23 8.16028 22.8951 7.73294 22.6342C5.34556 21.1765 3.75 18.5326 3.75 15.5129C3.75 13.5913 4.39608 11.8219 5.48113 10.4135ZM7.71931 5.01427C7.54757 4.23497 8.13565 3.49572 8.92734 3.49572H15.0727C15.8643 3.49572 16.4524 4.23497 16.2807 5.01427L15.7663 7.34822C15.6895 7.69669 15.3062 7.88023 14.9738 7.75063C14.0512 7.391 13.0484 7.1938 12 7.1938C10.9516 7.1938 9.94883 7.391 9.02625 7.75063C8.69378 7.88023 8.31046 7.69669 8.23366 7.34822L7.71931 5.01427Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
