<template>
  <div class="wrap">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
    <div class="line" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  position: relative;
  height: 3rem;
}

.line {
  position: absolute;
  background: linear-gradient(90deg, #ff4b1f 0%, #ff9068 100%);
  bottom: 1.5rem;
  left: -0.5rem;
  right: -0.5rem;
  height: 2px;
  opacity: 0.6;
}

.bar {
  background: var(--loader_indicator-bar-color);
  width: 2px;
  margin-right: 2px;
  height: 3rem;
  position: relative;
}

@for $i from 1 through 32 {
  .bar:nth-child(#{$i}) {
    animation: grow 0.8s ease-in-out 0.01s * $i infinite;
  }
}

.bar:nth-child(1),
.bar:nth-child(17) {
  width: 2px;
}

.bar:nth-child(2),
.bar:nth-child(18) {
  width: 1px;
  margin-right: 3px;
}

.bar:nth-child(3),
.bar:nth-child(19) {
  width: 1px;
  margin-right: 3px;
}

.bar:nth-child(4),
.bar:nth-child(20) {
  width: 1px;
  margin-right: 2px;
}

.bar:nth-child(5),
.bar:nth-child(21) {
  width: 3px;
  margin-right: 3px;
}

.bar:nth-child(6),
.bar:nth-child(7),
.bar:nth-child(8),
.bar:nth-child(22),
.bar:nth-child(23),
.bar:nth-child(24) {
  width: 2px;
  margin-right: 2px;
}

.bar:nth-child(8),
.bar:nth-child(25) {
  width: 3px;
  margin-right: 1px;
}

.bar:nth-child(9),
.bar:nth-child(26) {
  width: 3px;
  margin-right: 2px;
}

.bar:nth-child(10),
.bar:nth-child(27) {
  width: 1px;
  margin-right: 1px;
}

.bar:nth-child(11),
.bar:nth-child(28) {
  width: 2px;
  margin-right: 4px;
}

.bar:nth-child(12),
.bar:nth-child(29) {
  width: 1px;
  margin-right: 2px;
}

.bar:nth-child(13),
.bar:nth-child(30) {
  width: 3px;
  margin-right: 2px;
}

.bar:nth-child(14),
.bar:nth-child(31) {
  width: 1px;
  margin-right: 2px;
}

.bar:nth-child(15),
.bar:nth-child(16),
.bar:nth-child(32) {
  width: 2px;
  margin-right: 2px;
}

@keyframes grow {
  0%,
  100% {
    height: 3rem;
    top: 0;
  }

  50% {
    height: 3.5rem;
    top: -0.5rem;
  }
}
</style>
