import createRetryOnErrorFn from '@/fsd/shared/utils/retryRequest';
import BaseOrder from '@/models/orders/BaseOrder';
import { createQueue, QueueLoadFn } from '@/services/queue/queue';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import axios, { AxiosError } from 'axios';

const loadFn: QueueLoadFn<BaseOrder> = async (ids, setCancel) => {
  const uniqIds = [...new Set(ids)];
  const { data } = await useOrders().loadOrders(uniqIds, {
    cancelToken: new axios.CancelToken(setCancel),
  });
  const idToItem = data.orders.reduce((acc, order) => {
    acc[order.order_id] = order;
    return acc;
  }, {});
  return ids.map(id => idToItem[id] || null);
};
const orderCheckError = (e: AxiosError<any>) => {
  // нет ответа, сетевая ошибка
  if (!e.response) return true;
  // Отвалилась авторизация, пользователя разлогинило, не хотим ретраить
  if (e.response.status === 401) return false;
  // Кладана открепили от склада, разлогиним его.
  if (
    e.response.status === 403 &&
    e.response.data.details &&
    e.response.data.details.message === '/api/tsd/orders, element: None, over: my_store'
  ) {
    useUser().logout();
    return false;
  }
  return true;
};
const withRetry = createRetryOnErrorFn(loadFn, {
  maxRetry: 3,
  maxDelay: 8,
  chechError: orderCheckError,
});

export default createQueue<BaseOrder>(withRetry);
