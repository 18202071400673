<template>
  <teleport to="#root">
    <Overlay>
      <Layout>
        <template #header>
          <Bar @close-click="emits('close')" />
        </template>
        <template #default>
          <div class="flex flex-col p-4 h-full">
            <template v-if="picking_method === 'replace_items'">
              <Title2 class="font-semibold mb-3">
                {{ $gettext('Поищите замены недостающим товарам') }}
              </Title2>
              <Body2 class="mb-2">
                {{ $gettext('Важно, чтобы у замен были похожие цена и количество') }}
              </Body2>
              <Body2>
                {{ $gettext('Нужно будет позвонить клиенту и согласовать изменения в заказе') }}
              </Body2>
            </template>
            <template v-else>
              <Title2 class="font-semibold mb-3">
                {{ $gettext('Удалите из заказа товары, которых нет в наличии') }}
              </Title2>
              <Body2>
                {{ $gettext('Клиент попросил не искать замены и привезти только те товары, которые удалось собрать') }}
              </Body2>
            </template>

            <div class="flex-grow" />
            <StoreAndStorekeeper class="self-center" />
            <div class="flex-grow" />
          </div>
        </template>
        <template #footer>
          <LayoutFooter>
            <UiButton data-test="shelf-picking-rules start" background-color="primary" @click="emits('start')">
              {{ $gettext('Начать сборку') }}
            </UiButton>
          </LayoutFooter>
        </template>
      </Layout>
    </Overlay>
  </teleport>
</template>
<script lang="ts" setup>
import StoreAndStorekeeper from '@/fsd/shared/icons/store-and-storekeeper.vue';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';

const emits = defineEmits(['close', 'start']);
defineProps<{ picking_method: OrderOrderRetail['shelfPickingMethod'] }>();
</script>
