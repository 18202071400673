<template>
  <button
    v-if="!collected && props.suggest.status !== SuggestStatusEnum.done"
    data-test="suggest-card remove"
    class="flex pr-2"
    @click.stop="emits('remove')"
  >
    <IconBasketRound />
  </button>
  <button
    v-if="(collected || props.suggest.status === SuggestStatusEnum.done) && (props.suggest.result_count || collected)"
    data-test="suggest-card menu"
    class="flex pr-2"
    @click.stop="emits('menu')"
  >
    <IconMenuRound />
  </button>
</template>
<script setup lang="ts">
import IconBasketRound from '@/fsd/shared/icons/icon-basket-round.vue';
import IconMenuRound from '@/fsd/shared/icons/icon-menu-round.vue';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { defineEmits, defineProps } from 'vue';

interface SuggestCardProps {
  suggest: Suggest;
  collected: string[] | number;
}

const props = defineProps<SuggestCardProps>();

const emits = defineEmits<{
  (e: 'remove'): void;
  (e: 'menu'): void;
}>();
</script>
