<template>
  <div>
    <notifications
      class="universal-modal"
      classes="notifications"
      width="100%"
      group="universal-modal"
      position="bottom center"
    >
      <template #body="props">
        <UniversalModal
          :position="props.item.data.position"
          :backdrop-click="props.item.data.backdropClick"
          :class="props.item.data.class"
          @backdrop-click="executeAndClose(() => props.item.data.resolve(false), props.close)"
        >
          <ModalContent
            :data-test="props.item.data.dataTest"
            :title="props.item.data.title"
            :title-align="props.item.data.titleAlign"
            :text="props.item.data.text"
            :text-align="props.item.data.textAlign"
            :icon-top="props.item.data.iconTop"
            :icon-center="props.item.data.iconCenter"
            :icon-bottom="props.item.data.iconBottom"
            :is-wrap="props.item.data.isWrap"
            :buttons="props.item.data.buttons"
            @btn-click="cb => executeAndClose(() => props.item.data.resolve(cb()), props.close)"
          />
        </UniversalModal>
      </template>
    </notifications>

    <notifications
      class="universal-modal"
      classes="notifications"
      width="100%"
      group="custom-component-modal"
      position="bottom center"
    >
      <template #body="props">
        <UniversalModal
          :position="props.item.data.position"
          :backdrop-click="props.item.data.backdropClick"
          @backdrop-click="executeAndClose(() => props.item.data.resolve(false), props.close)"
        >
          <component
            :is="props.item.data.component"
            v-bind="props.item.data"
            @btn-click="result => executeAndClose(() => props.item.data.resolve(result), props.close)"
          />
        </UniversalModal>
      </template>
    </notifications>

    <notifications classes="notifications" width="100%" group="major-error" position="bottom center">
      <template #body="props">
        <MajorError
          :details="props.item.data.details"
          :title="props.item.data.title"
          @close="executeAndClose(props.item.data.onClose, props.close)"
          @repeat="executeAndClose(props.item.data.onRepeat, props.close)"
        />
      </template>
    </notifications>

    <notifications classes="notifications" width="100%" group="counter" position="bottom center">
      <template #body="props">
        <Counter
          :title="props.item.title"
          :text="props.item.text"
          :max="props.item.data.max"
          :suggest="props.item.data.suggest"
          @close="props.close"
          @ok="props.item.data.onOk"
        />
      </template>
    </notifications>

    <notifications classes="notifications" width="100%" group="report">
      <template #body="props">
        <Report
          :title="props.item.title"
          :product-id="props.item.data.productId"
          @close="props.close"
          @ok="props.item.data.onOk"
        />
      </template>
    </notifications>

    <notifications classes="notifications" width="100%" group="new-universal-modal">
      <template #body="props">
        <NewUniversalModal
          :title="props.item.data.title"
          :subtitle="props.item.data.subtitle"
          :text="props.item.data.text"
          :info-block="props.item.data.infoBlock"
          :info-block-title="props.item.data.infoBlockTitle"
          :btn-position="props.item.data.btnPosition"
          :confirm-btn-title="props.item.data.confirmBtnTitle"
          :close-btn-title="props.item.data.closeBtnTitle"
          :data-test="props.item.data.dataTest"
          @close="props.item.data.onClose?.() || executeAndClose(() => props.item.data.resolve(false), props.close)"
          @confirm="props.item.data.onConfirm?.() || executeAndClose(() => props.item.data.resolve(true), props.close)"
        >
          <template #icon>
            <component :is="props.item.data.component" />
          </template>
        </NewUniversalModal>
      </template>
    </notifications>
  </div>
</template>

<script lang="ts">
import { NewUniversalModal } from '@/fsd/shared/universalModal';
import MajorError from '@/temp/ui/notifications/major-error/major-error.vue';
import Alert from '@/ui/common/notifications/alert.vue';
import Counter from '@/ui/common/notifications/counter.vue';
import MicroAlert from '@/ui/common/notifications/micro-alert.vue';
import ModalContent from '@/ui/common/notifications/modal-content.vue';
import Report from '@/ui/common/notifications/report.vue';
import UniversalModal from '@/ui/common/notifications/universalModal.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationsGroup',
  components: {
    UniversalModal,
    NewUniversalModal,
    ModalContent,
    Counter,
    MajorError,
    Alert,
    MicroAlert,
    Report,
  },
  methods: {
    showDetails(title, text): void {
      this.$notification.modal({
        title,
        titleAlign: 'center',
        text,
        textAlign: 'center',
      });
    },
    executeAndClose(cb, close): void {
      cb();
      close();
    },
  },
});
</script>

<style lang="scss">
.vue-notification-group {
  z-index: 1000;

  &.universal-modal {
    z-index: 1001;
  }
}
</style>
