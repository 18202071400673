<template>
  <StrokeText v-if="showRamIndicator" :color="ramIndicatorColor" :text="`${usedMemory} Mb`" />
</template>

<script setup lang="ts">
import StrokeText from '@/fsd/shared/ui/text/StrokeText.vue';
import { useMemory } from '@vueuse/core/index';
import { computed } from 'vue';

const { isSupported: showRamIndicator, memory } = useMemory();

const usedMemory = computed<number>(() => {
  if (!memory.value || !showRamIndicator) return 0;
  return ~~(memory.value.usedJSHeapSize / 1024 / 1024);
});

const ramIndicatorColor = computed<string>(() => {
  if (usedMemory.value <= 50) {
    return 'green-normal';
  }
  if (usedMemory.value <= 80) {
    return 'yellow-dark';
  }
  return 'red-normal';
});
</script>
