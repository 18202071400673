<template>
  <UseDraggable
    v-show="!isActiveCamera"
    class="h-16 w-16 bg-gray-300 rounded-full border fixed flex items-center justify-center"
    storage-key="camera-scanner-trigger"
    storage-type="local"
  >
    <CameraIcon @click="getBarcodeFromCamera" />
  </UseDraggable>
</template>

<script setup lang="ts">
import { startScan, stopScan } from '@/fsd/data/services/camera.scanner';
import { useScanner } from '@/store/modules/scanner';
import CameraIcon from '@/temp/icons/camera-icon.vue';
import { UseDraggable } from '@vueuse/components';
import { computed, defineEmits, defineProps, onUnmounted } from 'vue';

const props = defineProps<{ resolve }>();
const emits = defineEmits<{ (e: 'close'): void }>();

const isActiveCamera = computed(() => {
  return useScanner().isActiveCamera;
});
const getBarcodeFromCamera = async () => {
  useScanner().setCameraActive(true);
  try {
    const result = await startScan();
    if (result) props.resolve(result);
    emits('close');
    useScanner().setCameraActive(false);
  } catch (e) {
    useScanner().setCameraActive(false);
  }
};

onUnmounted(() => {
  stopScan();
});
</script>
