<template>
  <div class="flex justify-center box-border">
    <div style="width: 174px; min-height: 174px" class="flex">
      <ImageWrapper :img-src="imgSrc" />
    </div>
  </div>
</template>
<script lang="ts">
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { ImageWrapper },
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
  },
});
</script>
