<template>
  <svg viewBox="0 0 288 56" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_6064_102785"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="288"
      height="56"
    >
      <rect width="288" height="56" rx="16" fill="#FAF9F7" />
    </mask>
    <g mask="url(#mask0_6064_102785)">
      <!--    основной фон  -->
      <rect width="288" height="56" rx="16" :fill="bgColor" />
      <!--    квадраты по краям  -->
      <rect
        x="216"
        y="-26.158"
        width="77.6454"
        height="77.6454"
        rx="20"
        transform="rotate(-21.8056 216 -26.158)"
        :fill="earsColor"
      />
      <rect
        x="62.5791"
        y="27.9818"
        width="83.2835"
        height="83.2835"
        rx="20"
        transform="rotate(117.909 62.5791 27.9818)"
        :fill="earsColor"
      />
    </g>
  </svg>
</template>
<script lang="ts">
import { ThemeEnum, useUser } from '@/store/modules/user';
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  // TODO Временное решение, в дизайн системе еще есть артифакты из старой системы, пока мы не добавляем их в палитру, а передаем в компонент хексом
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
    customBgColor: {
      type: String,
      default: '',
    },
    customEarsColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    theme(): ThemeEnum {
      return this.userStore.theme;
    },
    bgColor(): string {
      if (this.customBgColor) return this.customBgColor;
      switch (this.theme) {
        case ThemeEnum.main:
          return '#FFE7C4';
        case ThemeEnum.deli:
          return '#F3F5FE';
        case ThemeEnum.dark:
          return '#3B3E43';
        default:
          return '#FFE7C4';
      }
    },
    earsColor(): string {
      if (this.customEarsColor) return this.customEarsColor;
      switch (this.theme) {
        case ThemeEnum.main:
          return '#FDEFE5';
        case ThemeEnum.deli:
          return '#E7EAFB';
        case ThemeEnum.dark:
          return '#34373B';
        default:
          return '#FFE6C4';
      }
    },
  },
});
</script>
