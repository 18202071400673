<template>
  <div
    data-test="counter value"
    class="bg-control-minor rounded-2xl w-full overflow-hidden grow flex justify-between items-center h-14 px-4"
    @click="$emit('showCalc')"
  >
    <Title4 v-if="!value">
      {{ $gettext('Вес товара, в кг') }}
    </Title4>
    <div v-else class="grow flex justify-between">
      <Title4>
        {{ value / 1000 }}
      </Title4>
      <Title4>
        {{ weightToView }}
      </Title4>
    </div>
  </div>
</template>
<script setup lang="ts">
import { getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import { computed } from 'vue';
import { CounterFieldProps } from './types';

const props = withDefaults(defineProps<CounterFieldProps>(), { value: 0 });

defineEmits<{ (e: 'showCalc'): void }>();

const weightToView = computed<string>(() => {
  return getWeightToView(props.value);
});
</script>
