<template>
  <LayoutFooter class="flex-col">
    <OrderTotalPrice :total-price="order.totalPrice" :max-total-price="Number(order.max_total_price)" />
    <template v-if="confirmed && order.totalPrice <= +order.max_total_price">
      <UiButton
        class="mt-2"
        data-test="order_retail check-payment"
        :is-disabled="confirmingAssembled"
        @click="$emit('finish')"
      >
        {{ confirmingAssembled ? $gettext('Проверка возможности оплаты') : $gettext('Проверить возможность оплаты') }}
      </UiButton>
    </template>
  </LayoutFooter>
</template>

<script setup lang="ts">
import { OrderTotalPrice } from '@/fsd/features/order';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';

interface OrderRetailFooterProps {
  order: OrderOrderRetail;
  confirmed: boolean;
}

const props = defineProps<OrderRetailFooterProps>();

defineEmits<{ (e: 'finish'): void }>();

const confirmingAssembled = computed<boolean>(
  () =>
    !!props.order.vars.confirming_assembled_products &&
    typeof props.order.vars.fulfilled_conditions?.confirm_assembled_products !== 'boolean',
);
</script>
