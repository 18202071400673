<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar :text="controlBarText" :caption="controlBarCaption" @close-click="toHomePage" />
    </template>
    <template #default>
      <Hint class="my-2 mx-4">{{ $gettext('Чтобы начать, нажмите «В работу»') }}</Hint>
      <RequiredCard
        v-for="item in required"
        :key="item.product_id + item.shelf_id + item.item_id"
        :required-item="item"
      />
      <ListCardItemContainer v-if="needShowShelves">
        <ListCardItem v-for="shelf in shelves" :key="shelf.shelf_id" :is-done="false">
          {{ shelf.title }}
        </ListCardItem>
      </ListCardItemContainer>
      <CheckTransportConditions
        v-if="checkTransportConditions.visible.value"
        :order="order"
        :need-vehicle-checkin="needVehicleCheckin"
        :need-temperature="needTemperature"
        @done="() => checkTransportConditions.hide(true)"
        @close="() => checkTransportConditions.hide(false)"
      />
    </template>
    <template #footer>
      <LayoutFooter>
        <UiButton background-color="secondary" @click="toBack">
          {{ $gettext('Назад') }}
        </UiButton>
        <UiButton class="ml-2" data-test="order open btn" @click="onToWorkBtnClick">
          {{ $gettext('В работу') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { getShelf2BoxSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useAckOrder } from '@/fsd/features/order/utils/useAckOrder';
import CheckTransportConditions from '@/fsd/widgets/acceptance-check-transport-conditions/CheckTransportConditions.vue';
import { useComponent } from '@/hooks/useComponent';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Item, { ItemInRequired } from '@/models/Item';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments, orderTypeUrls, permits } from '@/temp/constants';
import { getOrderTypesInMenu } from '@/temp/constants/translations';
import { ProductInRequired, isProductInRequired } from '@/types/product';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import ListCardItemContainer from '@/ui/common/list-card-item-container.vue';
import ListCardItem from '@/ui/common/list-card-item/list-card-item.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import UiButton from '@/ui/common/ui-button.vue';
import RequiredCard from '@/ui/view-order/required-card.vue';
import { getFormatDate } from '@/utils';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'OrderView',
  components: {
    PageLayout,
    LayoutFooter,
    RequiredCard,
    Bar,
    UiButton,
    Hint,
    ListCardItem,
    ListCardItemContainer,
    CheckTransportConditions,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { showLoader } = useLoader();
    const shelvesStore = useShelves();
    const userStore = useUser();
    const ordersStore = useOrders();
    const checkTransportConditions = useComponent<void, boolean>();
    const router = useRouter();

    return { showLoader, shelvesStore, userStore, ordersStore, router, checkTransportConditions };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    isAvailableOrderType(): boolean {
      if (!this.order) return false;
      const permit = this.userStore.permitByName(permits.tsd_order_types);
      if (Array.isArray(permit)) {
        return permit.includes(this.order.type);
      }
      return false;
    },
    shelves(): Shelf[] {
      if (!this.order) return [];
      const shelfIds: string[] = this.order.shelves;
      const shelves = shelfIds
        .map(shelf_id => {
          return this.shelvesStore.shelfById(shelf_id);
        })
        .filter<Shelf>((shelf): shelf is Shelf => Boolean(shelf));
      return shelves.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
    },
    controlBarCaption(): string {
      if (!this.order) return '';
      return getOrderTypesInMenu[this.order.type] || '';
    },
    controlBarText(): string {
      if (!this.order) {
        return '';
      }
      const doc_number = this.order.orderNumberForView;
      const doc_date = getFormatDate(this.order.attr.doc_date);
      return this.$gettext('№ %{doc_number} от %{doc_date}', {
        doc_number,
        doc_date,
      });
    },
    order(): BaseOrder {
      return this.ordersStore.orderById(this.order_id)!;
    },
    required(): Suggest[] | (ProductInRequired | ItemInRequired)[] {
      if (!this.order) return [];
      if (this.order.type === 'inventory_check_more') {
        return [];
      }
      //похожк поле required в этом типе содержит полки
      if (this.order.required.length > 0) {
        return this.order.required.filter(require => {
          return Item.isItemInRequired(require) || isProductInRequired(require);
        });
      }
      if (this.order.suggests && this.order.suggests.length > 0) {
        if (this.order.isControl) {
          return getShelf2BoxSuggests(this.order.suggests);
        }
      }
      return [];
    },
    needShowShelves(): boolean {
      if (!this.order) return false;
      return ['check_more', 'inventory_check_more'].includes(this.order.type);
    },
    isMustBeTrust(): boolean {
      return Boolean(this.userStore.experimentByName(experiments['exp_lightman']) && this.order!.attr.trust_code);
    },
    needVehicleCheckin(): boolean {
      return (
        !this.order?.signals?.find(s => s.data.truck_departure_time !== undefined) &&
        this.userStore.experimentByName(experiments.exp_checkin_vehicle_upon_acceptance) &&
        this.isMustBeTrust
      );
    },
    needTemperature(): boolean {
      return (
        !this.order?.signals?.find(s => s.data.truck_temperature !== undefined) &&
        this.userStore.experimentByName(experiments['exp_marie-curie'])
      );
    },
  },
  watch: {
    order(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$alert.error('Задание было отменено или его взял в работу кто-то другой');
        this.toHomePage();
      }
    },
  },
  async mounted() {
    if (!this.order) {
      this.toHomePage();
      return;
    }
  },
  methods: {
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    toBack(): void {
      this.router.back();
    },
    async showCheckTransportConditions(): Promise<boolean> {
      this.uiStateNeedBarcodeRequest = false;
      const result = await this.checkTransportConditions.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
      return result;
    },
    async onToWorkBtnClick(): Promise<void> {
      if (!this.order) {
        return;
      }
      const confirmed = await this.$notification.confirmCenter({
        title: this.$gettext('Начать задание?'),
        text: this.$gettext('После взятия задания в работу вам необходимо будет его выполнить'),
      });
      if (!confirmed) return;
      if (this.order.type === 'acceptance' && (this.needVehicleCheckin || this.needTemperature)) {
        if (!(await this.showCheckTransportConditions())) return;
      }
      const result = await useAckOrder(this.order_id);
      if (!result) return;
      this.uiStateNeedBarcodeRequest = false;
      if (this.order.type === 'shipment' && (this.order as ShipmentOrder).isMarketShipment) {
        this.router.push({ name: 'market_shipment', params: { order_id: this.order.order_id } });
        return;
      }
      this.router.push({ name: orderTypeUrls[this.order.type], params: { order_id: this.order.order_id } });
    },
    async requestBarcode(): Promise<boolean> {
      const { product } = await this.requestProductCode({ checkSuggests: false });
      if (this.order?.type === 'acceptance') {
        if (product) {
          const productInRequired = this.required.some(p => 'product_id' in p && p.product_id === product.product_id);
          if (productInRequired) {
            this.onToWorkBtnClick();
          }
        }
      } else {
        this.$alert.error(this.$gettext('Чтобы начать,нажмите "В работу"'));
      }
      return true;
    },
  },
});
</script>
