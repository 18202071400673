<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar :order="order" :caption="$gettext('Перефасовка')" @close-click="toHomePage" />
    </template>

    <template #default>
      <Hint class="my-2 mx-4">{{ hintText }}</Hint>
      <SuggestCard
        v-for="(suggest, index) in suggests"
        :key="suggest.suggest_id"
        :suggest="suggest"
        doc-type="repacking"
        :order="order"
        :suggest-menu-config="suggestMenuConfig(suggest)"
        :data-index="index"
        :need-menu="suggest.status === 'request' || suggest.result_count > 0"
        @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
        @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
      />

      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :order-id="order.order_id"
        @cancel="suggestDetails.hide"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
      />

      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
    </template>

    <template #footer>
      <LayoutFooter>
        <UiButton
          data-test="repacking footer finish-btn"
          :disabled="order?.vars.stage !== 'complete'"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import RepackingOrder from '@/models/orders/RepackingOrder';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import { checkConditions } from '@/utils/checkConditions';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

// примерный алгоритм
// берем товары на треш (shelf2box)
// кладем на треш

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'Repacking',
  components: {
    PageLayout,
    ScanShelf,
    LayoutFooter,
    Bar,
    Hint,
    UiButton,
    SuggestCard,
    SuggestDetails,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return { showLoader, productsStore, ordersStore, suggestDetails, scanShelf, router };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): RepackingOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as RepackingOrder;
    },
    suggests(): Suggest[] {
      if (!this.order) return [];

      return this.order.suggests;
    },
    contractorName(): string {
      if (!this.order || !this.order.attr.contractor) {
        return '';
      }
      return this.order.attr.contractor;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Отметить как отсутствующий'),
          onClick: () => this.finishActiveSuggest({ count: 0 }, suggest),
          condition: () => {
            return checkConditions(suggest, 'all', true) && (suggest.status === 'request' || suggest.result_count! > 0);
          },
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    hintText(): string {
      if (!this.order) {
        return '';
      }
      if (['trash2box', 'trash2shelf'].includes(this.order.vars.stage!)) {
        return this.$gettext('Отсканируйте товар на списание');
      }
      if (['repacking2box', 'repacking2shelf'].includes(this.order.vars.stage!)) {
        return this.$gettext('Отсканируйте товар на размещение');
      }
      return this.$gettext('Все готово, можете завершить задание');
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product, item } = await this.requestProductCode({ checkSuggests: true });
      return await this.selectProduct(product || item);
    },
    async selectProduct(product): Promise<boolean> {
      const productSuggests = this.suggests.filter(
        s => (s.product_id === product.product_id || s.product_id === product.item_id) && s.status === 'request',
      )!;

      if (productSuggests.length === 1) {
        const shelf2BoxSuggest = productSuggests[0];
        await this.selectSuggest(shelf2BoxSuggest);
        return true;
      }
      if (productSuggests.length === 0) {
        this.$alert.error(this.$gettext('Этот товар уже отгружен'));
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = productSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (suggest) {
        await this.selectSuggest(suggest);
        return true;
      } else {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
    },
    async selectSuggest(suggest: Suggest): Promise<void> {
      if (suggest.vars.mode === 'item') {
        await this.finishActiveSuggest({ count: 1 }, suggest);
      } else {
        await this.suggestDetails.asyncShow(suggest);
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async finishActiveSuggest({ count = 0 }: Pick<Model, 'count'>, suggest: Suggest): Promise<any> {
      if (!this.order) {
        return;
      }
      if (count === suggest.result_count) {
        this.suggestDetails.hide();
        return;
      }
      if (!suggest) return;

      if (suggest.type === 'shelf2box') {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      } else {
        const result = await useBox2Shelf(this.order_id, { suggest_id: suggest.suggest_id, count });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirmed = await this.$notification.confirmCenter({
        title: this.$gettext('Вы уверены, что проверили все товары?'),
      });
      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
  },
});
</script>
