<template>
  <div class="flex w-full">
    <div class="bg-warmGray-550 rounded-full w-14 h-14 flex items-center justify-center">
      <IconScales color="white" />
    </div>
  </div>
</template>

<script lang="ts">
import IconScales from '@/temp/icons/icon-scales.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconScalesForModal',
  components: { IconScales },
});
</script>
