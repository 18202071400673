import Suggest from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const inputShelfTitles = gettextWrapper({
  default: $gettext('Полка'),
  hand_move: {
    box2shelf: $gettext('На полку'),
    shelf2box: $gettext('С полки'),
  },
  kitchen_provision: {
    box2shelf: $gettext('На полку'),
    shelf2box: $gettext('С полки'),
  },
});

export const inputShelfTitlesDone = gettextWrapper({
  default: $gettext('Полка'),
  hand_move: {
    box2shelf: $gettext('На полку'),
    shelf2box: $gettext('С полки'),
  },
  kitchen_provision: {
    box2shelf: $gettext('На полку'),
    shelf2box: $gettext('С полки'),
  },
});

interface Option {
  docType: DocTypes;
  suggest?: Suggest;
}

export const getShelfTitle = ({ docType, suggest }: Option) => {
  const result = suggest?.status === 'done' ? inputShelfTitlesDone[docType] : inputShelfTitles[docType];
  if (typeof result === 'object') {
    return result[suggest?.type!] || inputShelfTitles.default;
  }
  return result || inputShelfTitles.default;
};
