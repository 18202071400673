<template>
  <Row v-if="needShow" :label="label" :value="value" :is-bold="true" data-test="suggest-details result-count row" />
</template>

<script lang="ts">
import { getValueForView } from '@/fsd/entities/product';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getCountTitleDone } from '@/temp/constants/translations/countTitles';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

// Компонент для отображения итогового кол-ва в задании(если нужно)

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    step: {
      type: String,
      required: true,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();

    return { shelvesStore, productsStore };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    needShow(): boolean {
      return !!(this.suggest.result_count && (this.suggest.vars.mode === 'product' || !this.suggest.vars.mode));
    },
    label(): string {
      if (this.isControl && this.step !== 'count' && this.suggest.type === 'shelf2box') {
        return this.$gettext('Списываем');
      }
      return getCountTitleDone({ docType: this.order.type, suggest: this.suggest });
    },
    value(): string {
      let count = this.suggest.result_count || 0;

      if (['inventory_check_product_on_shelf'].includes(this.order.type)) {
        const available = this.productsStore.availableByProductId(this.suggest.product_id);
        const productOnShelf = available?.find(item => item.shelf_id === this.suggest.shelf_id)!;
        count = productOnShelf ? productOnShelf.count : 0;
      }
      if (['check_more', 'inventory_check_more'].includes(this.order.type)) {
        count = this.model.count!;
      }
      if (['acceptance'].includes(this.order.type) && this.suggest.conditions.need_weight) {
        count = this.model.count!;
      }

      if (this.isControl) {
        if (this.step !== 'count') {
          count = this.model.count!;
        }
      }

      return getValueForView({ product: this.product!, shelf: this.shelf, count });
    },
  },
});
</script>
