<template>
  <div class="inline-loader">
    <LoaderIndicator />
  </div>
</template>

<script>
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LoaderIndicator,
  },
});
</script>

<style lang="scss" scoped>
.inline-loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 1rem;
}
</style>
