import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';

export interface WeightStowageOrderVars extends OrderBaseVars {
  suggest_mode?: 'done';
  scan_unit_child_product?: boolean;
}

export default class WeightStowageOrder extends BaseOrder {
  public vars: WeightStowageOrderVars = {};

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
