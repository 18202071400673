<template>
  <div class="separator" />
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
<style scoped>
.separator {
  height: 12px;
  background-color: var(--element-bg);
}
</style>
