import { SuggestTypeEnum } from '@/models/Suggest';
import IconBasketReturn from '@/temp/icons/icon-basket-return.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default (type: SuggestTypeEnum) => {
  if (type === 'box2shelf') {
    return Notifications.modal({
      title: $gettext('Отсканирована не та марка'),
      text: $gettext('Нужно отсканировать ту же марку, что и при сборке'),
      iconTop: {
        icon: IconBasketReturn,
        position: 'center',
      },
    });
  }
  if (type === 'shelf2box') {
    return Notifications.modal({
      title: $gettext('Этот товар нельзя положить в заказ'),
      text: $gettext('Отложите для дальнейших указаний. Возьмите другой товар'),
      iconTop: {
        icon: IconBasketReturn,
        position: 'center',
      },
    });
  }
};
