import NoPicture from '@/assets/img/no-picture.svg';
import { imgUrl } from '@/fsd/data/api/api.service';
import { ModeService } from '@/services/mode.service';

export const getImgUrl = (url: string): string => {
  if (ModeService.isDevelopment()) {
    return NoPicture;
  }
  if (imgUrl) {
    try {
      const addr = new URL(url);
      const newAddr = new URL(imgUrl);
      newAddr.pathname = addr.pathname;
      return newAddr.href;
    } catch (error) {
      return url;
    }
  }
  return url;
};
