export const permits = {
  auth_done: 'auth_done',
  barcode: 'barcode',
  cur_user: 'cur_user',
  executors_load: 'executors_load',
  logout: 'logout',
  order_signals: 'order_signals',
  //массив
  products_load: 'products_load',
  tsd_create_check: 'tsd_create_check',
  tsd_set_valid: 'tsd_set_valid',
  tsd_writeoff: 'tsd_writeoff',
  tsd_downgrade: 'tsd_downgrade',
  tsd_free_device: 'tsd_free_device',
  tsd_items: 'tsd_items',
  tsd_move: 'tsd_move',
  tsd_order_linkuser: 'tsd_order_linkuser',
  tsd_order_types: 'tsd_order_types',
  //массив
  tsd_orders: 'tsd_orders',
  tsd_shelves: 'tsd_shelves',
  tsd_stocks: 'tsd_stocks',
  tsd_upgrade: 'tsd_upgrade',
  tsd_create_writeoff: 'tsd_create_writeoff',
  tsd_acceptance: 'tsd_acceptance',
  tsd_shipment_items: 'tsd_shipment_items',
  tsd_check_contractor: 'tsd_check_contractor', //может запускать проверку наличия товара в ассортименте поставщика (check_contractor)
  tsd_children: 'tsd_children', //  может в тсд получить дочерние ордеры
  tsd_acceptance_result_count: 'tsd_acceptance_result_count', // может получить финальное количество товара после раскладок/коррекций
  tsd_trash_revert: 'tsd_trash_revert', //возврат с полки списания
  tsd_move_repacking: 'tsd_move_repacking', //может перемещать на полку перефасовки
  tsd_repacking: 'tsd_repacking', //может создать перефасовку
  tsd_move_to_markdown: 'tsd_move_to_markdown', // может перемещать товары на markdown
  tsd_can_store_checkin: 'tsd_can_store_checkin', // может зачекиниться на складе
};
