<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest card shelf row" />
</template>

<script lang="ts">
//показываем полку для задач, где это важно
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { getShelfTitle } from '@/temp/constants/translations/shelfTitles';
import { DocTypes } from '@/temp/constants/translations/types';
import Row from '@/ui/common/suggest-card/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
  },
  setup() {
    const shelvesStore = useShelves();

    return { shelvesStore };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest?.shelf_id)!;
    },
    needShow(): boolean {
      if (!this.suggest) return false;
      return !['acceptance', 'onlyTitle', 'shelf', 'view', 'check_more', 'pack', 'create-acceptance-item'].includes(
        this.docType,
      );
    },
    label(): string {
      return getShelfTitle({ docType: this.docType, suggest: this.suggest });
    },
    value(): string {
      return this.shelf?.title || '—';
    },
  },
  async mounted() {
    //грузим на всякий случай полку в стор, если не была загружена раньше
    if (this.suggest?.shelf_id) {
      await this.shelvesStore.getShelfById(this.suggest.shelf_id);
    }
  },
});
</script>
