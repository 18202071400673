<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="16" height="16" rx="8" fill="#48C600" />
    <path
      d="M6.92399 10.5921L11.4665 6.0496C11.738 5.77816 12.1781 5.77816 12.4495 6.0496C12.7209 6.32105 12.7209 6.76115 12.4495 7.0326L6.92399 12.5581L3.9915 9.62561C3.72005 9.35416 3.72005 8.91406 3.9915 8.64262C4.26294 8.37117 4.70305 8.37117 4.97449 8.64262L6.92399 10.5921Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
