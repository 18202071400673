<template>
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.9696 0C4.66805 0.0247501 5.33985 0.270132 5.8856 0.699838C6.43134 1.12954 6.82205 1.66811 6.9999 2.33333C7.17775 1.66811 7.56846 1.12954 8.11421 0.699838C8.65996 0.270132 9.33176 0.0247501 10.0302 0C12.2726 0 13.6666 1.33333 13.6666 3.98923C13.6666 8 8.36354 11.4364 7.30293 11.9912H6.69687C5.63627 11.4364 0.333252 8 0.333252 4C0.333252 1.33333 1.72717 0 3.9696 0Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
