<template>
  <div class="flex items-center">
    <StrokeText :text="String(eventErrorCount)" />
    <EventIcon :color="eventIndicatorColor" />
  </div>
</template>

<script setup lang="ts">
import EventIcon from '@/fsd/features/indicators/img/event-icon.vue';
import StrokeText from '@/fsd/shared/ui/text/StrokeText.vue';
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { eventErrorCount, hasEventsPoll, hasEventError, eventPullCount } = storeToRefs(useIndicators());

const eventIndicatorColor = computed<string>(() => {
  if (!hasEventsPoll.value) {
    return 'semantic-text-minor';
  }
  if (hasEventError.value) {
    return 'red-normal';
  }
  if (eventPullCount.value % 2 === 0) {
    return 'blue-normal';
  }
  return 'green-normal';
});
</script>
