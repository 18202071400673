<template>
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6478 6.49805C10.1907 3.31445 4.81963 3.31445 1.3626 6.49805C1.22588 6.63477 1.22588 6.83008 1.34307 6.94727L2.00713 7.61133C2.14385 7.72852 2.33916 7.74805 2.45635 7.63086C5.30791 5.01367 9.70244 5.01367 12.554 7.63086C12.6907 7.74805 12.8665 7.72852 13.0032 7.61133L13.6673 6.94727C13.7845 6.83008 13.7845 6.63477 13.6478 6.49805ZM7.51494 10.3457C6.81182 10.3457 6.26494 10.9121 6.26494 11.5957C6.26494 12.2988 6.81182 12.8457 7.51494 12.8457C8.19854 12.8457 8.76494 12.2988 8.76494 11.5957C8.76494 10.9121 8.19854 10.3457 7.51494 10.3457ZM11.4603 8.72461C9.21416 6.73242 5.79619 6.73242 3.5501 8.72461C3.41338 8.8418 3.41338 9.05664 3.53057 9.17383L4.21416 9.83789C4.33135 9.95508 4.50713 9.95508 4.64385 9.85742C6.28447 8.43164 8.72588 8.43164 10.3665 9.85742C10.5032 9.95508 10.679 9.95508 10.7962 9.83789L11.4798 9.17383C11.597 9.05664 11.597 8.8418 11.4603 8.72461Z"
      stroke="white"
      stroke-width="3"
    />
    <path
      d="M13.6478 6.49805C10.1907 3.31445 4.81963 3.31445 1.3626 6.49805C1.22588 6.63477 1.22588 6.83008 1.34307 6.94727L2.00713 7.61133C2.14385 7.72852 2.33916 7.74805 2.45635 7.63086C5.30791 5.01367 9.70244 5.01367 12.554 7.63086C12.6907 7.74805 12.8665 7.72852 13.0032 7.61133L13.6673 6.94727C13.7845 6.83008 13.7845 6.63477 13.6478 6.49805ZM7.51494 10.3457C6.81182 10.3457 6.26494 10.9121 6.26494 11.5957C6.26494 12.2988 6.81182 12.8457 7.51494 12.8457C8.19854 12.8457 8.76494 12.2988 8.76494 11.5957C8.76494 10.9121 8.19854 10.3457 7.51494 10.3457ZM11.4603 8.72461C9.21416 6.73242 5.79619 6.73242 3.5501 8.72461C3.41338 8.8418 3.41338 9.05664 3.53057 9.17383L4.21416 9.83789C4.33135 9.95508 4.50713 9.95508 4.64385 9.85742C6.28447 8.43164 8.72588 8.43164 10.3665 9.85742C10.5032 9.95508 10.679 9.95508 10.7962 9.83789L11.4798 9.17383C11.597 9.05664 11.597 8.8418 11.4603 8.72461Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
