<template>
  <div>
    <slot />
    <div class="controls-container">
      <div class="control">
        <div class="field" :class="{ 'error-animation': hasError, invalid: !valid, 'need-separator': type !== 'pin' }">
          <Typography data-test="keyboard field">
            {{ visibleCount }}
          </Typography>
          <span v-if="needShowAdditionalValue" class="field-placeholder">
            {{ additionalValue }}
          </span>
          <div v-if="!value" class="field-placeholder">{{ placeholder || $gettext('Введите кол-во') }}</div>
        </div>
        <UiButton
          v-if="type !== 'pin'"
          class="btn-toggle"
          is-icon
          data-test="toggle keyboard btn"
          @click="toggleKeyboard"
        >
          <img v-if="!isVisible" src="./img/show.svg" alt="" />
          <img v-if="isVisible" src="./img/hide.svg" alt="" />
        </UiButton>
      </div>
      <UiButton
        v-if="!value && type === 'temperature'"
        background-color="secondary"
        class="ml-2"
        style="width: auto"
        @click="onNoThermometerClick"
      >
        {{ $gettext('Нет термометра') }}
      </UiButton>
      <UiButton
        v-else
        is-icon
        class="ml-2"
        :is-disabled="!!$attrs.disabled || !valid"
        data-test="keyboard send btn"
        @click="onInputClick"
      >
        <img class="icon-check" src="@/assets/img/check.svg" alt="" />
      </UiButton>
    </div>
    <BaseKeyboard :is-visible="isVisible" :type="type" @key-click="onKeyClick" />
  </div>
</template>
<script lang="ts">
import requestProductCode from '@/mixins/requestProductCode';
import { AudioService } from '@/services/audio.service';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useProducts } from '@/store/modules/products';
import { defaultSourceFormat, getDefaultLocalFormat } from '@/temp/constants/dateFormat';
import BaseKeyboard from '@/ui/common/keyboard/base-keyboard.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getFormatDate } from '@/utils';
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  isVisible?: boolean;
  value: string;
  hasError: boolean;
}
// В режиме даты принимает и отдает дату в формате YYYY-MM-DD
// поля для валидации в формате YYYY-MM-DD

export default defineComponent({
  name: 'Keyboard',
  components: { Typography, UiButton, BaseKeyboard },
  mixins: [requestProductCode],
  props: {
    placeholder: {
      type: String,
      default: undefined,
    },
    type: {
      type: String as PropType<'pin' | 'number' | 'temperature' | 'date'>,
      default: 'number',
    },
    min: {
      type: [String, Number],
      default: undefined,
    },
    max: {
      type: [String, Number],
      default: undefined,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    initialValue: {
      type: [String, Number],
      default: '',
    },
    productId: {
      type: String,
      default: undefined,
    },
  },
  emits: ['change-visible', 'input'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: false,
      isVisible: this.isOpen || undefined,
      value: '',
      hasError: false,
    };
  },
  computed: {
    product(): any {
      if (this.productId) {
        return this.productsStore.productById(this.productId);
      }
      return undefined;
    },
    needShowAdditionalValue(): boolean {
      return Boolean(this.type === 'date' && this.value);
    },
    additionalValue(): string {
      if (this.type === 'date' && this.placeholder) {
        return this.placeholder.slice(this.visibleCount.length);
      }

      return '';
    },
    valid(): boolean {
      return this.validator(this.value);
    },
    visibleCount(): string {
      if (this.type === 'temperature' && this.value) {
        return this.value + '°C';
      }
      return this.value;
    },
    parsedValue(): number | string {
      switch (this.type) {
        case 'number':
          return +this.value;
        case 'pin':
          return this.value;
        case 'date':
          return this.parsedDate;
        default:
          return +this.value;
      }
    },
    parsedDate(): string {
      const inputDate = dayjs(this.value, getDefaultLocalFormat());
      return inputDate.format(defaultSourceFormat);
    },
  },
  mounted() {
    this.value = this.initialValue ? getFormatDate(this.initialValue.toString()) : '';
    if (this.productId) {
      this.uiStateNeedBarcodeRequest = true;
    }
  },
  methods: {
    async requestBarcode() {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      if (this.product?.barcode.includes(barcode)) {
        this.increase();
      } else {
        AudioService.playError();
      }
      return true;
    },
    increase() {
      this.value = (+this.value + 1).toString();
    },
    onKeyClick(key): void {
      if (key === 'D' && !this.value) {
        return;
      }
      if (key === 'D' && this.value) {
        this.value = this.value.slice(0, -1);
        return;
      }
      const newValue = this.value + key;
      const valid = this.inputValidator(newValue);
      if (valid) {
        this.value = newValue;
        this.normalizeValue();
      } else {
        this.setError();
      }
    },

    validator(value): boolean {
      switch (this.type) {
        case 'number':
        case 'temperature':
          return this.numberValidator(value);
        case 'pin':
          return this.pinValidator(value);
        case 'date':
          return this.dateValidator(value);
        default:
          return this.numberValidator(value);
      }
    },
    inputValidator(value): boolean {
      switch (this.type) {
        case 'number':
          return this.numberInputValidator(value);
        case 'temperature':
          return this.temperatureInputValidator(value);
        case 'pin':
          return this.pinInputValidator(value);
        case 'date':
          return this.dateInputValidator(value);
        default:
          return this.numberInputValidator(value);
      }
    },
    normalizeValue(): void {
      if (this.type === 'date') {
        this.normalizeDate();
        return;
      }
    },
    //number
    numberValidator(value) {
      const number = +value;
      return !isNaN(number) && (!this.max || number <= +this.max) && (!this.min || number >= +this.min);
    },
    numberInputValidator(value) {
      const number = +value;
      return !isNaN(number);
    },
    temperatureInputValidator(value) {
      if (value === '-') return true;
      const number = +value;
      return !isNaN(number);
    },
    //pin
    pinValidator(value): boolean {
      return value.length === 4 && value.match(/^\d+$/);
    },
    pinInputValidator(value): boolean {
      return value.length <= 4 && value.match(/^\d+$/);
    },
    //date
    dateValidator(value): boolean {
      const inputDate = dayjs(value, getDefaultLocalFormat());
      if (this.min) {
        const minDate = dayjs(this.min, defaultSourceFormat);
        const isAfterOrSame = minDate.isBefore(inputDate) || minDate.isSame(inputDate);
        if (!isAfterOrSame) return false;
      }
      if (this.max) {
        const maxDate = dayjs(this.max, defaultSourceFormat);
        const isBeforeOrSame = maxDate.isAfter(inputDate) || maxDate.isSame(inputDate);

        if (!isBeforeOrSame) return false;
      }
      return !!value.match(/^[0-3]?[0-9].[01]?[0-9].[0-9][0-9]$/);
    },
    dateInputValidator(value): boolean {
      const [day, month, year] = value.split('.').filter(p => p);
      const dayValid = day === undefined || (day >= 0 && day <= 31);
      const monthValid = month === undefined || (month >= 0 && month <= 12);
      const yearValid = year === undefined || (year >= 0 && year <= 99);
      return dayValid && monthValid && yearValid;
    },

    toggleKeyboard(): void {
      this.isVisible = !this.isVisible;
      this.$emit('change-visible', this.isVisible);
    },
    onInputClick(): void {
      this.$emit('input', { value: this.parsedValue });
    },
    onNoThermometerClick() {
      this.$emit('input', { value: null });
    },
    setError(): void {
      this.hasError = true;
      setTimeout(() => {
        if (this) {
          this.hasError = false;
        }
      }, 1000);
    },
    normalizeDate(): void {
      const parts = this.value.split('.').filter(p => p);
      const lastSymbol = this.value[this.value.length - 1];
      switch (true) {
        //Если последний введенный символ точка, то добавляем (если нужно) ведущий ноль для числа или месяца
        case lastSymbol === '.':
          parts[parts.length - 1] = parts[parts.length - 1].padStart(2, '0');
          this.value = parts.join('.') + '.';
          break;
        //Если последний ввел число или месяц и в нем 2 цифры, то добавляем точку
        case parts.length <= 2 && parts[parts.length - 1].length === 2:
          this.value = this.value + '.';
          break;
        //Если последний ввел число и оно больше или равно 4 , то есть сразу же ясно, что пользователь имеет в виду 04, то добавляем ведущий ноль и ставим точку
        // case parts.length === 1:
        //   if (+parts[0] >= 4) {
        //     parts[0] = parts[0].padStart(2, '0');
        //     this.value = parts.join('.') + '.';
        //   }
        //   break;
        //Если последний ввел месяц и он больше или равно 2 , то есть сразу же ясно, что пользователь имеет в виду 02, то добавляем ведущий ноль и ставим точку
        // case parts.length === 2:
        //   if (+parts[1] >= 2) {
        //     parts[1] = parts[1].padStart(2, '0');
        //     this.value = parts.join('.') + '.';
        //   }
        //   break;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.controls-container {
  display: flex;
  align-items: center;
  padding: 0 16px 12px;
  background: var(--main-bg);
}

.field {
  flex-grow: 1;
  font-size: 16px;
  color: var(--text-primary-color);
  display: flex;
  height: 24px;
  align-items: center;
  .need-separator {
    border-right: 1px solid #c4c2be;
  }
}
.field-placeholder {
  font-size: 16px;
  color: var(--text-secondary-color);
}

.btn-toggle {
  background: var(--card-bg) !important;
  border-color: var(--card-bg) !important;
  path {
    fill: var(--text-primary-color);
    stroke: var(--text-primary-color);
  }
}
.control {
  padding-left: 16px;
  display: flex;
  border-radius: 16px;
  background: var(--card-bg);
  align-items: center;
  flex-grow: 1;
  height: 52px;
}

.error-animation {
  animation: error-animation 1s linear;
}

.invalid {
  color: #d4412e;
}

@keyframes error-animation {
  0% {
    color: var(--text-primary-color);
  }
  50% {
    color: #d4412e;
  }
  100% {
    color: var(--text-primary-color);
  }
}
</style>
