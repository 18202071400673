// сохранить кусок.
// старт. нужно проверить есть ли что в локал сторадж. и с3 сторадж.

// промежуточный сейв в локал сторадж.

// промежуточный сейв в s3
// загрузка всего в s3

// очистка локал сторадж

// валидация данных из s3 и локал сторадж

// хранение текущего состояния.

// инит(
// валидатор (данные)=>булеан
// сейв в локал после каждого добавления (по умолчанию тру)
// сейв в с3 после каждого добавления (по умолчанию фолс)
// )

// сохрани :

import { api } from '@/fsd/data/api/api.service';

// способы сохранения файлов. сейчас есть 2: put_object_s3 (кандидат в depricated), upload
// КТК использует put_object_s3, прямо сейчас я не готов переносить сохранение результатов ктк на новый метод upload тк это требует вдумчивого ручного тестирования.
export enum MethodsEnum {
  put_object_s3 = 'put_object_s3',
  upload = 'upload',
}
interface Props<Data = any, Item = any> {
  defaultData?: Data;
  validator: (data: Data) => boolean;
  updateAdaptor?: (data: Data, item: Item) => any;
  autoSaveToLocalStorage?: boolean;
  autoSaveToS3?: boolean;
  s3Folder: string;
  s3FileName: string;
  localStorageKey?: string;
  method?: MethodsEnum;
}

export default class S3storageHelper<Data = any, Item = any> {
  s3Folder: string;
  s3FileName: string;
  localStorageKey: string;
  data?: Data;
  validator: Props['validator'];
  updateAdaptor: Required<Props>['updateAdaptor'];

  autoSaveToLocalStorage: boolean;
  autoSaveToS3: boolean;

  method: MethodsEnum;

  constructor({
    defaultData,
    validator,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateAdaptor = (data, item) => {},
    autoSaveToLocalStorage = true,
    autoSaveToS3 = false,
    s3Folder,
    s3FileName,
    localStorageKey = s3Folder,
    method = MethodsEnum.put_object_s3,
  }: Props<Data, Item>) {
    this.data = defaultData;
    this.validator = validator;
    this.updateAdaptor = updateAdaptor;
    this.autoSaveToLocalStorage = autoSaveToLocalStorage;
    this.autoSaveToS3 = autoSaveToS3;
    this.localStorageKey = localStorageKey;
    this.s3Folder = s3Folder;
    this.s3FileName = s3FileName;
    this.method = method;
  }

  async tryLoadData(tryLoadFromS3 = false): Promise<Data | undefined> {
    const dataInLocal = this.loadFromLocal();
    if (dataInLocal) {
      this.data = dataInLocal;
      return dataInLocal;
    }
    if (!tryLoadFromS3) return;
    try {
      const dataInS3 = await this.loadFromS3();
      if (dataInS3) {
        this.data = dataInS3;
        return dataInS3;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async updateModel(item: Item): Promise<void> {
    this.data = this.updateAdaptor(this.data, item);
    if (this.autoSaveToLocalStorage) {
      this.saveToLocal();
    }
    if (this.autoSaveToS3) {
      await this.saveToS3().catch(console.error);
    }
  }
  saveToLocal(data?: Data): void {
    const dataToStorage = JSON.stringify(data || this.data);
    localStorage.setItem(this.localStorageKey, dataToStorage);
  }
  saveToS3(data?: Data): Promise<any> {
    if (this.method === MethodsEnum.put_object_s3) {
      const dataToStorage = btoa(JSON.stringify(data || this.data));
      return api.files.put_object_s3({
        filename: this.s3FileName,
        external_id: this.s3Folder,
        b64_data: dataToStorage,
      });
    }

    const file = new File([JSON.stringify(this.data)], this.s3FileName);
    const external_id = `${this.s3Folder}/${this.s3FileName}`;
    return api.files.upload({
      external_id,
      data: { filename: this.s3FileName, file },
    });
  }

  loadFromLocal(): Data | undefined {
    const dataInLocalStorage = localStorage.getItem(this.localStorageKey);
    if (dataInLocalStorage) {
      const parsed = JSON.parse(dataInLocalStorage);
      if (!this.validator(parsed)) {
        this.clearLocal();
        return;
      }
      return parsed;
    }
  }
  async loadFromS3(): Promise<Data | undefined> {
    if (this.method === MethodsEnum.put_object_s3) {
      const key = `temporary_files/${this.s3Folder}/${this.s3FileName}`;
      const { data } = await api.files.get_object_s3(key);
      if (this.validator(data)) {
        return data;
      }
      return undefined;
    }
    if (this.method === MethodsEnum.upload) {
      const key = `${this.s3Folder}/${this.s3FileName}`;
      const { data } = await api.files.get_object_s3(key);
      if (this.validator(data)) {
        return data;
      }
      return undefined;
    }
  }

  clearLocal() {
    localStorage.removeItem(this.localStorageKey);
  }
}
