<template>
  <div class="notification-template notifications" :class="type" @click="$emit('open-details')">
    <div class="notification-title">{{ title }}</div>
    <div class="close" :class="{ open: type === 'error' }">
      <Typography color="white">
        {{ $gettext('Подробности') }}
      </Typography>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 7.5L14.5 12L10 16.5" stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </div>
  </div>
</template>
<script lang="ts">
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Typography },
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ['open-details', 'close'],
});
</script>

<style lang="scss" scoped>
.notification-template {
  display: flex;
  align-items: center;
}

.notifications {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  margin: 0 16px 8px;
  border-radius: 16px;
  background: #00ca50;
  color: #ffffff;
  font-size: 0.875rem;

  &.warn {
    background: #ffb648;
  }

  &.error {
    background: #e54d42;
  }

  &.success {
    background: #00ca50;
  }
}

.open {
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
