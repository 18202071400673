<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details result-weights row" />
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
  computed: {
    needShow(): boolean {
      return this.model.weights && this.step === 'weight';
    },
    label(): string {
      return this.$gettext('Общий вес');
    },
    value(): string {
      const weight = this.model.weights.reduce((acc, value) => acc + value, 0);

      const g = weight % 1000;
      const kg = (weight - g) / 1000;
      if (kg) {
        return this.$gettext('%{kg} кг %{g} г', { kg: String(kg), g: String(g) });
      }
      return this.$gettext('%{g} г', { g: String(g) });
    },
  },
});
</script>
