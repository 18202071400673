<template>
  <svg width="100" height="92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.52 7.77A2 2 0 0 1 9.52 5.866h73.92a2 2 0 0 1 1.99 1.802L93.844 92H3.444a2 2 0 0 1-1.928-2.53l2.38-8.661a2 2 0 0 0 .07-.433L7.521 7.77Z"
      fill="#EDECE8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.668 5.795a2 2 0 0 1 2-1.995h73.958a2 2 0 0 1 1.988 1.779l8.251 74.117 2.006 9.907A2 2 0 0 1 96.91 92H13.99a2 2 0 0 1-1.98-1.712l-1.522-10.446a2.007 2.007 0 0 1-.021-.294l.2-73.753Z"
      fill="#F5F4F2"
    />
    <path
      d="M44.25 61.015C47.409 59.125 51.116 58 54.721 58c3.605 0 6.75 1.125 9.775 3.015M65.25 32s0 0 0 0 0 0 0 0 0 0 0 0v.003l.006.017a.419.419 0 0 0 .069.107c.089.108.259.25.544.393.28.14.637.262 1.053.348.415.086.867.132 1.328.132.461 0 .913-.046 1.328-.132a4.355 4.355 0 0 0 1.053-.348c.285-.143.455-.285.544-.393a.419.419 0 0 0 .075-.124V32s0 0 0 0 0 0 0 0 0 0 0 0M33.25 32s0 0 0 0 0 0 0 0 0 0 0 0v.003l.006.017a.419.419 0 0 0 .069.107c.089.108.259.25.544.393.28.14.637.262 1.053.348.415.086.867.132 1.328.132.461 0 .913-.046 1.328-.132a4.355 4.355 0 0 0 1.053-.348c.285-.143.455-.285.544-.393a.419.419 0 0 0 .075-.124V32s0 0 0 0 0 0 0 0 0 0 0 0"
      stroke="#75736F"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle cx="14.75" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="20.15" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="25.55" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="30.95" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="36.35" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="41.75" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="47.15" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="52.55" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="57.95" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="63.35" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="68.75" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="74.15" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="79.55" cy="3.8" r="1.8" fill="#fff" />
    <circle cx="84.95" cy="3.8" r="1.8" fill="#fff" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
