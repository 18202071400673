<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_12414_11370)">
      <mask
        id="mask0_12414_11370"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="14"
        height="14"
      >
        <rect width="14" height="14" rx="3.03333" fill="#FF5226" />
      </mask>
      <g mask="url(#mask0_12414_11370)">
        <rect width="14" height="14" fill="#FF5226" />
        <path
          d="M6.08017 0.947144L-2.28516 14H1.29359L6.36174 6.10832L6.2516 6.05851L5.00081 11.0846L7.64892 11.5511L10.3708 8.13103L10.2233 8.05728L9.30386 11.9428L15.3473 10.7913L14.1643 8.84416L11.8169 9.38086L12.0621 9.66339L13.0304 5.60551L10.7749 4.0971L7.82035 7.81211L7.96784 7.88585L9.18127 3.0187L6.08017 0.947144Z"
          fill="#FFDD00"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_12414_11370">
        <rect width="14" height="14" rx="7" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
