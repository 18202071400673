<template>
  <Overlay>
    <div class="content-container">
      <div>
        <div class="controls-container">
          <div class="control px-4">
            <div class="grow text-base accent-warmGray-600 flex justify-between items-center h-14">
              {{ count }}
              <Body2 v-if="isTrueWeight" class="text-day-textMinor">{{ weightToView }}</Body2>
            </div>
          </div>
          <UiButton
            is-icon
            class="ml-2"
            :is-disabled="!!$attrs.disabled || !valid"
            data-test="keyboard send btn"
            @click="onInputClick"
          >
            <img class="icon-check" src="@/assets/img/check.svg" alt="" />
          </UiButton>
        </div>
        <div class="keyboard" data-test="keyboard open" :class="{ openKeyboard: true }">
          <div class="keyboard-row">
            <div class="keyboard-cell" data-test="keyboard key 1" @click="onKeyClick('1')">1</div>
            <div class="keyboard-cell" data-test="keyboard key 2" @click="onKeyClick('2')">2</div>
            <div class="keyboard-cell" data-test="keyboard key 3" @click="onKeyClick('3')">3</div>
            <div class="keyboard-cell" data-test="keyboard key *" @click="onKeyClick('*')">*</div>
          </div>
          <div class="keyboard-row">
            <div class="keyboard-cell" data-test="keyboard key 4" @click="onKeyClick('4')">4</div>
            <div class="keyboard-cell" data-test="keyboard key 5" @click="onKeyClick('5')">5</div>
            <div class="keyboard-cell" data-test="keyboard key 6" @click="onKeyClick('6')">6</div>
            <div class="keyboard-cell" data-test="keyboard key +" @click="onKeyClick('+')">+</div>
          </div>
          <div class="keyboard-row">
            <div class="keyboard-cell" data-test="keyboard key 7" @click="onKeyClick('7')">7</div>
            <div class="keyboard-cell" data-test="keyboard key 8" @click="onKeyClick('8')">8</div>
            <div class="keyboard-cell" data-test="keyboard key 9" @click="onKeyClick('9')">9</div>
            <div class="keyboard-cell" data-test="keyboard key =" @click="onKeyClick('=')">=</div>
          </div>
          <div class="keyboard-row">
            <div v-if="!isTrueWeight" class="keyboard-cell" />
            <div v-else class="keyboard-cell" data-test="keyboard key ." @click="onKeyClick('.')">.</div>
            <div class="keyboard-cell" data-test="keyboard key 0" @click="onKeyClick('0')">0</div>
            <div class="keyboard-cell" data-test="keyboard key D" @click="onKeyClick('D')">
              <img src="./img/delete.svg" alt="" />
            </div>
            <div class="keyboard-cell" data-test="keyboard key -" @click="onKeyClick('-')">-</div>
          </div>
        </div>
      </div>
    </div>
  </Overlay>
</template>
<script lang="ts">
import Overlay from '@/ui/common/overlay/overlay.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';
import { getWeightToView } from '../suggest-card/formatters/count-formatter';

interface Data {
  count: string;
  hasError: boolean;
}

export default defineComponent({
  components: { UiButton, Overlay, Body2 },
  props: {
    isTrueWeight: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
  data(): Data {
    return {
      count: '',
      hasError: false,
    };
  },
  computed: {
    valid(): boolean {
      // Весовой товар мы считаем в кг, а значения мин и макс у нас в граммах
      const count = this.isTrueWeight ? +this.calc() * 1000 : +this.calc();
      return this.min <= count && this.max >= count;
    },
    weightToView(): string {
      return getWeightToView(1000 * Number(this.calc()));
    },
  },
  methods: {
    onKeyClick(key: string) {
      const lastIsSymbol = new RegExp(/\D$/gm);
      const isLastNoDigit = Boolean(this.count.match(lastIsSymbol));

      const threeDigitsAfterDot = new RegExp(/\.\d{3}$/gm);
      const hasThreeDigitsAfterDot = Boolean(this.count.match(threeDigitsAfterDot));

      const floatPart = new RegExp(/\d+\.\d+$/gm);
      const isFloatPart = Boolean(this.count.match(floatPart));

      if (key.match(/[0-9]/) && hasThreeDigitsAfterDot) {
        return;
      }
      if (key !== 'D' && !key.match(/[0-9]/) && isLastNoDigit) {
        return;
      }
      if (key === '.' && (isFloatPart || this.count.length === 0)) {
        return;
      }
      if (key === 'D' && !this.count) {
        return;
      }
      if (key === 'D' && this.count) {
        this.count = this.count.slice(0, -1);
        return;
      }

      if (key === '=' && this.count) {
        this.count = this.calc();
        return;
      }
      const newValue = this.count + key;
      this.count = newValue;
    },
    calc(): string {
      const actions = {
        multiplication: {
          value: '*',
          label: 'multiplication',
          func: (a, b) => parseFloat(a) * parseFloat(b),
        },
        division: {
          value: '/',
          label: 'division',
          func: (a, b) => a / b,
        },
        addition: {
          value: '+',
          label: 'addintion',
          func: (a, b) => parseFloat(a) + parseFloat(b),
        },
        subtraction: {
          value: '-',
          label: 'subtraction',
          func: (a, b) => parseFloat(a) - parseFloat(b),
        },
      };

      function calcExpr(str) {
        let res;
        Object.keys(actions).map(function (type) {
          res = parseExpr(str, actions[type]);
          if (res) {
            str = str.replace(res.str, res.value.toString());
            str = calcExpr(str);
          }
        });
        return Number(str).toFixed(3);
      }

      function parseExpr(str, action) {
        const reg = new RegExp(`(\\d+\\.?\\d*)\\s*\\${action.value}\\s*(\\d+\\.?\\d*)`);
        const matching = str.match(reg);
        if (!matching) return false;
        const [expression, operandA, operandB] = matching;

        const result = {
          str: expression,
          value: '',
        };

        result.value = action.func(operandA, operandB);
        return result;
      }
      return calcExpr(this.count);
    },
    onInputClick() {
      this.count = this.calc();
      const count = this.count;
      this.count = '';
      this.$emit('close', count);
    },
  },
});
</script>

<style scoped>
.content-container {
  background: #fff;
  padding: 26px 8px 8px 8px;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  border-radius: 16px;
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
}

.controls-container {
  display: flex;
  align-items: center;
  padding: 0 16px 12px;
  background-color: #fff;
}

.keyboard {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: #faf9f7;
}

.keyboard-row {
  display: flex;
}

.keyboard-cell {
  flex: 1 1 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #21201f;
}

.control {
  display: flex;
  border-radius: 16px;
  background: #faf9f7;
  align-items: center;
  flex-grow: 1;
}
</style>
