import emitter from 'tiny-emitter/instance';

/**
 * Глобальная шина событий
 * Вызывая $emitter.emit(EVENT), это событие будет вызвано во всех обработчиках $emitter.on,
 * которые на него подписаны (где бы они не находились --> не нужно искать parent)
 */
export const Emitter = {
  install(app) {
    app.config.globalProperties.$emitter = {
      on: (...args) => emitter.on(...args),
      emit: (...args) => emitter.emit(...args),
    };
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $emitter: {
      on: typeof emitter.on;
      emit: typeof emitter.emit;
    };
  }
}
