<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.61362 1.04516C7.93164 -0.272864 10.0686 -0.272864 11.3866 1.04516L16.9551 6.61362C18.2731 7.93164 18.2731 10.0686 16.9551 11.3866L11.3866 16.9551C10.0686 18.2731 7.93164 18.2731 6.61362 16.9551L1.04515 11.3866C-0.272864 10.0686 -0.272864 7.93164 1.04516 6.61362L6.61362 1.04516ZM10.125 11.8126C10.125 12.4339 9.62136 12.9376 9.00004 12.9376C8.37872 12.9376 7.87504 12.4339 7.87504 11.8126C7.87504 11.1913 8.37872 10.6876 9.00004 10.6876C9.62136 10.6876 10.125 11.1913 10.125 11.8126ZM9.84381 5.62508C9.84381 5.15909 9.46605 4.78133 9.00006 4.78133C8.53407 4.78133 8.15631 5.15909 8.15631 5.62508V8.43758C8.15631 8.90357 8.53407 9.28133 9.00006 9.28133C9.46605 9.28133 9.84381 8.90357 9.84381 8.43758V5.62508Z"
      fill="#FFA60D"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
