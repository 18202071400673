<template>
  <div class="product">
    <div class="picture" @click="$emit('image-click')">
      <ImageWrapper :img-src="defect" />
    </div>

    <div class="info">
      <h4 class="title" />
      <Typography color="primary-text" type="h4">
        {{ title }}
      </Typography>
      <div class="card-row">
        <div class="card-cell">
          <Typography color="primary-text" type="text3">
            {{ $gettext('Количество') }}
          </Typography>
        </div>
        <div class="card-cell">
          <div class="mini-counter" @click="$emit('counter-click')">
            <template v-if="typeAccounting === 'true_weight'">
              <Typography class="ml-2" data-test="mini-counter value" color="primary-text" type="text2" is-bold>
                {{ getCountToView({ count: Number(defectCount), type_accounting: typeAccounting }) }}
              </Typography>
              <Chevron />
            </template>
            <template v-else>
              <div
                class="counter-btn"
                :class="{ disabled: initialValue <= 0 }"
                data-test="mini-counter minus btn"
                @click="dec"
              >
                –
              </div>
              <Typography data-test="mini-counter value" color="primary-text" type="text2" is-bold>
                {{ defectCount }}
              </Typography>
              <div class="counter-btn" :class="{ disabled: !valid }" data-test="mini-counter plus btn" @click="inc">
                +
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { TypeAccountingEnum } from '@/models/Product';
import Chevron from '@/temp/icons/chevron.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';
import { getCountToView } from '../suggest-card/formatters/count-formatter';

export default defineComponent({
  components: {
    Chevron,
    Typography,
    ImageWrapper,
  },
  props: {
    defect: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    typeAccounting: {
      type: String as PropType<TypeAccountingEnum>,
      default: TypeAccountingEnum.unit,
    },
    valid: {
      type: Boolean,
      required: true,
    },
    initialValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ['image-click', 'change', 'counter-click'],
  computed: {
    defectCount(): string {
      return this.initialValue.toString();
    },
  },
  methods: {
    getCountToView,
    inc() {
      if (this.valid) {
        this.$emit('change', this.initialValue + 1);
      }
    },
    dec() {
      if (this.initialValue) {
        this.$emit('change', this.initialValue - 1);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.product {
  background: var(--card-bg);
  display: flex;
  padding: 16px 16px 8px 0;
  overflow-x: hidden;
  transform: scale(1);
  transition: transform 0.3s ease 0s;
  border-radius: 20px;
  margin: 4px 0;
}

.picture {
  position: relative;
  box-sizing: border-box;
  margin: 0 16px;
  width: 52px;
  height: 52px;
}

.img {
  display: block;
  max-width: 4.5rem;
  height: 100%;
  margin-bottom: -50px;

  &.luminosity {
    mix-blend-mode: luminosity;
  }

  &.grayscale {
    filter: grayscale(100%);
  }
}

.info {
  flex: 1 1 auto;
}

.product:last-child .info {
  border-bottom: none;
}

.card-row {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.card-cell {
  display: flex;
  align-items: center;
}

.mini-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--counter-bg);
  border-radius: 10px;
  width: 115px;
  height: 40px;
}
.counter-btn {
  flex: 1 1 0;
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  color: var(--text-primary-color);
  &.disabled {
    color: var(--counter-btn-disabled);
  }
}
</style>
