import { useRumStore } from '@/store/modules/rum';
import { rumSpaManager } from '../tools/RumSpaManager';
import { RestartMultiMark, useMark } from './useMark';

type FinishMetric<M extends string> = (markName: string, deltaName: M) => void;
type StartMetric<M extends string> = (deltaName: M) => {
  uniqueMarkName: string;
  finish: () => ReturnType<FinishMetric<M>>;
};

type AddDeltaReduceMetric = (multiMarkName: string) => void;
type FinishReduceMetric<M extends string> = (multiMarkName: string, deltaName: M) => void;
type StartReduceMetric<M extends string> = (deltaName: M) => {
  multiMarkName: string;
  restart: () => ReturnType<RestartMultiMark>;
  addDelta: () => ReturnType<AddDeltaReduceMetric>;
  finishReduce: () => ReturnType<FinishReduceMetric<M>>;
};

export const useMetric = <M extends string>(pageName: string) => {
  const {
    checkPageExist,
    checkMarkExist,

    checkMultiMarkExist,
    getDeltaMultiMark,
    addDeltaToMultiMark,
  } = useRumStore();

  const {
    createMark,
    getDurationMark,

    createMultiMark,
    restartMultiMark,
    getDurationMultiMark,
    clearMultiMark,
  } = useMark();

  const startMetric: StartMetric<M> = deltaName => {
    const { uniqueMarkName } = createMark();
    return {
      uniqueMarkName,
      finish: () => finishMetric(uniqueMarkName, deltaName),
    };
  };

  const finishMetric: FinishMetric<M> = (markName, deltaName) => {
    if (checkMarkExist(markName) && checkPageExist(pageName)) {
      const duration = getDurationMark(markName);
      if (duration !== undefined) {
        rumSpaManager.sendDelta(pageName, deltaName, duration);
      }
    }
  };

  const startReduceMetric: StartReduceMetric<M> = deltaName => {
    const { uniqueMarkName: multiMarkName, restart } = createMultiMark();

    return {
      multiMarkName,
      restart,
      addDelta: () => addDeltaReduceMetric(multiMarkName),
      finishReduce: () => finishReduceMetric(multiMarkName, deltaName),
    };
  };

  const addDeltaReduceMetric: AddDeltaReduceMetric = multiMarkName => {
    if (checkMultiMarkExist(multiMarkName)) {
      const duration = getDurationMultiMark(multiMarkName);
      if (duration !== undefined) {
        addDeltaToMultiMark(multiMarkName, duration);
      }
    }
  };

  const finishReduceMetric: FinishReduceMetric<M> = (multiMarkName, deltaName) => {
    if (checkMultiMarkExist(multiMarkName) && checkPageExist(pageName)) {
      addDeltaReduceMetric(multiMarkName);
      const delta = getDeltaMultiMark(multiMarkName);
      rumSpaManager.sendDelta(pageName, deltaName, delta);
      clearMultiMark(multiMarkName);
    }
  };

  return {
    startMetric,
    finishMetric,

    startReduceMetric,
    restartMultiMark,
    addDeltaReduceMetric,
    finishReduceMetric,
  };
};
