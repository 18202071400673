<template>
  <div class="flex items-center">
    <div v-if="icons.includes('fragile')" data-test="fragile container" class="w-5 mb-1.5">
      <IconFragile height="100%" width="100%" color="purple-dark" />
    </div>
    <div v-if="icons.includes('heavy')" class="w-7 mb-1.5" data-test="heavy-order container">
      <IconHeavyOrder />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconType } from '@/fsd/entities/order/ui/order-card/types';
import IconFragile from '@/temp/icons/icon-fragile.vue';
import IconHeavyOrder from '@/temp/icons/icon-heavy-order.vue';
import { toRefs } from 'vue';

const props = defineProps<{ icons: IconType[] }>();
const { icons } = toRefs(props);
</script>
