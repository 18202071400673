<template>
  <svg :width="width + 'px'" height="16px">
    <text x="3px" y="13px" font-size="12px" font-weight="bold" stroke="white" stroke-width="3">
      {{ text }}
    </text>
    <text ref="textRef" x="3px" y="13px" font-size="12px" font-weight="bold" :fill="getColorFromToken(color)">
      {{ text }}
    </text>
  </svg>
</template>

<script setup lang="ts">
import { getColorFromToken } from '@/fsd/shared/tools/colorFromTailwindToken';
import { onMounted, onUpdated, ref } from 'vue';

const props = withDefaults(defineProps<{ text: string; color?: string }>(), { color: 'warmGray-600' });

const textRef = ref();
const width = ref(32);

const calcWidth = () => {
  if (!textRef.value) return;
  const textW = textRef.value.getBBox().width;
  width.value = Math.ceil(textW / 4 + 1) * 4;
};
onMounted(calcWidth);
onUpdated(calcWidth);
</script>
