<template>
  <StrokeText :color="fpsIndicatorColor" :text="String(fps)" />
</template>

<script setup lang="ts">
import StrokeText from '@/fsd/shared/ui/text/StrokeText.vue';
import { logger } from '@/temp/plugins/logs';
import { useFps } from '@vueuse/core';
import { computed, watch } from 'vue';

const fps = useFps({ every: 120 });

const fpsIndicatorColor = computed<string>(() => {
  if (fps.value >= 40) {
    return 'green-normal';
  }
  if (fps.value >= 20) {
    return 'yellow-dark';
  }
  return 'red-normal';
});

let sendedError = false;
watch(fps, val => {
  if (val <= 5 && !sendedError) {
    sendedError = true;
    logger.error('status/fps', {
      additional: {
        fps: val,
      },
    });
  }
  if (val > 20) {
    sendedError = false;
  }
});
</script>
