<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details writeoff-for row" />
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import Row from '@/ui/common/suggest-details/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    step: {
      type: String,
      required: true,
    },
  },
  computed: {
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    needShow(): boolean {
      if (this.isControl) {
        return this.step === 'count' && this.suggest.type === 'shelf2box';
      }
      return false;
    },
    label(): string {
      return this.$gettext('Списывать за');
    },
    value(): string {
      if (this.product?.write_off_before !== undefined && this.product.write_off_before !== null) {
        return this.product.write_off_before.toString();
      }
      return this.$gettext('Не задано');
    },
  },
});
</script>
