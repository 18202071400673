<template>
  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M231.909 41.6929H84.8867V45.0306H231.909"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M160.463 118.18H231.909"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M231.91 114.843H162.291"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.4199 41.6932L109.411 31.4307H231.91"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.3056 69.6781C55.0141 69.6781 63.6987 60.9974 63.6987 50.2851C63.5918 45.2138 61.5022 40.3843 57.8796 36.8348C54.2551 33.2853 49.3844 31.2969 44.3112 31.2969C39.2381 31.2969 34.3673 33.2853 30.7429 36.8348C27.1184 40.3843 25.0288 45.212 24.9238 50.2851C24.9163 60.9955 33.5971 69.6781 44.3056 69.6781Z"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.306 65.3079C47.2765 65.3079 50.1813 64.4271 52.6494 62.7779C55.1195 61.1268 57.0442 58.7824 58.1818 56.0387C59.3193 53.295 59.6173 50.274 59.0363 47.3617C58.4572 44.4475 57.0254 41.7713 54.9265 39.6704C52.8256 37.5696 50.1513 36.1397 47.2371 35.5606C44.3248 34.9815 41.3037 35.2776 38.5601 36.4152C35.8164 37.5527 33.4701 39.4774 31.819 41.9475C30.1679 44.4175 29.2871 47.3205 29.2871 50.2909C29.2871 54.2733 30.8688 58.0946 33.6856 60.9113C36.5042 63.7262 40.3236 65.3079 44.306 65.3079Z"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.3047 38.4922V50.2896L49.391 55.3758"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M232 188.499C230.418 188.617 228.822 188.677 227.214 188.677H25"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M232 192.641C230.418 192.759 228.822 192.819 227.214 192.819H25"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9121 193.58V224.521"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25 188.632V192.659"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M99.4629 44.9834V96.3333"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M231.959 80.0288H139.004"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M231.96 76.6553H133.383"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M231.96 154.992H185.482"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M231.96 151.619H182.672"
      stroke="#C9C9C9"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.934 107.99C113.839 107.99 124.3 116.114 124.3 129.445V132.814C124.3 133.236 124.208 133.652 124.03 134.034L121.747 135.391L124.103 136.401C124.233 136.735 124.3 137.091 124.3 137.451V141.531C124.3 141.932 124.229 142.329 124.088 142.706L122.113 144.467L123.355 145.403C123.951 145.85 124.3 146.553 124.3 147.297V150.078C124.3 150.813 124 151.514 123.47 152.022L121.798 153.62L123.185 154.679C123.89 155.217 124.302 156.053 124.302 156.939V159.413C124.302 160.326 123.871 161.186 123.138 161.733L121.798 162.734L122.934 163.422C123.783 163.935 124.302 164.857 124.302 165.849V167.639C124.302 168.771 123.845 169.854 123.033 170.645L121.798 171.848L122.741 172.419C123.71 173.006 124.302 174.055 124.302 175.187V175.315C124.302 182.378 118.159 188.156 110.647 188.156H91.3461C83.8366 188.156 77.6934 182.378 77.6934 175.315V175.187C77.6934 174.055 78.2856 173.006 79.2545 172.419L80.1971 171.848L78.9621 170.645C78.1506 169.856 77.6934 168.771 77.6934 167.639V165.849C77.6934 164.856 78.2125 163.935 79.0614 163.422L80.1971 162.734L78.8572 161.733C78.1244 161.186 77.6934 160.328 77.6934 159.413V156.939C77.6934 156.053 78.1057 155.217 78.8103 154.679L80.1971 153.62L78.5255 152.022C77.9951 151.514 77.6952 150.813 77.6952 150.078V147.297C77.6952 146.551 78.0457 145.85 78.6398 145.403L79.8823 144.467L77.907 142.706C77.7683 142.329 77.6952 141.932 77.6952 141.531V137.451C77.6952 137.093 77.7627 136.737 77.892 136.401L80.2477 135.391L77.9651 134.034C77.7871 133.652 77.6952 133.236 77.6952 132.814V129.445C77.6952 116.114 88.1564 107.99 101.061 107.99H100.934Z"
      fill="#59C3FF"
    />
    <path
      d="M100.98 185.559C107.717 185.559 113.178 184.92 113.178 184.133C113.178 183.345 107.717 182.707 100.98 182.707C94.2427 182.707 88.7812 183.345 88.7812 184.133C88.7812 184.92 94.2427 185.559 100.98 185.559Z"
      fill="#01ADFF"
    />
    <path
      d="M121.407 135.521C117.495 137.238 84.0692 137.185 80.2461 135.403"
      stroke="#01ADFF"
      stroke-width="1.68668"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M121.407 144.634C117.495 146.351 84.0692 146.298 80.2461 144.516"
      stroke="#01ADFF"
      stroke-width="1.68668"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M121.407 153.746C117.495 155.463 84.0692 155.411 80.2461 153.628"
      stroke="#01ADFF"
      stroke-width="1.68668"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M121.407 162.86C117.495 164.576 84.0692 164.524 80.2461 162.742"
      stroke="#01ADFF"
      stroke-width="1.68668"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M121.407 171.972C117.495 173.688 84.0692 173.636 80.2461 171.854"
      stroke="#01ADFF"
      stroke-width="1.68668"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.934 107.99C113.839 107.99 124.3 116.114 124.3 129.445V132.814C124.3 133.236 124.208 133.652 124.03 134.034L121.747 135.391L124.103 136.401C124.233 136.735 124.3 137.091 124.3 137.451V141.531C124.3 141.932 124.229 142.329 124.088 142.706L122.113 144.467L123.355 145.403C123.951 145.85 124.3 146.553 124.3 147.297V150.078C124.3 150.813 124 151.514 123.47 152.022L121.798 153.62L123.185 154.679C123.89 155.217 124.302 156.053 124.302 156.939V159.413C124.302 160.326 123.871 161.186 123.138 161.733L121.798 162.734L122.934 163.422C123.783 163.935 124.302 164.857 124.302 165.849V167.639C124.302 168.771 123.845 169.854 123.033 170.645L121.798 171.848L122.741 172.419C123.71 173.006 124.302 174.055 124.302 175.187V175.315C124.302 182.378 118.159 188.156 110.647 188.156H91.3461C83.8366 188.156 77.6934 182.378 77.6934 175.315V175.187C77.6934 174.055 78.2856 173.006 79.2545 172.419L80.1971 171.848L78.9621 170.645C78.1506 169.856 77.6934 168.771 77.6934 167.639V165.849C77.6934 164.856 78.2125 163.935 79.0614 163.422L80.1971 162.734L78.8572 161.733C78.1244 161.186 77.6934 160.328 77.6934 159.413V156.939C77.6934 156.053 78.1057 155.217 78.8103 154.679L80.1971 153.62L78.5255 152.022C77.9951 151.514 77.6952 150.813 77.6952 150.078V147.297C77.6952 146.551 78.0457 145.85 78.6398 145.403L79.8823 144.467L77.907 142.706C77.7683 142.329 77.6952 141.932 77.6952 141.531V137.451C77.6952 137.093 77.7627 136.737 77.892 136.401L80.2477 135.391L77.9651 134.034C77.7871 133.652 77.6952 133.236 77.6952 132.814V129.445C77.6952 116.114 88.1564 107.99 101.061 107.99H100.934Z"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M97.0024 108.858L83.7939 105.554C82.9655 105.346 82.2871 104.868 82.2871 104.489V102.85C82.2871 102.471 98.5073 108.547 98.5073 108.547C98.5073 108.925 97.8308 109.066 97.0024 108.858Z"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.906 104.064C107.906 103.304 104.724 102.687 100.798 102.687C96.8716 102.687 93.6895 103.304 93.6895 104.064V110.056C93.6895 110.817 107.906 110.817 107.906 110.056V104.064Z"
      fill="#01ADFF"
    />
    <path
      d="M107.962 101.969C107.962 101.202 93.6309 101.202 93.6309 101.969V108.009C93.6309 108.776 107.962 108.776 107.962 108.009V101.969Z"
      fill="white"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M142.156 139.318C143.774 137.519 160.101 135.075 163.613 132.065C167.842 128.439 167.959 124.582 160.151 126.171C152.734 127.683 142.156 139.318 142.156 139.318Z"
      fill="#A3E8FE"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M142.134 140.036C140.692 135.257 142.857 124.376 142.497 120.144C142.282 117.593 138.77 117.736 136.058 118.456C133.582 119.113 131.839 120.12 132.574 122.674C134.18 128.259 142.134 140.036 142.134 140.036Z"
      fill="#A3E8FE"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M141.803 139.533C141.803 139.533 140.45 129.154 139.453 121.844C139.131 119.477 135.34 120.783 135.752 122.513C136.164 124.241 141.803 139.533 141.803 139.533Z"
      fill="white"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M161.045 129.344L142.156 139.318"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.918 139.661C135.018 145.137 128.507 151.73 124.105 156.745C120.152 161.247 116.4 166.97 116.52 172.913C116.584 176.035 117.737 179.06 119.185 181.892C120.576 184.609 122.381 188.726 126.256 188.726C126.256 188.726 155.411 188.726 155.445 188.726C160.846 188.726 165.685 185.018 167.074 180.332C169.454 172.306 165.73 163.917 159.669 157.247C155.694 152.873 148.88 143.865 146.318 141.087C144.776 139.414 142.147 137.172 139.918 139.661Z"
      fill="#A3E8FE"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M161.966 179.451C161.966 179.451 162.755 187.328 150.082 188.614L161.966 179.451Z" fill="#A3E8FE" />
    <path
      d="M161.966 179.451C161.966 179.451 162.755 187.328 150.082 188.614"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M122.475 178.016C122.475 178.016 124.427 186.505 136.808 184.873L122.475 178.016Z" fill="#A3E8FE" />
    <path
      d="M122.475 178.016C122.475 178.016 124.427 186.505 136.808 184.873"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M155.104 159.501C157.377 160.954 159.146 163.049 160.102 165.422"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.865 143.654L131.236 153.671"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.079 148.578L135.998 156.346"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M147.504 145.907L153.34 154.177"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M141.73 138.551C141.879 138.56 150.421 157.886 150.421 157.886L141.73 138.551Z" fill="white" />
    <path
      d="M141.73 138.551C141.879 138.56 150.421 157.886 150.421 157.886"
      stroke="#001D45"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.185 81.9033C124.185 87.3513 120.011 91.7666 114.863 91.7666"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M133.527 91.7666C128.379 91.7666 124.205 87.3513 124.205 81.9033"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.185 101.639C124.185 96.1912 120.011 91.7759 114.863 91.7759"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M133.527 91.7759C128.379 91.7759 124.205 96.1912 124.205 101.639"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M87.3238 79.9395C87.3238 84.5984 83.7537 88.3766 79.3496 88.3766"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M95.3141 88.3746C90.91 88.3746 87.3398 84.5983 87.3398 79.9375"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M87.3238 96.819C87.3238 92.16 83.7537 88.3818 79.3496 88.3818"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M95.3141 88.3843C90.91 88.3843 87.3398 92.1606 87.3398 96.8214"
      stroke="#FF9040"
      stroke-width="1.68668"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M132.828 101.566H138.344" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M135.561 98.8345V104.35" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M167.828 140.082H176.211" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M171.979 135.928V144.311" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M68.7051 100.059H76.1827" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M72.4102 96.3545V103.83" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M70.0406 116.199L61.2324 113.763" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M70.0408 122.384L61.6074 122.946" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M146.129 110.577L150.439 102.893" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
    <path d="M150.439 113.201L156.062 107.953" stroke="#FF9040" stroke-width="1.68668" stroke-linecap="round" />
  </svg>
</template>
