<template>
  <div class="card-row" :class="{ vertical: mode === 'vertical', 'is-rtl': isRTL }" :data-test="dataTest">
    <div class="card-cell-label">
      <Typography color="primary-text" type="text1" data-test="suggest-details row label">
        {{ label }}
      </Typography>
    </div>
    <div class="card-cell">
      <Typography
        color="primary-text"
        type="text1"
        data-test="suggest-details row value"
        class="card-value"
        :is-bold="isBold"
        :class="{ vertical: mode === 'vertical' }"
      >
        {{ value }}
      </Typography>
    </div>
  </div>
</template>
<script lang="ts">
import { useUser } from '@/store/modules/user';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Typography },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '-',
    },
    mode: {
      type: String,
      default: 'horizontal',
    },
    dataTest: {
      type: String,
      default: '',
    },
    isBold: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
  },
});
</script>
<style lang="scss" scoped>
.card-row {
  display: flex;
  padding: 15px 0;
  &.vertical {
    flex-direction: column;
  }
}
.card-cell {
  flex: 1 1 0;
}
.card-cell-label {
  flex: 2 1 0;
}
.card-value {
  text-align: end;
  &.vertical {
    text-align: start;
    margin-top: 4px;
  }
}
</style>
