<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#FF3D2B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 28.75C24.8325 28.75 28.75 24.8325 28.75 20C28.75 15.1675 24.8325 11.25 20 11.25C15.1675 11.25 11.25 15.1675 11.25 20C11.25 24.8325 15.1675 28.75 20 28.75ZM18.1629 16.8371C17.7968 16.471 17.2032 16.471 16.8371 16.8371C16.471 17.2032 16.471 17.7968 16.8371 18.1629L18.6742 20L16.8371 21.8371C16.471 22.2032 16.471 22.7968 16.8371 23.1629C17.2032 23.529 17.7968 23.529 18.1629 23.1629L20 21.3258L21.8371 23.1629C22.2032 23.529 22.7968 23.529 23.1629 23.1629C23.529 22.7968 23.529 22.2032 23.1629 21.8371L21.3258 20L23.1629 18.1629C23.529 17.7968 23.529 17.2032 23.1629 16.8371C22.7968 16.471 22.2032 16.471 21.8371 16.8371L20 18.6742L18.1629 16.8371Z"
      fill="white"
    />
  </svg>
</template>
