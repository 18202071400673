<template>
  <div class="flex h-full">
    <LeftIcon :color="color" />
    <caption1 color="white" :class="`bg-${color}`" class="font-bold flex flex-auto items-center justify-center px-1">
      <slot />
    </caption1>
    <RightIcon :color="color" />
  </div>
</template>

<script lang="ts">
import LeftIcon from '@/temp/ui/badge/left-icon.vue';
import RightIcon from '@/temp/ui/badge/right-icon.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Badge',
  components: { RightIcon, LeftIcon },
  props: {
    color: {
      type: String,
      required: true,
    },
  },
});
</script>
