<template>
  <div>
    <div
      class="flex justify-between py-5 px-4"
      data-test="content-language row"
      @click="!isDisabled && settingContainer.show()"
    >
      <Body2 :color="isDisabled ? 'text-secondary' : undefined">
        {{ $gettext('Язык содержимого') }}
      </Body2>
      <div class="flex items-center">
        <Body2 class="mr-[10px] capitalize" color="text-secondary">
          {{ currentContentLanguage }}
        </Body2>
        <div v-if="!isDisabled">
          <IconChevron color="text-primary" />
        </div>
      </div>
    </div>
    <SettingContainer
      v-if="settingContainer.visible.value"
      data-test="content-language setting-container"
      :caption="$gettext('Язык содержимого')"
      @close="settingContainer.hide"
    >
      <CustomSelect
        class="capitalize"
        :options="selectOptions"
        :value="contentLanguage"
        @change="showChangeDataLangConfirm"
      />
    </SettingContainer>
  </div>
</template>

<script setup lang="ts">
import IconWarning from '@/fsd/shared/icons/warning/icon-warning.vue';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { getLanguageNameByCode } from '@/temp/constants/languages';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import CustomSelect from '@/ui/common/fields/select.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import SettingContainer from '@/ui/home/settings/options/setting-container.vue';
import { computed, markRaw } from 'vue';

const { showLoader } = useLoader();
const userStore = useUser();
const settingContainer = useComponent();

const contentLanguages = computed(() => {
  return userStore.options?.constants.company.data_languages;
});

const selectOptions = computed<{ name: string; value: string }[]>(() => {
  return (
    contentLanguages.value?.map(value => ({
      name: getLanguageNameByCode(value),
      value,
    })) || []
  );
});

const contentLanguage = computed(() => {
  return userStore.dataLanguage;
});

const currentContentLanguage = computed(() => {
  return getLanguageNameByCode(contentLanguage.value);
});

const isDisabled = computed(() => {
  return selectOptions.value?.length === 0;
});

const showChangeDataLangConfirm = (value: string) => {
  Notifications.universalModal({
    title: $gettext('Вы уверены, что хотите изменить язык содержимого?'),
    titleAlign: 'left',
    text: $gettext('Потребуется повторный вход в приложение'),
    textAlign: 'left',
    position: 'bottom',
    buttons: [
      {
        title: $gettext('Нет'),
        color: 'secondary',
        dataTest: 'confirm decline-btn',
        onClick: () => false,
      },
      {
        title: $gettext('Да'),
        color: 'primary',
        dataTest: 'confirm ok-btn',
        onClick: () => {
          changeDataLang(value);
        },
      },
    ],
    iconTop: {
      icon: markRaw(IconWarning),
      position: 'left',
      props: {
        bgColor: 'orange-normal',
      },
    },
  });
};

const changeDataLang = async (value: string): Promise<void> => {
  const { closeLoader } = showLoader($gettext('Сохраняем выбранный язык'));
  try {
    await userStore.setContentLanguage(value);
    Alerts.success($gettext('Язык сохранен'));
    userStore.logout();
  } catch (error) {
    Alerts.error($gettext('Произошла ошибка сохранения языка'));
  } finally {
    closeLoader();
  }
};
</script>
