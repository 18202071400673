import { useUser } from '@/store/modules/user';
import { permits } from '../constants';

// https://st.yandex-team.ru/LAVKAPROJECTS-2936
export const Checkin = {
  // true, если чекин для кладовщика неизбежен
  checkinRequired() {
    return Boolean(useUser().permitByName(permits.tsd_can_store_checkin) && !useUser().storeId);
  },
  // если true - спрашиваем кладовщика, находится ли он на том же складе, или уже умчал на другой
  checkinStoreConfirmRequired() {
    return Boolean(useUser().permitByName(permits.tsd_can_store_checkin) && useUser().storeId);
  },
};
