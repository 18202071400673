<template>
  <Overlay>
    <div class="overlay-card">
      <Layout>
        <template #header>
          <Bar :caption="$gettext('Связанные документы')" :icons="{ left: 'back' }" @close-click="$emit('close')" />
        </template>
        <Order v-for="order in ordersByItem" :key="order.order_id" :order-id="order.order_id" only-view />
        <template #footer>
          <LayoutFooter>
            <UiButton background-color="secondary" @click="$emit('close')">
              {{ $gettext('Назад') }}
            </UiButton>
          </LayoutFooter>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script lang="ts">
import Order from '@/fsd/entities/order/ui/order-card/order.vue';
import Item from '@/models/Item';
import OrderType from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LayoutFooter,
    Layout,
    Overlay,
    Bar,
    UiButton,
    Order,
  },
  props: {
    itemId: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  setup() {
    const ordersStore = useOrders();

    return { ordersStore };
  },
  computed: {
    ordersByItem(): OrderType[] {
      return this.ordersStore.ordersGetter.filter(o => {
        if (o.status !== 'request') return false;
        return o.required.find(r => {
          if (Item.isItemInRequired(r)) {
            return r.item_id === this.itemId;
          }
          return false;
        });
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.overlay-card {
  background: #ffffff;
  pointer-events: auto;
  height: 100vh;
  display: flex;
}
</style>
