<template>
  <svg
    class="primary-color-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18" cy="12" r="2" fill="#42413E" />
    <circle cx="12" cy="12" r="2" fill="#42413E" />
    <circle cx="6" cy="12" r="2" fill="#42413E" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
