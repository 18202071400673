import BaseOrder, { OrderBaseVars, OrderTypeEnum } from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';

interface Conditions {
  is_agree: boolean;
}

export interface SaleStowageOrderVars extends OrderBaseVars {
  addition_tag?: 'perishable' | 'parcel' | 'office';
}

export const isSaleStowageOrder = (order: BaseOrder): order is SaleStowageOrder => {
  return [OrderTypeEnum.sale_stowage].includes(order.type);
};

export default class SaleStowageOrder extends BaseOrder {
  public conditions: Conditions = { is_agree: true };
  public vars: SaleStowageOrderVars = {};

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
    this.vars = data.vars;
  }

  get isTrustStowage(): boolean {
    return this.conditions.is_agree;
  }
  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
