<template>
  <Layout>
    <div class="flex flex-col px-4 py-3 gap-3 h-full">
      <Title2 class="font-bold">
        {{ $gettext('Определяем тип доставки') }}
      </Title2>
      <Body2>
        {{ $gettext('Этот заказ на паузе, поэтому пока вы можете выполнять другие задания') }}
      </Body2>
      <Body2>
        {{ $gettext('Сообщим, когда определим тип доставки') }}
      </Body2>
      <div class="grow" />
      <IconRoverAntennaBig class="self-center" />
      <div class="grow" />
    </div>

    <template #footer>
      <LayoutFooter>
        <UiButton data-test="maybe_rover to-home-btn" @click="toHomePage">
          {{ $gettext('Хорошо') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import IconRoverAntennaBig from '@/fsd/shared/icons/rover/icon-rover-antenna-big.vue';
import BaseOrder from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps<{ order_id: BaseOrder['order_id'] }>();
const emit = defineEmits(['done']);

const order = computed<OrderOrder>(() => {
  return useOrders().orderById(props.order_id) as OrderOrder;
});

const toHomePage = (): Promise<any> => {
  return router.push({ name: 'home' });
};

watch(
  () => order.value.courier,
  v => {
    if (v) emit('done');
  },
);
</script>
