import iconRover from '@/fsd/shared/icons/rover/icon-rover.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { markRaw } from 'vue';

export const orderByRoverModal = () => {
  return Notifications.modal({
    title: $gettext('Этот заказ будет доставлен ровером'),
    text: $gettext(
      'После сборки заказа вам нужно будет положить товары в ровер, который будет ждать вас на улице рядом с лавкой',
    ),
    iconTop: {
      icon: markRaw(iconRover),
      position: 'center',
    },
    buttonText: $gettext('Все понятно'),
  });
};
