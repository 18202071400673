<template>
  <Overlay @backdrop-click="$emit('backdrop-click')">
    <OverlayCard data-test="overlay scan" :is-stretch="false">
      <template #title>
        <Typography type="h3" class="center">{{
          text || $gettext('Сканируй полку на которой лежит товар')
        }}</Typography>
      </template>

      <template #body>
        <div class="barcode mx-auto">
          <img src="./img/barcode.svg" alt="" />
        </div>
      </template>
    </OverlayCard>
  </Overlay>
</template>

<script lang="ts">
import OverlayCard from '@/ui/common/overlay-card.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    Typography,
    OverlayCard,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  emits: ['backdrop-click'],
});
</script>

<style scoped>
.barcode {
  padding-top: 1rem;
  text-align: center;
}
</style>
