<template>
  <div class="rounded p-3 bg-green-400">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.4421 12.4346C26.4723 12.3944 26.5051 12.356 26.543 12.323C26.7811 12.1155 27.0894 12 27.4098 12H30L24.6481 20.0278H35L34.6246 21.6828C34.4629 22.3956 33.8175 22.9028 33.0721 22.9028C32.7706 22.9028 32.5105 23.1102 32.4492 23.3994L30.2136 33.9632C29.9626 35.1494 28.8959 36 27.6593 36H12.3407C11.1041 36 10.0374 35.1494 9.78635 33.9632L7.55076 23.3994C7.48955 23.1102 7.22943 22.9028 6.92788 22.9028C6.1825 22.9028 5.53713 22.3956 5.37543 21.6828L5 20.0278H20.7312L26.4421 12.4346ZM11 24H15L15.5 33H12.9236C12.6791 33 12.4705 32.8233 12.4304 32.5822L11 24ZM18 24H22L21.5 33H18.5L18 24ZM29 24H25L24.5 33H27.0764C27.3209 33 27.5295 32.8233 27.5696 32.5822L29 24Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.5025 10.4766L21.5 11H23.0341C23.4397 11 23.6765 11.4575 23.4424 11.7886L19.9082 16.7875C19.709 17.0693 19.2909 17.0693 19.0917 16.7875L15.5575 11.7886C15.3234 11.4575 15.5602 11 15.9658 11H17.5L17.5863 10.1364C17.8399 7.6009 16.6406 5.13944 14.4875 3.7765L13.9385 3.42893C13.7417 3.30437 13.83 3 14.0629 3C18.1861 3 21.5229 6.35337 21.5025 10.4766Z"
        fill="white"
      />
    </svg>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
