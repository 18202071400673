<template>
  <Layout class="flex flex-col">
    <template #header>
      <Bar
        :caption="$gettext('Приёмка Маркета')"
        :text="$gettext('Сканирование QR-кода курьера')"
        :icons="{ left: 'back' }"
        :menu-config="menuConfig"
        @close-click="$router.back()"
      />
    </template>

    <template #default>
      <div class="flex flex-col justify-center items-center p-4 h-full">
        <span class="text-3xl font-bold text-center mb-16">
          {{ $gettext('Отсканируйте QR-код курьера') }}
        </span>
        <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnOrderStatus } from '@/fsd/data/utils/subscribeOnOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import OrderEvent from '@/models/events/OrderEvent';
import { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { isString } from '@/models/typeGuards';
import EventService from '@/services/event.service';
import { useItems } from '@/store/modules/items';
import { useUser } from '@/store/modules/user';
import { permits } from '@/temp/constants';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { BaseError } from '@/types/baseErrorResponse';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import { needUpgradeRoleModal } from '@/utils/modals';
import { AxiosError } from 'axios';
import uuidv1 from 'uuid/v1';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const userStore = useUser();
const router = useRouter();

const orderId = ref<string | undefined>(undefined);

const menuConfig = computed<MenuItemConfig[]>(() => {
  const menuConfig: MenuItemConfig[] = [];

  const createAcceptanceBtn: MenuItemConfig = {
    buttonText: $gettext('Создать приемку'),
    onClick: async () => {
      if (userStore.permitByName(permits.tsd_acceptance)) {
        router.push({ name: 'create-acceptance' });
      } else {
        const result = await needUpgradeRoleModal();
        if (result) {
          router.push({ name: 'settings-upgrade-role', params: { upgrade: 'true' } });
        } else {
          router.push({ name: 'home' });
        }
      }
    },
    dataTest: 'create-acceptance',
  };
  menuConfig.push(createAcceptanceBtn);
  return menuConfig;
});

useRequestBarcode(async barcode => {
  try {
    await createAcceptance(barcode);
    await router.push({ name: 'acceptance_market', params: { order_id: orderId.value } });
    return false;
  } catch (e: any) {
    if (e?.isAxiosError) {
      const response = (e as AxiosError<BaseError>)['response'];
      if (response?.data.code === 'ER_WRONG_COURIER_QR_CODE') {
        Notifications.modal({
          title: $gettext('Курьер не отсканировал посылки'),
          text:
            $gettext('Попросите водителя отсканировать все посылки и после показать QR-код') +
            '\nER_WRONG_COURIER_QR_CODE',
        });
        return true;
      }
      if (response?.data.code === 'ER_COURIER_QR_CODE_WRONG_ITEMS') {
        Notifications.modal({
          title: $gettext('Нельзя принять посылку'),
          text:
            $gettext('Посылка предназначена для другой Лавки, отдайте ее водителю') +
            '\nER_COURIER_QR_CODE_WRONG_ITEMS',
        });
        return true;
      }
      Notifications.modal({ title: response?.data.code!, text: response?.data.message });
    } else {
      Alerts.error($gettext('Отсканирован неверный баркод'));
    }

    return true;
  }
});

const tryLoadProblem = async (order_id: string): Promise<void> => {
  try {
    const { data } = await api.ordersAll([order_id]);
    const order = data.orders.find(o => o.order_id === order_id);
    if (!order) return;
    const problems = order.problems.filter(p => p.type === 'stock_found_for_item');
    const item_ids = problems.map(p => p.item_id).filter(isString);
    const items = await useItems().getItemsByIds(item_ids);
    if (!items) return;
    await Notifications.modal({
      title: $gettext('Данные посылки уже есть на складе.'),
      text: items.map(i => i.title).join('\n'),
    });
  } catch (e) {
    logger.error(e);
  }
};

const createAcceptance = async (courier_qr_code: string): Promise<void> => {
  const { closeLoader } = showLoader();
  try {
    const { data } = await api.order.acceptance_items({ courier_qr_code, external_id: uuidv1() });
    orderId.value = data.order_id;
    // ждем создания ордера
    await new Promise<void>((resolve, reject) => {
      const unSub = EventService.subscribe(['order', 'store', userStore.storeId], (code, events) => {
        if (!events) return;
        const orderEvents = events.filter(OrderEvent.isOrderEvent).filter(e => e.order_id === orderId.value);
        const hasFailed = orderEvents.find(o => o.status === 'failed');
        if (hasFailed) {
          unSub();
          reject();
          tryLoadProblem(data.order_id);
        }
        const hasProcessing = orderEvents.find(o => o.status === 'processing');
        if (hasProcessing) {
          unSub();
          resolve();
        }
      });
    });
    await useSubscribeOnOrderStatus(data.order_id)(s => {
      return s === OrderWorkStatusEnum.processing;
    });
  } catch (e) {
    console.error(e);
    throw e;
  } finally {
    closeLoader();
  }
};
</script>
