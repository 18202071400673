<template>
  <div class="relative flex justify-center">
    <IconItemBox v-if="isItem" :class="classes" />
    <img v-else-if="showImg" :class="classes" :src="imgSrc" @error="imgLoadError = true" />
    <img v-else :class="classes" src="@/assets/img/no-picture.svg" />
    <slot />
  </div>
</template>

<script lang="ts">
import { ThemeEnum, useUser } from '@/store/modules/user';
import IconItemBox from '@/temp/icons/icon-item-box.vue';
import { defineComponent } from 'vue';

interface Data {
  imgLoadError: boolean;
}

export default defineComponent({
  components: { IconItemBox },
  props: {
    imgSrc: {
      type: String,
      default: undefined,
    },
    isItem: {
      type: Boolean,
      default: false,
    },
    grayscale: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  data(): Data {
    return {
      imgLoadError: false,
    };
  },
  computed: {
    classes(): string[] {
      const result: string[] = [];
      result.push('object-contain');
      if (this.showImg && this.grayscale) {
        result.push('grayscale', 'mix-blend-luminosity');
      }
      if (this.userStore.theme === ThemeEnum.dark) {
        result.push('opacity-70');
      }
      return result;
    },
    theme(): string {
      return this.userStore.theme;
    },
    showImg(): boolean {
      return Boolean(this.imgSrc) && !this.imgLoadError;
    },
  },
});
</script>

<style lang="scss" scoped>
.icon {
  object-fit: contain;
  &.dark-theme {
    opacity: 0.72;
  }
}

.error-icon {
  object-fit: contain;
}
</style>
