<template>
  <div
    v-if="needScale"
    v-touch:hold="onLongTap"
    v-touch-class="'container-touched'"
    class="transition-transform duration-300 scale-100"
  >
    <slot />
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    needScale: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['long-press'],
  methods: {
    onLongTap(): void {
      if (this.needScale) {
        this.$emit('long-press');
      }
    },
  },
});
</script>

<style>
.container-touched {
  transition-duration: 1200ms !important;
  transform: scale(0.9) !important;
}
</style>
