<template>
  <LayoutFooter v-if="!hasActiveConfirmSignal" class="flex-col">
    <OrderTotalPrice
      class="mb-2"
      :total-price="totalPrice"
      :max-total-price="maxTotalPrice"
      :has-warn-over-price="false"
      :price-text="$gettext('Стоимость товара')"
    />
    <UiButton v-if="childrenSuggest.length" data-test="suggest-change btn-ready" @click="$emit('done')">
      {{ $gettext('Готово') }}
    </UiButton>
  </LayoutFooter>
</template>

<script setup lang="ts">
import { OrderTotalPrice } from '@/fsd/features/order';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';

interface RetailFooterDetailsProps {
  suggest: Suggest;
  order: OrderOrderRetail;
  childrenSuggest: Suggest[];
  collected: number;
}

const props = defineProps<RetailFooterDetailsProps>();

defineEmits<{ (e: 'done'): void }>();

const hasActiveConfirmSignal = computed<boolean>(() => {
  return (
    props.order.vars.confirming_assembled_products || props.order.vars.fulfilled_conditions?.confirm_assembled_products
  );
});

const maxTotalPrice = computed<number>(() => {
  if (props.suggest && props.suggest.count) {
    return props.suggest.count * +props.suggest.price;
  }
  return 0;
});

const totalPrice = computed<number>(() => {
  const countParentSuggest = props.suggest.result_count || props.collected;
  const priceChildren = props.childrenSuggest.reduce((acc, child) => {
    if (child.result_count) {
      return acc + child.result_count * +child.price;
    }
    return acc;
  }, 0);

  return priceChildren + countParentSuggest * +props.suggest.price;
});
</script>
