<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.17187 2.40625C2.74904 2.40625 2.40625 2.74904 2.40625 3.17188V5.08594H0.875L0.875 3.17188C0.875 1.90335 1.90335 0.875 3.17187 0.875H5.08594V2.40625H3.17187ZM3.17187 11.5937C2.74904 11.5937 2.40625 11.251 2.40625 10.8281V8.91406H0.875L0.875 10.8281C0.875 12.0967 1.90335 13.125 3.17187 13.125H5.08594V11.5937H3.17187ZM11.5938 3.17188C11.5938 2.74904 11.251 2.40625 10.8281 2.40625H8.91406V0.875H10.8281C12.0967 0.875 13.125 1.90335 13.125 3.17188V5.08594H11.5938V3.17188ZM10.8281 11.5937C11.251 11.5937 11.5938 11.251 11.5938 10.8281V8.91406H13.125V10.8281C13.125 12.0967 12.0967 13.125 10.8281 13.125H8.91406V11.5937H10.8281ZM10.9867 5.24451L9.90394 4.16174L6.23437 7.83132L4.47888 6.07581L3.39612 7.15856L6.23437 9.99681L10.9867 5.24451Z"
      :fill="isColor ? colorValue : 'currentColor'"
    />
  </svg>
</template>

<script>
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
