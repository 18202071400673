<template>
  <div class="flex flex-col">
    <ul class="flex flex-row px-4 pt-0 pb-3 border-b-2 border-solid border-stone-100 overflow-x-auto">
      <template v-for="tab in slots.default().filter(t => t.props)" :key="tab.props.tab_key">
        <Component :is="tab" :is-active="tab.props.tab_key === activeTabKey" @click="changeTab(tab.props.tab_key)" />
      </template>
    </ul>
  </div>
  <div class="py-3 px-4">
    <template v-for="slot in activeSlot" :key="slot">
      <Component :is="slot" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, defineSlots, ref, watch } from 'vue';
import type { TabProps, TabsProps } from './tabs-types';

const props = defineProps<TabsProps>();

const emits = defineEmits<{ (e: 'changeTab', key: string) }>();

const slots = defineSlots<{ default(): { props: TabProps; children: { default() } }[] }>();

const activeTabKey = ref<string>(props.activeKey || props.defaultActiveTabKey || slots.default()[0].props.tab_key);

const activeSlot = computed<any[]>(() =>
  slots
    .default()
    .find(i => activeTabKey.value === i.props?.tab_key)
    ?.children.default(),
);

const changeTab = (key: string) => {
  activeTabKey.value = props.activeKey || key;
  emits('changeTab', key);
};

watch(
  () => props.activeKey,
  key => {
    if (key) {
      activeTabKey.value = key;
    }
  },
);
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}
</style>
