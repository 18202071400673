<template>
  <div>
    <ScaleContainer
      data-test="control_check shelf-card"
      class="rounded-2xl bg-warmGray-75 flex flex-col p-4 items-start relative"
      :need-scale="needMenu"
      @long-press="openSuggestMenu"
    >
      <caption-1 class="font-medium mb-2" color="warmGray-500">
        {{ $gettext('Палета') }}
      </caption-1>
      <title-4 class="font-bold mb-2" style="z-index: 1">{{ title }}</title-4>

      <tag v-if="needTag" :class="{ 'mb-2': zoneTypeTag }" :type="tagType">
        {{ tagText }}
      </tag>
      <tag v-if="zoneTypeTag" type="info">
        {{ zoneTypeTag }}
      </tag>
      <div v-else class="h-5" />
      <div class="absolute right-0 bottom-0 z-0">
        <img src="@/assets/img/waiting.svg" alt="" />
      </div>
    </ScaleContainer>
    <Teleport to="#root">
      <SuggestMenu
        v-if="suggestMenu.visible.value"
        :menu-config="menuConfig"
        @close="() => suggestMenu.executeAndHide($emit, 'close-suggest-menu')"
    /></Teleport>
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import SuggestMenu from '@/ui/common/menu/suggest-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScaleContainer from '@/ui/common/scale-container/scale-container.vue';
import tag, { TagTypes } from '@/ui/common/tag/tag.vue';
import { ShelfStatusEnum } from '@/views/control_check/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { ScaleContainer, tag, SuggestMenu },
  props: {
    title: {
      type: String,
      required: true,
    },
    zoneTypeTag: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: ShelfStatusEnum['not-processed'],
    },
    needMenu: {
      type: Boolean,
      default: true,
    },
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      default: () => [],
    },
  },
  emits: ['open-suggest-menu', 'close-suggest-menu'],
  setup() {
    const suggestMenu = useComponent();

    return { suggestMenu };
  },
  computed: {
    needTag(): boolean {
      return Boolean(this.tagType);
    },
    tagType(): TagTypes | undefined {
      switch (this.status) {
        case ShelfStatusEnum['in-stock']:
        case ShelfStatusEnum['stock-scanned']:
          return 'success';
        case ShelfStatusEnum.missing:
        case ShelfStatusEnum['empty-shelf']:
          return 'warning';
        case ShelfStatusEnum['waiting-products-scan']:
          return 'info';
        default:
          return undefined;
      }
    },
    tagText(): string | undefined {
      switch (this.status) {
        case ShelfStatusEnum['in-stock']:
          return this.$gettext('В зоне проверки');
        case ShelfStatusEnum['stock-scanned']:
          return this.$gettext('Товары отсканированы');
        case ShelfStatusEnum.missing:
          return this.$gettext('Отсутствует');
        case ShelfStatusEnum['empty-shelf']:
          return this.$gettext('Товары отсутствуют');
        case ShelfStatusEnum['waiting-products-scan']:
          return this.$gettext('Ожидается сканирование');
        default:
          return undefined;
      }
    },
  },
  methods: {
    openSuggestMenu(): void {
      this.suggestMenu.show();
      this.$emit('open-suggest-menu');
    },
  },
});
</script>
