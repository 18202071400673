<template>
  <label class="flex flex-row items-center justify-between" :data-test="`checkbox ${fieldId}`">
    <input
      type="checkbox"
      class="hidden"
      :checked="checked"
      @input="event => $emit('update:checked', event.target.checked)"
    />
    <Body2 v-if="label" class="mr-2">
      {{ label }}
    </Body2>
    <div v-if="checked" class="w-8 h-8 bg-day-control rounded-lg flex items-center justify-center">
      <IconCheck />
    </div>
    <div v-else class="w-8 h-8 bg-day-controlMinor rounded-lg" />
  </label>
</template>

<script lang="ts">
import IconCheck from '@/temp/icons/icon-check.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Checkbox',
  components: { IconCheck, Body2 },
  props: {
    label: {
      type: String,
    },
    fieldId: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
    },
  },
  emits: ['update:checked'],
});
</script>
