import { useItems } from '@/store/modules/items';
import { useUser } from '@/store/modules/user';
import { permits } from '@/temp/constants';

import createRetryOnErrorFn from '@/fsd/shared/utils/retryRequest';
import Item from '@/models/Item';
import { createQueue, QueueLoadFn } from '@/services/queue/queue';
import axios, { AxiosError } from 'axios';

const loadFn: QueueLoadFn<Item> = async (ids, setCancel) => {
  // Если нет пермита, то не делаем бесполезных запросов
  if (!useUser().permitByName(permits.tsd_items)) {
    throw new Error('Отсутствует пермит для загрузки посылок');
  }
  const uniqIds = [...new Set(ids)];
  const { data } = await useItems().loadItems({
    ids: uniqIds,
    config: {
      cancelToken: new axios.CancelToken(setCancel),
    },
  });
  const idToItem = data.items.reduce((acc, i) => {
    acc[i.item_id] = i;
    return acc;
  }, {});
  return ids.map(id => idToItem[id] || null);
};
const itemCheckError = (e: AxiosError) => {
  // нет ответа, сетевая ошибка
  if (!e.response) return true;
  // Отвалилась авторизация, пользователя разлогинило, не хотим ретраить
  if (e.response.status === 401) return false;
  return true;
};
const withRetry = createRetryOnErrorFn(loadFn, {
  maxRetry: 3,
  maxDelay: 8,
  chechError: itemCheckError,
});

const cacheMap = {
  get(id: string) {
    const item = useItems().items.get(id) as Item;
    if (!item) return;
    const cacheValid = useItems().checkValidCache(id);
    if (cacheValid === false) return;
    return Promise.resolve(item);
  },
  set() {},
  clear() {},
  delete() {},
};

export default createQueue<Item>(withRetry, cacheMap);
