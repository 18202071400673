<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :caption="captionText"
        :text="controlBarText"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        @close-click="toHomePage"
      />
    </template>

    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">{{ $gettext('Отсканируйте товар') }}</Hint>
        <SuggestCardContainer
          doc-type="hand_move"
          :order="order"
          :suggest-menu-config="suggestMenuConfig"
          :suggests="
            sortedFilteredSuggests.map(s => {
              s.need_menu = s.status === 'request';
              return s;
            })
          "
          @open-suggest-menu="() => (uiStateNeedBarcodeRequest = false)"
          @close-suggest-menu="() => (uiStateNeedBarcodeRequest = true)"
        />
      </div>

      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :suggest-id="suggestDetails.props.value.suggest_id"
        :order-id="order.order_id"
        @cancel="suggestDetails.hide"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
      />

      <ScanShelf
        v-if="scanShelf.visible.value"
        :text="
          stage === 'take'
            ? $gettext('Отсканируйте полку с которой перемещаете товар')
            : $gettext('Отсканируйте полку на которую перемещаете товар')
        "
        @scanned="scanShelf.hide"
      />
      <CountWindow v-if="countWindow.visible.value" @finish="value => noProduct(value, countWindow.props.value)" />
    </template>

    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="uiStateFilter === 'all'" :menu-config="filterMenuConfig" />
        <UiButton
          v-if="stage === 'take'"
          data-test="hand_move footer next-stage-btn"
          :disabled="!allSuggestDone"
          @click="next_stage"
        >
          {{ $gettext('Перейти к размещению') }}
        </UiButton>
        <UiButton
          v-if="uiStateFilter === 'all' && stage === 'put'"
          data-test="hand_move footer finish-btn"
          :disabled="!allSuggestDone"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
        <UiButton v-if="uiStateFilter !== 'all'" @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import { getOrderTitle } from '@/fsd/entities/order/tools/getOrderTitle';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getBox2ShelfSuggests,
  getEmptyDoneSuggests,
  getFullDoneSuggests,
  getPartDoneSuggests,
  getRequestSuggests,
  getShelf2BoxSuggests,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useBox2Shelf } from '@/fsd/entities/suggest/tools/useBox2Shelf';
import { useShelf2Box } from '@/fsd/entities/suggest/tools/useShelf2Box';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import HandMoveOrder from '@/models/orders/HandMoveOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import Bar from '@/ui/common/bar/bar.vue';
import { getDefaultProgressConfig } from '@/ui/common/bar/getDefaultProgressConfig';
import CountWindow from '@/ui/common/count-window/count-window.vue';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
  uiStateFilter: 'all' | 'done' | 'request' | 'part' | 'noProduct';
}

export default defineComponent({
  name: 'HandMove',
  components: {
    PageLayout,
    ScanShelf,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    Hint,
    UiButton,
    SuggestDetails,
    FilterMenu,
    CountWindow,
  },
  mixins: [requestProductCode, requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();

    const ordersStore = useOrders();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const suggestDetails = useComponent<Suggest>();
    const countWindow = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      ordersStore,
      shelvesStore,
      productsStore,
      suggestDetails,
      countWindow,
      scanShelf,
      router,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
      uiStateFilter: 'all',
    };
  },
  computed: {
    order(): HandMoveOrder {
      return this.ordersStore.orderById(this.order_id) as HandMoveOrder;
    },
    suggests(): Suggest[] {
      if (this.order.target === 'canceled') {
        return this.order.suggests.filter(suggest => suggest.vars.target === 'canceled');
      }

      if (this.stage === 'take') {
        return getShelf2BoxSuggests(this.order.suggests);
      } else {
        return getBox2ShelfSuggests(this.order.suggests);
      }
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    fullCompletedSuggests(): Suggest[] {
      return getFullDoneSuggests(this.suggests);
    },
    partiallyCompletedSuggests(): Suggest[] {
      return getPartDoneSuggests(this.suggests);
    },
    noProductSuggests(): Suggest[] {
      return getEmptyDoneSuggests(this.suggests);
    },
    filteredSuggests(): Suggest[] {
      switch (this.uiStateFilter) {
        case 'all':
          return this.suggests;
        case 'done':
          return this.fullCompletedSuggests;
        case 'request':
          return this.requestSuggests;
        case 'part':
          return this.partiallyCompletedSuggests;
        case 'noProduct':
          return this.noProductSuggests;
      }
      return this.suggests;
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
    contractorName(): string {
      return this.order.attr.contractor || '';
    },
    controlBarText(): string {
      switch (this.uiStateFilter) {
        case 'all':
          return this.$gettext('Осталось %{request} из %{all} позиций', {
            request: String(this.requestSuggests.length),
            all: String(this.suggests.length),
          });
        case 'done':
        case 'request':
        case 'part':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} позиция с таким статусом',
            '%{filtered} позиций с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return this.contractorName;
    },
    suggestMenuConfig(): (suggest: Suggest) => MenuItemConfig[] {
      return suggest => {
        const menuConfig: MenuItemConfig[] = [];
        const noProductBtn: MenuItemConfig = {
          buttonText: this.$gettext('Недостаточное количество'),
          dataTest: 'suggest-menu no-product btn',
          onClick: () => {
            this.countWindow.asyncShow(suggest);
          },
          condition: () => suggest.type === 'shelf2box',
        };
        menuConfig.push(noProductBtn);

        return menuConfig;
      };
    },
    progressConfig(): any {
      if (!this.order) return;
      if (this.order.type === 'order') {
        if (this.order.target === 'canceled') {
          return [
            {
              count: getBox2ShelfSuggests(this.fullCompletedSuggests).length,
              color: 'green',
            },
          ];
        }
      }
      return getDefaultProgressConfig(this.order.suggests);
    },
    captionText(): string {
      if (this.order?.type === 'kitchen_provision') {
        return `${getOrderTitle(this.order_id).value} ${this.order.orderNumberForView}`;
      }
      switch (this.uiStateFilter) {
        case 'all':
          return `${this.$gettext('Руч. пер. №')} ${this.order.orderNumberForView}`;
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        case 'noProduct':
          return this.$gettext('Товары со статусом “Нет”');
      }
      return this.$gettext('Руч. пер.');
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      return [
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'gray',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
      ];
    },
    stage(): string {
      return this.order.vars?.stage || 'take';
    },
    sortedFilteredSuggests(): Suggest[] {
      return [...this.filteredSuggests].sort((a, b) => {
        switch (true) {
          case a.status === b.status:
            return 0;
          case a.status === 'request':
            return -1;
          case b.status === 'request':
            return 1;
          default:
            return 0;
        }
      });
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product, item } = await this.requestProductCode({ checkSuggests: true });
      if (product) {
        return await this.selectProduct(product);
      }
      if (item) {
        return await this.selectItem(item);
      }
      return true;
    },
    async selectProduct(product): Promise<boolean> {
      const productSuggests = this.order.suggests.filter(
        s =>
          s.product_id === product.product_id &&
          s.status === 'request' &&
          (s.type === (this.stage === 'take' ? 'shelf2box' : 'box2shelf') || this.order.target === 'canceled'),
      );
      if (productSuggests.length === 1) {
        await this.suggestDetails.asyncShow(productSuggests[0]);
        return true;
      }
      if (productSuggests.length === 0) {
        this.$alert.error(this.$gettext('Этот товар уже перемещен'));
        return true;
      }

      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = productSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (suggest) {
        await this.suggestDetails.asyncShow(suggest);
        return true;
      } else {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return this.selectProduct(product);
      }
    },
    async selectItem(item): Promise<boolean> {
      const itemSuggests = this.order.suggests.filter(
        s =>
          s.product_id === item.item_id &&
          s.status === 'request' &&
          s.type === (this.stage === 'take' ? 'shelf2box' : 'box2shelf'),
      );
      if (itemSuggests.length === 1) {
        await this.suggestDetails.asyncShow(itemSuggests[0]);
        return true;
      }
      if (itemSuggests.length === 0) {
        this.$alert.error(this.$gettext('Этот товар уже перемещен'));
        return true;
      }

      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const suggest = itemSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (suggest) {
        await this.suggestDetails.asyncShow(suggest);
        return true;
      } else {
        this.$alert.error(this.$gettext('Отсканирована неверная полка'));
        return this.selectItem(item);
      }
    },
    toHomePage(): void {
      this.router.push({ name: 'home' });
    },
    async noProduct(value: number, suggest: Suggest): Promise<void> {
      this.countWindow.hide();
      try {
        await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          status: 'error',
          reason: {
            code: 'PRODUCT_ABSENT',
            count: suggest.count! - value,
          },
        });

        this.uiStateNeedBarcodeRequest = true;
      } catch (error) {
        console.error(error);
      }
    },
    async finishActiveSuggest(
      { count = 0, shelf_id }: Pick<Model, 'count'> & { shelf_id: string },
      suggest: Suggest,
    ): Promise<void> {
      if (!suggest) return;
      const activeProduct = suggest.product;
      const getTitle = () => {
        if (activeProduct?.quants && activeProduct?.quants > 1 && activeProduct.quant_unit) {
          if (suggest.type === 'box2shelf') {
            return this.$ngettext(
              'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
              'Вы уверены, что разместили %{count} упаковок этого товара(%{unit_count} %{unit}) на полке %{shelf}?',
              count,
              {
                count: String(count),
                shelf: String(this.shelvesStore.shelfById(shelf_id)?.title),
                unit_count: String(activeProduct?.quants * count),
                unit: getQuantUnit(activeProduct?.quant_unit),
              },
            );
          } else {
            return this.$ngettext(
              'Вы уверены, что забрали %{count} упаковок этого товара(%{unit_count} %{unit}) с полки %{shelf}?',
              'Вы уверены, что забрали %{count} упаковок этого товара(%{unit_count} %{unit}) с полки %{shelf}?',
              count,
              {
                count: String(count),
                shelf: String(suggest.shelf?.title),
                unit_count: String(activeProduct?.quants * count),
                unit: getQuantUnit(activeProduct?.quant_unit),
              },
            );
          }
        }
        if (suggest.type === 'box2shelf') {
          return this.$ngettext(
            'Вы уверены, что разместили %{count} товар на полке %{shelf}?',
            'Вы уверены, что разместили %{count} товаров на полке %{shelf}?',
            suggest.count!,

            {
              count: String(suggest.count),
              shelf: String(suggest.shelf?.title),
            },
          );
        } else {
          return this.$ngettext(
            'Вы уверены, что забрали %{count} товар с полки %{shelf}?',
            'Вы уверены, что забрали %{count} товаров с полки %{shelf}?',
            count,
            {
              count: String(count),
              shelf: String(suggest.shelf?.title),
            },
          );
        }
      };
      const confirmed = await this.$notification.confirmBottom({
        title: getTitle(),
      });
      if (!confirmed) return;
      if (suggest.type === 'shelf2box') {
        const result = await useShelf2Box(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      } else {
        //если нужно выбрать другую полку
        if (suggest.shelf_id !== shelf_id) {
          const result = await useBox2Shelf(this.order_id, {
            suggest_id: suggest.suggest_id,
            status: 'error',
            reason: { code: 'LIKE_SHELF', shelf_id },
          });
          if (!result) return;
        }
        const result = await useBox2Shelf(this.order_id, {
          suggest_id: suggest.suggest_id,
          count,
        });
        if (!result) return;
      }
      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Вы уверены, что переместили все товары?'),
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    setFilter(filter): void {
      this.uiStateFilter = filter;
    },
    async next_stage(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Перейти к размещению собранных товаров?'),
      });
      if (!confirm) return;
      if (this.stage === 'put') {
        return;
      }
      const { closeLoader, updateLoader } = this.showLoader(undefined, this.order_id);
      try {
        await this.ordersStore.signal({
          order_id: this.order_id,
          signal: 'next_stage',
        });
        if (this.stage === 'put') {
          closeLoader();
          return;
        }
        this.uiStateNeedBarcodeRequest = false;
        updateLoader(this.$gettext('Ожидаем переход на стадию раскладки'), this.order_id);
        await useSubscribeOnOrder(this.order_id)(o => {
          if (!o) return true;
          return o.vars.stage === 'put';
        });
        this.uiStateNeedBarcodeRequest = true;
        closeLoader();
      } catch (error) {
        console.error(error);
        closeLoader();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-icon {
  margin-right: 16px;
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;

  & div {
    flex-grow: 1;
  }
}

.title-container {
  display: flex;
  align-items: center;
  padding: 0 16px;

  & p {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #302f2d;
    text-align: center;
  }
}
</style>
