<template>
  <div class="flex flex-row rounded-b-3xl mt-1 bg-white items-center">
    <IconPerson />
    <div class="flex flex-col flex-1 ml-4">
      <Caption1 color="day-textMinor" class="mb-2">
        {{ $gettext('Номер телефона клиента') }}
      </Caption1>
      <Body2>
        <a :href="`tel:${phone}`">{{ phone || $gettext('Загружаем номер телефона клиента') }}</a>
      </Body2>
    </div>
    <IconQr v-if="phone" class="cursor-pointer" @click="showModalWithQR" />
  </div>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import IconPerson from '@/temp/icons/icon-person.vue';
import IconQr from '@/temp/icons/icon-qr.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { getErrorText } from '@/utils';
import { defineProps, markRaw, onBeforeMount, ref } from 'vue';
import OrderRetailClientPhoneModal from './client-phone-modal.vue';

const { showLoader } = useLoader();

interface ClientPhoneProps {
  order: OrderOrderRetail;
}

const phone = ref<string>();

const props = defineProps<ClientPhoneProps>();

onBeforeMount(() => {
  getPhone();
});

const getPhone = async () => {
  const { closeLoader } = showLoader();
  try {
    const { data } = await api.order.get_client_phone({ order_id: props.order.order_id });
    phone.value = data.phone;
  } catch (e: any) {
    logger.error(e, { method: 'get_client_phone', type: 'api', source: 'client-phone.vue' });
    Alerts.error(getErrorText(e));
  } finally {
    closeLoader();
  }
};

const showModalWithQR = async () => {
  if (!phone.value) return;
  await Notifications.customComponentModal<typeof OrderRetailClientPhoneModal>({
    component: markRaw(OrderRetailClientPhoneModal),
    phone: phone.value,
  });
};
</script>
