<template>
  <Overlay>
    <div class="overlay-card">
      <Layout>
        <template #header>
          <Bar :caption="caption" :icons="{ left: 'back' }" :menu-config="[]" @close-click="$emit('close')" />
        </template>
        <slot />
        <template #footer>
          <div class="m-3 my-4">
            <UiButton background-color="secondary" @click="$emit('close')">
              {{ $gettext('Назад') }}
            </UiButton>
          </div>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script lang="ts">
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    Bar,
    Layout,
    UiButton,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  methods: {
    onMenuClick(): void {},
  },
});
</script>

<style lang="scss" scoped>
.overlay-card {
  background: var(--main-bg);
  pointer-events: auto;
  height: 100vh;
  display: flex;
}
</style>
