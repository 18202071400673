<template>
  <svg viewBox="0 0 320 524" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <rect width="320" height="524" fill="#302F2D" />
      <g opacity="0.92">
        <rect width="320" height="20" fill="#302F2D" />
        <g filter="url(#filter0_b)">
          <rect width="224" height="60" transform="translate(48 20)" fill="#302F2D" />
        </g>
        <rect width="48" height="60" transform="translate(0 20)" fill="#302F2D" />
        <rect width="48" height="60" transform="translate(272 20)" fill="#302F2D" />
        <path
          d="M0 0V-1H-1V0H0ZM320 0H321V-1H320V0ZM320 80V81H321V80H320ZM0 80H-1V81H0V80ZM0 1H320V-1H0V1ZM319 0V80H321V0H319ZM320 79H0V81H320V79ZM1 80V0H-1V80H1Z"
          fill="#302F2D"
        />
      </g>
      <rect x="239" y="163" width="156" height="156" rx="20" transform="rotate(-21.8056 239 163)" fill="#42413E" />
      <rect
        x="76"
        y="-0.0820312"
        width="102.374"
        height="102.374"
        rx="20"
        transform="rotate(117.909 76 -0.0820312)"
        fill="#42413E"
      />
      <rect
        x="32.7866"
        y="401"
        width="77.1833"
        height="77.1833"
        rx="20"
        transform="rotate(68.4474 32.7866 401)"
        fill="#42413E"
      />
    </g>
    <defs>
      <filter
        id="filter0_b"
        x="-8"
        y="-36"
        width="336"
        height="172"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="28" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
      </filter>
      <clipPath id="clip0">
        <rect width="320" height="524" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
