<template>
  <Layout>
    <template v-if="needHeader" #header>
      <Bar :caption="$gettext('Чат с поддержкой')" :icons="{ left: 'back' }" type="support-chat" @close-click="close" />
    </template>
    <Body2 v-if="!iframeLoaded">
      {{ $gettext('Загружаем чат с поддержкой') }}
    </Body2>
    <div v-if="iframeSrc" class="flex h-full relative px-4 py-2">
      <iframe :src="iframeSrc" class="flex-1" @load="onLoadIframe" />
    </div>
  </Layout>
</template>
<script lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { SupportTypeEnum, useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  iframeSrc?: string;
  iframeLoaded: boolean;
}

export default defineComponent({
  name: 'SupportChat',
  components: { Body2, Bar, Layout },
  setup() {
    const userStore = useUser();
    const router = useRouter();

    return { userStore, router };
  },
  data(): Data {
    return {
      iframeSrc: undefined,
      iframeLoaded: false,
    };
  },
  computed: {
    needHeader(): boolean {
      return !this.iframeLoaded || this.userStore.supportType !== SupportTypeEnum.sapsan;
    },
  },
  beforeUnmount() {
    //  если пользователь возвращается в заказ аппаратной кнопкой назад, то может не сброситься мета
    window.removeEventListener('message', this.onMessage);
  },
  mounted() {
    // если в мете есть флаг для автосоздания обращения, то логируем этот факт.
    if (this.userStore.chatMetaData.initial_message_autosend) {
      logger.error('open-support-chat', {
        additional: {
          meta: this.userStore.chatMetaData,
        },
      });
    }
    this.loadIframeSrc();
    window.addEventListener('message', this.onMessage);
  },
  methods: {
    async loadIframeSrc(): Promise<void> {
      try {
        const resp = await api.support.url();
        const url = new URL(resp.data.url);
        if (this.userStore.chatMetaData.initial_message_autosend) {
          url.pathname = url.pathname + '/chat';
        }

        for (let key in this.userStore.chatMetaData) {
          url.searchParams.append(key, this.userStore.chatMetaData[key]);
        }
        // Признак нужен поддержке, чтобы знать источник обращения
        url.searchParams.append('wms_source', 'tsd');
        this.userStore.setChatMetaData({});
        this.iframeSrc = url.toString();
      } catch (error) {
        logger.error(error, { type: 'API', block: 'support chat' });
      }
    },
    close() {
      this.router.push({ name: 'home' });
    },
    onMessage(event: MessageEvent) {
      if (event.data === 'backPressed') {
        this.close();
      }
    },
    onLoadIframe() {
      this.iframeLoaded = true;
      logger.event('loadSupportFrame');
    },
  },
});
</script>
