import { get } from 'lodash';
import config from '../../../../tailwind.config';

const colorsConfig = config.theme.extend.colors;

export const getColorFromToken = token => {
  const path = token.split('-');
  const color = get(colorsConfig, path);
  return color || colorsConfig.warmGray['600'];
};
