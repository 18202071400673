<template>
  <div class="rtl">
    <div class="text-secondary text-sm mb-0.5">{{ title }}</div>
    <div class="text-primary">{{ label }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ErrorRow',
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    label: {
      type: String as PropType<string>,
      default: '',
    },
  },
});
</script>
