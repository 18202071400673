<template>
  <button
    v-touch:hold="onLongTap"
    v-touch-class="'active-tap'"
    :disabled="btnDisabled"
    class="button"
    :class="{ [btnBgColor]: btnBgColor, 'icon-button': isIcon, 'is-hold': isHold }"
    :data-test="dataTest"
    @click.stop="onClick"
  >
    <template v-if="isIcon">
      <slot />
    </template>
    <template v-else>
      <div class="flex justify-center items-center gap-2">
        <slot name="icon" />
        <!-- z-index нужен чтобы текст в кнопке был поверх желтой заливки при зажатии        -->
        <Body2 color="primary" style="z-index: 2">
          <slot />
          <Body2 v-if="uiState.timer" class="block" color="primary">
            {{ timerLeftTitle }}
          </Body2>
        </Body2>
      </div>
    </template>
  </button>
</template>

<script lang="ts">
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent, PropType } from 'vue';

interface Data {
  uiState: {
    timer: number;
  };
}

export default defineComponent({
  components: { Body2 },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isIcon: {
      type: Boolean,
      default: false,
    },
    isHold: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 0,
    },
    backgroundColor: {
      type: String as PropType<'primary' | 'warning' | 'secondary' | 'backgroundInvert' | 'success'>,
      default: 'primary',
    },
    dataTest: {
      type: String,
      default: 'ui btn',
    },
  },
  emits: ['click'],
  data(): Data {
    return {
      uiState: {
        timer: 0,
      },
    };
  },
  computed: {
    timerLeftTitle(): string {
      if (this.uiState.timer) {
        return this.$gettext('Через %{time} сек', { time: String(this.uiState.timer) });
      }
      return '';
    },
    btnDisabled(): boolean {
      return Boolean(this.isDisabled || this.uiState.timer);
    },
    btnBgColor(): string {
      if (this.isHold) {
        return 'secondary';
      }
      return this.backgroundColor;
    },
  },
  mounted() {
    if (this.timeout) {
      this.uiState.timer = this.timeout;
      this.tick();
    }
  },
  methods: {
    tick(): void {
      if (this.uiState.timer) {
        setTimeout(() => {
          if (this.uiState && this.tick) {
            this.uiState.timer = this.uiState.timer - 1;
            this.tick();
          }
        }, 1000);
      }
    },
    onClick(): void {
      if (!this.isHold) {
        this.$emit('click');
      }
    },
    onLongTap(): void {
      if (this.isHold && !this.btnDisabled) {
        this.$emit('click');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
// WARN: Изменение этого класса может сломать компонент options
.button {
  border-radius: 16px;
  box-sizing: border-box;
  height: 52px;
  min-width: 52px;
  padding: 0 16px;
  width: 100%;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
  outline: none !important;
}

.button::before {
  position: absolute;
  content: '';
  background: var(--primary-btn-bg);
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  transition: 1.5s;
}

.button.active-tap.is-hold:enabled::before {
  left: 0;
}

.icon-button {
  align-items: center;
  display: flex;
  height: 52px;
  justify-content: center;
  line-height: 1.5rem;
  padding: 0;
  width: 52px;
}

.warning {
  background: var(--warning-btn-bg);
  border: 2px solid var(--warning-btn-border_color);
}

.success {
  background: var(--success-btn-bg);
  & p {
    color: var(--success-btn-text_color);
  }
  & span {
    color: var(--success-btn-text_color);
  }
  & path {
    fill: var(--success-btn-text_color);
  }
}

.primary {
  background: var(--primary-btn-bg);
  border: 2px solid var(--primary-btn-border_color);
  & p {
    color: var(--primary-btn-text_color);
  }
  & path {
    fill: var(--primary-btn-text_color);
  }
}

.secondary {
  background: var(--secondary-btn-bg);
  border: 2px solid var(--secondary-btn-border_color);
}

.backgroundInvert {
  @apply bg-day-backgroundInvert;

  p,
  span {
    @apply text-white;
  }
}

.button[disabled] {
  opacity: 0.6;
  background-color: var(--disabled-btn-bg);
  border-color: var(--disabled-btn-border_color);
  & p {
    color: var(--disabled-btn-text_color);
  }
  & path {
    fill: var(--disabled-btn-text_color);
  }
  &.backgroundInvert {
    @apply bg-day-backgroundInvert;

    p,
    span {
      @apply text-white;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 120% 0;
  }
  100% {
    background-position: -20% 0;
  }
}
</style>
