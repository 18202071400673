<template>
  <div ref="containerRef" class="flex flex-col">
    <Title4 class="font-bold mb-4 text-left" color="primary">
      {{ $gettext('Номер телефона клиента') }}
    </Title4>
    <Body2 color="day-textMinor">
      {{
        $gettext(
          'Вы можете отсканировать QR-код камерой своего телефона, чтобы сразу набрать номер телефона. Или введите его вручную.',
        )
      }}
    </Body2>

    <div class="mt-4 border-y border-day-line">
      <QrcodeVue :value="'tel:' + phone" :size="width" :margin="2" />
    </div>
    <div class="flex mt-3 pb-3 border-b border-day-line">
      <div class="flex flex-col flex-1">
        <Body2>
          <a :href="`tel:${phone}`">{{ phone }}</a>
        </Body2>
        <Caption1 color="day-textMinor">
          {{ $gettext('Номер телефона клиента') }}
        </Caption1>
      </div>
      <IconCopy @click="copy" />
    </div>
    <div class="flex mt-6">
      <UiButton class="mr-2" background-color="secondary" @click="$emit('btn-click')">
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconCopy from '@/temp/icons/icon-copy.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import QrcodeVue from 'qrcode.vue';
import { computed, defineEmits, defineProps, ref } from 'vue';

interface ClientPhoneModalProps {
  phone: string;
}

const containerRef = ref<HTMLDivElement>();

const props = defineProps<ClientPhoneModalProps>();

defineEmits<{ (e: 'btn-click'): void }>();

const width = computed<number>(() => {
  return containerRef.value?.clientWidth || 200;
});

const copy = () => {
  navigator.clipboard.writeText(props.phone);
};
</script>
