<template>
  <div
    class="flex mx-3 py-4 justify-between border-b-2 border-day-line last:border-b-0"
    :class="{ 'opacity-40': isDeleted }"
  >
    <div class="flex flex-col">
      <body-2 class="font-medium mb-px">
        {{ time }}
        <template v-if="isDeleted">
          {{ $gettext('Удалено') }}
        </template>
      </body-2>
      <caption-1 color="day-textMinor">{{ historyType }}</caption-1>
    </div>
    <div v-if="!isDeleted" class="cursor-pointer" @click="onDeleteClick">
      <Trash />
    </div>
  </div>
</template>

<script lang="ts">
import Trash from '@/temp/icons/trash.vue';
import { HistoryItem, HistoryStatusEnum, HistoryTypeEnum } from '@/views/control_check/types';
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Trash,
  },
  props: {
    historyItem: {
      type: Object as PropType<HistoryItem>,
      required: true,
    },
  },
  emits: ['delete'],
  computed: {
    time(): string {
      return dayjs(this.historyItem.time).format('HH:mm:ss');
    },
    isDeleted(): boolean {
      return this.historyItem.status === HistoryStatusEnum.deleted;
    },
    historyType(): string {
      const mapType = {
        [HistoryTypeEnum.input]: this.$gettext('Ручной ввод'),
        [HistoryTypeEnum.scan]: this.$gettext('Сканирование'),
      };
      if (this.historyItem.type === HistoryTypeEnum.scan) {
        return mapType[HistoryTypeEnum.scan];
      }
      return `${mapType[HistoryTypeEnum.input]} · ${this.$gettext('%{count} шт.', {
        count: String(this.historyItem.count),
      })}`;
    },
  },
  methods: {
    onDeleteClick(): void {
      this.$emit('delete');
    },
  },
});
</script>
