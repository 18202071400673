import { useUser } from '@/store/modules/user';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// формат даты, что прилетает с бека
// считать. что внутри приложения мы храним и получаем из компонентов ввода даты дату именно в таком формате!
export const defaultSourceFormat = 'YYYY-MM-DD';
export const defaultSourceFormatLong = 'YYYY-MM-DDTHH:mm:ssZZ';

export const debugFormat = 'DD.MM.YY HH:mm:ssZ';

export const timeFormat = 'HH:mm';

// формат даты, что показываем пользователю, и в котором забираем у него дату
export const defaultLocalFormats = {
  ru_RU: 'DD.MM.YY',
};

export const getDefaultLocalFormat = () => {
  const lang = useUser().language;
  return defaultLocalFormats[lang] || defaultLocalFormats.ru_RU;
};
