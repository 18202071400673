import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
export interface CheckProductOnShelfOrderVars extends OrderBaseVars {
  reserve?: boolean;
}

export default class CheckProductOnShelfOrder extends BaseOrder {
  public vars: CheckProductOnShelfOrderVars = {};

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }

  protected sorter(suggests: Suggest[]): Suggest[] {
    return suggests;
  }
}
