<template>
  <div>
    <div v-for="(label, name) in items" :key="label" class="h-12 last:h-auto">
      <input
        :id="name"
        type="radio"
        class="float-right hidden"
        :value="name"
        :checked="name === modelValue"
        :name="name"
        @change="updateValue(name)"
      />
      <label :for="name" :data-test="`radio-option-${name}`" class="block relative">
        <typography class="inline">
          {{ label }}
        </typography>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

interface Item {
  [key: string]: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
    items: {
      type: Object as PropType<Item>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
  },
});
</script>

<style lang="scss" scoped>
label:after,
label:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f1f0ed;
  border-radius: 50%;
}

input[type='radio']:checked + label:before {
  background-color: #fce45e;
}

input[type='radio']:checked + label:after {
  background-color: #21201f;
  transform: scale(0.4);
}
</style>
