<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.27374 15.1691L17.6195 6.82332C18.0101 6.43279 18.6432 6.43279 19.0337 6.82332L19.3873 7.17687C19.7778 7.5674 19.7778 8.20056 19.3873 8.59108L9.27374 18.7046L3.82332 13.2542C3.43279 12.8637 3.43279 12.2305 3.82332 11.84L4.17687 11.4865C4.5674 11.0959 5.20056 11.0959 5.59108 11.4865L9.27374 15.1691Z"
      :fill="colorValue"
    />
  </svg>
</template>
<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
