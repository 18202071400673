<template>
  <Caption2
    :color="color"
    class="font-medium uppercase px-1.5 py-1 rounded-lg"
    :class="`${backgroundColor}/${bgOpacity}`"
  >
    <slot />
  </Caption2>
</template>

<script setup lang="ts">
import Caption2 from '@/ui/common/typo/caption-2.vue';
import { computed } from 'vue';

const props = defineProps({
  bgColor: {
    type: String,
    default: 'white',
  },
  color: {
    type: String,
    default: 'black',
  },
  bgOpacity: {
    type: String,
    default: '100',
  },
});

const backgroundColor = computed(() => {
  return 'bg-' + props.bgColor;
});
</script>
