<template>
  <svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="56" height="56" rx="8" fill="#F5523A" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.4421 20.9346C34.4723 20.8944 34.5051 20.856 34.543 20.823C34.7811 20.6155 35.0894 20.5 35.4098 20.5H38L32.6481 28.5278H43L42.6246 30.1828C42.4629 30.8956 41.8175 31.4028 41.0721 31.4028C40.7706 31.4028 40.5105 31.6102 40.4492 31.8994L38.2136 42.4632C37.9626 43.6494 36.8959 44.5 35.6593 44.5H20.3407C19.1041 44.5 18.0374 43.6494 17.7864 42.4632L15.5508 31.8994C15.4895 31.6102 15.2294 31.4028 14.9279 31.4028C14.1825 31.4028 13.5371 30.8956 13.3754 30.1828L13 28.5278H28.7312L34.4421 20.9346ZM19 32.5H23L23.5 41.5H20.9236C20.6791 41.5 20.4705 41.3233 20.4304 41.0822L19 32.5ZM26 32.5H30L29.5 41.5H26.5L26 32.5ZM37 32.5H33L32.5 41.5H35.0764C35.3209 41.5 35.5295 41.3233 35.5696 41.0822L37 32.5Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.5155 15.3892L23.0227 15.2127L23.5475 13.771C23.6862 13.3899 23.3373 13.0109 22.946 13.1176L17.0399 14.7289C16.7069 14.8198 16.5639 15.2126 16.7606 15.4962L20.2492 20.527C20.4804 20.8602 20.9912 20.7942 21.13 20.413L21.6547 18.9714L22.4957 19.1856C24.965 19.8146 26.8679 21.7834 27.4122 24.2727L27.5511 24.9075C27.6008 25.135 27.917 25.1562 27.9967 24.9374C29.4069 21.0628 27.397 16.7803 23.5155 15.3892Z"
      fill="white"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
