<template>
  <svg width="14" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.646.146 10.5 1h3a.5.5 0 0 1 .5.5V3H0V1.5A.5.5 0 0 1 .5 1h3l.854-.854A.5.5 0 0 1 4.707 0h4.586a.5.5 0 0 1 .353.146ZM1 17.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V4H1v13.5ZM3 6h8v10H3V6Z"
      fill="#F5523A"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
