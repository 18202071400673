import { NetworkLog } from '@/devtools/sections/networkLog';
import { AxiosResponse } from 'axios';

export class RequestLog extends NetworkLog {
  public path: AxiosResponse['config']['url'];
  public duration: AxiosResponse['duration'];
  constructor(response: AxiosResponse) {
    super(response);

    this.path = response.config.url?.replace('/api/tsd', '');
    this.duration = response.duration;
  }
}
