<template>
  <span class="text">
    <slot />
  </span>
</template>

<script>
export default {};
</script>

<style>
.is-he-main .text {
  direction: rtl;
}
</style>
