<template>
  <svg width="90" height="73" viewBox="0 0 90 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 9.5H22.5V14H27V18.5H22.5V32H27V36.5H22.5V45.5H27V59H31.5V45.5H27V41H31.5H36V54.5H40.5V45.5H45H49.5V41H54V45.5H58.5H63V54.5H58.5H54V50H49.5V54.5H45V59H49.5V54.5H54V59H58.5H63V54.5H67.5V59H72V63.5H67.5H63H58.5H54H49.5H45H40.5H36H31.5H27H22.5H18V9.5ZM67.5 54.5V50H72V54.5H67.5ZM36 32V41H40.5V36.5H45V32H49.5H54V36.5H58.5H63V41H67.5V45.5H72V41H67.5V36.5H72V32H67.5V27.5H72V23H67.5H63V27.5H58.5V18.5H63V14H67.5V18.5H72V14H67.5V9.5H63V14H58.5V9.5H54V14H58.5V18.5H54V27.5H49.5V18.5H45V14H49.5V9.5H45V14H40.5V9.5H36V14H31.5V9.5H27V14H31.5V18.5H27V23H31.5V27.5H27V32H31.5H36ZM40.5 23H36V32H40.5V23ZM67.5 32H63V36.5H67.5V32Z"
      fill="#E3E3E0"
    />
    <rect x="1" y="35" width="88" height="4" rx="2" fill="#FF3D2B" stroke="white" stroke-width="2" />
  </svg>
</template>
