<template>
  <div class="h-full p-4 bg-main box-border">
    <div class="rounded-[20px] h-full w-full flex flex-col bg-card">
      <!--Шапка-->
      <div class="flex pt-5">
        <div class="flex flex-col flex-1 items-center">
          <Body3 class="mb-2" color="text-secondary">
            {{ $gettext('Раздел') }}
          </Body3>
          <Title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">{{
            suggest && suggest.vars.product_group ? suggest.vars.product_group.name : ' - '
          }}</Title4>
        </div>
        <div class="flex flex-col flex-1 items-center">
          <Body3 color="text-secondary">
            {{ $gettext('Количество') }}
          </Body3>

          <Body1
            :color="suggest && suggest.result_count ? 'lime-dark' : 'text-primary'"
            class="font-medium text-center"
            data-test="product count"
          >
            {{ count }}
          </Body1>

          <Body3 v-if="product?.isTrueWeight && suggest?.allowableError" class="mb-2" color="text-secondary">
            {{ $gettext('± %{g} г', { g: String(suggest.allowableError) }) }}
          </Body3>
        </div>
      </div>
      <!--Картинка-->
      <div class="relative flex flex-[2_1_0] m-8 justify-center h-48">
        <ImgFullScreen :img-src="product?.images[0]" :badges="suggest ? getImgBadge(suggest) : []" />
      </div>
      <!-- Баджики -->
      <div class="w-fit mx-6 my-3 flex flex-row gap-2">
        <FragileBadge v-if="product?.fragile" />
        <WeightBadge v-if="product?.isTrueWeight" :plu_code="product.plu_code" />
      </div>
      <!-- Параметры продукта -->
      <div class="flex flex-col justify-center items-start pb-6 px-6">
        <Body1 color="text-primary" class="font-bold text-center" data-test="product title">
          {{ product?.title }}
        </Body1>
        <div v-if="suggest" class="flex flex-row gap-1 mt-2">
          <Body2 v-if="product?.plu_code">
            {{ product?.plu_code }}
          </Body2>
          <Body2 v-if="product?.plu_code" color="day-textMinor">
            {{ $gettext('·') }}
          </Body2>
          <Body2 color="day-textMinor">
            <SuggestPricePerCount :suggest="suggest" />
          </Body2>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { FragileBadge, WeightBadge } from '@/fsd/entities/badges';
import { ImgFullScreen } from '@/fsd/shared/ui/imgFullScreen';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { getCountText, getCountTextFromAll } from '@/ui/common/suggest-card/formatters/count-formatter';
import Body1 from '@/ui/common/typo/body-1.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Body3 from '@/ui/common/typo/body-3.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import { computed, defineProps } from 'vue';
import { getImgBadge } from '../../tools/getImgBadge';
import SuggestPricePerCount from '../suggest-card-info/suggest-price-per-count.vue';

interface SuggestDetailsProps {
  suggest?: Suggest;
  product?: Product;
  collected: string[];
}

const props = defineProps<SuggestDetailsProps>();

const count = computed<string>(() => {
  if (props.suggest) return suggestCount(props.suggest);
  return productCount();
});

const suggestCount = (suggest: Suggest): string => {
  let result_count = suggest.result_count || props.collected.length;
  if (suggest.product?.isTrueWeight) {
    result_count = suggest.result_count || Product.weightFromBarcode(props.collected);
  }
  const count = suggest.count || 0;

  return getCountTextFromAll(!!props.suggest?.product?.isTrueWeight, result_count, count);
};

const productCount = (): string => {
  const count = props.product?.isTrueWeight ? Product.weightFromBarcode(props.collected) : props.collected.length;
  return getCountText(!!props.product?.isTrueWeight, count);
};
</script>
