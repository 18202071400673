import { api } from '@/fsd/data/api/api.service';
import { OrderDoneCheckMoreRequest } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { AxiosError } from 'axios';

export interface CheckMoreOptions {
  onValidateError?: (e: string) => void;
  beforeRequest?: () => void;
  afterRequest?: () => void;
  onRequestError?: (e: AxiosError, retry: () => Promise<any>) => void | Promise<boolean> | boolean;
  onEventWaitingError?: (e: string) => void;
  closeLoader?: () => void;
}

const checkMore = async (
  order_id: string,
  payload: OrderDoneCheckMoreRequest,
  options: CheckMoreOptions = {},
): Promise<boolean> => {
  const { suggest_id } = payload;
  if (!order_id || !suggest_id) {
    options.onValidateError?.('Suggest not found');
    return false;
  }
  try {
    options.beforeRequest?.();
    const response = await api.order.done.check_more(payload);
    if (response.data?.suggests) {
      await useOrders().updateSuggestsFromResponse(response.data.suggests);
    }
    options.afterRequest?.();
  } catch (e) {
    options.closeLoader?.();
    return options.onRequestError?.(e as AxiosError, () => checkMore(order_id, payload, options)) || false;
  }
  options.closeLoader?.();
  return true;
};

export default checkMore;
