import Product, { TypeAccountingEnum } from '@/models/Product';
import { $gettext } from '@/temp/plugins/gettext';

interface DataForView {
  count?: number | null;
  weight?: number | null;
  type_accounting?: Product['type_accounting'];
}

export const getWeightToView = (weight: number) => {
  const g = weight % 1000;
  const kg = (weight - g) / 1000;
  if (isNaN(weight)) {
    return $gettext('%{g} г', { g: '-' });
  }
  if (kg && g) {
    return $gettext('%{kg} кг %{g} г', { kg: String(kg), g: String(g) });
  }
  if (kg) {
    return $gettext('%{kg} кг', { kg: String(kg) });
  }
  return $gettext('%{g} г', { g: String(g) });
};

export const getCountToView = ({ count, weight, type_accounting }: DataForView) => {
  const hasWeight = Number.isInteger(weight);
  const hasCount = Number.isInteger(count);
  switch (true) {
    case Boolean(!type_accounting && weight):
    case type_accounting === TypeAccountingEnum.true_weight && hasWeight:
    case type_accounting === TypeAccountingEnum.weight && hasWeight && !hasCount:
      return getWeightToView(weight!);
    case type_accounting === TypeAccountingEnum.true_weight && hasCount:
      return getWeightToView(count!);
    case !type_accounting && hasCount:
    case type_accounting === TypeAccountingEnum.unit && hasCount:
    case type_accounting === TypeAccountingEnum.weight && hasCount && !hasWeight:
      return $gettext('%{count} шт.', { count: String(count) });

    case type_accounting === TypeAccountingEnum.weight && hasWeight && hasCount:
      return $gettext('%{count} шт. (%{weightPart})', {
        count: String(count),
        weightPart: getWeightToView(weight!),
      });

    default:
      return $gettext('%{count} шт.', { count: String(count) });
  }
};

export const getCountText = (isTrueWeight: boolean, count: number): string => {
  if (isTrueWeight) {
    return $gettext('%{count} кг', {
      count: count ? (count / 1000).toFixed(3) : String(count),
    });
  } else {
    return $gettext('%{count} шт.', { count: String(count) });
  }
};

export const getCountTextFromAll = (isTrueWeight: boolean, count: number, allCount: number): string => {
  if (isTrueWeight) {
    return $gettext('%{count} из %{all} кг', {
      count: count ? (count / 1000).toFixed(3) : String(count),
      all: (allCount / 1000).toFixed(3),
    });
  } else {
    return $gettext('%{count} из %{all} шт.', { count: String(count), all: String(allCount) });
  }
};
