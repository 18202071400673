import { BarcodeHandler } from '@/services/scanner/handlers/BarcodeHandler';
import { dialog } from '@/utils';

export class DialogScanner extends BarcodeHandler {
  mode: string;
  reject: () => any = () => {};
  constructor() {
    super();
    this.mode = 'dialog';
  }

  async scan(): Promise<any> {
    await super.scan();
    return new Promise((resolve, reject) => {
      this.reject = reject;
      dialog(resolve);
    });
  }

  async destroy() {
    await super.destroy();
    if (this.reject) {
      this.reject();
    }
  }
}
