<template>
  <WebViewUpdater v-if="webViewUpdater.visible.value" :new-version="uiState.newVersion" @close="checkForUpdateLater" />
</template>

<script lang="ts">
import WebViewUpdater from '@/components/updater/web-view-updater.vue';
import { api } from '@/fsd/data/api/api.service';
import { useComponent } from '@/hooks/useComponent';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';
import { defineComponent } from 'vue';

interface Data {
  uiState: {
    interval: number;
    newVersion: string;
    currentVersion: string;
  };
}

// Интервал проверки на наличие новой версии статики
const INTERVAL_TIMEOUT = 30_000;
// Через сколько еще раз запросить обновление, если пользователь отказался обновляться сейчас
const UPDATE_LATER_INTERVAL = 120_000;

export default defineComponent({
  components: {
    WebViewUpdater,
  },
  emits: ['backdrop-click'],
  setup() {
    const userStore = useUser();
    const webViewUpdater = useComponent();

    return { userStore, webViewUpdater };
  },
  data(): Data {
    return {
      uiState: {
        interval: -1,
        newVersion: '',
        currentVersion: '',
      },
    };
  },
  computed: {
    currentVersion(): string {
      // @ts-expect-error vue-cli
      return this.userStore.version;
    },
  },
  mounted() {
    this.uiState.interval = setInterval(() => {
      this.checkVersion();
    }, INTERVAL_TIMEOUT);
  },
  beforeUnmount() {
    if (this.uiState.interval) {
      clearInterval(this.uiState.interval);
    }
  },
  methods: {
    checkForUpdateLater(): void {
      if (this.uiState.interval) {
        clearInterval(this.uiState.interval);
      }
      this.uiState.interval = setInterval(() => {
        this.checkVersion();
      }, UPDATE_LATER_INTERVAL);

      this.webViewUpdater.hide();
      this.$emit('backdrop-click');
    },
    async setNewVersion(): Promise<void> {
      // Проверяем новую версию webView
      try {
        const { data } = await api.version();
        if (data.version !== this.currentVersion) {
          this.uiState.newVersion = data.version;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async checkVersion(): Promise<void> {
      if (this.webViewUpdater.visible.value) {
        return;
      }
      try {
        await this.setNewVersion();

        const isHomeOrLoginPage =
          window.location.pathname === `/` ||
          window.location.pathname === `/${this.currentVersion}/` ||
          window.location.pathname === `/${this.currentVersion}/login`;

        if (isHomeOrLoginPage && this.uiState.newVersion.length) {
          this.webViewUpdater.show();
        }
      } catch (error) {
        logger('Не удалось проверить версию.');
      }
    },
  },
});
</script>
