<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details weights row" />
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import Row from '@/ui/common/suggest-details/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
  },
  computed: {
    needShow(): boolean {
      return !!this.suggest.weight;
    },
    label(): string {
      return this.$gettext('Учетный вес');
    },
    value(): string {
      const weight = this.suggest.weight!;
      const g = weight % 1000;
      const kg = (weight - g) / 1000;
      if (kg) {
        return this.$gettext('%{kg} кг %{g} г', { kg: String(kg), g: String(g) });
      }
      return this.$gettext('%{g} г', { g: String(g) });
    },
  },
});
</script>
