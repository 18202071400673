<template>
  <div class="backdrop-blur-md blur-container fixed top-0 left-0 w-screen h-screen z-10" @click.self="$emit('click')">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Blur',
  emits: ['click'],
});
</script>

<style scoped>
.blur-container {
  background: rgba(0, 0, 0, 0.5);
}
</style>
