import { api } from '@/fsd/data/api/api.service';
import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import OrderEvent from '@/models/events/OrderEvent';
import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { subscribeOnOrder } from '@/utils/subscribeOnOrder';
import { AxiosError } from 'axios';

// инкапсулирует в себе логику по взятия докуента в работу
// Возвращает true/false, где true- успех, false - нейдача

export interface AckOptions {
  onValidateError?: (e: string) => void;
  beforeRequest?: () => void;
  afterRequest?: () => void;
  onRequestError?: (e: AxiosError, retry: () => Promise<any>) => void | Promise<boolean> | boolean;
  beforeEventWaiting?: () => void;
  afterEventWaiting?: () => void;
  afterDataLoadingWaiting?: () => void;
  onEventWaitingError?: (e: string) => void;
  closeLoader?: () => void;
}

const ackOrder = async (order_id?: BaseOrder['order_id'], options: AckOptions = {}): Promise<boolean> => {
  if (!order_id) {
    options.onValidateError?.('Order not found');
    return false;
  }
  const order = useOrders().orderById(order_id);
  const user_id = useUser().userId;

  if (order?.status === OrderWorkStatusEnum.processing) {
    return true;
  }
  if (!order || order.status !== OrderWorkStatusEnum.request) {
    options.onValidateError?.('Order not in request status');
    return false;
  }
  try {
    options.beforeRequest?.();
    await api.order.ack({ order_id });
    options.afterRequest?.();
  } catch (e) {
    options.closeLoader?.();
    // сетевая ошибка, возвращаем false
    return options.onRequestError?.(e as AxiosError, () => ackOrder(order_id, options)) || false;
  }
  try {
    options.beforeEventWaiting?.();
    await new Promise<void>((res, rej) => {
      const cb = (ev: OrderEvent, unSub) => {
        if (ev.status === 'request') return;
        if (ev.status === 'processing') {
          if (!ev.users.includes(user_id)) {
            unSub();
            return rej('Intercept order by other user');
          }
          if (ev.estatus === 'waiting') {
            unSub();
            res();
          }
        }
        if (ev.status === 'failed') {
          unSub();
          return rej('Ордер перешел в статус failed');
        }
      };
      subscribeOnOrder({ order_id, cb });
    });
    options.afterEventWaiting?.();
    await useSubscribeOnOrder(order_id)(o => {
      if (!o) {
        throw 'Intercept order by other user';
      }
      if (o.status === 'processing' && o.users.length > 0) {
        if (o.users.includes(useUser().userId)) {
          if (o.estatus === 'waiting') {
            return true;
          }
        } else {
          throw 'Intercept order by other user';
        }
      }
      return false;
    });
    options.afterDataLoadingWaiting?.();
  } catch (e) {
    options.onEventWaitingError?.(e as string);
    options.closeLoader?.();
    // ордер перехватили, возвращаем false;
    return false;
  }
  options.closeLoader?.();
  return true;
};

export default ackOrder;
