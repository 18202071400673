<template>
  <Blur v-if="needShowLoader" style="z-index: 50000">
    <div class="w-full h-full flex items-center justify-center">
      <div class="flex flex-col items-center bg-main flex-1 mx-4 p-2 pt-6 rounded-xl" data-test="loader container">
        <LoaderIndicator class="m-4" />
        <template v-if="!uiState.timeoutPanelVisible">
          <div v-if="messages.length" class="mb-2">
            <Body2 v-for="message in messages" :key="message" class="mb-2 text-center block">{{ message }}</Body2>
          </div>
          <template v-else>
            <body1 class="mb-2">
              {{ $gettext('Загрузка') }}
            </body1>
            <caption1 class="mb-2">
              {{ $gettext('Пожалуйста, подождите') }}
            </caption1>
          </template>
        </template>
        <template v-else>
          <body1 class="mb-2 text-center">
            {{ $gettext('Выполнение операции затянулось') }}
          </body1>
          <caption1 class="text-center">
            {{ $gettext('Вы можете вернуться на предыдущий экран и попробовать еще раз или подождать еще') }}
          </caption1>

          <div class="flex mt-4 self-stretch">
            <UiButton background-color="secondary" @click="createTimer">
              {{ $gettext('Подождать') }}
            </UiButton>
            <UiButton class="ml-2" @click="removeAllReasons">
              {{ $gettext('Вернуться') }}
            </UiButton>
          </div>
        </template>
      </div>
    </div>
  </Blur>
</template>

<script lang="ts">
import { isString } from '@/models/typeGuards';
import { ModeService } from '@/services/mode.service';
import orderQueue from '@/services/queue/order-queue';
import Blur from '@/temp/ui/blur/blur.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { LoaderEventsEnum } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { flatten, uniq } from 'lodash';
import { defineComponent } from 'vue';

interface Data {
  timerId?: number;
  reloadOrderTimerId?: number;
  uiState: {
    timeoutPanelVisible: boolean;
  };

  reasons: Map<string, Map<string, { message?: string; order_id?: string }>>;
}

// Таймаут на слииишком долгий лоудер, по истечении которого кладовщик может самостоятельно закрыть лоудер
const LOCKOUT_TIMEOUT = ModeService.isDevelopment() ? 3_000 : 30_000;
// Таймаут на перезапрос выполняемого ордера
const RELOAD_ORDER_TIMEOUT = 10_000;

export default defineComponent({
  components: {
    Blur,
    Body2,
    LoaderIndicator,
    UiButton,
  },
  data(): Data {
    return {
      timerId: undefined,
      reloadOrderTimerId: undefined,
      uiState: {
        timeoutPanelVisible: false,
      },
      reasons: new Map(),
    };
  },
  computed: {
    needShowLoader(): boolean {
      return this.reasons.size > 0;
    },
    messages(): string[] {
      return flatten(
        Array.from(this.reasons.values()).map(c => {
          return Array.from(c.values())
            .map(i => i.message)
            .filter(isString);
        }),
      );
    },
    ordersForReload(): string[] {
      return uniq(
        flatten(
          Array.from(this.reasons.values()).map(c => {
            return Array.from(c.values())
              .map(i => i.order_id)
              .filter(isString);
          }),
        ),
      );
    },
  },
  watch: {
    needShowLoader(value) {
      if (value) {
        this.createTimer();
        this.createTimerReloadOrder();
      } else {
        clearTimeout(this.timerId);
        clearTimeout(this.reloadOrderTimerId);
        this.timerId = undefined;
        this.reloadOrderTimerId = undefined;
        this.uiState.timeoutPanelVisible = false;
      }
    },
    reasons() {
      this.updateTimers();
    },
  },
  mounted() {
    this.$emitter.on(LoaderEventsEnum.SHOW_LOADER, this.showLoader);
    this.$emitter.on(LoaderEventsEnum.CLOSE_LOADER, this.closeLoader);
    this.$emitter.on(LoaderEventsEnum.UPDATE_LOADER, this.updateLoader);
  },
  methods: {
    createTimer() {
      this.uiState.timeoutPanelVisible = false;
      this.timerId = setTimeout(() => {
        this.showTimeoutPanel();
      }, LOCKOUT_TIMEOUT);
    },
    createTimerReloadOrder() {
      this.reloadOrderTimerId = setTimeout(() => {
        this.tryReloadOrder();
      }, RELOAD_ORDER_TIMEOUT);
    },
    updateTimers() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.createTimer();
      }
      if (this.reloadOrderTimerId) {
        clearTimeout(this.reloadOrderTimerId);
        this.createTimerReloadOrder();
      }
    },
    showTimeoutPanel() {
      this.timerId = undefined;
      this.uiState.timeoutPanelVisible = true;
    },
    tryReloadOrder() {
      if (this.ordersForReload.length === 0) return;
      // запасной путь для загрузки ордера, если лоадер долго висит. отключено для теста, чтобы было сразу видно ошибки
      ModeService.isProduction() && orderQueue.loadMany(this.ordersForReload);
      this.createTimerReloadOrder();
    },

    showLoader({
      component_id,
      loader_id,
      message,
      order_id,
    }: {
      component_id: string;
      loader_id: string;
      message: string;
      order_id?: string;
    }) {
      const componentReasons = this.reasons.get(component_id);
      if (componentReasons) {
        componentReasons.set(loader_id, { message, order_id });
      } else {
        this.reasons.set(component_id, new Map([[loader_id, { message, order_id }]]));
      }
    },
    closeLoader({ component_id, loader_id }: { component_id: string; loader_id: string }) {
      if (!loader_id) {
        this.removeComponentReasons(component_id);
        return;
      }
      const componentReasons = this.reasons.get(component_id);
      if (componentReasons) {
        componentReasons.delete(loader_id);
        if (componentReasons.size === 0) {
          this.reasons.delete(component_id);
        }
      }
    },
    removeComponentReasons(component_id: string) {
      this.reasons.delete(component_id);
    },
    removeAllReasons() {
      this.reasons.clear();
    },
    updateLoader({ component_id, loader_id, message, order_id }) {
      const componentReasons = this.reasons.get(component_id);
      if (!componentReasons) return;

      const loader = componentReasons.get(loader_id);
      if (!loader) return;
      loader.message = message;
      loader.order_id = order_id;
    },
  },
});
</script>
