<template>
  <SignalQualityIcon :color="signalQualityIndicatorColor" />
</template>

<script setup lang="ts">
import SignalQualityIcon from '@/fsd/features/indicators/img/signal-quality-icon.vue';
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { requestTime } = storeToRefs(useIndicators());

const signalQualityIndicatorColor = computed<string>(() => {
  if (requestTime.value >= 10) {
    return 'red-normal';
  }
  if (requestTime.value >= 3) {
    return 'yellow-dark';
  }
  return 'green-normal';
});
</script>
