<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details valid row" />
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { getValidTitle } from '@/temp/constants/translations/validTitles';
import { calculateControlDate } from '@/ui/common/suggest-details/fields/utils';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { getFormatDate } from '@/utils';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
    step: {
      type: String,
      required: true,
    },
    mode: {
      type: String as PropType<'productionDate' | 'expirationDate'>,
      default: 'expirationDate',
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const userStore = useUser();

    return { shelvesStore, userStore };
  },
  computed: {
    shelf(): Shelf {
      return this.shelvesStore.shelfById(this.suggest.shelf_id)!;
    },
    needShow(): boolean {
      //для посылок не нужно
      if (this.suggest.vars?.mode === 'item') return false;
      if (this.userStore.experimentByName('exp_short_need_valid') && this.product?.valid! <= 14) return false;
      if (this.isControl) {
        return this.step === 'count' && this.suggest.type === 'shelf2box';
      }
      return !!(
        this.suggest.conditions.valid ||
        ['acceptance'].includes(this.order.type) ||
        (['sale_stowage'].includes(this.order.type) && this.order.vars.stage === 'stowage')
      );
    },
    label(): string {
      return getValidTitle({ docType: this.order.type, mode: this.mode as any, suggest: this.suggest });
    },
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    value(): string {
      if (this.isControl) {
        return calculateControlDate({
          mode: this.mode,
          order: this.order,
          product: this.product!,
          shelf: this.shelf,
        });
      }
      let date: string;
      if (['acceptance', 'sale_stowage'].includes(this.order.type)) {
        date = this.model.date || this.suggest.result_valid || this.suggest.valid!;
      } else {
        date = this.suggest.conditions.valid!;
      }
      return getFormatDate(date, { mode: this.mode as any, product: this.product });
    },
  },
});
</script>
