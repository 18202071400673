import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';

export interface CheckMoreOrderVars extends OrderBaseVars {
  third_party_assistance?: boolean;
}

export default class CheckMoreOrder extends BaseOrder {
  public vars: CheckMoreOrderVars = {};

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }
}
