import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const productWriteoffReasons = gettextWrapper({
  TRASH_DAMAGE: $gettext('Товар/упаковка повреждены'),
  TRASH_TTL: $gettext('Истек срок годности'),
  TRASH_DECAYED: $gettext('Товар пришёл в нетоварный вид'),
  TRASH_ACCIDENT: $gettext('Поломка оборудования'),
  TRASH_ORDINANCE: $gettext('По приказу/распоряжению'),
  TRASH_MOL: $gettext('Списание на мол'),
});
