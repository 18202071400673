<template>
  <div v-if="needShow" class="rounded-2xl bg-yellow-400 py-2 text-center mt-3 mr-4">
    {{ $gettext('Нужно сканировать марку') }}
  </div>
</template>

<script lang="ts">
import Suggest from '@/models/Suggest';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
  },
  computed: {
    needShow(): boolean {
      if (!this.suggest) return false;
      return this.suggest.conditions?.need_true_mark;
    },
  },
});
</script>
