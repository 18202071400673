<template>
  <svg
    class="primary-color-icon"
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.54545 1.75006C5.95967 1.75006 6.29545 1.41427 6.29545 1.00006C6.29545 0.585848 5.95967 0.250061 5.54545 0.250061L5.54545 1.75006ZM6 17.75C6.41421 17.75 6.75 17.4142 6.75 17C6.75 16.5858 6.41421 16.25 6 16.25L6 17.75ZM4.63636 17L4.63636 16.25L4.63636 16.25L4.63636 17ZM1 3.00006L1.75 3.00006L1 3.00006ZM5.54545 0.250061L4.63636 0.250061L4.63636 1.75006L5.54545 1.75006L5.54545 0.250061ZM4.63636 0.250061L3 0.250061L3 1.75006L4.63636 1.75006L4.63636 0.250061ZM0.250001 3.00006L0.250001 15L1.75 15L1.75 3.00006L0.250001 3.00006ZM3 17.75L4.63636 17.75L4.63636 16.25L3 16.25L3 17.75ZM4.63636 17.75L6 17.75L6 16.25L4.63636 16.25L4.63636 17.75ZM0.250001 15C0.250001 16.5188 1.48122 17.75 3 17.75L3 16.25C2.30965 16.25 1.75 15.6904 1.75 15L0.250001 15ZM3 0.250061C1.48122 0.250061 0.250001 1.48128 0.250001 3.00006L1.75 3.00006C1.75 2.30971 2.30965 1.75006 3 1.75006L3 0.250061Z"
      fill="#21201F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 8.75C5 8.47386 5.22386 8.25 5.5 8.25H14.15L10.3036 4.40355C10.1083 4.20829 10.1083 3.89171 10.3036 3.69645L10.6464 3.35355C10.8417 3.15829 11.1583 3.15829 11.3536 3.35355L17 9L11.3536 14.6464C11.1583 14.8417 10.8417 14.8417 10.6464 14.6464L10.3036 14.3036C10.1083 14.1083 10.1083 13.7917 10.3036 13.5964L14.15 9.75H5.5C5.22386 9.75 5 9.52614 5 9.25V8.75Z"
      fill="#21201F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
