<template>
  <Overlay @backdrop-click="$emit('close')">
    <div class="share-window-container">
      <div class="share-window">
        <template v-if="!isLoading">
          <img class="share-img" src="@/assets/img/barcode.svg" />
          <Typography type="h2" align="center" margin="0 22px 8px">
            <template v-if="!hasErrorScan">
              {{ $gettext('Отсканируйте штрих-код другого кладовщика') }}
            </template>
            <template v-else> {{ $gettext('Отсканируйте штрих-код другого кладовщика еще раз') }} </template>
          </Typography>
          <Typography type="text1" align="center" margin="0 16px 20p">
            {{ $gettext('Чтобы разделить это задание с другим кладовщиком, отсканируйте его штрих-код') }}
          </Typography>
          <div class="btn-container">
            <UiButton class="mr-2" background-color="secondary" @click="$emit('close')">
              {{ $gettext('Отмена') }}
            </UiButton>
          </div>
        </template>
        <template v-else>
          <InlineLoader />
          <Typography type="text1" align="center" margin="0 16px 20p">
            {{ $gettext('Отправляем приглашение другому кладовщику') }}
          </Typography>
          <Typography type="text1" align="center" margin="0 16px 20p">
            {{ $gettext('Пожалуйста, подождите') }}
          </Typography>
        </template>
      </div>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import BaseOrder from '@/models/orders/BaseOrder';
import UserByBarcode from '@/models/UserByBarcode';
import { AudioService } from '@/services/audio.service';
import { $gettext } from '@/temp/plugins/gettext';
import InlineLoader from '@/ui/common/inline-loader.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits, defineProps, ref } from 'vue';

interface ShareOrderProps {
  order: BaseOrder;
}

const props = defineProps<ShareOrderProps>();

const emits = defineEmits<{ (e: 'close'): void }>();

const isLoading = ref<boolean>(false);
const hasErrorScan = ref<boolean>(false);

const joinToOrder = async (userId: string): Promise<void> => {
  try {
    await api.order.executer({ order_id: props.order.order_id, link: [userId] });
    Alerts.success($gettext('Приглашение отправлено'));
  } catch (error) {
    console.error(error);
  }
};

useRequestBarcode(async barcode => {
  try {
    isLoading.value = true;
    const { data } = await api.barcode({ barcode });
    const found = data.found[0];
    if (UserByBarcode.isUserByBarcode(found)) {
      await joinToOrder(found.user_id);
      emits('close');
      isLoading.value = false;
      return false;
    } else {
      AudioService.playError();
      isLoading.value = false;
      hasErrorScan.value = true;
    }
  } catch (error) {
    isLoading.value = false;
    hasErrorScan.value = true;
    console.error(error);
    AudioService.playError();
  }
});
</script>

<style lang="scss" scoped>
.share-window-container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
}

.share-window {
  margin: 0 16px;
  background: var(--main-bg);
  border-radius: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}
.btn-container {
  display: flex;
  width: 100%;
  padding: 8px;
}
.share-img {
  margin: 32px 0;
}
</style>
