import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';

const checkStatus = (s: Suggest, status?: SuggestStatusEnum | SuggestStatusEnum[]) => {
  if (!status) return true;
  if (Array.isArray(status)) {
    return status.includes(s.status);
  } else {
    return s.status === status;
  }
};
const checkType = (s: Suggest, type?: SuggestTypeEnum | SuggestTypeEnum[]) => {
  if (!type) return true;
  if (Array.isArray(type)) {
    return type.includes(s.type);
  } else {
    return s.type === type;
  }
};

export const getSuggestsByStatus = (suggests: Suggest[], status?: SuggestStatusEnum | SuggestStatusEnum[]) => {
  return suggests.filter(s => {
    return checkStatus(s, status);
  });
};
export const getSuggestsByType = (suggests: Suggest[], type?: SuggestTypeEnum | SuggestTypeEnum[]) => {
  return suggests.filter(s => {
    return checkType(s, type);
  });
};
export const getSuggestsByStatusAndType = (
  suggests: Suggest[],
  status?: SuggestStatusEnum | SuggestStatusEnum[],
  type?: SuggestTypeEnum | SuggestTypeEnum[],
) => {
  return suggests.filter(s => {
    return checkStatus(s, status) && checkType(s, type);
  });
};

export const getBox2ShelfSuggests = (suggests: Suggest[]) => {
  return getSuggestsByType(suggests, SuggestTypeEnum.box2shelf);
};
export const getShelf2BoxSuggests = (suggests: Suggest[]) => {
  return getSuggestsByType(suggests, SuggestTypeEnum.shelf2box);
};
export const getRequestSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.request);
};
export const getDoneSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.done);
};
export const getBlockedSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.blocked);
};
export const getUltimaItemSuggests = (suggests: Suggest[]) => {
  return suggests.filter(s => s.item?.isUltimaParcel);
};

export const getFullDoneSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.done).filter(
    s => s.count && s.result_count && s.result_count >= s.count,
  );
};
export const getPartDoneSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.done).filter(
    s => s.count && s.result_count && s.count > s.result_count,
  );
};

export const getEmptyDoneSuggests = (suggests: Suggest[]) => {
  return getSuggestsByStatus(suggests, SuggestStatusEnum.done).filter(s => s.count && s.result_count === 0);
};
