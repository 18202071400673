<template>
  <span style="font-size: 24px; line-height: 26px" :class="colorClass">
    <slot />
  </span>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
