import { UserLanguageEnum } from '@/models/User';
import { setLanguage } from '@/temp/plugins/gettext';
import dayjs from 'dayjs';

export const LangService = {
  getLang(): UserLanguageEnum {
    return (localStorage.getItem('lang') as UserLanguageEnum) || UserLanguageEnum.ru_RU;
  },
  setLang(lang: UserLanguageEnum) {
    localStorage.setItem('lang', lang);
    dayjs.locale(lang);
    setLanguage(lang);
  },
};
