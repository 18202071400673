import Product from '@/models/Product';

/**
 * Получить количество, продуктов, которые еще не образуют полный квант.
 * @constructor
 * @param {Product} product - Необходимый продукт.
 * @param {number} count - Текущее количество.
 */
export const getProductTail = (product: Product, count: number | undefined | null): number => {
  if (!count) return 0;
  return count % product.quants;
};
