export const getItem = (groupName: string, itemName: string) => {
  const group = localStorage.getItem(groupName);
  if (!group) {
    return undefined;
  }
  try {
    const parsedGroup = JSON.parse(group);
    if (parsedGroup && parsedGroup[itemName]) {
      return parsedGroup[itemName];
    }
    return undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const setItem = (groupName: string, itemName: string, itemValue: any) => {
  const group = localStorage.getItem(groupName);
  if (!group) {
    const newGroup = { [itemName]: itemValue };
    localStorage.setItem(groupName, JSON.stringify(newGroup));
    return;
  }
  try {
    const parsedGroup = JSON.parse(group);
    if (parsedGroup) {
      parsedGroup[itemName] = itemValue;
      localStorage.setItem(groupName, JSON.stringify(parsedGroup));
      return;
    }
    const newGroup = { itemName: itemValue };
    localStorage.setItem(groupName, JSON.stringify(newGroup));
    return;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const deleteItem = (groupName: string, itemName: string) => {
  const group = localStorage.getItem(groupName);
  if (!group) {
    return;
  }
  try {
    const parsedGroup = JSON.parse(group);
    if (parsedGroup) {
      delete parsedGroup[itemName];
      localStorage.setItem(groupName, JSON.stringify(parsedGroup));
      return;
    }
    return;
  } catch (e) {
    console.error(e);
    return;
  }
};
