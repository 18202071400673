<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="black" fill-opacity="0.08" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.1 11C23.4925 11 23 11.4925 23 12.1V15.9C23 16.5075 23.4925 17 24.1 17H27.9C28.5075 17 29 16.5075 29 15.9V12.1C29 11.4925 28.5075 11 27.9 11H24.1ZM25 15V13H27V15H25ZM12.1 23C11.4925 23 11 23.4925 11 24.1V27.9C11 28.5075 11.4925 29 12.1 29H15.9C16.5075 29 17 28.5075 17 27.9V24.1C17 23.4925 16.5075 23 15.9 23H12.1ZM13 27V25H15V27H13ZM11 12.1C11 11.4925 11.4925 11 12.1 11H15.9C16.5075 11 17 11.4925 17 12.1V15.9C17 16.5075 16.5075 17 15.9 17H12.1C11.4925 17 11 16.5075 11 15.9V12.1ZM13 13V15H15V13H13ZM19.5 23C19.2239 23 19 23.2239 19 23.5V28.5C19 28.7761 19.2239 29 19.5 29H20.5C20.7761 29 21 28.7761 21 28.5V23.5C21 23.2239 20.7761 23 20.5 23H19.5ZM19 11.5C19 11.2239 19.2239 11 19.5 11H20.5C20.7761 11 21 11.2239 21 11.5V19.5V20.5C21 20.7761 20.7761 21 20.5 21H19.5H11.5C11.2239 21 11 20.7761 11 20.5V19.5C11 19.2239 11.2239 19 11.5 19H19V11.5ZM23.5 19C23.2239 19 23 19.2239 23 19.5V20.5V24.5C23 24.7761 23.2239 25 23.5 25H24.5C24.7761 25 25 24.7761 25 24.5V21H28.5C28.7761 21 29 20.7761 29 20.5V19.5C29 19.2239 28.7761 19 28.5 19H24.5H23.5ZM27 23.5C27 23.2239 27.2239 23 27.5 23H28.5C28.7761 23 29 23.2239 29 23.5V27.5V28.5C29 28.7761 28.7761 29 28.5 29H27.5H23.5C23.2239 29 23 28.7761 23 28.5V27.5C23 27.2239 23.2239 27 23.5 27H27V23.5Z"
      fill="#21201F"
    />
  </svg>
</template>
