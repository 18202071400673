<template>
  <teleport to="#teleport-confirm">
    <Overlay>
      <div class="sidebar-menu">
        <CommonMenu
          :menu-config="menuConfig"
          :title="title"
          :subtitle="subtitle"
          @click="$emit('close')"
          @close="$emit('close')"
        />
      </div>
    </Overlay>
  </teleport>
</template>

<script lang="ts">
import CommonMenu from '@/ui/common/menu/common-menu.vue';
import { MenuItemConfig } from '@/ui/common/menu/types';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Overlay,
    CommonMenu,
  },
  props: {
    order: {
      type: Object,
      default: undefined,
    },
    menuConfig: {
      type: Array as PropType<MenuItemConfig[]>,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
});
</script>

<style lang="scss" scoped>
.sidebar-menu {
  margin-left: auto;
  width: 100vw;
  display: flex;
  background: var(--main-bg);
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-direction: column;

  text-align: left;
  pointer-events: auto;
}
</style>
