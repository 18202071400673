import { DeviceHandler } from '@/services/scanner/handlers/DeviceHandler';
import { NativeScanner } from '@/services/scanner/native-scanner';

export class HardwareScanner extends DeviceHandler {
  mode: string;
  constructor() {
    super();
    this.mode = 'hard';
  }

  async init() {
    await super.init();
    const data: any = await NativeScanner.start();
    if (data.mode === 'camera') {
      throw new Error('Нет встроенного сканера');
    }
  }
}
