import BaseOrder, { OrderBaseVars, OrderTypeEnum } from '@/models/orders/BaseOrder';

interface Conditions {
  need_transfer_act: boolean;
}

export interface TransferAct {
  status?: 'CREATED' | 'CANCELLED' | 'CLOSED';
}

interface Item {
  barcode: string;
  item_id: string;
}

export interface MarketOrders {
  external_id: string;
  price: string;
  items: Item[];
}

export interface VarsAcceptanceMarketOrderVars extends OrderBaseVars {
  need_transfer_act?: boolean;
  market_orders?: MarketOrders;
  transfer_act?: TransferAct;
}

export const isAcceptanceMarketOrder = (order: BaseOrder): order is AcceptanceMarketOrder => {
  return [OrderTypeEnum.acceptance_market].includes(order.type);
};

export default class AcceptanceMarketOrder extends BaseOrder {
  public type: OrderTypeEnum.acceptance_market = OrderTypeEnum.acceptance_market;
  public vars: VarsAcceptanceMarketOrderVars = {};
  public conditions: Conditions = { need_transfer_act: false };

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
    this.vars = data.vars;
  }

  get isMarketAcceptance() {
    return Boolean(this.vars.market_orders);
  }
}
