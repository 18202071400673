export const ORDERS_WEIGHT = {
  order: {
    default: 0,
  },
  order_retail: {
    default: 0,
  },
  acceptance_market: {
    default: 2,
  },
  hand_move: {
    default: 1,
  },
  writeoff_prepare_day: {
    default: 3,
  },
  check_valid_short: {
    default: 4,
  },
  check_valid_regular: {
    default: 5,
  },
  stowage_market: {
    default: 2,
  },
  sale_stowage: {
    parcel: 2,
    default: 7,
    // Скоропортящаяся еда
    perishable: 3,
    // Товары для внутренних нужд
    office: 8,
  },
  refund: {
    default: 8,
  },
  part_refund: {
    default: 8,
  },
  kitchen_provision: {
    default: 0,
  },
};
