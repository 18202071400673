<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2878_34900)">
      <rect x="8" y="5" width="40" height="40" rx="20" fill="#FC9000" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 25.4211L28.3017 14L38 25.4211L32.569 24.4211L31.5056 30H24.7851L23.431 24.4211L18 25.4211ZM25.0278 31H31.315L30.9339 33H25.5132L25.0278 31ZM25.756 34L26.2414 36H30.3621L30.7433 34H25.756Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2878_34900"
        x="0"
        y="0"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.94902 0 0 0 0 0.71702 0 0 0 0 0.266667 0 0 0 0.35 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2878_34900" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2878_34900" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
  props: {
    color: {
      type: String,
      default: 'warmGray-600',
    },
  },
});
</script>
