<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#5C5A57" fill-opacity="0.1" />
    <path
      transform="translate(-8, -3)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.2428 10.6661L23.3307 10.6663H24.6687L24.7566 10.6661C25.1161 10.6654 25.475 10.6647 25.8017 10.7741C26.231 10.9178 26.6017 11.1975 26.8579 11.5708C27.0528 11.8549 27.1507 12.2001 27.2488 12.546L27.2728 12.6306L27.4743 13.3359C29.8743 13.5917 31.5 14.1229 31.5 14.8329V16.4996L30.2583 17.3329L29.079 25.5331C29.0264 25.8993 28.9584 26.2783 28.7015 26.5446C28.2256 27.0378 27.0164 27.7493 24 27.7493C21.0012 27.7493 19.7886 27.0461 19.3069 26.5533C19.0425 26.2827 18.9734 25.8952 18.9202 25.5206L17.7583 17.3329L16.5 16.4996V14.8829C16.5 14.1088 18.1263 13.5785 20.5269 13.3292L20.7265 12.6306L20.7506 12.546C20.8486 12.2001 20.9465 11.8549 21.1414 11.5708C21.3976 11.1975 21.7684 10.9178 22.1977 10.7741C22.5244 10.6647 22.8833 10.6654 23.2428 10.6661ZM25.6703 13.0884L25.7033 13.2041C25.1567 13.1791 24.5866 13.1663 24 13.1663C23.4133 13.1663 22.8432 13.1783 22.2966 13.2021L22.3291 13.0884C22.4696 12.5966 22.4959 12.5426 22.5157 12.5138C22.5669 12.4392 22.6411 12.3832 22.7269 12.3545C22.76 12.3434 22.8191 12.3329 23.3307 12.3329H24.6687C25.1802 12.3329 25.2394 12.3434 25.2724 12.3545C25.3583 12.3832 25.4325 12.4392 25.4837 12.5138C25.5034 12.5426 25.5297 12.5966 25.6703 13.0884ZM28.7088 16.3656L29.8333 15.6109V15.486C29.629 15.4122 29.3432 15.3293 28.964 15.2482C27.764 14.9915 26.0152 14.8329 24 14.8329C21.9769 14.8329 20.2314 14.9822 19.0372 15.2385C18.6506 15.3215 18.3654 15.4068 18.1667 15.4816V15.6043L19.3085 16.3605L20.5986 25.4682C21.4107 26.022 23.0576 26.0827 24 26.0827C26.2531 26.0827 27.1349 25.6502 27.4021 25.4676L28.7088 16.3656ZM22.7663 16.4996L23.1663 24.6243L21.9167 24.416L21.0996 16.6079L22.7663 16.4996ZM24.8333 24.6243L25.2333 16.4996L26.9 16.6079L26.0833 24.416L24.8333 24.6243Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
