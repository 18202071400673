<template>
  <Blur @click="emits('close')">
    <AbsoluteCenter data-test="modal about-order">
      <RoundedCard24 class="flex flex-col">
        <IconSpot class="mx-1 mt-1" />
        <Title4 class="font-medium mx-4 my-3">
          {{ $gettext('О заказе') }}
        </Title4>
        <RoundedCard16 v-if="order" bg="bg-semantic-bg-minor" class="mx-3 px-4 mb-2">
          <InfoWithLabelVertical :label="$gettext('Номер заказа')" :value="order?.attr.doc_number" />
          <Divider />
          <InfoWithLabelVertical
            :label="$gettext('Номер заказа внешнего партнёра')"
            :value="order?.attr.partner_order_id"
          />
        </RoundedCard16>
        <div class="m-2">
          <UiButton data-test="modal about-order close" background-color="secondary" @click="emits('close')">
            {{ $gettext('Закрыть') }}
          </UiButton>
        </div>
      </RoundedCard24>
    </AbsoluteCenter>
  </Blur>
</template>
<script setup lang="ts">
import IconSpot from '@/fsd/shared/icons/spot/IconSpot.vue';
import RoundedCard16 from '@/fsd/shared/ui/cards/RoundedCard16.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import Divider from '@/fsd/shared/ui/divider/divider.vue';
import InfoWithLabelVertical from '@/fsd/shared/ui/output/InfoWithLabelVertical.vue';
import AbsoluteCenter from '@/fsd/shared/ui/positions/AbsoluteCenter.vue';
import { useOrders } from '@/store/modules/orders';
import Blur from '@/temp/ui/blur/blur.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits } from 'vue';

const props = defineProps<{ resolve: () => void; order_id }>();
const emits = defineEmits<{ (e: 'close'): void }>();

const order = computed(() => {
  return useOrders().orderById(props.order_id);
});
</script>
