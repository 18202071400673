<template>
  <Layout>
    <div class="bg-day-cardDivider h-full flex flex-col gap-1.5">
      <Sector class="rounded-t-none pt-0">
        <div class="flex flex-col">
          <Title2 class="font-bold tracking-tight">
            {{ $gettext('Вскройте эти посылки и передайте содержимое курьеру') }}
          </Title2>
          <Body2 class="mt-2">{{ $gettext('Будьте аккуратны') }}</Body2>
        </div>
      </Sector>

      <Sector class="h-full rounded-b-none !px-0">
        <div class="flex flex-col h-full">
          <ProductGroupItem
            v-for="s in ultimaItems"
            :key="s.product_id"
            :suggest="s"
            data-test="product-group-item ultima-package"
          />
        </div>
      </Sector>
    </div>

    <template #footer>
      <LayoutFooter>
        <ProductListTrigger :suggests="suggests" />
        <UiButton data-test="next-stage-btn" @click="emit('done')">
          {{ $gettext('Далее') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { getUltimaItemSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import ProductGroupItem from '@/fsd/widgets/product-list/ProductGroupItem.vue';
import ProductListTrigger from '@/fsd/widgets/product-list/product-list-trigger.vue';
import Suggest from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from './tools/useMeasureOrderFinish';

const props = defineProps<{ packing: Suggest[]; suggests: Suggest[]; order_id: OrderOrder['order_id'] }>();
const emit = defineEmits(['done']);

const ultimaItems = computed<Suggest[]>(() => {
  return getUltimaItemSuggests(props.suggests);
});

useRUMOrderFinishingStep({
  orderId: props.order_id,
  markName: OrderFinishingMarkNamesEnum.ULTIMA,
  deltaName: OrderFinishingDeltaNamesEnum.ULTIMA,
});
</script>
