<template>
  <Blur>
    <AbsoluteCenter :data-test="dataTest" class="flex items-center justify-center">
      <RoundedCard24 class="flex flex-col break-words w-[312px]">
        <div class="flex items-center mx-2.5 mt-2.5">
          <slot name="icon" />
        </div>
        <!-- Тексты -->
        <div class="flex flex-col px-4 py-3">
          <Title4 class="font-bold">
            {{ title }}
          </Title4>
          <Body2 v-if="subtitle" class="pt-1" color="text-secondary">
            {{ subtitle }}
          </Body2>
        </div>
        <Body2 v-if="text" class="px-4 pb-4">
          {{ text }}
        </Body2>

        <!-- Информационный блок -->
        <RoundedCard16 v-if="infoBlock?.length > 0" bg="bg-semantic-bg-minor" class="mx-3 px-4 py-2 mb-2">
          <Body2 v-if="infoBlockTitle" class="inline-block py-2 font-medium" color="semantic-text-main">
            {{ infoBlockTitle }}
          </Body2>
          <div v-for="item in infoBlock" :key="infoBlock?.indexOf(item)">
            <InfoWithLabelVertical :label="item.subtitle" :value="item.title" class="py-2" />
            <Divider v-if="infoBlock?.indexOf(item) !== infoBlock?.length - 1" />
          </div>
        </RoundedCard16>
        <!-- Кнопки  -->
        <div
          class="m-2"
          :class="{
            'flex flex-col gap-1': btnPosition === ButtonPositionsEnum.vertical,
            'flex flex-row-reverse gap-1': btnPosition === ButtonPositionsEnum.horizontal,
          }"
        >
          <UiButton :timeout="confirmTimeout" :data-test="confirmBtnDataTest" @click="emits('confirm')">
            {{
              confirmBtnTitle || (btnPosition === ButtonPositionsEnum.one_button ? $gettext('Понятно') : $gettext('Да'))
            }}
          </UiButton>
          <UiButton
            background-color="secondary"
            data-test="confirm decline-btn"
            :class="{ hidden: btnPosition === ButtonPositionsEnum.one_button }"
            @click="emits('close')"
          >
            {{ closeBtnTitle || $gettext('Нет') }}
          </UiButton>
        </div>
      </RoundedCard24>
    </AbsoluteCenter>
  </Blur>
</template>
<script setup lang="ts">
import RoundedCard16 from '@/fsd/shared/ui/cards/RoundedCard16.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import Divider from '@/fsd/shared/ui/divider/divider.vue';
import InfoWithLabelVertical from '@/fsd/shared/ui/output/InfoWithLabelVertical.vue';
import AbsoluteCenter from '@/fsd/shared/ui/positions/AbsoluteCenter.vue';
import Blur from '@/temp/ui/blur/blur.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { ButtonPositionsEnum, UniversalModalProps } from './types';

const props = withDefaults(defineProps<UniversalModalProps>(), {
  dataTest: 'universal modal',
  btnPosition: ButtonPositionsEnum.one_button,
  infoBlock: () => [],
});

const emits = defineEmits<{
  (e: 'confirm'): void;
  (e: 'close'): void;
}>();

const confirmBtnDataTest = props.btnPosition === ButtonPositionsEnum.one_button ? 'modal close-btn' : 'confirm ok-btn';
</script>
