<template>
  <svg width="26" height="15" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3203 4.60938L18.8906 4.17969C18.6562 3.94531 18.2266 3.94531 17.9922 4.17969L16 6.21094L13.9688 4.17969C13.7344 3.94531 13.3047 3.94531 13.0703 4.17969L12.6406 4.60938C12.4062 4.84375 12.4062 5.27344 12.6406 5.50781L14.6719 7.5L12.6406 9.53125C12.4062 9.76562 12.4062 10.1953 12.6406 10.4297L13.0703 10.8594C13.3047 11.0938 13.7344 11.0938 13.9688 10.8594L16 8.82812L17.9922 10.8594C18.2266 11.0938 18.6562 11.0938 18.8906 10.8594L19.3203 10.4297C19.5547 10.1953 19.5547 9.76562 19.3203 9.53125L17.2891 7.5L19.3203 5.50781C19.5547 5.27344 19.5547 4.84375 19.3203 4.60938ZM23.5 0H9.00781C8.34375 0 7.71875 0.273438 7.25 0.742188L1.35156 6.64062C0.84375 7.10938 0.84375 7.92969 1.35156 8.39844L7.25 14.2969C7.71875 14.7656 8.34375 15 9.00781 15H23.5C24.8672 15 26 13.9062 26 12.5V2.5C26 1.13281 24.8672 0 23.5 0ZM24.125 12.5C24.125 12.8516 23.8125 13.125 23.5 13.125H9.00781C8.8125 13.125 8.65625 13.0859 8.53906 12.9688L3.10938 7.5L8.53906 2.07031C8.65625 1.95312 8.8125 1.875 9.00781 1.875H23.5C23.8125 1.875 24.125 2.1875 24.125 2.5V12.5Z"
      fill="#1C1D20"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
