<template>
  <Bar :icons="{ left: 'back', right: 'menu' }" @close-click="emits('close')" />
</template>
<script setup lang="ts">
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Bar from '@/ui/common/bar/bar.vue';
import { defineEmits, defineProps } from 'vue';

interface RetailHeaderDetails {
  order: OrderOrderRetail;
}

defineProps<RetailHeaderDetails>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();
</script>
