<template>
  <Layout>
    <template #header>
      <Bar
        :caption="caption"
        :text="text"
        :icons="{ left: 'close' }"
        :menu-config="[]"
        :data-test="`control_check bar ${step}-step`"
        @close-click="onCloseClick"
      />
    </template>
    <template #default>
      <template v-if="order">
        <div class="px-4 py-2 flex flex-col">
          <Hint class="mb-2">{{ $gettext('Отсканируйте штрих-код каждой палеты') }}</Hint>
          <ShelfCard
            v-for="(shelf, index) in shelves_to_check"
            :key="shelf.barcode"
            :class="{ 'mb-2': index !== shelves_to_check.length - 1 }"
            :title="shelf.barcode"
            :zone-type-tag="shelf.tag[0]"
            :status="statusForShelf(shelf.barcode)"
            :need-menu="step === 'shelves'"
            :menu-config="suggestMenuConfig(shelf.barcode)"
            @open-suggest-menu="needBarcodeRequest = false"
            @close-suggest-menu="needBarcodeRequest = true"
          />
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col justify-center items-center p-4 h-full">
          <span class="text-3xl font-bold text-center mb-16">
            {{ $gettext('Отсканируйте штрих-код любой палеты из проверяемых') }}
          </span>
          <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
        </div>
      </template>
      <ShelfDetails
        v-if="shelfDetails.visible.value && shelfDetails.props.value && order"
        :shelf="shelfDetails.props.value"
        :stock="shelves[shelfDetails.props.value].products"
        :order="order"
        @cancel="shelfDetails.hide"
        @change-report="products => updateModel(products, shelfDetails.props.value)"
        @finish="() => shelfDetails.executeAndHide(finishActiveShelf, shelfDetails.props.value)"
        @empty="() => shelfDetails.executeAndHide(saveEmptyShelf, shelfDetails.props.value)"
        @save-to-s3="saveModelToS3"
      />
    </template>
    <template #footer>
      <LayoutFooter>
        <SliderButton
          v-if="step === 'shelves'"
          :key="step"
          :disabled="!order"
          data-test="control_check to-product-step btn"
          @slide-complete="finishCheckShelves"
        >
          {{ $gettext('Завершить проверку палет') }}
        </SliderButton>
        <SliderButton
          v-if="step === 'products'"
          :key="step + buttonState"
          :disabled="shelves_to_check.length !== completeShelvesCount"
          data-test="control_check finish btn"
          @slide-complete="finish"
        >
          {{ $gettext('Завершить') }}
        </SliderButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import ControlCheckOrder from '@/models/orders/ControlCheckOrder';
import Report from '@/models/Report';
import productQueue from '@/services/queue/product-queue';
import { useOrders } from '@/store/modules/orders';
import IconHappyBasket from '@/temp/icons/icon-happy-basket.vue';
import IconSadBasket from '@/temp/icons/icon-sad-basket.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { IOrderError, Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import SliderButton from '@/ui/common/slider-button/slider-button.vue';
import ShelfCard from '@/ui/control-check/shelf-card.vue';
import ShelfDetails from '@/ui/control-check/shelf-details.vue';
import { checkPermit } from '@/utils/checkPermit';
import { needUpgradeRoleModal } from '@/utils/modals';
import S3storageHelper from '@/utils/s3storageHelper';
import ChooseDateModal from '@/views/control_check/choose-date-modal.vue';
import { ShelfStatusEnum, ShelfStock } from '@/views/control_check/types';
import axios, { AxiosError } from 'axios';
import { computed, defineProps, markRaw, onMounted, ref, withDefaults } from 'vue';

import { useSubscribeOnOrder } from '@/fsd/data/utils/subscribeOnOrder';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import uuidv1 from 'uuid/v1';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const ordersStore = useOrders();
const shelfDetails = useComponent<string>();
const router = useRouter();

interface RequiredItem {
  count: number;
  price_type: string;
  product_id: string;
  shelf_id: string;
}

interface ShelfData {
  status: ShelfStatusEnum;
  products?: ShelfStock;
}

interface ShelfToCheck {
  barcode: string;
  tag: string[];
}

type Step = 'init' | 'shelves' | 'products';

interface ControlCheckProps {
  order_id: string;
  taskId?: string;
  system?: string;
  type?: string;
}

const props = withDefaults(defineProps<ControlCheckProps>(), {
  taskId: '',
  system: '',
  type: '',
});

const shelves = ref<Record<string, ShelfData>>({});
const step = ref<Step>('init');
const buttonState = ref<number>(0);

const ktkResults = new S3storageHelper<{ order_id: string; model: Record<string, ShelfData> }>({
  s3Folder: 'ktk_results',
  s3FileName: props.order_id,
  validator: data => {
    return data.order_id === props.order_id;
  },
});

useHandleOrderStatus(props.order_id);

const order = computed<ControlCheckOrder | undefined>(() => ordersStore.controlCheckOrders[0]);

const suggestMenuConfig = computed<(shelf: string) => MenuItemConfig[]>(() => {
  return shelf => {
    const menuConfig: MenuItemConfig[] = [];
    if (!shelf) return menuConfig;
    if (step.value === 'shelves') {
      const missingShelfBtn = {
        buttonText: $gettext('Палеты нет'),
        onClick: () => {
          addShelfToMissing(shelf);
        },
      };
      menuConfig.push(missingShelfBtn);
    }

    return menuConfig;
  };
});

const shelves_to_check = computed<ShelfToCheck[]>(() => {
  if (!order.value?.vars?.shelves_to_check) {
    return [];
  }
  return order.value.vars.shelves_to_check;
});

const completeShelvesCount = computed<number>(() => {
  return shelves_to_check.value.reduce((acc, s) => {
    const shelfData = shelves.value[s.barcode];
    if (step.value === 'shelves') {
      if (shelfData?.status !== 'not-processed') {
        acc++;
      }
    }
    if (step.value === 'products') {
      if (shelfData?.products) {
        acc++;
      }
    }
    return acc;
  }, 0);
});

const text = computed<string>(() => {
  if (order.value) {
    return $gettext('Осталось %{required} из %{total} паллет', {
      total: String(shelves_to_check.value.length),
      required: String(shelves_to_check.value.length - completeShelvesCount.value),
    });
  }
  return $gettext('Сканирование первой палеты');
});

const caption = computed<string>(() => {
  if (order.value && step.value === 'products') {
    return $gettext('Проверка комплектации');
  }
  return $gettext('Проверка наличия палет');
});

const statusForShelf = computed<(shelf: string) => ShelfStatusEnum | undefined>(() => {
  return shelf => {
    return shelves.value[shelf]?.status;
  };
});

onMounted(async () => {
  const hasPermit = order.value
    ? checkPermit('tsd_order_types', 'control_check')
    : checkPermit('tsd_create_control_check');

  if (!hasPermit) {
    const result = await needUpgradeRoleModal();
    if (result) {
      router.push({ name: 'settings-upgrade-role', params: { upgrade: 'true' } });
    } else {
      router.push({ name: 'home' });
    }
    return;
  }
  if (order.value) {
    // собираем список для проверки из ордера
    shelves.value = createModel(shelves_to_check.value, order.value.required as RequiredItem[]);
    // смотрим в локал сторадж и если там что то есть, то применяем к ордеру
    step.value = 'shelves';
    await tryLoadModel();
  }
});

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  try {
    if (!order.value) {
      await createControlCheck(barcode);
      return true;
    }
    if (shelves_to_check.value.find(s => s.barcode === barcode)) {
      if (step.value === 'shelves') {
        return addShelfToControl(barcode);
      }
      if (step.value === 'products') {
        await shelfDetails.asyncShow(barcode);
        return true;
      }
    } else {
      Notifications.modal({
        title: $gettext('Отсканирован неверный штрих-код'),
        text: $gettext('Убедитесь, что вы сканируете штрих-код палеты.'),
        iconTop: {
          icon: markRaw(IconSadBasket),
          position: 'center',
        },
      });
    }
    return true;
  } catch (e: any) {
    const error = e as AxiosError;
    if (axios.isAxiosError(error) && !error.response) {
      await Notifications.modal({
        title: $gettext('Кажется, что у вас нет интернета'),
      });
    }
    return true;
  }
});

const addShelfToControl = (shelf: string): boolean => {
  if (shelves.value[shelf].status === ShelfStatusEnum['in-stock']) {
    Notifications.modal({
      title: $gettext('Палета уже в зоне проверки'),
      iconTop: {
        icon: markRaw(IconHappyBasket),
        position: 'center',
      },
    });
  } else {
    addShelfToStock(shelf);
  }
  return true;
};

const onCloseClick = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Остановить контроль точности комплектации?'),
    text: $gettext('Весь прогресс будет утерян.'),
  });
  if (confirmed) {
    ktkResults.saveToLocal({ model: shelves.value, order_id: props.order_id });
    router.back();
  }
};

const createControlCheck = async (barcode: string, date?: string): Promise<void> => {
  const { closeLoader } = showLoader(undefined, props.order_id);
  try {
    //создаем ктк
    const { data } = await api.order.control_check({ order_id: uuidv1(), shelf_barcode: barcode, date });
    closeLoader();
    shelves.value = createModel(data.order.vars.shelves_to_check!, data.order.required as any);
    addShelfToStock(barcode);
    step.value = 'shelves';
  } catch (error) {
    closeLoader();
    if (!axios.isAxiosError(error)) {
      await Notifications.modal({
        title: $gettext('Произошла ошибка при создании КТК'),
        text: String(error),
      });
      return;
    }
    if (!error.response) {
      await Notifications.modal({
        title: $gettext('Кажется, что у вас нет интернета'),
      });
      return;
    }
    if (error.response.data.code === 'ER_TOO_MANY_DATES') {
      const dates = error.response.data.details.dates;
      //     нужно выбрать дату
      const date: any = await Notifications.customComponentModal<typeof ChooseDateModal>({
        component: markRaw(ChooseDateModal),
        dates,
      });
      if (date) {
        return createControlCheck(barcode, date);
      }
      return;
    }
    Alerts.error($gettext('Отсканирован неверный баркод'));
  }
};

const createModel = (shelves_to_check: ShelfToCheck[], required?: RequiredItem[]): Record<string, ShelfData> => {
  const fillShelfFromRequired = (shelfRequired: RequiredItem[]) => {
    return shelfRequired.reduce((acc, cur) => {
      acc[cur.product_id] = {
        product_id: cur.product_id,
        count: 0,
        accounting_quantity: cur.count,
        history: [],
      };
      return acc;
    }, {});
  };

  return shelves_to_check.reduce((acc, s) => {
    acc[s.barcode] = { status: ShelfStatusEnum['not-processed'] };
    if (required && required.length) {
      const shelfRequired = required.filter(r => r.shelf_id === s.barcode);
      acc[s.barcode].products = fillShelfFromRequired(shelfRequired);
    }
    return acc;
  }, {});
};

const setShelfStatus = (shelf: string, status: ShelfStatusEnum): void => {
  if (!shelves.value[shelf]) return;
  shelves.value[shelf].status = status;
};
const addShelfToStock = (shelf: string): void => {
  setShelfStatus(shelf, ShelfStatusEnum['in-stock']);
};
const addShelfToMissing = (shelf: string): void => {
  setShelfStatus(shelf, ShelfStatusEnum['missing']);
};
const addShelfToWaitingProductScan = (shelf: string): void => {
  setShelfStatus(shelf, ShelfStatusEnum['waiting-products-scan']);
};

const finishCheckShelves = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Завершить проверку наличия палет?'),
    text: $gettext('Убедитесь, что все отсканированные палеты находятся в зоне проверки.'),
  });
  if (!confirmed) return;

  const hasInCompleteShelves = completeShelvesCount.value < shelves_to_check.value.length;
  if (hasInCompleteShelves) {
    setNotProcessingShelvesAsMissing();
    Notifications.modal({
      title: $gettext('Остальные палеты помечены как отсутствующие'),
      text: $gettext('Их статус можно будет поменять, отсканировав их штрих-коды на экране проверки наличия палет.'),
    });
  }

  step.value = 'products';
  setStockShelvesAsWaitingScan();
};

const finishActiveShelf = (activeShelf: string): void => {
  if (!activeShelf) {
    //   error
    return;
  }
  setShelfStatus(activeShelf, ShelfStatusEnum['stock-scanned']);
  saveModelToS3();
};

const updateModel = (stock: ShelfStock, activeShelf: string): void => {
  // вызывается на каждое действие пользователя
  // обновляет модельку и сохраняет ее в локал сторадж
  if (!activeShelf) {
    //   error
    return;
  }
  shelves.value[activeShelf].products = stock;
  ktkResults.saveToLocal({ model: shelves.value, order_id: props.order_id });
};

const saveEmptyShelf = (activeShelf: string): void => {
  if (!activeShelf) {
    //   error
    return;
  }
  shelves.value[activeShelf].products = {};
  setShelfStatus(activeShelf, ShelfStatusEnum['empty-shelf']);
};

const finish = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Завершить задание?'),
    text: $gettext('Все товары на палетах должны быть отсканированы и корректно подсчитаны.'),
  });
  if (!confirmed) {
    buttonState.value++;
    return;
  }

  if (!order.value) {
    return;
  }

  const order_id = order.value.order_id;
  const { closeLoader, updateLoader } = showLoader($gettext('Отправляем запрос на завершение задания'), order_id);
  try {
    await api.order.complete_control_check({ order_id, report: createReport() });
    updateLoader($gettext('Ожидаем ответ сервера'), order_id);
    await useSubscribeOnOrder(order_id)(o => {
      if (!o || o.status === o.target) {
        return true;
      }
      return false;
    });
    Alerts.success($gettext('Задание завершено'));
    closeLoader();
    ktkResults.clearLocal();
    router.push({ name: 'home' });
  } catch (e: any) {
    console.error(e);
    closeLoader();
    buttonState.value = buttonState.value + 1;
    if (!e?.isAxiosError) {
      Alerts.success($gettext('Ошибка проверки'));
      return;
    }
    const error = e as AxiosError;

    if (!error.response) {
      const confirmed = await Notifications.confirmCenter({
        title: $gettext('Кажется, что у вас нет интернета'),
        text: $gettext(
          'Для завершения задания нужен интернет. Зайдите в область действия Wi-Fi-сети и попробуйте ещё раз.',
        ),
        ok: $gettext('Повторить'),
        decline: $gettext('Отмена'),
      });
      if (confirmed) {
        finish();
      }
    } else {
      const response = error.response;
      const majorErrorConfig: IOrderError = {
        title: $gettext('Ошибка завершения документа'),
        reason: response.status.toString(),
        body: response.data.message,
        onClose: () => {},
        onRepeat: () => finish(),
      };

      majorErrorConfig.reason = response.status.toString();
      majorErrorConfig.body = response.data.message;
      Notifications.error.major.order(majorErrorConfig);
    }
  }
};

const createReport = (): Report[] => {
  const result: Report[] = [];
  Object.keys(shelves.value).forEach(shelf => {
    const shelfData = shelves.value[shelf];
    const products = shelfData.products;
    if (!products) return;
    Object.keys(products).forEach(product_id => {
      if (products[product_id].count) {
        result.push({ shelf_barcode: shelf, product_id, count: products[product_id].count } as Report);
      }
    });
  });
  return result;
};

const getShelvesByStatus = (statusForSearch: ShelfStatusEnum): string[] => {
  return shelves_to_check.value
    .map(s => s.barcode)
    .filter(shelf => {
      const status = shelves.value[shelf].status;
      return statusForSearch === status;
    });
};

const setNotProcessingShelvesAsMissing = (): void => {
  getShelvesByStatus(ShelfStatusEnum['not-processed']).forEach(shelf => {
    addShelfToMissing(shelf);
  });
};

const setStockShelvesAsWaitingScan = (): void => {
  getShelvesByStatus(ShelfStatusEnum['in-stock']).forEach(shelf => {
    addShelfToWaitingProductScan(shelf);
  });
};

const saveModelToS3 = async (): Promise<void> => {
  //  сохраняет модельку в s3. сохраняем ТОЛЬКО отчет
  //  сохраняет модельку в локал сторадж. сохраняем отчет и историю
  if (!order.value) {
    return;
  }
  // нужно удалить историю из модельки и отправить ее в s3
  const temp_model: Record<string, ShelfData> = {};
  Object.keys(shelves.value).forEach(shelf => {
    const shelfData: ShelfData = shelves.value[shelf];
    const products = shelfData.products;
    const temp_result = {
      status: shelfData.status,
      products: {},
    };
    if (products) {
      Object.keys(products).forEach(product_id => {
        const p = products[product_id];
        temp_result.products[product_id] = {
          ...p,
          history: [],
        };
      });
    }

    temp_model[shelf] = temp_result;
  });
  const { closeLoader } = showLoader($gettext('Сохраняем промежуточные результаты'));
  try {
    await ktkResults.saveToS3({ order_id: props.order_id, model: temp_model });
  } catch (e) {
    console.error(e);
  } finally {
    closeLoader();
  }
};

const tryLoadModel = async (): Promise<void> => {
  let data;
  const { closeLoader } = showLoader($gettext('Пробуем загрузить промежуточные результаты'));
  try {
    data = await ktkResults.tryLoadData(true);
  } catch (e) {
    console.error(e);
  } finally {
    closeLoader();
  }
  if (data) {
    shelves.value = data.model;
    step.value = 'products';
    const product_ids = [...new Set(createReport().map(r => r.product_id))];
    productQueue.loadMany(product_ids);
  }
};
</script>
