<template>
  <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2361_12474" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="46" height="46">
      <path
        d="M6.61129 14.0052L23.2113 4.24046C24.9325 3.22797 27.0673 3.22797 28.7885 4.24046L45.3885 14.0052C47.0684 14.9933 48.0999 16.7968 48.0999 18.7458V33.2544C48.0999 35.2034 47.0684 37.0069 45.3885 37.995L28.7885 47.7597C27.0673 48.7722 24.9325 48.7722 23.2113 47.7597L6.61129 37.995C4.93138 37.0069 3.8999 35.2034 3.8999 33.2544V18.7458C3.8999 16.7968 4.93138 14.9933 6.61129 14.0052Z"
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#mask0_2361_12474)">
      <path
        d="M25.9999 2.6001L3.8999 15.6001L25.9999 28.6001L48.0999 15.6001L25.9999 2.6001Z"
        fill="url(#paint0_linear_2361_12474)"
      />
      <path d="M3.8999 36.3997V15.6001L25.9999 28.6001V49.4001L3.8999 36.3997Z" fill="url(#paint1_radial_2361_12474)" />
      <path
        d="M48.1001 36.4001V15.6001L26.0001 28.6001V49.4001L48.1001 36.4001Z"
        fill="url(#paint2_radial_2361_12474)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6609 5.00684L38.8331 19.3795C39.7425 19.9203 40.2999 20.9001 40.2999 21.9581V46.8003H33.7999V25.6555C33.7999 24.5974 33.2425 23.6176 32.3331 23.0769L11.3389 10.5938L14.6609 5.00684Z"
        fill="url(#paint3_radial_2361_12474)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2361_12474"
        x1="25.1159"
        y1="4.0041"
        x2="26.58"
        y2="24.7064"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFE7C2" />
        <stop offset="1" stop-color="#E3B673" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2361_12474"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(25.033 47.8397) rotate(-130.069) scale(24.4619 22.9188)"
      >
        <stop stop-color="#CA8D32" />
        <stop offset="1" stop-color="#F2C888" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2361_12474"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.0247 40.8197) rotate(-27.4023) scale(27.963 25.2703)"
      >
        <stop stop-color="#C4892F" />
        <stop offset="1" stop-color="#E6BE81" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2361_12474"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.6997 11.0504) rotate(30.7627) scale(31.7703 23.2593)"
      >
        <stop stop-color="#FFF6E8" />
        <stop offset="0.677761" stop-color="#FFF9EF" />
        <stop offset="1" stop-color="#F0DBBC" />
      </radialGradient>
    </defs>
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
