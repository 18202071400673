import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { getDuration, markEnd, markStart } from '@/fsd/shared/utils/timeMarkHelper';
import { onMounted, onUnmounted } from 'vue';

const pageName = 'order.finishing';

// * enum для уникальных префиксов временных марок (performance.mark)
export const enum OrderFinishingMarkNamesEnum {
  SELECT_BAGS = 'order.finishing.selectBags',
  SELECT_BAGS_RESPONSE = 'order.finishing.selectBags.response',
  CONFIRM_ASSEMBLED = 'order.finishing.confirm_assembled',
  CONFIRM_ASSEMBLED_RESPONSE = 'order.finishing.confirm_assembled.response',
  ULTIMA = 'order.finishing.ultima',
  ROVER_MAYBE = 'order.finishing.maybeRover',
  ROVER_FIND = 'order.finishing.findRover',
  ROVER = 'order.finishing.rover',
  FINISH = 'order.finishing.finish',
  QR_CODE = 'order.finishing.qrCode',
}

// * enum для уникальных имен временных дельт
export const enum OrderFinishingDeltaNamesEnum {
  SELECT_BAGS = 'data.order.select_bags',
  SELECT_BAGS_RESPONSE = 'data.order.select_bags.response',
  CONFIRM_ASSEMBLED = 'data.order.confirm_assembled',
  CONFIRM_ASSEMBLED_RESPONSE = 'data.order.confirm_assembled.response',
  ULTIMA = 'data.order.ultima',
  ROVER_MAYBE = 'data.order.maybe_rover',
  ROVER_FIND = 'data.order.find_rover',
  ROVER = 'data.order.rover',
  FINISH = 'data.order.finish',
  QR_CODE = 'data.order.qr_code',
}

interface OrderFinishing {
  orderId: string;
  markName: OrderFinishingMarkNamesEnum;
  deltaName: OrderFinishingDeltaNamesEnum;
  useLifecycleHooks?: boolean;
}

export const useRUMOrderFinishing = () => {
  const startOrderFinishing = () => rumSpaManager.makeSpaSubPage(pageName, true);

  const finishOrderFinishing = () => rumSpaManager.finalizeSpaData(pageName);

  return { startOrderFinishing, finishOrderFinishing };
};

export const useRUMOrderFinishingStep = (params: OrderFinishing) => {
  const { orderId, markName, deltaName, useLifecycleHooks = true } = params;
  const markNameOrder = markName + orderId;

  const startMetric = () => markStart(markNameOrder);

  const finishMetric = () => {
    markEnd(markNameOrder);
    const duration = getDuration(markNameOrder);
    if (duration !== undefined) {
      rumSpaManager.sendDelta(pageName, deltaName, duration);
    }
  };

  if (useLifecycleHooks) {
    onMounted(startMetric);
    onUnmounted(finishMetric);
  }

  return { startMetric, finishMetric };
};
