import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const errorCodes = gettextWrapper({
  ER_TRY_LATER: $gettext('Попробуйте позже'),
  ER_ACCESS: $gettext('Доступ запрещён'),
  ER_ORDER_DONE_IN_PROGRESS: $gettext('Заказ в процессе завершения'),
  ER_ORDER_NOT_FOUND: $gettext('Заказ не найден'),
  ER_PRODUCT_NOT_FOUND: $gettext('Продукт не найден'),
  ER_SHELF_NOT_FOUND: $gettext('Полка не найдена'),
  ER_SUGGEST_COUNT: $gettext('Некорректное значение количества при закрытии саджеста'),
});

export const errorMessages = gettextWrapper({
  ER_ORDER_COMPLETE: $gettext('Ошибка завершения заказа'),
  ER_ORDER_COMPLETE_NETWORK: $gettext('Ошибка завершения заказа (сеть)'),
  ER_MOVING: $gettext('Ошибка перемещения'),
  ER_MOVING_DETAILS: $gettext(
    'Перемещение товара оказалось невозможным. Перемещение товара было отменено. Верните товар на старое место. Возможно есть оформленные ордера связанные с товаром на этой полке. Сперва нужно выполнить их, а затем можно будет повторить перемещение остатка',
  ),
  ER_CHECK: $gettext('Ошибка проверки'),
  ER_CHECK_CREATE: $gettext('Ошибка пересчета товара'),
  ER_CHECK_MORE: $gettext('Ошибка инвентаризации'),
  ER_BOX2SHELF: $gettext('Ошибка переноса из коробки на полку'),
  ER_SHELF2BOX: $gettext('Ошибка переноса с полки в коробку'),
  ER_ACK: $gettext('Ошибка принятия заказа'),
  ER_FETCH_ORDERS: $gettext('Ошибка загрузки заказов'),
  ER_FETCH_SHELVES: $gettext('Ошибка загрузки полок'),
  ER_FETCH_PRODUCTS: $gettext('Ошибка загрузки товаров'),
  ER_LOGIN: $gettext('Ошибка входа'),
  ER_WRITEOFF: $gettext('Ошибка создания списания'),
  ER_BARCODE: $gettext('Ошибка обработки баркода'),
});

export const successMessages = gettextWrapper({
  SUC_MOVING: $gettext('Перемещение успешно завершено'),
  SUC_CHECK: $gettext('Пересчет успешно выполнен'),
  SUC_CHECK_CREATE: $gettext('Задание на пересчет успешно создано'),
});
