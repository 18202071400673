<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5899 1.70718L13.1757 0.292969L8.4686 5.00008L13.1757 9.70718L14.5899 8.29297L12.2998 6.00287C12.3663 6.001 12.433 6.00006 12.5 6.00006C16.366 6.00006 19.5 9.13407 19.5 13.0001C19.5 16.8661 16.366 20.0001 12.5 20.0001C8.63401 20.0001 5.5 16.8661 5.5 13.0001C5.5 11.0671 6.2835 9.31706 7.55025 8.05031L6.13604 6.6361C4.50736 8.26478 3.5 10.5148 3.5 13.0001C3.5 17.9706 7.52944 22.0001 12.5 22.0001C17.4706 22.0001 21.5 17.9706 21.5 13.0001C21.5 8.0295 17.4706 4.00006 12.5 4.00006C12.4314 4.00006 12.363 4.00083 12.2947 4.00236L14.5899 1.70718Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
