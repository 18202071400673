<template>
  <Layout class="flex flex-col">
    <template #header>
      <Bar
        :caption="$gettext('Создание приемки')"
        :text="text"
        :icons="{ left: 'back' }"
        :menu-config="[]"
        @close-click="$router.back()"
      />
    </template>

    <template #default>
      <Hint class="my-2 mx-4">
        {{ $gettext('Отсканируйте посылки для создания приемки') }}
      </Hint>
      <SuggestCard
        v-for="item in items"
        :key="item.item_id"
        doc-type="create-acceptance-item"
        :available-item="{ product_id: item.item_id }"
        card-type="item"
        :suggest-menu-config="suggestMenuConfig(item)"
        :need-menu="true"
        @open-suggest-menu="needBarcodeRequest = false"
        @close-suggest-menu="needBarcodeRequest = true"
      />
    </template>

    <template #footer>
      <LayoutFooter>
        <UiButton data-test="create-acceptance finish-btn" :is-disabled="items.length === 0" @click="createAcceptance">
          {{ $gettext('Создать приемку') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Item from '@/models/Item';
import { useItems } from '@/store/modules/items';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import UiButton from '@/ui/common/ui-button.vue';
import uuidv1 from 'uuid/v1';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const { showLoader } = useLoader();
const itemsStore = useItems();
const router = useRouter();

const items = ref<Item[]>([]);

const suggestMenuConfig = computed<(item: Item) => MenuItemConfig[]>(() => {
  return item => {
    const menuConfig: MenuItemConfig[] = [];
    const deleteBtn: MenuItemConfig = {
      buttonText: $gettext('Удалить'),
      onClick: () => {
        deleteActiveItem(item);
      },
    };
    menuConfig.push(deleteBtn);

    return menuConfig;
  };
});

const text = computed<string>(() => {
  const count = items.value.length;
  if (!count) return '';
  return $ngettext('%{count} посылка', '%{count} посылок', count, { count: String(count) });
});

const deleteActiveItem = (item: Item): void => {
  items.value = items.value.filter(i => {
    return i.item_id !== item.item_id;
  });
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  logger('Получен баркод в create/acceptance', barcode);
  const { closeLoader } = showLoader();
  try {
    const item = await itemsStore.getItemByBarcode(barcode);
    if (!item) {
      Alerts.error($gettext('Произошла ошибка при загрузке посылки'));
      return true;
    }
    await itemsStore.fetchAvailable(item.item_id);
    const available = itemsStore.availableById(item.item_id);
    if (available && available.length > 0) {
      Alerts.error($gettext('Посылка уже размещена'));
      return true;
    }
    if (items.value.find(i => i.item_id === item.item_id)) {
      Alerts.error($gettext('Посылка уже добавлена'));
      return true;
    }
    items.value.push(item);
    return true;
  } catch (error) {
    Alerts.error($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});

const createAcceptance = async (): Promise<void> => {
  const confirmed = await Notifications.confirmCenter({
    title: $gettext('Вы уверены, что хотите создать приемку?'),
  });
  if (!confirmed) return;
  needBarcodeRequest.value = false;
  const required = items.value.map(i => {
    return {
      item_id: i.item_id,
    };
  });
  const { closeLoader } = showLoader();
  try {
    await api.order.acceptance({ external_id: uuidv1() as string, required });
    Alerts.success($gettext('Запрос на создание приемки отправлен'));
    items.value = [];
    router.back();
  } catch (e) {
    Alerts.error($gettext('Произошла ошибка при создании приемки'));
    logger.error(e, { method: 'acceptance', type: 'api', source: 'create-acceptance.vue' });
  } finally {
    closeLoader();
  }
};
</script>
