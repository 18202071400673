import { sleep } from '@/temp/utils';
import axios, { AxiosError, isAxiosError } from 'axios';

interface Options {
  maxDelay?: number;
  maxRetry?: number;
  chechError?: (e: AxiosError) => boolean;
}

interface Wrappable extends Function {
  (...rest): Promise<any>;
}
const createRetryOnErrorFn = <T extends Wrappable>(
  fn: T,
  options: Options = {},
): ((...args: Parameters<T>) => ReturnType<T>) => {
  const { maxDelay = 16, maxRetry, chechError } = options;
  let delay: number = 1;
  let retryCount: number = 0;
  const retryOnError = async (...args) => {
    try {
      const result = await fn(...args);
      delay = 1;
      retryCount = 0;
      return result;
    } catch (e: unknown) {
      if (maxRetry && retryCount >= maxRetry) throw e;
      if (isAxiosError(e)) {
        if (axios.isCancel(e)) throw e;
        if (!chechError?.(e)) throw e;
      }
      await sleep(delay * 1000);
      if (delay < maxDelay) delay *= 2;
      retryCount++;
      return retryOnError(...args);
    }
  };
  return retryOnError;
};
export default createRetryOnErrorFn;
