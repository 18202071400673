<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest card collected row" />
</template>

<script lang="ts">
// поле для отображения собранного количества пакетов
import Suggest from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import Row from '@/ui/common/suggest-card/row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    collected: {
      type: Number,
      required: true,
    },
  },
  computed: {
    needShow(): boolean {
      if (['create-writeoff-by-order-item'].includes(this.docType)) {
        return true;
      }
      if (['pack'].includes(this.docType)) {
        return this.suggest?.status === 'request';
      }
      return false;
    },
    label(): string {
      return this.$gettext('Количество');
    },
    value(): string {
      return this.$gettext('%{count} шт.', { count: String(this.collected) });
    },
  },
});
</script>
