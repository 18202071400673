<template>
  <div>
    <notifications
      class="z-[1200]"
      classes="notifications"
      :max="2"
      width="100%"
      group="micro-alert"
      position="top center"
    >
      <template #body="props">
        <MicroAlert :type="props.item.type" :title="props.item.title" :text="props.item.text" @close="props.close" />
      </template>
    </notifications>
  </div>
</template>

<script lang="ts">
import MicroAlert from '@/ui/common/notifications/micro-alert.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertsGroup',
  components: {
    MicroAlert,
  },
});
</script>
