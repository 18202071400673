<template>
  <Blur @click="onBackdropClick">
    <div
      data-test="modal alert info"
      class="absolute bg-main p-2 pt-2.5 rounded-t-3xl whitespace-pre-line"
      :class="{
        'bottom-0': position === 'bottom',
        center: position === 'center',
        'rounded-b-3xl': position === 'center',
        'left-4': position === 'center',
        'right-4': position === 'center',
        'left-0': position === 'bottom',
        'right-0': position === 'bottom',
      }"
    >
      <slot />
    </div>
  </Blur>
</template>

<script lang="ts">
import Blur from '@/temp/ui/blur/blur.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UniversalModal',
  components: { Blur },
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    backdropClick: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['backdropClick'],
  methods: {
    onBackdropClick(): void {
      if (this.backdropClick) {
        this.$emit('backdropClick');
      }
    },
  },
});
</script>

<style scoped>
.center {
  top: 50%;
  transform: translateY(-50%);
}
</style>
