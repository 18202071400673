<template>
  <div class="flex flex-row gap-2">
    <ImgCardList :img-src="suggest.product?.images[0]" :badges="badges" />
    <div class="flex flex-1 flex-col gap-2">
      <Body2 class="font-medium"> {{ suggest.product?.title }} </Body2>
      <div class="flex flex-row gap-1">
        <FragileBadge v-if="suggest.product?.fragile" size="small" />
        <WeightBadge v-if="suggest.product?.isTrueWeight" size="small" :plu_code="suggest.product.plu_code" />
      </div>
      <div class="flex flex-row items-center gap-1 font-medium" color="day-textMinor">
        <Caption1 v-if="suggest.product?.plu_code">
          {{ suggest.product?.plu_code }}
        </Caption1>
        <Caption1 v-if="suggest.product?.plu_code" color="day-textMinor">
          {{ $gettext('·') }}
        </Caption1>
        <Caption1 color="day-textMinor">
          <SuggestPricePerCountRow :suggest="suggest" />
        </Caption1>
      </div>
      <SuggestCount v-if="!suggest.vars.more_product && needCounter" :collected="collected" :suggest="suggest" />
      <Body2 v-else-if="needCounter" class="font-medium">{{ countText }} </Body2>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FragileBadge, WeightBadge } from '@/fsd/entities/badges';
import { ImgCardList } from '@/fsd/shared/ui/imgCardList';
import Suggest from '@/models/Suggest';
import { getCountText } from '@/ui/common/suggest-card/formatters/count-formatter';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, defineProps, withDefaults } from 'vue';
import { getImgBadge } from '../../tools/getImgBadge';
import SuggestCount from './suggest-count.vue';
import SuggestPricePerCountRow from './suggest-price-per-count.vue';

interface CardInListProps {
  suggest: Suggest;
  collected?: number;
  needCounter?: boolean;
}

const props = withDefaults(defineProps<CardInListProps>(), {
  collected: 0,
  needCounter: true,
});

const countText = computed(() => {
  const result_count = props.suggest.result_count || props.collected || 0;

  return getCountText(!!props.suggest.product?.isTrueWeight, result_count);
});

const badges = computed(() => {
  if (props.suggest.vars.more_product) {
    return [];
  }
  return getImgBadge(props.suggest);
});
</script>
