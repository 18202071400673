<template>
  <Layout class="flex flex-col">
    <template #header>
      <Bar
        :caption="title"
        :text="$gettext('Сканирование QR-кода курьера')"
        :icons="{ left: 'back' }"
        :menu-config="[]"
        @close-click="$router.back()"
      />
    </template>

    <template #default>
      <div class="flex flex-col justify-center items-center p-4 h-full">
        <span class="text-3xl font-bold text-center mb-16">
          {{ $gettext('Отсканируйте QR-код курьера') }}
        </span>
        <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
      </div>
    </template>
  </Layout>
</template>

<script lang="ts">
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';

export default {
  name: 'CourierQr',
  components: {
    Bar,
    Layout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
