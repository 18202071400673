<template>
  <div>
    <div class="picture relative">
      <div class="img-container">
        <ImageWrapper :img-src="product?.images[0]" />
      </div>
    </div>
    <div data-test="about product" class="info">
      <Typography data-test="product title" type="h3" color="title" align="center" margin="12px 16px">
        {{ product.title }}
      </Typography>
    </div>
    <Typography type="sub-title-italic" align="center" margin="0 0 12px 0">
      · {{ productStorageTypeTitle }} ·
    </Typography>
    <template v-if="needShowInfo">
      <InfoRow
        v-if="product.external_id"
        :title="$gettext('Артикул')"
        :value="product.external_id"
        data-test="product card vendor row"
      />
      <InfoRow v-if="product.valid !== null" :title="$gettext('Срок хранения')" :value="product.valid || '-'" />
      <InfoRow
        v-if="product.write_off_before !== null"
        :title="$gettext('Списать за')"
        :value="product.write_off_before"
      />
      <InfoRow
        v-if="
          product.parent_id &&
          product.type_accounting === 'weight' &&
          (product.lower_weight_limit || product.upper_weight_limit)
        "
        :title="$gettext('Весовая группа')"
        :value="weightGroup"
        data-test="info-row weight-group"
      />
      <InfoRow
        v-if="product.quants > 1"
        :title="$gettext('В одной упаковке')"
        :value="quants"
        data-test="info-row quants"
      />
    </template>
  </div>
</template>

<script lang="ts">
import Product from '@/models/Product';
import { storageTypesInfo } from '@/temp/constants/translations';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Typography from '@/ui/common/typography.vue';
import InfoRow from '@/ui/home/product-card/common/info-row.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    ImageWrapper,
    InfoRow,
    Typography,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    needShowInfo: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    productStorageTypeTitle(): string {
      const tag = this.product.tags[0] || 'empty';
      return storageTypesInfo[tag];
    },
    weightGroup(): string {
      switch (true) {
        case Boolean(this.product.lower_weight_limit && this.product.upper_weight_limit):
          return this.$gettext('от %{lower} г до  %{upper} г', {
            lower: String(this.product.lower_weight_limit),
            upper: String(this.product.upper_weight_limit),
          });
        case Boolean(this.product.lower_weight_limit):
          return this.$gettext('от %{lower} г', {
            lower: String(this.product.lower_weight_limit),
          });
        case Boolean(this.product.upper_weight_limit):
          return this.$gettext('до  %{upper} г', {
            upper: String(this.product.upper_weight_limit),
          });
        default:
          return '';
      }
    },
    quants(): string {
      return `${this.product?.quants} ${getQuantUnit(this.product?.quant_unit, this.product?.quants)}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.picture {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.img-container {
  height: 174px;
  width: 174px;
  display: flex;
  position: relative;
}
</style>
