<template>
  <SuggestCard :collected="numCollected" :suggest="suggest">
    <template #right-button>
      <SuggestCardRightButtons
        :collected="numCollected"
        :suggest="suggest"
        @menu="$emit('menu')"
        @remove="$emit('remove')"
      />
    </template>
    <template #childrenSuggests>
      <template v-for="child in childSuggests" :key="child.suggest_id">
        <DividerPlus />
        <div class="flex flex-row gap-2 pl-2 pr-4 py-2 justify-between">
          <SuggestCardInfo :collected="child.result_count || 0" :suggest="child" />
          <button class="flex" @click.stop="$emit('removeChild', child)">
            <IconBasketRound />
          </button>
        </div>
      </template>
    </template>
    <template #buttons>
      <SuggestCardButtons
        v-if="needButtons"
        :collected="numCollected"
        :suggest="suggest"
        :need-confirm="needConfirm"
        :confirmed="confirmed"
        @change="$emit('change')"
        @ready="$emit('ready')"
        @confirm="$emit('confirm')"
      />
    </template>
  </SuggestCard>
</template>

<script setup lang="ts">
import { SuggestCard, SuggestCardInfo } from '@/fsd/entities/suggest';
import SuggestCardRightButtons from '@/fsd/features/suggest/ui/order-retail-suggest-card/suggest-card-right-buttons.vue';
import IconBasketRound from '@/fsd/shared/icons/icon-basket-round.vue';
import { DividerPlus } from '@/fsd/shared/ui/dividerPlus';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { computed, defineEmits, defineProps, withDefaults } from 'vue';
import SuggestCardButtons from './suggest-card-buttons.vue';

interface SuggestCardProps {
  suggest: Suggest;
  childSuggests: Suggest[];
  collected: string[] | number;
  needButtons?: boolean;
  needRightButtons?: boolean;
  needConfirm?: boolean;
  confirmed?: boolean;
}

const props = withDefaults(defineProps<SuggestCardProps>(), {
  needButtons: true,
  needRightButtons: true,
});

defineEmits<{
  (e: 'change'): void;
  (e: 'remove'): void;
  (e: 'menu'): void;
  (e: 'ready'): void;
  (e: 'confirm'): void;
  (e: 'removeChild', suggest: Suggest): void;
}>();

const numCollected = computed<number>(() => {
  if (!props.collected) return 0;
  if (typeof props.collected === 'number') return props.collected;

  if (props.suggest.product?.isTrueWeight) {
    return Product.weightFromBarcode(props.collected);
  } else {
    return props.collected.length;
  }
});
</script>
