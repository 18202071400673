<template>
  <typo-base style="font-size: 14px; line-height: 15px" :class="colorClass">
    <slot />
  </typo-base>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
