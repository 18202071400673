<template>
  <div class="flex flex-row p-2 gap-2">
    <UiButton
      v-if="needChange"
      data-test="suggest-card change"
      :data-index="suggest.product_id"
      background-color="secondary"
      @click="$emit('change')"
    >
      <template #icon><IconPlus /></template>
      {{ $gettext('Замена') }}
    </UiButton>
    <UiButton
      v-if="needConfirm"
      data-test="suggest-card confirm"
      :background-color="confirmed ? 'success' : 'secondary'"
      @click="$emit('confirm')"
    >
      <template #icon>
        <Completed v-if="confirmed" />
        <Check v-else />
      </template>
      {{ confirmed ? $gettext('Согласовано') : $gettext('Согласовать') }}
    </UiButton>
    <UiButton
      v-if="collected && suggest.status !== SuggestStatusEnum.done"
      data-test="suggest-card ready"
      background-color="secondary"
      @click="$emit('ready')"
    >
      <template #icon><Check /></template>
      {{ $gettext('Собрано') }}
    </UiButton>
  </div>
</template>
<script setup lang="ts">
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import Check from '@/temp/icons/check.vue';
import Completed from '@/temp/icons/completed.vue';
import IconPlus from '@/temp/icons/icon-plus.vue';
import { $gettext } from '@/temp/plugins/gettext';
import UiButton from '@/ui/common/ui-button.vue';

import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useOrders } from '@/store/modules/orders';
import { computed, defineEmits, defineProps, toRefs } from 'vue';

interface CardButtonsProps {
  suggest: Suggest;
  collected: number;
  needConfirm?: boolean;
  confirmed?: boolean;
}

const props = defineProps<CardButtonsProps>();
const { suggest } = toRefs(props);
const order = computed<OrderOrderRetail>(() => {
  return useOrders().orderById(suggest.value.order_id) as OrderOrderRetail;
});

defineEmits<{
  (e: 'change'): void;
  (e: 'ready'): void;
  (e: 'confirm'): void;
}>();

const needChange = computed(() => {
  return !suggest.value.isFull && !suggest.value.vars.more_product && order.value.isReplaceItemsMethod;
});
</script>
