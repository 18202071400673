<template>
  <Row v-if="needShow" :label="label" :value="value" mode="vertical" data-test="suggest-details reason row" />
</template>

<script lang="ts">
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

interface Reasons {
  [key: string]: string;
}

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    reasons: {
      type: Object as PropType<Reasons>,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
  computed: {
    needShow(): boolean {
      if (this.model.reason && this.step !== 'reason') {
        return true;
      }
      return false;
    },
    label(): string {
      return this.$gettext('Причина списания');
    },
    value(): string {
      if (this.model.reason) {
        return this.reasons[this.model.reason];
      }
      return '';
    },
  },
});
</script>
