<template>
  <Overlay>
    <div class="bg-main pointer-events-auto h-screen" data-test="suggest-details container">
      <Layout>
        <template #default>
          <Bar
            :icons="{ left: 'back' }"
            :menu-config="suggestMenuConfig"
            :menu-title="product.title"
            is-fixed
            @close-click="$emit('close')"
          />
          <div class="p-4">
            <div class="flex justify-center">
              <div class="flex img-container">
                <ImageWrapper :img-src="product?.images[0]" />
              </div>
            </div>
            <div class="flex flex-col">
              <span class="text-2xl font-medium my-3 text-primary text-center">
                {{ product.title }}
              </span>
              <span class="text-sm mb-3 text-center text-sub-title italic"> · {{ productStorageTypeTitle }} · </span>
              <Row :label="$gettext('На полке')" :value="availableOnShelf" />
            </div>
          </div>
        </template>
        <template #footer>
          <div class="bg-main max-w-full mt-auto">
            <CountField
              :product-id="product && product.product_id"
              :initial-value="count"
              :disabled="!shelfId"
              :hint-text="hintText"
              :max="availableCountOnShelf"
              @confirm-input="finish"
            />
          </div>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { storageTypesInfo } from '@/temp/constants/translations';
import CountField from '@/temp/fields/count/count-field.vue';
import { $gettext } from '@/temp/plugins/gettext';
import Bar from '@/ui/common/bar/bar.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Row from '@/ui/common/row/row.vue';
import { computed, defineEmits, defineProps, ref } from 'vue';

const { showLoader } = useLoader();
const shelvesStore = useShelves();
const productsStore = useProducts();

interface Required {
  count?: number;
  shelf_id?: Shelf['shelf_id'];
  product_id: Product['product_id'];
}

interface ProductDetailsProps {
  required: Required;
}

const props = defineProps<ProductDetailsProps>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'remove', required: Required): void;
  (e: 'finish', required: Required): void;
}>();

const count = ref<number>(props.required?.count || 1);
const shelfId = ref<string>(props.required?.shelf_id || '');

const product = computed<Product>(() => productsStore.productById(props.required.product_id)!);

const productStorageTypeTitle = computed<string>(() => storageTypesInfo[product.value.tags[0] || 'empty']);

const hintText = computed<string>(() => {
  if (shelfId.value) {
    return $gettext('Сколько расходника хотите списать?');
  }
  return $gettext('Отсканируйте полку');
});

const availableCountOnShelf = computed<number>(() => {
  if (!shelfId.value) {
    return 0;
  }
  const available = productsStore.availableByProductId(props.required.product_id);
  if (!available) {
    return 0;
  }
  const productOnShelf = available.find(a => a.shelf_id === shelfId.value);
  if (productOnShelf) {
    return productOnShelf.count;
  }
  return 0;
});

const availableOnShelf = computed<string>(() => {
  if (availableCountOnShelf.value) {
    return $gettext('%{count} шт.', { count: String(availableCountOnShelf.value) });
  }
  return '-';
});

const suggestMenuConfig = computed<MenuItemConfig[]>(() => {
  const menuConfig: MenuItemConfig[] = [];
  const removeBtn: MenuItemConfig = {
    buttonText: $gettext('Удалить из списка сканирования'),
    onClick: () => emits('remove', props.required),
    dataTest: 'delete-item btn',
  };
  menuConfig.push(removeBtn);

  return menuConfig;
});

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const shelf = await shelvesStore.getShelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const available = productsStore.availableByProductId(props.required.product_id);
    if (!available) {
      Alerts.error($gettext('Продукт отсутствует на полке'));
      return true;
    }
    const productOnShelf = available.find(a => a.shelf_id === shelf.shelf_id);
    if (!productOnShelf || !productOnShelf.count) {
      Alerts.error($gettext('Продукт отсутствует на полке'));
      return true;
    }
    shelfId.value = shelf.shelf_id;
    closeLoader();
    return false;
  } catch (error) {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
});

const finish = async (count: number): Promise<void> => {
  emits('finish', {
    count,
    shelf_id: shelfId.value,
    product_id: props.required.product_id,
  });
};
</script>

<style lang="scss" scoped>
.img-container {
  min-height: 174px;
  width: 174px;
}
</style>
