<template>
  <DevtoolsSection>
    <template #title> SSE </template>
    <Log
      v-for="event in eventsSse"
      :key="event.lastEventId"
      :date="event.date"
      :type="event.type"
      :content="event.lastEventId.slice(-6)"
      :more-info="!!event.date.length"
      @more-info="visibleLog = event"
    />
  </DevtoolsSection>

  <LogDetails
    v-if="visibleLog"
    class="fixed top-0 left-0 w-screen h-screen z-10"
    :sse-log="visibleLog"
    @close="visibleLog = undefined"
  />
</template>

<script setup lang="ts">
import LogDetails from '@/devtools/sections/log-details.vue';
import Log from '@/devtools/sections/log.vue';
import DevtoolsSection from '@/devtools/sections/section/devtools-section.vue';
import { SseLog } from '@/devtools/sections/sse/sseLog';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { ref } from 'vue';
const { eventsSse } = useDevtoolsStore();
const visibleLog = ref<SseLog>();
</script>
