import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const trashReasons = gettextWrapper({
  TRASH_DAMAGE: $gettext('Повреждена упаковка'),
  TRASH_TTL: $gettext('Истек срок годности'),
  TRASH_DECAYED: $gettext('Потерял товарный вид'),
  TRASH_ACCIDENT: $gettext('Поломка оборудования'),
  TRASH_ORDINANCE: $gettext('По приказу/распоряжению'),
  TRASH_MOL: $gettext('Списание на мол'),
});
