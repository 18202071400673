enum PlatformEnum {
  web = 'web',
  cordova = 'cordova',
  capacitor = 'capacitor',
}

export const PlatformService = {
  getMode() {
    if (localStorage.getItem('isCapacitor')) {
      return PlatformEnum.capacitor;
    }
    if (localStorage.getItem('isWeb')) {
      return PlatformEnum.web;
    }
    return PlatformEnum.cordova;
  },
  isWeb() {
    return this.getMode() === PlatformEnum.web;
  },
  isCordova() {
    return this.getMode() === PlatformEnum.cordova;
  },
  isCapacitor() {
    return this.getMode() === PlatformEnum.capacitor;
  },
};
