/**
 * Сервис для работы с менеджером загрузки файлов
 */

import { logger } from '@/temp/plugins/logs';

export const DownloadManagerService = {
  download(url: string, fileName: string, description: string = ''): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // @ts-expect-error
      if (!(window.cordova && window.cordova.plugins.DownloadManager)) {
        logger('Не удалось получить доступ к плагину cordova.plugins.DownloadManager');
        return;
      }
      // @ts-expect-error
      window.cordova.plugins.DownloadManager.download(url, fileName, description, resolve(true), reject(false), {
        setDestinationInExternalPublicDir: true,
      });
    });
  },
};
