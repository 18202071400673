import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const shelfTagsInStowage = gettextWrapper({
  freezer: $gettext('морозильник'),
  refrigerator: $gettext('холодильник'),
  empty: $gettext('теплую полку'),
  freezer2_2: $gettext('холодильник -2+2'),
  domestic: $gettext('бытовая химия'),
  freezer24: $gettext('морозильник −24'),
  safe: $gettext('сейф'),
  parcel: $gettext('посылки'),
});

export const storageTypesInfo = gettextWrapper({
  empty: $gettext('Хранится на теплых полках'),
  refrigerator: $gettext('Хранится в холодильнике'),
  freezer2_2: $gettext('Хранится в холодильнике -2+2'),
  freezer: $gettext('Хранится в морозильнике'),
  domestic: $gettext('Хранится на полках с бытовой химией'),
  freezer24: $gettext('Хранится в морозильнике −24'),
  safe: $gettext('Хранится в сейфе'),
  parcel: $gettext('Хранится на полке посылок'),
});
