/* eslint-disable no-console */
import { EventName, EventValue, LogSources } from '@/temp/plugins/logs/types';
import { errorShouldNotBeIgnored } from '@/utils';

const Style = {
  base: 'color: #fff; background-color: #444; padding: 2px 4px; border-radius: 2px;',
  get error() {
    return this.base + 'color: #eee; background-color: red';
  },
  get success() {
    return this.base + 'background-color: green';
  },
  get method() {
    return this.base + 'background-color: Coral';
  },
};

export function logHandler(eventName: EventName, eventValue?: EventValue) {
  const valueText = eventValue ? JSON.stringify(eventValue) : eventValue;
  console.groupCollapsed(`%c${eventName}${valueText !== undefined ? ': ' + valueText : ''}`, Style.success);
  console.log('Лог: ', eventName);
  if (eventValue !== undefined) {
    console.log(`Значение: %c${eventValue}`, Style.base);
  }
  console.log('Файл: ');
  console.groupEnd();
}

export function errorHandler(error: unknown, options?: Ya.Rum.LogErrorOptions) {
  let errorToSend: Error;
  switch (typeof error) {
    case 'string':
      errorToSend = new Error(error);
      break;
    case 'object':
      errorToSend = error instanceof Error ? error : new Error(JSON.stringify(error));
      break;
    case 'function':
      errorToSend = new Error('Передана функция в логгер');
      break;
    default:
      errorToSend = new Error('Неизвестный тип данных :' + JSON.stringify(error));
  }
  console.groupCollapsed('%c' + errorToSend, Style.error);
  console.error(errorToSend);
  for (const optionsKey in options) {
    console.log(optionsKey, options[optionsKey]);
  }
  console.groupEnd();

  // @ts-expect-error
  if (errorShouldNotBeIgnored(errorToSend) && window?.Ya?.Rum?.logError) {
    Ya.Rum.logError({ source: 'logger' as LogSources, page: window.location.href, ...options }, errorToSend);
  }
}

export function eventHandler(event: string, value?: string | number, options?: Ya.Rum.LogEventOptions) {
  logHandler(event, value);
  if (Number.isInteger(value)) {
    Ya.Rum.logEventInteger(event, (value as number) || 0, options);
  } else {
    Ya.Rum.logEventString(event, (value as string) || 'Нет значения', options);
  }
}
