<template>
  <Layout>
    <template #header>
      <Bar :caption="caption" :text="barText" :icons="barIcon" @close-click="$emit('close-click')" />
    </template>
    <slot />
  </Layout>
</template>

<script lang="ts">
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Wizard',
  components: {
    Bar,
    Layout,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    currentStepIndex: {
      type: Number,
      default: 0,
    },
    stepsNumber: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close-click'],
  computed: {
    barText(): string {
      return this.$gettext('Шаг %{stepIndex} из %{stepsNumber}', {
        stepIndex: String(this.currentStepIndex + 1),
        stepsNumber: String(this.stepsNumber),
      });
    },
    barIcon(): object {
      if (this.currentStepIndex === 0) return { left: 'close' };
      else return { left: 'back' };
    },
  },
});
</script>
