<template>
  <div class="flex flex-col gap-6">
    <InfoRow
      v-if="product.external_id"
      :title="$gettext('Артикул')"
      :value="product.external_id"
      data-test="product card vendor row"
    />
    <InfoRow
      data-test="info-row valid"
      :title="$gettext('Срок хранения')"
      :value="String(product.valid === null ? '-' : product.valid)"
    />
    <InfoRow
      v-if="product.write_off_before !== null"
      :title="$gettext('Списать за')"
      :value="String(product.write_off_before)"
    />
    <InfoRow
      v-if="
        product.parent_id &&
        product.type_accounting === 'weight' &&
        (product.lower_weight_limit || product.upper_weight_limit)
      "
      :title="$gettext('Весовая группа')"
      :value="weightGroup"
      data-test="info-row weight-group"
    />
    <InfoRow
      v-if="product.quants > 1"
      :title="$gettext('В одной упаковке')"
      :value="quants"
      data-test="info-row quants"
    />
  </div>
</template>

<script lang="ts" setup>
import InfoRow from '@/fsd/widgets/productCard/InfoRow.vue';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import { $gettext } from '@/temp/plugins/gettext';
import { computed, toRefs } from 'vue';

const props = defineProps<{ product_id: Product['product_id'] }>();
const { product_id } = toRefs(props);
const product = computed(() => {
  return useProducts().productById(product_id.value);
});

const weightGroup = computed(() => {
  if (!product.value) return '';
  const lower = String(product.value.lower_weight_limit);
  const upper = String(product.value.upper_weight_limit);
  switch (true) {
    case Boolean(lower && upper):
      return $gettext('от %{lower} г до  %{upper} г', {
        lower,
        upper,
      });
    case Boolean(lower):
      return $gettext('от %{lower} г', {
        lower,
      });
    case Boolean(upper):
      return $gettext('до  %{upper} г', {
        upper,
      });
    default:
      return '';
  }
});

const quants = computed(() => {
  if (!product.value) return '';
  const { quants, quant_unit } = product.value;

  return `${quants} ${getQuantUnit(quant_unit, quants)}`;
});
</script>
