<template>
  <div
    class="flex items-center justify-between h-full"
    :class="{ 'flex-row-reverse': side === 'left' }"
    :data-test="`product swipe button ${side}`"
  >
    <div class="w-10 h-10">
      <ImageWrapper :img-src="suggest.imageSrc">
        <Completed v-if="isCompleted" class="top-1/3 left-1/3 z-10 absolute" />
      </ImageWrapper>
    </div>
    <div class="flex flex-col items-center justify-evenly h-full w-1/2">
      <Body2 class="font-medium max-w-full truncate">{{ shelf }}</Body2>
      <Body2 class="font-medium max-w-full truncate">{{ productCountValue }}</Body2>
    </div>
    <div>
      <IconChevron v-if="side === 'right'" />
      <IconChevron v-else class="rotate-180" />
    </div>
  </div>
</template>

<script lang="ts">
import { Problem } from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import Completed from '@/temp/icons/completed.vue';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProductSwipeButton',
  components: {
    IconChevron,
    Completed,
    ImageWrapper,
    Body2,
  },
  props: {
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    side: {
      type: String,
      required: true,
    },
  },
  setup() {
    const shelvesStore = useShelves();
    const productsStore = useProducts();

    return { shelvesStore, productsStore };
  },
  computed: {
    shelf(): string {
      if (!this.suggest.shelf_id) {
        return '—';
      }
      const shelf = this.shelvesStore.shelfById(this.suggest.shelf_id);
      if (shelf) {
        return shelf.title;
      }
      return '—';
    },
    product(): Product {
      return this.productsStore.productById(this.suggest.product_id)!;
    },
    productCountValue(): string {
      if (this.suggest.isItemSuggest) {
        return this.$gettext('%{count} шт.', { count: '1' });
      }
      let totalCount = (this.suggest && this.suggest.count) || 0;
      if (this.problem && this.suggest) {
        totalCount = this.suggest.count! + this.problem.count!;
      }
      return getCountToView({ count: totalCount, type_accounting: this.product.type_accounting });
    },
    isCompleted(): boolean {
      return !this.isProblem && this.suggest.status === 'done';
    },
    isProblem(): boolean {
      return !!this.problem;
    },
    imgSrc(): string | undefined {
      return this.product?.images[0];
    },
    isItem(): boolean {
      return this.suggest.vars && this.suggest.vars.mode === 'item';
    },
  },
});
</script>
