export const enum StatusTypeEnum {
  complete = 'complete',
  waiting = 'waiting',
  error = 'error',
  custom = 'custom',
  processing = 'processing',
}

export interface StatusBadgeConfig {
  text: string;
  type: StatusTypeEnum;
}
