<template>
  <div ref="container" class="flex flex-col">
    <Title4 class="font-bold mb-4 text-left" color="primary">{{ suggest.product.title }}</Title4>
    <caption1 color="day-textMinor" class="flex pb-3 border-b border-day-line">
      {{
        $gettext('Количество %{n} из %{all} шт.', {
          n: String(collected),
          all: String(suggest.count),
        })
      }}
    </caption1>
    <div v-if="!suggest.product?.isTrueWeight" class="flex mt-3 pb-3 border-b border-day-line">
      <ButtonToModal data-test="suggest-modal remove-products" @click="emits('btnClick', 'removeProducts')">
        <template #icon>
          <IconReturn />
        </template>
        {{ $gettext('Уменьшить количество товара') }}
      </ButtonToModal>
    </div>
    <div v-if="suggest.product?.isTrueWeight && !suggest.result_count" class="flex mt-3 pb-3 border-b border-day-line">
      <ButtonToModal data-test="suggest-modal cancel" @click="emits('btnClick', 'cancelScans')">
        <template #icon>
          <IconReturn />
        </template>
        {{ $gettext('Отменить все сканирования') }}
      </ButtonToModal>
    </div>
    <div class="flex mt-3 pb-3">
      <ButtonToModal data-test="suggest-modal remove" text-color="red-dark" @click="emits('btnClick', 'removeSuggest')">
        <template #icon>
          <IconBasketRemove />
        </template>
        {{ $gettext('Удалить') }}
      </ButtonToModal>
    </div>
    <div class="flex mt-6">
      <UiButton data-test="suggest-modal close" background-color="secondary" @click="emits('btnClick')">
        {{ $gettext('Закрыть') }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconBasketRemove from '@/fsd/shared/icons/icon-basket-remove.vue';
import IconReturn from '@/fsd/shared/icons/icon-return.vue';
import { ButtonToModal } from '@/fsd/shared/ui/buttonToModal';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits, defineProps } from 'vue';

interface MenuModalProps {
  suggest: Suggest;
  collected: number;
}

defineProps<MenuModalProps>();

const emits = defineEmits<{ (e: 'btnClick', needDo?: 'removeSuggest' | 'removeProducts' | 'cancelScans'): void }>();
</script>
