import { onBeforeUnmount } from 'vue';
import { rumSpaManager } from '../tools/RumSpaManager';
import { RumOptions } from './types';
import { useMakeSubPage } from './useMakeSubPage';
import { useMetric } from './useMetric';

export const useRum = <M extends string>(options: RumOptions<M>) => {
  const { pageName, isBlock = false, pageLifecycleHooks = false, deltaLifecycleHooks = false, deltaName } = options;

  const { makeSubPage, finishSubPage } = useMakeSubPage(pageName, isBlock);
  const { startMetric, finishMetric, startReduceMetric, addDeltaReduceMetric, finishReduceMetric } =
    useMetric<M>(pageName);

  const startLoading = () => rumSpaManager.startDataLoading(pageName);

  const finishLoading = (isCache = false) => rumSpaManager.finishDataLoading(pageName, isCache);

  const startStubRendering = () => rumSpaManager.startStubRendering(pageName);

  const finishStubRendering = () => rumSpaManager.finishStubRendering(pageName);

  const startDataRendering = (shouldCallFinishDataRendering?: boolean) =>
    rumSpaManager.startDataRendering(pageName, shouldCallFinishDataRendering);

  const finishDataRendering = () => rumSpaManager.finishDataRendering(pageName);

  const sendMetric = (deltaName: M, delta?: number) =>
    delta !== undefined && rumSpaManager.sendDelta(pageName, deltaName, delta);

  if (pageLifecycleHooks) {
    let markName = '';
    makeSubPage();
    if (deltaLifecycleHooks && deltaName) {
      const { uniqueMarkName } = startMetric(deltaName);
      markName = uniqueMarkName;
    }

    onBeforeUnmount(() => {
      if (deltaLifecycleHooks && deltaName && markName) {
        finishMetric(markName, deltaName);
      }
      finishSubPage();
    });
  }

  return {
    makeSubPage,
    finishSubPage,

    startMetric,
    finishMetric,
    startReduceMetric,
    addDeltaReduceMetric,
    finishReduceMetric,

    startLoading,
    finishLoading,
    startStubRendering,
    finishStubRendering,
    startDataRendering,
    finishDataRendering,

    sendMetric,
  };
};
