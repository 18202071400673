<template>
  <Layout>
    <div class="flex flex-col px-4 py-3 gap-3 h-full">
      <Title2 class="font-bold">
        {{ title }}
      </Title2>
      <Body2 v-if="isOrderClosable">{{ $gettext('Для этого нажмите кнопку ниже') }}</Body2>
      <Body2 v-else>{{ $gettext('Пожалуйста, подождите несколько секунд') }}</Body2>
      <Hint v-if="order.hasCoffee" bg-color="bg-blue-info" data-test="hint coffee-reminder" class="mt-4">
        <template #image>
          <BagAndCoffee />
        </template>
        <template #text> {{ $gettext('Не забудьте про готовые напитки') }}</template>
        <template #sub-text> {{ $gettext('Заберите их у кофемашины') }}</template>
      </Hint>
      <div class="grow" />
      <BottleAndBags class="self-center" />
      <div class="grow" />
    </div>

    <template #footer>
      <LayoutFooter>
        <UiButton :is-disabled="!isOrderClosable" data-test="finish-order btn" @click="nextStage">
          {{ $gettext('Завершить заказ') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import BagAndCoffee from '@/fsd/shared/icons/bag-and-coffee/bag-and-coffee.vue';
import BottleAndBags from '@/fsd/shared/icons/bottle-and-bags.vue';
import Hint from '@/fsd/shared/ui/hint/hint.vue';
import BaseOrder from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from './tools/useMeasureOrderFinish';

const props = defineProps<{ order_id: BaseOrder['order_id'] }>();
const emit = defineEmits(['done']);

useRUMOrderFinishingStep({
  orderId: props.order_id,
  markName: OrderFinishingMarkNamesEnum.FINISH,
  deltaName: OrderFinishingDeltaNamesEnum.FINISH,
});

const order = computed<OrderOrder>(() => {
  return useOrders().orderById(props.order_id) as OrderOrder;
});

const title = computed(() => {
  if (isOrderClosable.value) {
    return $gettext('Заказ %{number} можно завершить', { number: order.value?.orderNumberForView });
  } else {
    return $gettext('Заказ %{number} пока нельзя завершить', { number: order.value?.orderNumberForView });
  }
});

const isOrderClosable = computed<boolean>(() => {
  if (!order.value) return false;
  return !order.value.isOrderPaused && order.value.problems.length === 0;
});

const nextStage = () => {
  emit('done');
};
</script>
