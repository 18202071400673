import { useRumStore } from '@/store/modules/rum';
import { rumSpaManager } from '../tools/RumSpaManager';

export const useMakeSubPage = (pageName: string, isBlock = false) => {
  const { addPage, removePage } = useRumStore();

  const makeSubPage = () => {
    if (addPage(pageName)) {
      rumSpaManager.makeSpaSubPage(pageName, isBlock);
    }
  };

  const finishSubPage = () => {
    if (removePage(pageName)) {
      rumSpaManager.finalizeSpaData(pageName);
    }
  };

  return { makeSubPage, finishSubPage };
};
