<template>
  <Blur>
    <AbsoluteCenter data-test="modal about-order">
      <template v-if="!APKtoInstall">
        <template v-if="!loading">
          <NeedUpdateWindow v-if="!hasError" @close="emits('close')" @load="loadUpdate" />
          <HasErrorUpdateWindow v-else @close="emits('close')" @load="loadUpdate" />
        </template>
        <LoadUpdateWindow v-else :progress="progress" />
      </template>
      <InstallUpdateWindow v-else @install="installAPK" />
    </AbsoluteCenter>
  </Blur>
</template>
<script setup lang="ts">
import HasErrorUpdateWindow from '@/fsd/features/tools/useWebViewUpdate/HasErrorUpdateWindow.vue';
import InstallUpdateWindow from '@/fsd/features/tools/useWebViewUpdate/InstallUpdateWindow.vue';
import LoadUpdateWindow from '@/fsd/features/tools/useWebViewUpdate/LoadUpdateWindow.vue';
import NeedUpdateWindow from '@/fsd/features/tools/useWebViewUpdate/NeedUpdateWindow.vue';
import AbsoluteCenter from '@/fsd/shared/ui/positions/AbsoluteCenter.vue';
import { logger } from '@/temp/plugins/logs';
import Blur from '@/temp/ui/blur/blur.vue';
import { Device } from '@capacitor/device';
import { Directory, Filesystem, ProgressListener } from '@capacitor/filesystem';
import { Installer } from '@lavka/capacitor-installer-plugin';
import { scanner } from '@lavka/capacitor-scanner-plagin';
import { defineEmits, ref } from 'vue';

const emits = defineEmits<{ (e: 'close'): void }>();
const loading = ref(false);
const hasError = ref(false);
const progress = ref(0);
const APKtoInstall = ref('');

const getApkName = async (): Promise<string> => {
  try {
    const result = ['chrome'];
    const { osVersion } = await Device.getInfo();
    const { cpu } = await scanner.info();
    const is7 = osVersion.includes('7');
    if (is7) result.push('7');
    const isArm = cpu.toLowerCase().includes('arm');
    result.push(isArm ? 'arm' : 'x86');
    const is64 = cpu.includes('64');
    if (is64) result.push('64');
    return result.join('_') + '.apk';
  } catch (e) {
    logger.error(e);
    throw e;
  }
};
const loadUpdate = async () => {
  loading.value = true;
  hasError.value = false;
  const updateProgress: ProgressListener = ({ bytes, contentLength }) => {
    progress.value = ~~((bytes / contentLength) * 100);
  };
  const { remove } = await Filesystem.addListener('progress', updateProgress);
  try {
    const apkName = await getApkName();
    const result = await Filesystem.downloadFile({
      url: 'https://lavkaitinfrastorage.s3.yandex.net/chrome/' + apkName,
      path: apkName,
      progress: true,
      directory: Directory.Documents,
    });
    APKtoInstall.value = result.path || '';
    await remove();
    loading.value = false;
    progress.value = 0;
  } catch (e) {
    logger.error(e);
    await remove();
    loading.value = false;
    progress.value = 0;
    hasError.value = true;
  }
};

const installAPK = async () => {
  Installer.install({ filePath: APKtoInstall.value });
};
</script>
