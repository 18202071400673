import { api } from '@/fsd/data/api/api.service';
import { CheckTrueMarkRequest } from '@/services/requests';
import { ERR } from '@/temp/constants/errors';
import { checkTrueMarkErrorsCode } from '@/temp/constants/translations/checkTrueMarkErrorsCode';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { isAxiosError } from 'axios';
export const TrueMark = {
  // проверка марки ЧЗ
  async check(request: CheckTrueMarkRequest) {
    // Пока что от нас требуется только сообщить о честном знаке, ответ нас не интересует, но когда-нибудь там будет что-то нужное
    try {
      return await api.check_true_mark(request);
    } catch (err: unknown) {
      if (!isAxiosError(err)) return;
      const response = err.response;
      if (!response) return;
      if (
        response.data.code === ERR.ER_BAD_TRUE_MARK &&
        ['NOT_APPLIED', 'NOT_VERIFIED'].includes(response.data.true_mark_status)
      ) {
        Notifications.modal({
          title: $gettext(
            'Отложите этот товар, возьмите другой и отсканируйте его марку. Марка не введена в оборот поставщиком',
          ),
        });
        throw err;
      }
      if (checkTrueMarkErrorsCode[response.data.code]) {
        Notifications.modal({
          title: checkTrueMarkErrorsCode[response.data.code],
        });
      } else {
        Notifications.modal({
          title: response.data.code,
          text: response.data.message,
        });
      }
      // TODO а нужна ли эта строчка?
      throw err;
    }
  },
};
