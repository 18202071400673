<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="16" fill="#5C5A57" fill-opacity="0.1" />
    <path
      transform="translate(-8, -3)"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.084 19.0007C16.084 19.9211 16.8302 20.6673 17.7507 20.6673C18.6711 20.6673 19.4173 19.9211 19.4173 19.0007C19.4173 18.0802 18.6711 17.334 17.7507 17.334C16.8302 17.334 16.084 18.0802 16.084 19.0007ZM24.0007 20.6673C23.0802 20.6673 22.334 19.9211 22.334 19.0007C22.334 18.0802 23.0802 17.334 24.0007 17.334C24.9211 17.334 25.6673 18.0802 25.6673 19.0007C25.6673 19.9211 24.9211 20.6673 24.0007 20.6673ZM30.2506 20.6673C29.3302 20.6673 28.584 19.9211 28.584 19.0007C28.584 18.0802 29.3302 17.334 30.2507 17.334C31.1711 17.334 31.9173 18.0802 31.9173 19.0007C31.9173 19.9211 31.1711 20.6673 30.2506 20.6673Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
