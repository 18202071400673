<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest card valid row" />
</template>

<script lang="ts">
//поле для отображения срока годности
import { AvailableItem } from '@/models/Item';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { DocTypes } from '@/temp/constants/translations/types';
import { getValidTitle } from '@/temp/constants/translations/validTitles';
import { AvailableProduct } from '@/types/product';
import Row from '@/ui/common/suggest-card/row.vue';
import { getFormatDate } from '@/utils';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct | AvailableItem>,
      default: undefined,
    },
  },
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  computed: {
    product(): Product {
      return this.productsStore.productById(this.suggest?.product_id)!;
    },
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.docType);
    },
    needShow(): boolean {
      //для КСГ показываем только для выполненных саджестов на контроль
      if (this.isControl) {
        return this.suggest?.type === 'shelf2box' && this.suggest.status === 'done';
      }
      //показываем всегда, если это не посылка
      if (['acceptance'].includes(this.docType)) {
        return this.suggest?.vars?.mode !== 'item';
      }
      if (['shelf'].includes(this.docType)) {
        return Boolean(this.productsStore.productById(this.availableProduct?.product_id));
      }
      return false;
    },
    label(): string {
      return getValidTitle({ docType: this.docType, suggest: this.suggest });
    },
    value(): string {
      if (this.isControl) {
        if (this.suggest?.status === 'done') {
          return getFormatDate(this.suggest.result_valid, { mode: 'productionDate', product: this.product });
        }
        return '-';
      }
      if (['acceptance'].includes(this.docType)) {
        return getFormatDate(this.suggest?.result_valid || this.suggest?.valid!);
      }
      if (['shelf'].includes(this.docType)) {
        return getFormatDate(this.availableProduct?.valid);
      }
      return getFormatDate(this.suggest?.conditions.valid!);
    },
  },
});
</script>
