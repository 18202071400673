<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.36719 6.88281C9.26953 6.78516 9.09375 6.62891 8.97656 6.55078C8.83984 6.45312 8.68359 6.47266 8.56641 6.57031L8.15625 6.98047C8 7.15625 7.92188 7.37109 7.92188 7.56641C7.92188 7.66406 8 7.76172 8.05859 7.82031C8.11719 7.85938 8.19531 7.91797 8.27344 7.97656C8.82031 8.54297 8.82031 9.42188 8.27344 9.96875L6.94531 11.2773C6.39844 11.8438 5.5 11.8438 4.95312 11.2773C4.40625 10.7305 4.42578 9.83203 4.97266 9.28516L5.22656 9.03125C5.32422 8.95312 5.34375 8.81641 5.30469 8.69922C5.1875 8.36719 5.12891 8.01562 5.10938 7.68359C5.10938 7.41016 4.77734 7.27344 4.58203 7.46875L3.85938 8.19141C2.70703 9.34375 2.70703 11.2383 3.85938 12.3906C5.01172 13.543 6.90625 13.543 8.05859 12.3906L9.36719 11.082C9.36719 11.082 9.36719 11.082 9.36719 11.0625C10.5195 9.92969 10.5391 8.05469 9.36719 6.88281ZM12.1211 4.12891C10.9688 2.97656 9.07422 2.97656 7.92188 4.12891L6.61328 5.4375C6.61328 5.4375 6.61328 5.4375 6.61328 5.45703C5.46094 6.58984 5.44141 8.46484 6.61328 9.63672C6.71094 9.73438 6.88672 9.89062 7.00391 9.96875C7.14062 10.0664 7.29688 10.0469 7.41406 9.94922L7.82422 9.53906C7.98047 9.36328 8.05859 9.14844 8.05859 8.95312C8.05859 8.85547 7.98047 8.75781 7.92188 8.69922C7.86328 8.66016 7.78516 8.60156 7.70703 8.54297C7.16016 7.97656 7.16016 7.09766 7.70703 6.55078L9.03516 5.24219C9.58203 4.67578 10.4805 4.67578 11.0273 5.24219C11.5742 5.78906 11.5547 6.6875 11.0078 7.23438L10.7539 7.48828C10.6562 7.56641 10.6367 7.70312 10.6758 7.82031C10.793 8.15234 10.8516 8.50391 10.8711 8.83594C10.8711 9.10938 11.2031 9.24609 11.3984 9.05078L12.1211 8.32812C13.2734 7.17578 13.2734 5.28125 12.1211 4.12891Z"
      stroke="white"
      stroke-width="3"
    />
    <path
      d="M9.36719 6.88281C9.26953 6.78516 9.09375 6.62891 8.97656 6.55078C8.83984 6.45312 8.68359 6.47266 8.56641 6.57031L8.15625 6.98047C8 7.15625 7.92188 7.37109 7.92188 7.56641C7.92188 7.66406 8 7.76172 8.05859 7.82031C8.11719 7.85938 8.19531 7.91797 8.27344 7.97656C8.82031 8.54297 8.82031 9.42188 8.27344 9.96875L6.94531 11.2773C6.39844 11.8438 5.5 11.8438 4.95312 11.2773C4.40625 10.7305 4.42578 9.83203 4.97266 9.28516L5.22656 9.03125C5.32422 8.95312 5.34375 8.81641 5.30469 8.69922C5.1875 8.36719 5.12891 8.01562 5.10938 7.68359C5.10938 7.41016 4.77734 7.27344 4.58203 7.46875L3.85938 8.19141C2.70703 9.34375 2.70703 11.2383 3.85938 12.3906C5.01172 13.543 6.90625 13.543 8.05859 12.3906L9.36719 11.082C9.36719 11.082 9.36719 11.082 9.36719 11.0625C10.5195 9.92969 10.5391 8.05469 9.36719 6.88281ZM12.1211 4.12891C10.9688 2.97656 9.07422 2.97656 7.92188 4.12891L6.61328 5.4375C6.61328 5.4375 6.61328 5.4375 6.61328 5.45703C5.46094 6.58984 5.44141 8.46484 6.61328 9.63672C6.71094 9.73438 6.88672 9.89062 7.00391 9.96875C7.14062 10.0664 7.29688 10.0469 7.41406 9.94922L7.82422 9.53906C7.98047 9.36328 8.05859 9.14844 8.05859 8.95312C8.05859 8.85547 7.98047 8.75781 7.92188 8.69922C7.86328 8.66016 7.78516 8.60156 7.70703 8.54297C7.16016 7.97656 7.16016 7.09766 7.70703 6.55078L9.03516 5.24219C9.58203 4.67578 10.4805 4.67578 11.0273 5.24219C11.5742 5.78906 11.5547 6.6875 11.0078 7.23438L10.7539 7.48828C10.6562 7.56641 10.6367 7.70312 10.6758 7.82031C10.793 8.15234 10.8516 8.50391 10.8711 8.83594C10.8711 9.10938 11.2031 9.24609 11.3984 9.05078L12.1211 8.32812C13.2734 7.17578 13.2734 5.28125 12.1211 4.12891Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
