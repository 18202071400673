<template>
  <Layout>
    <div class="flex flex-col h-full" :data-test="`${name} step`">
      <Title2 class="font-bold px-4 pt-2 my-[18px]">{{ title }}</Title2>
      <Body2 class="px-4 pb-4">{{ text }}</Body2>
      <label class="flex justify-between mx-4 py-4 border-b" :data-test="`${name} date`">
        <div class="flex items-center">
          <Calendar /> <Body2 class="ml-4">{{ $gettext('Дата') }}</Body2>
        </div>
        <div class="flex items-center">
          <Body2>
            {{ currentDate }}
            <input
              ref="DateRef"
              type="date"
              class="w-0 opacity-0"
              :value="date"
              :max="inputMaxDate"
              :min="inputMinDate"
              @click="$refs.DateRef?.showPicker"
              @input="changeDate"
            />
          </Body2>
          <Chevron />
        </div>
      </label>
      <label class="flex justify-between mx-4 py-4 border-b" :data-test="`${name} time`">
        <div class="flex items-center">
          <Clock /> <Body2 class="ml-4">{{ $gettext('Время') }}</Body2>
        </div>
        <div class="flex items-center">
          <Body2 :class="{ 'opacity-50': !time }">
            {{ time ? time : $gettext('Не указано') }}
            <input
              ref="TimeRef"
              type="time"
              class="w-0 opacity-0"
              :value="time"
              @click="$refs.TimeRef?.showPicker"
              @input="changeTime"
            />
          </Body2>
          <Chevron />
        </div>
      </label>
    </div>
    <template #footer>
      <div class="p-2">
        <UiButton
          background-color="primary"
          :is-disabled="isDisabled"
          :data-test="`${name} continue`"
          @click="$emit('done', dateAndTime)"
        >
          {{ btnText }}
        </UiButton>
      </div>
    </template>
  </Layout>
</template>
<script lang="ts">
import { defaultSourceFormat, getDefaultLocalFormat, timeFormat } from '@/temp/constants/dateFormat';
import Calendar from '@/temp/icons/calendar.vue';
import Chevron from '@/temp/icons/chevron.vue';
import Clock from '@/temp/icons/clock.vue';
import Layout from '@/ui/common/layout.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { getItem, setItem } from '@/utils/sessionStorageHelper';
import dayjs from 'dayjs';
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'InputDateAndTime',
  components: {
    Layout,
    Clock,
    Chevron,
    Calendar,
    Body2,
    Title2,
    UiButton,
  },
  props: {
    name: { type: String, required: true },
    btnText: { type: String },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    propsDate: { type: Object as PropType<Date>, default: undefined },
    minDate: { type: Object as PropType<Date> },
    maxDate: { type: Object as PropType<Date> },
  },
  emits: ['done'],
  setup(props) {
    const savedDate = getItem('date', props.name);
    const savedTime = getItem('time', props.name);
    const date = ref<string>(dayjs(savedDate || props.propsDate).format(defaultSourceFormat));
    const time = ref<string>(savedTime || (props.propsDate ? dayjs(props.propsDate).format(timeFormat) : ''));
    return { date, time };
  },
  computed: {
    isDisabled(): boolean {
      const dateBeforeMin = this.minDate && dayjs(this.minDate).isAfter(this.dateAndTime);
      const dateAfterMax = this.maxDate && dayjs(this.maxDate).isBefore(this.dateAndTime);
      return !this.time || Boolean(dateBeforeMin) || Boolean(dateAfterMax);
    },
    inputMinDate(): string {
      return this.minDate ? dayjs(this.minDate).format(defaultSourceFormat) : '';
    },
    inputMaxDate(): string {
      return this.maxDate ? dayjs(this.maxDate).format(defaultSourceFormat) : '';
    },
    currentDate(): string {
      return dayjs(this.date).format(getDefaultLocalFormat());
    },
    dateAndTime(): Date {
      if (!this.time) return dayjs(this.date).toDate();
      const [hours, minutes] = this.time.split(':');
      return dayjs(this.date).set('hour', +hours).set('minutes', +minutes).toDate();
    },
  },
  methods: {
    changeDate(event): void {
      const newDate = event.target.value;
      // 'YYYY-MM-DD'
      if (!newDate) return;
      this.date = newDate;
      setItem('date', this.name, this.date);
    },
    changeTime(event): void {
      const newTime = event.target.value;
      if (!newTime) return;
      // 'HH:mm'
      this.time = newTime;
      setItem('time', this.name, newTime);
    },
  },
});
</script>

<style lang="scss" scoped>
input[type='date']::-webkit-calendar-picker-indicator,
input[type='time']::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  display: none;
}
</style>
