<template>
  <RoundedCard24 class="flex flex-col">
    <IconDownload class="mx-2 mt-2" />
    <Title4 class="font-medium mx-4 my-3">
      {{ $gettext('Установка обновления') }}
    </Title4>
    <Body2 class="mx-4 mb-3">
      {{ $gettext('После установки перезагрузите ТСД, чтобы приложение работало без ошибок') }}
    </Body2>
    <div class="m-2">
      <UiButton background-color="primary" @click="emits('install')"> Установить</UiButton>
    </div>
  </RoundedCard24>
</template>
<script setup lang="ts">
import IconDownload from '@/fsd/shared/icons/download/icon-download.vue';
import RoundedCard24 from '@/fsd/shared/ui/cards/RoundedCard24.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineEmits } from 'vue';

const emits = defineEmits<{ (e: 'install'): void }>();
</script>
