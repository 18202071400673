<template>
  <div class="progress-container">
    <div
      v-for="(item, index) in config"
      :key="index"
      class="progress-line"
      :class="{ separate: config[index + 1] && config[index + 1].count, [item.color]: true }"
      :style="{ width: progressLineWidth(index) }"
    />
  </div>
</template>
<script lang="ts">
import { ProgressConfig } from '@/ui/common/bar/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    config: {
      type: Array as PropType<ProgressConfig[]>,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  computed: {
    allProgressLineWidth(): number[] {
      let result = this.config.map(item => {
        return (item.count / this.total) * 100;
      });
      let delta = 0;
      result.forEach((width, index) => {
        if (width > 0 && width < 3) {
          delta = delta + 3 - width;
          result[index] = 3;
        }
      });
      if (delta > 0) {
        const parts = result.map(width => {
          return (width / 100) * delta;
        });
        result = result.map((width, index) => {
          return width - parts[index];
        });
      }
      return result;
    },

    progressLineWidth(): (index: number) => string {
      return index => {
        return this.allProgressLineWidth[index] + '%';
      };
    },
  },
});
</script>
<style lang="scss" scoped>
.progress-container {
  display: flex;
  width: 100%;
  height: 2px;
  padding: 0;
  background: var(--element-bg);
}

.progress-line {
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;

  &.red {
    background: #f5523a;
  }

  &.green {
    background: #48c600;
  }

  &.orange {
    background: #fc9000;
  }

  &.blue {
    background: #5c5cda;
  }
}

.incomplete {
  background-color: #fc9000;
}

.complete {
  background-color: #48c600;
}

.separate {
  margin-right: 2px;
}
</style>
