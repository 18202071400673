export enum ERR {
  ER_SUGGEST_TRUE_MARK_DUPLICATED = 'ER_SUGGEST_TRUE_MARK_DUPLICATED',
  ER_SUGGEST_WRONG_TRUE_MARK = 'ER_SUGGEST_WRONG_TRUE_MARK',
  ER_INVALID_TRUE_MARK = 'ER_INVALID_TRUE_MARK',
  ER_BAD_TRUE_MARK = 'ER_BAD_TRUE_MARK',
  ER_TRUE_API_BROKEN = 'ER_TRUE_API_BROKEN',
  ER_EXTERNAL_SERVICE = 'ER_EXTERNAL_SERVICE',
  ER_RETIRED_TRUE_MARK_ON_REVIEW = 'ER_RETIRED_TRUE_MARK_ON_REVIEW',
  ER_RETIRED_TRUE_MARK_ON_STORE = 'ER_RETIRED_TRUE_MARK_ON_STORE',
  ER_RETIRED_TRUE_MARK_OTHER_STORE = 'ER_RETIRED_TRUE_MARK_OTHER_STORE',
  ER_RETIRED_TRUE_MARK_OUTSIDE = 'ER_RETIRED_TRUE_MARK_OUTSIDE',

  ER_SUGGEST_TRUE_MARK_REQUIRED = 'ER_SUGGEST_TRUE_MARK_REQUIRED',
  ER_SUGGEST_INVALID_TRUE_MARK = 'ER_SUGGEST_INVALID_TRUE_MARK',
  ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK = 'ER_SUGGEST_WRONG_PRODUCT_TRUE_MARK',
  ER_SUGGEST_TRUE_MARK_CONSUMED = 'ER_SUGGEST_TRUE_MARK_CONSUMED',
  ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER = 'ER_SUGGEST_TRUE_MARK_IN_ANOTHER_ORDER',
  ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER = 'ER_SUGGEST_TRUE_MARK_NOT_IN_CURRENT_ORDER',
  ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN = 'ER_SUGGEST_TRUE_MARK_ALREADY_TAKEN',
  ER_MARK_IS_SELLING_YET = 'ER_MARK_IS_SELLING_YET',
  ER_TRUE_MARK_HAS_EXPIRED = 'ER_TRUE_MARK_HAS_EXPIRED',
}
