<template>
  <div v-if="order" data-test="order card">
    <OrderCard
      :type="cardType"
      :title="title"
      :actions="actionsCount"
      :number="docDate"
      :work-btn-disabled="toWorkBtnDisabled"
      :need-work-btn="needToWorkButton"
      :status="order.status"
      :need-view-btn="needViewButton"
      :is-paused="order.isOrderPaused"
      :icons="icons"
      @continue-btn-click="continueOrder"
      @view-btn-click="viewOrder"
      @work-btn-click="workOrder"
    />
    <WaitingWindow v-if="waiting.visible.value" position="center" :timer="15" />
    <ShelfPickingRules
      v-if="shelfPickingRules.visible.value"
      :picking_method="shelfPickingRules.props.value"
      @close="shelfPickingRules.hide(false)"
      @start="shelfPickingRules.hide(true)"
    />
  </div>
</template>

<script lang="ts">
import { getOrderTitle } from '@/fsd/entities/order/tools/getOrderTitle';
import { orderByRoverModal } from '@/fsd/entities/order/ui/order-card/additional-windows/order-by-rover-modal';
import ShelfPickingRules from '@/fsd/entities/order/ui/order-card/additional-windows/shelf-picking-rules.vue';
import WaitingWindow from '@/fsd/entities/order/ui/order-card/additional-windows/waiting-window.vue';
import { getActionsCount } from '@/fsd/entities/order/ui/order-card/getActionsCount';
import OrderCard from '@/fsd/entities/order/ui/order-card/order-card.vue';
import { CardType, IconType } from '@/fsd/entities/order/ui/order-card/types';
import { getRequestSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import { UseAckOrderOptions, useAckOrder } from '@/fsd/features/order/utils/useAckOrder';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { useComponent } from '@/hooks/useComponent';
import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { isClientOrder } from '@/models/orders/ClientOrder';
import { isOrderOrder } from '@/models/orders/OrderOrder';
import OrderOrderRetail, { isOrderOrderRetail } from '@/models/orders/OrderOrderRetail';
import ShipmentOrder from '@/models/orders/ShipmentOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments, orderTypeUrls } from '@/temp/constants';
import { orderStatusesList } from '@/temp/constants/translations';
import { useLoader } from '@/ui/common/loader/useLoader';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomeOrder',
  components: {
    ShelfPickingRules,
    OrderCard,
    WaitingWindow,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const waiting = useComponent();
    const shelfPickingRules = useComponent<OrderOrderRetail['shelfPickingMethod'], boolean>();
    const router = useRouter();
    const { actionsCount } = getActionsCount(props.orderId);
    const title = getOrderTitle(props.orderId);

    return { showLoader, waiting, shelfPickingRules, router, actionsCount, title };
  },
  computed: {
    order(): BaseOrder {
      return useOrders().orderById(this.orderId) as BaseOrder;
    },
    docDate(): string {
      const number = this.order.attr.request_number || this.order.attr.doc_number || this.$gettext('без номера');
      return this.$gettext('%{number} от %{date}', {
        number,
        date: this.order.date,
      });
    },
    needToWorkButton(): boolean {
      if (this.onlyView) {
        // докумнт открытый только для просмотра, показываем из списка связанных с посылкой документов
        return false;
      }
      // приемки берем в работу только через экран просмотра
      if (this.order.type === 'acceptance') return false;
      return this.order.status === 'request';
    },
    needViewButton(): boolean {
      if (this.onlyView) {
        // документ открытый только для просмотра, показываем из списка связанных с посылкой документов
        return true;
      }
      const longOrderTypes = [
        'acceptance',
        'stowage',
        'sale_stowage',
        'weight_stowage',
        'check_more',
        'hand_move',
        'kitchen_provision',
        'inventory_check_more',
        'inventory_check_product_on_shelf',
        'check',
        'check_final',
      ];
      return longOrderTypes.includes(this.order.type) && this.order.status === 'request';
    },
    isPaused(): boolean {
      return this.order?.data?.isPaused;
    },
    isRover(): boolean {
      return this.order.isRover && !this.isPaused;
    },
    waitingRover(): boolean {
      return Boolean(
        isOrderOrder(this.order) &&
          this.order.attr.maybe_rover &&
          !this.order.courier &&
          this.order.status === OrderWorkStatusEnum.processing &&
          getRequestSuggests(this.order.suggests).length === 0,
      );
    },
    cardType(): CardType {
      if (this.isPaused || (isOrderOrder(this.order) && this.order.hasOnlyBlockedSuggests) || this.waitingRover) {
        return 'pause';
      }
      if (this.isRover) {
        return 'rover';
      }
      return 'default';
    },
    toWorkBtnDisabled(): boolean {
      // кнопка взять в работу только для первого в очереди заказа
      if (!isOrderOrder(this.order)) return false;
      if (!useUser().experimentByName(experiments.exp_black_jack)) return false;
      const firstRequestOrder = useOrders().orderOrders.find(o => o.status === OrderWorkStatusEnum.request);
      if (!firstRequestOrder) return false;
      return firstRequestOrder.order_id !== this.order.order_id;
    },
    icons(): IconType[] {
      const result: IconType[] = [];
      if (
        ['order', 'acceptance', 'sale_stowage'].includes(this.order.type) &&
        (this.order.hasFragile || this.order.hasUltimaPackageItems)
      ) {
        result.push('fragile');
      }
      if (
        isClientOrder(this.order) &&
        this.order.isHeavyOrder &&
        useUser().experimentByName(experiments.exp_black_jack)
      ) {
        result.push('heavy');
      }

      return result;
    },
  },
  methods: {
    async workOrder(): Promise<void> {
      // Общий случай
      const options: UseAckOrderOptions = {};
      if (this.order.status === orderStatusesList.processing) {
        this.continueOrder();
        return;
      }
      if (this.order.isControl) {
        const confirmed = await this.$notification.confirmCenter({
          title: this.$gettext('Начать задание?'),
          text: this.$gettext('Создание заданий может занимать некоторое время. На это время ТСД будет заблокирован.'),
        });
        if (!confirmed) return;
        options.beforeRequest = () => this.waiting.show();
        options.closeLoader = () => this.waiting.hide();
      }
      if (isOrderOrderRetail(this.order)) {
        const result = await this.shelfPickingRules.asyncShow(this.order.shelfPickingMethod);
        if (!result) return;
      }
      if (this.isRover && this.order.type === 'order') {
        await orderByRoverModal();
      }
      const result = await useAckOrder(this.orderId, options);
      if (result) this.continueOrder(false);
    },
    viewOrder(): void {
      rumSpaManager.makeSpaSubPage(orderTypeUrls[this.order.type] + '-view');
      this.router.push({ name: orderTypeUrls[this.order.type] + '-view', params: { order_id: this.order.order_id } });
    },
    continueOrder(createSubPage: boolean = true): void {
      if (this.order.type === 'shipment' && (this.order as ShipmentOrder).isMarketShipment) {
        createSubPage && rumSpaManager.makeSpaSubPage('market_shipment');
        this.router.push({ name: 'market_shipment', params: { order_id: this.order.order_id } });
        return;
      }
      createSubPage && rumSpaManager.makeSpaSubPage(orderTypeUrls[this.order.type]);
      this.router.push({ name: orderTypeUrls[this.order.type], params: { order_id: this.order.order_id } });
    },
  },
});
</script>
