import Item from '@/models/Item';
import { Found } from '@/services/response';
import { BaseModel } from 'sjs-base-model';

export default class ItemByBarcode extends BaseModel {
  public type: string = 'item';
  public item_id: string = '';
  public status: Item['status'] = 'active';

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }
  static isItemByBarcode(found: Found): found is ItemByBarcode {
    return found.type === 'item';
  }
}
