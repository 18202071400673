import BaseOrder from '@/models/orders/BaseOrder';

export default class ControlCheckOrder extends BaseOrder {
  public vars: any = {};

  constructor(data: any) {
    super(data);
    this.vars.shelves_to_check = data.vars.shelves_to_check;
  }
}
