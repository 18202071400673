<template>
  <div
    v-if="available"
    class="w-12 h-12 rounded-full border border-black flex items-center justify-center"
    :class="enabled ? 'bg-gray-800' : 'bg-gray-100'"
    @click="toggleTorch"
  >
    <FlashLight :mode="enabled ? 'dark' : 'light'" />
  </div>
</template>
<script setup lang="ts">
import FlashLight from '@/fsd/shared/ui/QrAim/FlashLightToggle/FlashLight.vue';
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { ref } from 'vue';

const available = ref(false);
const enabled = ref(false);

BarcodeScanner.isTorchEnabled()
  .then(res => {
    enabled.value = res.enabled;
  })
  .catch(console.error);

BarcodeScanner.isTorchAvailable()
  .then(res => {
    available.value = res.available;
  })
  .catch(console.error);
const toggleTorch = () => {
  BarcodeScanner.toggleTorch()
    .then(() => {
      enabled.value = !enabled.value;
    })
    .catch(console.error);
};
</script>
