<template>
  <div ref="container" class="flex flex-col">
    <Title4 class="font-bold mb-4 text-left" color="primary">
      {{ $gettext('Возникла ошибка при замене.') }}
    </Title4>
    <Body2>{{ $gettext('Отсканируйте этот товар заново') }}</Body2>
    <div class="flex justify-center flex-col gap-2 mt-4">
      <CardBlock
        v-for="s in nonClosedChildSuggest"
        :key="s.suggest_id"
        class="p-4"
        data-test="suggest-card-rescan"
        :data-index="s.product_id"
      >
        <SuggestCardInfo :suggest="s" :need-counter="false" />
      </CardBlock>
    </div>
    <div class="mt-6">
      <UiButton
        data-test="order-retail remove-products-btn-cancel"
        background-color="secondary"
        @click="$emit('btnClick')"
      >
        {{ $gettext('Отменить') }}
      </UiButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SuggestCardInfo } from '@/fsd/entities/suggest';
import { CardBlock } from '@/fsd/shared/ui/cardBlock';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title4 from '@/ui/common/typo/title-4.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps, watch } from 'vue';

interface ReScanSuggestsProps {
  suggest: Suggest;
}

const props = defineProps<ReScanSuggestsProps>();

const nonClosedChildSuggest = computed<Suggest[]>(() => {
  return props.suggest.nonClosedChildrenSuggest;
});

const emits = defineEmits<{ (e: 'btnClick'): void }>();

watch(
  () => nonClosedChildSuggest.value.length,
  len => {
    if (!len) emits('btnClick');
  },
);
</script>
