<template>
  <div class="list-item-card-container">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
.list-item-card-container {
  margin: 0.5rem 0.75rem 0;
  display: flex;
  flex-wrap: wrap;
}
</style>
