<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.25 11C19.25 15.5563 15.5563 19.25 11 19.25C6.44365 19.25 2.75 15.5563 2.75 11C2.75 6.44365 6.44365 2.75 11 2.75C15.5563 2.75 19.25 6.44365 19.25 11ZM21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.5 11C0.5 5.20101 5.20101 0.5 11 0.5C16.799 0.5 21.5 5.20101 21.5 11ZM12.125 5.75C12.125 5.12868 11.6213 4.625 11 4.625C10.3787 4.625 9.875 5.12868 9.875 5.75V11C9.875 11.3541 10.0417 11.6875 10.325 11.9L13.325 14.15C13.8221 14.5228 14.5272 14.4221 14.9 13.925C15.2728 13.4279 15.1721 12.7228 14.675 12.35L12.125 10.4375V5.75Z"
      fill="#1C1D20"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
