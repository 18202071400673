import { api } from '@/fsd/data/api/api.service';
import { BrandingEnum } from '@/models/Constants';
import { computed, ref } from 'vue';

const branding = ref<BrandingEnum>(BrandingEnum.yandex);
const loadBranding = async () => {
  try {
    const { data } = await api.user.options();
    const respBranding = data?.constants?.domain_configs?.branding;
    if (respBranding && respBranding in BrandingEnum) {
      branding.value = respBranding;
      localStorage.setItem('branding', respBranding);
    }
  } catch (e) {
    console.error(e);
  }
};

const local = localStorage.getItem('branding');
if (local && local in BrandingEnum) {
  branding.value = local as BrandingEnum;
} else {
  loadBranding();
}

export const isYandex = computed(() => {
  return branding.value === BrandingEnum.yandex;
});
export const isB2B = computed(() => {
  return branding.value !== BrandingEnum.yandex;
});
