<template>
  <Layout>
    <template #header>
      <Bar :menu-config="[]" :icons="{ left: 'back' }" @close-click="emit('close')" />
    </template>

    <div class="px-4 flex flex-col gap-2">
      <slot />

      <DevtoolsSection>
        <template #title>Запрос</template>
        <pre>{{ log?.requestBody || sseLog?.keys }}</pre>
      </DevtoolsSection>

      <DevtoolsSection>
        <template #title> Ответ </template>
        <pre>{{ log?.responseBody || sseLog?.responseBody }}</pre>
      </DevtoolsSection>
    </div>
  </Layout>
</template>

<script setup lang="ts">
import { NetworkLog, NetworkLogSse } from '@/devtools/sections/networkLog';
import DevtoolsSection from '@/devtools/sections/section/devtools-section.vue';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import { defineEmits, defineProps } from 'vue';

defineProps<{ log?: NetworkLog; sseLog?: NetworkLogSse }>();
const emit = defineEmits(['close']);
</script>
