<template>
  <Overlay>
    <Layout class="pointer-events-auto">
      <template #header>
        <Bar :caption="caption" :icons="{ left: 'back' }" @close-click="$emit('close')" />
      </template>
      <div class="bg-day-cardDivider flex flex-col gap-1.5">
        <Sector class="rounded-t-none pt-0">
          <div>
            <Title2 class="font-bold">{{ $gettext('Состав заказа') }}</Title2>
            <br />
            <Body2 color="day-textMinor">
              {{
                $gettext(
                  'Бережно упакуйте хрупкие товары, товары для взрослых — в непрозрачный пакет, а бытовую химию — в отдельный..',
                )
              }}
            </Body2>
          </div>
        </Sector>

        <ProductGroupsByType :suggests="suggests" :order-id="order.order_id" />
      </div>

      <template #footer>
        <LayoutFooter>
          <UiButton background-color="secondary" data-test="order close-basket-btn" @click="$emit('close')">
            {{ $gettext('Закрыть') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </Overlay>
</template>

<script lang="ts">
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import ProductGroupsByType from '@/fsd/widgets/product-list/ProductGroupsByType.vue';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ProductList',
  components: { Bar, Body2, Title2, Sector, ProductGroupsByType, Overlay, LayoutFooter, UiButton, Layout },
  props: {
    suggests: {
      type: Array as PropType<Suggest[]>,
      required: true,
    },
  },
  emits: ['done', 'close'],
  computed: {
    order() {
      const order_id = this.suggests[0].order_id;
      return useOrders().orderById(order_id);
    },
    caption() {
      if (!this.order) return '';
      return `${this.$gettext('Заказ')} ${this.order.orderNumberForView}`;
    },
  },
});
</script>
