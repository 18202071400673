<template>
  <svg width="139" height="73" viewBox="0 0 139 73" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M10.0724 31.4189V62.0084H10.8032H11.534V31.4189V0.829442H10.8032H10.0724V31.4189Z" />
      <path d="M12.5781 31.4189V62.0084H12.8913H13.2045V31.4189V0.829442H12.8913H12.5781V31.4189Z" />
      <path d="M14.9792 31.4189V62.0084H15.2924H15.6056V31.4189V0.829442H15.2924H14.9792V31.4189Z" />
      <path d="M19.155 31.4189V62.0084H20.6688H22.1825V31.4189V0.829442H20.6688H19.155V31.4189Z" />
      <path d="M23.2263 31.4189V62.0084H23.5395H23.8527V31.4189V0.829442H23.5395H23.2263V31.4189Z" />
      <path d="M24.8969 31.4189V62.0084H26.0452H27.1936V31.4189V0.829442H26.0452H24.8969V31.4189Z" />
      <path d="M28.2377 31.4189V62.0084H29.3338H30.43V31.4189V0.829442H29.3338H28.2377V31.4189Z" />
      <path d="M31.4738 31.4189V62.0084H31.787H32.1002V31.4189V0.829442H31.787H31.4738V31.4189Z" />
      <path d="M34.71 31.4189V62.0084H35.4407H36.1715V31.4189V0.829442H35.4407H34.71V31.4189Z" />
      <path d="M37.2156 31.4189V62.0084H37.5288H37.842V31.4189V0.829442H37.5288H37.2156V31.4189Z" />
      <path d="M41.2872 31.4189V62.0084H42.018H42.7488V31.4189V0.829442H42.018H41.2872V31.4189Z" />
      <path d="M43.7926 31.4189V62.0084H44.1058H44.419V31.4189V0.829442H44.1058H43.7926V31.4189Z" />
      <path d="M46.2982 31.4189V62.0084H46.9768H47.6554V31.4189V0.829442H46.9768H46.2982V31.4189Z" />
      <path d="M51.205 31.4189V62.0084H51.5181H51.8313V31.4189V0.829442H51.5181H51.205V31.4189Z" />
      <path d="M53.7103 31.4189V62.0084H54.0235H54.3366V31.4189V0.829442H54.0235H53.7103V31.4189Z" />
      <path d="M55.3808 31.4189V62.0084H55.6418H55.9028V31.4189V0.829442H55.6418H55.3808V31.4189Z" />
      <path d="M59.4521 31.4189V62.0084H60.1829H60.9136V31.4189V0.829442H60.1829H59.4521V31.4189Z" />
      <path d="M61.8531 31.4189V62.0084H62.1663H62.4795V31.4189V0.829442H62.1663H61.8531V31.4189Z" />
      <path d="M64.3588 31.4189V62.0084H64.672H64.9852V31.4189V0.829442H64.672H64.3588V31.4189Z" />
      <path d="M66.0291 31.4189V62.0084H66.3422H66.6554V31.4189V0.829442H66.3422H66.0291V31.4189Z" />
      <path d="M68.4304 31.4189V62.0084H69.9964H71.5623V31.4189V0.829442H69.9964H68.4304V31.4189Z" />
      <path d="M73.4413 31.4189V62.0084H73.7545H74.0677V31.4189V0.829442H73.7545H73.4413V31.4189Z" />
      <path d="M75.1116 31.4189V62.0084H75.7902H76.4688V31.4189V0.829442H75.7902H75.1116V31.4189Z" />
      <path d="M78.3481 31.4189V62.0084H78.6613H78.9745V31.4189V0.829442H78.6613H78.3481V31.4189Z" />
      <path d="M82.4194 31.4189V62.0084H82.7326H83.0458V31.4189V0.829442H82.7326H82.4194V31.4189Z" />
      <path d="M86.491 31.4189V62.0084H86.8042H87.1174V31.4189V0.829442H86.8042H86.491V31.4189Z" />
      <path d="M88.9963 31.4189V62.0084H89.7271H90.4579V31.4189V0.829442H89.7271H88.9963V31.4189Z" />
      <path d="M91.502 31.4189V62.0084H91.8152H92.1284V31.4189V0.829442H91.8152H91.502V31.4189Z" />
      <path d="M95.5737 31.4189V62.0084H96.3044H97.0352V31.4189V0.829442H96.3044H95.5737V31.4189Z" />
      <path d="M98.079 31.4189V62.0084H98.3922H98.7054V31.4189V0.829442H98.3922H98.079V31.4189Z" />
      <path d="M100.532 31.4189V62.0084H100.825H101.107V31.4189V0.829442H100.825H100.532V31.4189Z" />
      <path d="M102.986 31.4189V62.0084H103.299H103.612V31.4189V0.829442H103.299H102.986V31.4189Z" />
      <path d="M104.656 31.4189V62.0084H105.387H106.117V31.4189V0.829442H105.387H104.656V31.4189Z" />
      <path d="M109.563 31.4189V62.0084H109.876H110.189V31.4189V0.829442H109.876H109.563V31.4189Z" />
      <path d="M111.233 31.4189V62.0084H112.747H114.261V31.4189V0.829442H112.747H111.233V31.4189Z" />
      <path d="M115.304 31.4189V62.0084H116.453H117.601V31.4189V0.829442H116.453H115.304V31.4189Z" />
      <path d="M118.645 31.4189V62.0084H119.376H120.107V31.4189V0.829442H119.376H118.645V31.4189Z" />
      <path d="M122.717 31.4189V62.0084H123.865H125.014V31.4189V0.829442H123.865H122.717V31.4189Z" />
      <path d="M125.953 31.4189V62.0084H126.266H126.579V31.4189V0.829442H126.266H125.953V31.4189Z" />
      <path d="M127.623 31.4189V62.0084H128.354H129.085V31.4189V0.829442H128.354H127.623V31.4189Z" />
      <line x1="-0.671509" y1="31.6468" x2="139" y2="31.6468" stroke="#F5523A" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="139" height="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss" scoped>
path {
  fill: var(--loader_indicator-bar-color);
}
</style>
