<template>
  <div class="flex items-center" @click="showOverPriceModal">
    <IconWarning v-if="overPrice && hasWarnOverPrice" class="w-8" />
    <div class="flex flex-col items-center flex-1">
      <Caption1 class="font-medium">{{
        $gettext('%{count} %{currency} из %{max_count} %{currency}', {
          count: totalPrice.toLocaleString(),
          currency: getCurrency(),
          max_count: maxTotalPrice.toLocaleString(),
        })
      }}</Caption1>
      <Caption1 v-if="overPrice && hasWarnOverPrice" color="orange-dark">{{ $gettext('Сумма превышена') }}</Caption1>
      <Caption1 v-else color="day-textMinor">{{ priceText || $gettext('Сумма заказа') }}</Caption1>
    </div>
    <IconChevron v-if="overPrice && hasWarnOverPrice" class="w-8" />
  </div>
</template>
<script setup lang="ts">
import { getCurrency } from '@/temp/constants/currencies';
import IconChevron from '@/temp/icons/icon-chevron.vue';
import IconWarning from '@/temp/icons/icon-warning.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, defineProps, toRefs, withDefaults } from 'vue';

interface OrderTotalPriceProps {
  totalPrice: number;
  maxTotalPrice: number;
  hasWarnOverPrice?: boolean;
  priceText?: string;
}

const props = withDefaults(defineProps<OrderTotalPriceProps>(), {
  hasWarnOverPrice: true,
});

const { totalPrice, maxTotalPrice } = toRefs(props);

const overPrice = computed(() => totalPrice.value > maxTotalPrice.value);
const showOverPriceModal = () => {
  if (!overPrice.value || props.hasWarnOverPrice) return;
  Notifications.modal({
    title: $gettext('Сумма заказа не должна превышать %{count} %{currency}', {
      count: maxTotalPrice.value.toLocaleString(),
      currency: getCurrency(),
    }),
    text: $gettext('Согласуйте с клиентом, какие товары убрать из заказа'),
    buttonText: $gettext('Хорошо'),
  });
};
</script>
