<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0913 2.00014L11.1968 2.00029H12.8024L12.9079 2.00014C13.3393 1.99927 13.77 1.99839 14.162 2.12967C14.6771 2.30217 15.1221 2.6378 15.4295 3.08574C15.6634 3.42663 15.7809 3.84095 15.8985 4.25599L15.9274 4.35744L16.1692 5.20386C19.0491 5.51087 21 6.14828 21 7.00029V9.00029L19.51 10.0003L18.0948 19.8405C18.0316 20.28 17.9501 20.7348 17.6418 21.0543C17.0708 21.6462 15.6197 22.5 12 22.5C8.40143 22.5 6.94627 21.6561 6.3683 21.0647C6.05104 20.74 5.96807 20.275 5.90429 19.8255L4.51 10.0003L3 9.00029V7.06029C3 6.1313 4.95155 5.49504 7.8323 5.19582L8.07183 4.35744L8.10067 4.25599C8.21834 3.84095 8.33581 3.42663 8.56973 3.08574C8.87713 2.6378 9.32208 2.30217 9.83724 2.12967C10.2293 1.99839 10.6599 1.99927 11.0913 2.00014ZM14.0043 4.90688L14.044 5.04572C13.3881 5.01575 12.7039 5.00029 12 5.00029C11.296 5.00029 10.6119 5.01476 9.9559 5.04334L9.99488 4.90689C10.1635 4.31665 10.1951 4.25186 10.2188 4.21738C10.2803 4.12779 10.3693 4.06066 10.4723 4.02616C10.512 4.01288 10.5829 4.00029 11.1968 4.00029H12.8024C13.4163 4.00029 13.4873 4.01288 13.5269 4.02616C13.63 4.06066 13.7189 4.12779 13.7804 4.21738C13.8041 4.25186 13.8357 4.31665 14.0043 4.90688ZM17.6506 8.83954L19 7.9339V7.78403C18.7549 7.69547 18.4118 7.59589 17.9568 7.49857C16.5168 7.19064 14.4182 7.00029 12 7.00029C9.57226 7.00029 7.4777 7.17946 6.04467 7.48701C5.58067 7.58659 5.2385 7.68891 5 7.77865V7.92598L6.3702 8.8334L7.91838 19.7626C8.89288 20.4272 10.8691 20.5 12 20.5C14.7038 20.5 15.7618 19.9811 16.0826 19.7619L17.6506 8.83954ZM10.5195 9.00029L10.9995 18.75L9.5 18.5L8.51953 9.13029L10.5195 9.00029ZM13 18.75L13.48 9.00029L15.48 9.13029L14.5 18.5L13 18.75Z"
      fill="#FA3E2C"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
