<template>
  <svg
    class="primary-color-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1654 15.1806L18.5229 6.8232C18.9134 6.43267 19.5465 6.43267 19.9371 6.8232L20.2929 7.17902C20.6834 7.56954 20.6834 8.20271 20.2929 8.59323L10.1654 18.7207L4.70711 13.2624C4.31658 12.8718 4.31658 12.2387 4.70711 11.8481L5.06293 11.4923C5.45345 11.1018 6.08662 11.1018 6.47714 11.4923L10.1654 15.1806Z"
      fill="#21201F"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
