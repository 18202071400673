<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="controlBarCaption"
        :menu-config="menuConfig"
        @close-click="onCloseClick"
      />
    </template>

    <template #default>
      <div class="check_more">
        <template v-if="activeStep.type === 'shelf'">
          <Hint class="my-2 mx-4">{{ $gettext('Отсканируйте полку') }}</Hint>
          <ListCardItemContainer>
            <ListCardItem
              v-for="suggest in checkShelfSuggests"
              :key="suggest.shelf_id"
              :is-done="isCompleted(suggest.shelf_id)"
              :is-in-progress="isInProgress(suggest.shelf_id)"
              @long-press="onListItemClick(suggest)"
            >
              {{ shelfById(suggest.shelf_id).title }}
            </ListCardItem>
          </ListCardItemContainer>
        </template>
        <template v-if="activeStep.type === 'products'">
          <div v-if="activeShelfSuggests.length === 0" class="products-wrapper">
            <Typography
              type="h2"
              align="center"
              color="title"
              margin="20px 16px 88px"
              data-test="check_more product-step empty-title"
            >
              {{ productStepHintText }}
            </Typography>
            <img class="empty-img" src="@/assets/img/scan.svg" alt="" />
          </div>
          <div v-else class="products-wrapper">
            <Hint class="my-2 mx-4">{{ productStepHintText }}</Hint>
            <SuggestCard
              v-for="suggest in activeShelfSuggests"
              :key="suggest.suggest_id"
              doc-type="check_more"
              :order="order"
              :suggest="suggest"
            />
          </div>
        </template>
        <SuggestDetails
          v-if="uiState.activeProduct && activeShelfSuggest && suggestDetails.visible.value"
          :product-id="uiState.activeProduct.product_id"
          :order-id="order.order_id"
          :suggest-id="activeShelfSuggest.suggest_id"
          @cancel="onCancel"
          @finish="onDetailsFinished"
        />
      </div>
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>

    <template #footer>
      <div class="button-wrapper">
        <UiButton
          v-if="activeStep.type === 'products'"
          :is-disabled="!isCompletable(uiState.activeShelf && uiState.activeShelf.shelf_id)"
          data-test="check_more product-step finish-btn"
          @click="finishShelf"
        >
          <template v-if="activeShelfSuggests.length > 0"> {{ $gettext('Завершить') }}</template>
          <template v-else> {{ $gettext('Пустая полка') }}</template>
        </UiButton>
        <UiButton
          v-if="activeStep.type === 'shelf'"
          data-test="check_more footer finish-btn"
          :is-disabled="!allSuggestDone || order.estatus === 'waiting_signal'"
          @click="finishOrder"
        >
          {{ $gettext('Завершить') }}
        </UiButton>
      </div>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import retryCheckMoreModal from '@/fsd/entities/modals/retryCheckMoreModal';
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import {
  getDoneSuggests,
  getRequestSuggests,
  getSuggestsByStatusAndType,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import { useCheckMore } from '@/fsd/entities/suggest/tools/useCheckMore';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest, { SuggestStatusEnum, SuggestTypeEnum } from '@/models/Suggest';
import CheckMoreOrder from '@/models/orders/CheckMoreOrder';
import { ScannerService } from '@/services/scanner/scanner.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import ListCardItemContainer from '@/ui/common/list-card-item-container.vue';
import ListCardItem from '@/ui/common/list-card-item/list-card-item.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SuggestCard from '@/ui/common/suggest-card/suggest-card.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { erSuggestAcceptedCountModal, erSuggestNonQuantsProduct } from '@/utils/modals';
import { isAxiosError } from 'axios';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Step {
  type: 'shelf' | 'products';
}

interface Data {
  uiState: {
    activeProduct?: Product;
    activeShelf?: Shelf;
    activeStepNumber: number;
  };
  uiStateNeedBarcodeRequest: boolean;
  steps: Step[];
  history: [];
}

export default defineComponent({
  name: 'CheckMore',
  components: {
    PageLayout,
    Typography,
    Hint,
    Bar,
    ListCardItem,
    UiButton,
    ListCardItemContainer,
    SuggestDetails,
    SuggestCard,
    ShareOrder,
  },
  mixins: [requestBarcode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      productsStore,
      shelvesStore,
      userStore,
      ordersStore,
      shareOrder,
      suggestDetails,
      router,
      detachBtn,
    };
  },
  data(): Data {
    return {
      uiState: {
        activeProduct: undefined,
        activeShelf: undefined,
        activeStepNumber: 0,
      },
      uiStateNeedBarcodeRequest: true,
      steps: [{ type: 'shelf' }, { type: 'products' }],
      history: [],
    };
  },
  computed: {
    order(): CheckMoreOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as CheckMoreOrder;
    },
    suggests(): Suggest[] {
      if (!this.order?.suggests) return [];
      return this.order.suggests;
    },
    requestSuggests(): Suggest[] {
      return getRequestSuggests(this.suggests);
    },
    allSuggestDone(): boolean {
      return this.requestSuggests.length === 0;
    },
    activeStep(): Step {
      return this.steps[this.uiState.activeStepNumber];
    },
    activeShelfSuggests(): Suggest[] {
      if (!this.uiState.activeShelf) {
        return [];
      } else {
        return this.suggests.filter(
          suggest =>
            suggest.status === 'done' && suggest.shelf_id === this.uiState.activeShelf!.shelf_id && suggest.product_id,
        );
      }
    },
    checkShelfSuggests(): Suggest[] {
      const shelves = [...new Set(this.suggests.map(suggest => suggest.shelf_id))];
      return shelves
        .map(shelf_id => this.suggests.find(suggest => suggest.shelf_id === shelf_id))
        .filter(suggest => suggest) as Suggest[];
    },
    remaining(): number {
      return getRequestSuggests(this.checkShelfSuggests).length;
    },
    controlBarText(): string {
      if (this.uiState.activeShelf) {
        return this.$gettext('Инвентаризация');
      }
      if (this.order) {
        if (this.remaining) {
          return this.$ngettext(
            'Осталось %{request} из %{all} полка',
            'Осталось %{request} из %{all} полок',
            this.checkShelfSuggests.length,
            {
              request: String(this.remaining),
              all: String(this.checkShelfSuggests.length),
            },
          );
        }
        return this.$ngettext('%{n} полка', '%{n} полок', this.checkShelfSuggests.length, {
          n: String(this.checkShelfSuggests.length),
        });
      }
      return '';
    },
    controlBarCaption(): string {
      if (this.uiState.activeShelf) {
        return this.$gettext('Полка %{shelf}', {
          shelf: this.uiState.activeShelf!.title,
        });
      }
      return this.$gettext('Инвентаризация');
    },
    checkSuggest(): (shelf_id: string) => Suggest | undefined {
      return shelf_id => {
        return this.suggests.find(suggest => suggest.status !== 'done' && suggest.shelf_id === shelf_id);
      };
    },
    activeShelfSuggest(): Suggest | undefined {
      return this.suggests.find(
        suggest => suggest.status === 'request' && suggest.shelf_id === this.uiState.activeShelf!.shelf_id,
      );
    },
    shelfSuggests(): (shelf_id: string) => Suggest[] {
      return shelf_id => this.suggests.filter(suggest => suggest.shelf_id === shelf_id);
    },
    isCompleted(): (shelf_id: string) => boolean {
      return shelf_id => {
        const suggests = this.shelfSuggests(shelf_id);
        return getSuggestsByStatusAndType(suggests, SuggestStatusEnum.request, SuggestTypeEnum.check_more).length === 0;
      };
    },
    isInProgress(): (shelf_id: string) => boolean {
      return shelf_id => {
        const suggests = this.shelfSuggests(shelf_id);
        const hasDone = getDoneSuggests(suggests).length > 0;
        return hasDone && !this.isCompleted(shelf_id);
      };
    },
    isCompletable(): (shelf_id?: string) => boolean {
      return shelf_id => {
        if (!shelf_id) return false;
        const suggests = this.shelfSuggests(shelf_id);
        return getRequestSuggests(suggests).length > 0;
      };
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];

      if (this.order?.type === 'inventory_check_more') {
        menuConfig.push(this.detachBtn);
      }

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        onClick: () => this.showShareOrder(),
        dataTest: 'share order btn',
        condition: () =>
          this.userStore.experimentByName(experiments.exp_tsd_companion) && this.order?.type === 'inventory_check_more',
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },

    productStepHintText(): string {
      if (this.activeShelfIsComplete) {
        return this.$gettext('Подсчет полки завершен');
      }
      return this.$gettext('Отсканируйте товары, которые лежат на полке');
    },
    activeShelfIsComplete(): boolean {
      return this.activeShelfSuggest?.status === 'error';
    },
    shelfById() {
      return (id: string) => {
        return this.shelvesStore.shelfById(id)!;
      };
    },
  },
  watch: {
    order: {
      handler(newValue) {
        if (!newValue) {
          this.$alert.success(this.$gettext('Инвентаризация успешно выполнена'));
          this.toHomePage();
          return;
        }
        if (newValue.estatus === 'waiting_signal') {
          this.$notification.modal({
            title: this.$gettext('Подсчет стеллажа завершен'),
            text: this.$gettext('Требуется ручное завершение подсчета в диспетчерской'),
          });
          this.toHomePage();
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.order?.estatus === 'waiting_signal') {
      this.$notification.modal({
        title: this.$gettext('Подсчет стеллажа завершен'),
        text: this.$gettext('Требуется ручное завершение подсчета в диспетчерской'),
      });
    }
  },
  methods: {
    handleOrderStatusComplete(): void {},
    async requestBarcode(): Promise<boolean> {
      const barcode = await ScannerService.requestCode(this.$options.name + this._uuid);
      if (this.activeStep.type === 'shelf') {
        const shelf = await this.shelvesStore.getShelfByBarcode(barcode);
        if (shelf) {
          if (this.shelfSuggests(shelf.shelf_id).length > 0) {
            this.onGroupItemClick(shelf);
          } else {
            this.$alert.error(this.$gettext('Отсканирована неверная полка'));
          }
        } else {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
        }
        return true;
      } else {
        const { closeLoader } = this.showLoader(this.$gettext('Запрашиваем продукт по штрихкоду'), this.order_id);
        try {
          this.uiState.activeProduct = await this.productsStore.getProductByBarcode(barcode);
          this.suggestDetails.show();
          return false;
        } catch (error) {
          this.$alert.error(this.$gettext('Не найден штрихкод %{barcode}', { barcode }));
          return true;
        } finally {
          closeLoader();
        }
      }
    },
    async finishOrder(): Promise<void> {
      if (this.order?.vars.third_party_assistance) {
        await this.$notification.modal({
          title: this.$gettext('Требуется ручное завершение подсчета в диспетчерской'),
          buttonText: this.$gettext('Понятно'),
        });
        return;
      }
      const confirmed = await this.$notification.confirmCenter({
        title: this.$gettext('Вы уверены?'),
      });
      if (!confirmed) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.toHomePage();
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    toHomePage() {
      this.router.push({ name: 'home' });
    },
    async finishShelf(): Promise<void> {
      const confirmed = await this.$notification.confirmCenter({
        title: this.$gettext('Вы уверены?'),
      });
      if (!confirmed) return;
      const checkSuggest = this.activeShelfSuggest!;

      const result = await useCheckMore(
        this.order_id,
        { status: 'error', suggest_id: checkSuggest.suggest_id },
        {
          onRequestError: async (e, retry) => {
            if (isAxiosError(e)) {
              if (e.response?.data.code === 'ER_SUGGEST_NON_QUANTS_PRODUCT') {
                await erSuggestNonQuantsProduct();
                return;
              }
            }

            const confirmed = await retryCheckMoreModal(e);
            if (!confirmed) return false;
            return retry();
          },
        },
      );
      if (!result) return;
      this.resetUiState();
      this.suggestDetails.hide();
      this.uiStateNeedBarcodeRequest = true;
    },
    resetUiState(): void {
      this.uiState.activeProduct = undefined;
      this.uiState.activeShelf = undefined;
      this.uiState.activeStepNumber = 0;
    },
    onCloseClick(): void {
      if (this.uiState.activeStepNumber > 0) {
        this.resetUiState();
        this.uiStateNeedBarcodeRequest = true;
      } else {
        this.resetUiState();
        this.toHomePage();
      }
    },
    async onListItemClick(suggest: Suggest): Promise<void> {
      const shelf = await this.shelvesStore.getShelfById(suggest.shelf_id);
      if (!shelf) {
        this.$alert.error(this.$gettext('Произошла ошибка при загрузке полки'));
        return;
      }
      this.onGroupItemClick(shelf);
    },
    onGroupItemClick(shelf: Shelf): void {
      this.uiState.activeShelf = shelf;
      this.uiState.activeStepNumber = 1;
    },
    onCancel(): void {
      this.uiState.activeProduct = undefined;
      this.suggestDetails.hide();
      this.uiStateNeedBarcodeRequest = true;
    },
    async onDetailsFinished({ count = 0, date }: Pick<Model, 'count' | 'date'>): Promise<void> {
      const checkSuggest = this.activeShelfSuggest;
      await useCheckMore(
        this.order_id,
        {
          suggest_id: checkSuggest!.suggest_id,
          product_id: this.uiState.activeProduct!.product_id,
          count,
          valid: date,
        },
        {
          onRequestError: async (e, retry) => {
            if (isAxiosError(e)) {
              if (e.response?.data?.code === 'ER_SUGGEST_ACCEPTED_COUNT') {
                await erSuggestAcceptedCountModal();
                return;
              }
              if (e.response?.data.code === 'ER_SUGGEST_NON_QUANTS_PRODUCT') {
                await erSuggestNonQuantsProduct();
                return;
              }
            }

            const confirmed = await retryCheckMoreModal(e);
            if (!confirmed) return false;
            return retry();
          },
        },
      );
      this.onCancel();
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.empty-img {
  width: 100%;
  padding: 0 1rem;
}

.button-wrapper {
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.07);
  padding: 16px;
}
</style>
