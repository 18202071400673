<template>
  <div class="flex flex-col gap-3">
    <Tag type="disable">{{ $gettext('Упаковка посылки') }}</Tag>
    <RadioGroup v-model="typeRef" :items="TYPE_PACKAGE" />
    <Tag v-if="typeRef !== 'without_package'" :type="'disable'">{{ $gettext('Размер упаковки') }}</Tag>
    <RadioGroup v-if="typeRef !== 'without_package'" v-model="sizeRef" :items="SIZE_PACKAGE" />
  </div>
</template>

<script setup lang="ts">
import type { TypePackageClass, TypePackageSize, TypePackageType } from '@/models/Item';
import RadioGroup from '@/temp/ui/inputs/radio-group/radio-group.vue';
import Tag from '@/ui/common/tag/tag.vue';
import { getTypeAndSizeFromPackageClass, SIZE_PACKAGE, TYPE_PACKAGE } from '@/utils/packageClassItem';
import { defineEmits, defineProps, ref, watch } from 'vue';

const typeRef = ref<TypePackageType>();
const sizeRef = ref<TypePackageSize>();
const packageClassRef = ref<TypePackageClass>();

watch([typeRef, sizeRef], ([newType, newSize]) => {
  if (newType === 'without_package') {
    packageClassRef.value = newType;
    sizeRef.value = undefined;
  } else if (newType && newSize) {
    packageClassRef.value = `${newType}_${newSize}`;
  } else {
    packageClassRef.value = undefined;
  }
});

watch(packageClassRef, packageClass => {
  emits('change', packageClass);
});

const props = defineProps<{ package_class?: TypePackageClass }>();

const emits = defineEmits<{ change: [packageClass: TypePackageClass | undefined] }>();

if (props.package_class) {
  const { type, size } = getTypeAndSizeFromPackageClass(props.package_class);

  typeRef.value = type;
  sizeRef.value = size;
}
</script>
