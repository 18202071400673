<template>
  <div
    class="flex justify-between items-center px-4 py-3"
    :class="{ 'is-rtl': isRTL, 'font-bold': bold }"
    :data-test="dataTest"
  >
    <span :class="{ 'text-gray-400': !bold }">
      {{ title }}
    </span>
    <span class="text-right" data-test="info-row value">
      {{ value }}
    </span>
  </div>
</template>
<script lang="ts">
import { useUser } from '@/store/modules/user';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
  },
});
</script>
