<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.875 7.24877C5.25368 7.24877 4.75 6.74509 4.75 6.12377V5.00368C3.50736 5.00368 2.5 6.01104 2.5 7.25368V8.75368H17.5V7.25368C17.5 6.01104 16.4926 5.00368 15.25 5.00368V6.12871C15.25 6.75003 14.7463 7.25371 14.125 7.25371C13.5037 7.25371 13 6.75003 13 6.12871V5.00368H7V6.12377C7 6.74509 6.49632 7.24877 5.875 7.24877ZM13 2.75368H7V1.625C7 1.00368 6.49632 0.5 5.875 0.5C5.25368 0.5 4.75 1.00368 4.75 1.625V2.75368C2.26472 2.75368 0.25 4.7684 0.25 7.25368V16.2537C0.25 18.739 2.26472 20.7537 4.75 20.7537H15.25C17.7353 20.7537 19.75 18.739 19.75 16.2537V7.25368C19.75 4.7684 17.7353 2.75368 15.25 2.75368V1.62868C15.25 1.00736 14.7463 0.503682 14.125 0.503682C13.5037 0.503683 13 1.00736 13 1.62868V2.75368ZM2.5 11.0037V16.2537C2.5 17.4963 3.50736 18.5037 4.75 18.5037H15.25C16.4926 18.5037 17.5 17.4963 17.5 16.2537V11.0037H2.5Z"
      fill="#1C1D20"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
