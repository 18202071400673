<template>
  <div class="flex mt-3" :class="{ 'is-rtl': isRTL }" :data-test="dataTest">
    <span data-test="suggest row label" class="text-color-primary text-sm flex-1"> {{ label }}</span>
    <span data-test="suggest row value" class="text-color-primary text-sm ml-2 flex-1">
      {{ value }}
    </span>
  </div>
</template>
<script lang="ts">
import { useUser } from '@/store/modules/user';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: '-',
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
  },
});
</script>
