import { checkTrueMarkErrorsCode } from '@/temp/constants/translations/checkTrueMarkErrorsCode';
import IconBasketReturn from '@/temp/icons/icon-basket-return.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default () => {
  return Notifications.modal({
    title: checkTrueMarkErrorsCode.ER_SUGGEST_INVALID_TRUE_MARK,
    text: $gettext('Отложите для дальнейших указаний. Возьмите другой товар'),
    iconTop: {
      icon: IconBasketReturn,
      position: 'center',
    },
  });
};
