import { useUser } from '@/store/modules/user';
export enum CurrenciesEnum {
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  ILS = 'ILS',
  GBP = 'GBP',
  BYN = 'BYN',
  KZT = 'KZT',
  ZAR = 'ZAR',
  AED = 'AED',
  SAR = 'SAR',
  UZS = 'UZS',
  TRY = 'TRY',
  BOB = 'BOB',
}

export const currencies = {
  [CurrenciesEnum.RUB]: '₽',
  [CurrenciesEnum.USD]: '$',
  [CurrenciesEnum.EUR]: '€',
  [CurrenciesEnum.ILS]: '₪',
  [CurrenciesEnum.GBP]: '£',
  [CurrenciesEnum.BYN]: 'Br.',
  [CurrenciesEnum.KZT]: '₸',
  [CurrenciesEnum.ZAR]: 'R',
  [CurrenciesEnum.AED]: 'DH',
  [CurrenciesEnum.SAR]: 'SR',
  [CurrenciesEnum.UZS]: 'Soʻm',
  [CurrenciesEnum.TRY]: '₺',
  [CurrenciesEnum.BOB]: 'Bs',
};

export const getCurrency = () => {
  const currency = useUser().currency;
  if (currency) {
    return currencies[currency];
  }
  return CurrenciesEnum.RUB;
};
