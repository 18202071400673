<template>
  <Layout>
    <InputDateAndTime
      :name="`arrival-time-${order.order_id}`"
      :title="$gettext('Когда приехала машина?')"
      :text="$gettext('Пожалуйста, укажите точные дату и время')"
      :props-date="savedDateAndTime"
      :max-date="new Date()"
      :min-date="minArrivalDate"
      :btn-text="$gettext('Продолжить')"
      @done="date => $emit('done', date)"
    />
  </Layout>
</template>
<script lang="ts">
import InputDateAndTime from '@/fsd/features/input/date-and-time/input-date-and-time.vue';
import AcceptanceOrder from '@/models/orders/AcceptanceOrder';
import Layout from '@/ui/common/layout.vue';
import dayjs from 'dayjs';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'ArrivalTime',
  components: {
    InputDateAndTime,
    Layout,
  },
  props: {
    order: {
      type: Object as PropType<AcceptanceOrder>,
      required: true,
    },
    savedDateAndTime: {
      type: Object as PropType<Date>,
      default: undefined,
    },
  },
  emits: ['done'],
  computed: {
    minArrivalDate(): Date {
      return new Date(dayjs(this.order.attr.doc_date).toDate());
    },
  },
});
</script>
