import { AudioService } from '@/services/audio.service';
import Vue3Notification, { notify } from '@kyvg/vue3-notification';

export const Alerts = {
  error(title: string) {
    AudioService.playError();
    notify({
      group: 'micro-alert',
      type: 'error',
      title,
      duration: 4000,
    });
  },
  success(title: string) {
    notify({
      group: 'micro-alert',
      type: 'success',
      title,
      duration: 4000,
    });
  },
};

export const alert = {
  install(app) {
    app.use(Vue3Notification);
    app.config.globalProperties.$alert = Alerts;
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $alert: IAlert;
  }
}

export interface IAlert {
  error: TMicro;
  success: TMicro;
}

type TMicro = (title: string) => void;
