<template>
  <svg width="156" height="65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#fff" d="M18 .5h120v64H18z" />
    <rect x="18" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="38" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="42" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="30" y=".5" width="3" height="64" rx="1.5" fill="#8A8784" fill-opacity=".4" />
    <rect x="102" y=".5" width="3" height="64" rx="1.5" fill="#8A8784" fill-opacity=".4" />
    <rect x="22" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="48" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="52" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="57" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="66" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="61" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="129" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="133" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="85" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="136" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="89" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="112" y=".5" width="2" height="64" rx="1" fill="#8A8784" fill-opacity=".4" />
    <rect x="109" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="118" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="121" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="124" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="94" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="97" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="72" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="75" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="80" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="35" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="25" y=".5" width="1" height="64" rx=".5" fill="#8A8784" fill-opacity=".4" />
    <rect x="1" y="30.5" width="154" height="4" rx="2" fill="#FA3E2C" stroke="#fff" stroke-width="2" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
