<template>
  <div :class="{ stretch: isStretch }" :data-test="dataTest" class="card">
    <div class="card-title">
      <slot name="title" />
    </div>

    <slot name="body" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    isStretch: {
      type: Boolean,
      default: true,
    },
    dataTest: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.card {
  margin-top: 20px;
  min-height: 283px;
  padding: 16px;
  display: flex;
  background: var(--main-bg);
  box-shadow: 0px 8px 20px rgba(33, 32, 31, 0.12), 0px 4px 8px rgba(33, 32, 31, 0.12);
  border-radius: 8px 8px 0px 0px;
  box-sizing: border-box;
  flex-direction: column;
  pointer-events: auto;
}

.stretch {
  height: 100%;
}

.card-title {
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid theme('colors.day.line');
}
</style>
