<template>
  <div class="flex flex-col flex-1 items-center">
    <Body3 class="mb-2" color="text-secondary">
      {{ $gettext('Полка') }}
    </Body3>
    <Title4 color="text-primary" class="font-medium text-center" data-test="product shelf title">
      {{ shelf }}
    </Title4>
  </div>
</template>

<script setup lang="ts">
import Suggest from '@/models/Suggest';
import { useShelves } from '@/store/modules/shelves';
import { computed } from 'vue';

const shelvesStore = useShelves();

interface OrderProductCardShelfProps {
  suggest: Suggest;
}

const props = defineProps<OrderProductCardShelfProps>();

const shelf = computed(() => {
  if (!props.suggest.shelf_id) {
    return '—';
  }
  const shelf = shelvesStore.shelfById(props.suggest.shelf_id);
  if (shelf) {
    return shelf.title;
  }
  return '—';
});
</script>
