<template>
  <OrderBadge bg-color="blue-toxic" text-color="white" data-test="order true-mark-badge" @click="openInstruction">
    <template #icon>
      <IconTrueMarkBadge color="white" />
    </template>
    <template #title>
      {{ $gettext('Честный знак') }}
    </template>
  </OrderBadge>
</template>
<script lang="ts">
import IconTrueMarkBadge from '@/temp/icons/icon-true-mark-badge.vue';
import OrderBadge from '@/ui/order/badges/order-badge.vue';
import { trueMarkModal } from '@/utils/modals';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TrueMarkBadge',
  components: { IconTrueMarkBadge, OrderBadge },
  methods: {
    openInstruction(): void {
      trueMarkModal();
    },
  },
});
</script>
