import BaseOrder, { OrderBaseVars, OrderTypeEnum } from '@/models/orders/BaseOrder';
import itemQueue from '@/services/queue/item-queue';

interface Conditions {
  is_agree: boolean;
}

export interface StowageMarketOrderVars extends OrderBaseVars {
  lot_item_ids?: Record<string, string[]>;
}

export default class StowageMarketOrder extends BaseOrder {
  public type: OrderTypeEnum.stowage_market = OrderTypeEnum.stowage_market;
  public conditions: Conditions = { is_agree: true };
  public vars: StowageMarketOrderVars = {};

  constructor(data: any) {
    super(data);
    this.conditions = data.conditions;
    this.vars = data.vars;
  }

  async loadProducts() {
    await super.loadProducts();
    try {
      if (this.vars.lot_item_ids) {
        await itemQueue.loadMany(Object.keys(this.vars.lot_item_ids));
      }
    } catch (e) {
      console.error(e);
    }
  }

  get lots(): string[] {
    // этот геттер возвращает список названий мешков в которых приехали посылки. нужно только для дизайна.
    if (this.vars.lot_item_ids) {
      return Object.keys(this.vars.lot_item_ids);
    }
    return [];
  }
}
