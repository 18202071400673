import { logger } from '@/temp/plugins/logs';
import { defineStore } from 'pinia';

export const useRumStore = defineStore('rum', () => {
  const sendLogger = (message: string) => {
    logger.error('rum store', { additional: { message } });
  };

  // Work with page
  const pages = new Set<string>();

  const hasPage = (pageName: string) => pages.has(pageName);

  const checkPageExist = (pageName: string) => {
    if (!hasPage(pageName)) {
      sendLogger(`Страница не существует: ${pageName}`);
      return false;
    }
    return true;
  };

  const checkPageNotExist = (pageName: string) => {
    if (hasPage(pageName)) {
      sendLogger(`Страница уже существует: ${pageName}`);
      return false;
    }
    return true;
  };

  const addPage = (pageName: string) => {
    if (checkPageNotExist(pageName)) {
      pages.add(pageName);
      return true;
    }
    return false;
  };
  const removePage = (pageName: string) => pages.delete(pageName);
  // END Work with page

  // Work with single mark
  const marks = new Set<string>();

  const hasMark = (markName: string) => marks.has(markName);

  const checkMarkExist = (markName: string) => {
    if (!hasMark(markName)) {
      sendLogger(`Марка не существует: ${markName}`);
      return false;
    }
    return true;
  };

  const checkMarkNotExist = (markName: string) => {
    if (hasMark(markName)) {
      sendLogger(`Марка уже существует: ${markName}`);
      return false;
    }
    return true;
  };

  const addMark = (markName: string) => {
    if (checkMarkNotExist(markName)) {
      marks.add(markName);
      return true;
    }
    return false;
  };

  const removeMark = (markName: string) => marks.delete(markName);
  // END Work with single mark

  // Work with multi mark
  const multiMarks: Record<string, number> = {};

  const hasMultiMark = (multiMarkName: string) => multiMarks[multiMarkName] !== undefined;

  const checkMultiMarkExist = (multiMarkName: string) => {
    if (!hasMultiMark(multiMarkName)) {
      sendLogger(`MultiMark не существует: ${multiMarkName}`);
      return false;
    }
    return true;
  };

  const checkMultiMarkNotExist = (multiMarkName: string) => {
    if (hasMultiMark(multiMarkName)) {
      sendLogger(`MultiMark уже существует: ${multiMarkName}`);
      return false;
    }
    return true;
  };

  const getDeltaMultiMark = (multiMarkName: string) => {
    if (checkMultiMarkExist(multiMarkName)) {
      return multiMarks[multiMarkName];
    }
    return 0;
  };

  const addMultiMark = (multiMarksName: string) => {
    if (checkMultiMarkNotExist(multiMarksName)) {
      multiMarks[multiMarksName] = 0;
      return true;
    }
    return false;
  };

  const addDeltaToMultiMark = (multiMarkName: string, delta: number) => {
    if (checkMultiMarkExist(multiMarkName)) {
      multiMarks[multiMarkName] += delta;
      return true;
    }
    return false;
  };

  const removeMultiMark = (multiMarkName: string) => delete multiMarks[multiMarkName];
  // END Work with multi mark

  return {
    hasPage,
    checkPageExist,
    checkPageNotExist,
    addPage,
    removePage,

    hasMark,
    checkMarkExist,
    checkMarkNotExist,
    addMark,
    removeMark,

    hasMultiMark,
    checkMultiMarkExist,
    checkMultiMarkNotExist,
    getDeltaMultiMark,
    addMultiMark,
    addDeltaToMultiMark,
    removeMultiMark,
  };
});
