<template>
  <OrderBadge bg-color="purple-light" text-color="purple-dark" data-test="order fragile-badge" @click="openInstruction">
    <template #icon>
      <IconFragile color="purple-dark" />
    </template>
    <template #title>
      {{ $gettext('Хрупкий товар') }}
    </template>
  </OrderBadge>
</template>
<script lang="ts">
import IconFragile from '@/temp/icons/icon-fragile.vue';
import OrderBadge from '@/ui/order/badges/order-badge.vue';
import { fragileModal } from '@/utils/modals';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FragileBadge',
  components: { OrderBadge, IconFragile },
  methods: {
    openInstruction(): void {
      fragileModal();
    },
  },
});
</script>
