<template>
  <svg
    class="primary-color-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6464 6.75355C18.8417 6.55829 18.8417 6.24171 18.6464 6.04645L17.9536 5.35355C17.7583 5.15829 17.4417 5.15829 17.2464 5.35355L12 10.6L6.75355 5.35355C6.55829 5.15829 6.24171 5.15829 6.04645 5.35355L5.35355 6.04645C5.15829 6.24171 5.15829 6.55829 5.35355 6.75355L10.6 12L5.35355 17.2464C5.15829 17.4417 5.15829 17.7583 5.35355 17.9536L6.04645 18.6464C6.24171 18.8417 6.55829 18.8417 6.75355 18.6464L12 13.4L17.2464 18.6464C17.4417 18.8417 17.7583 18.8417 17.9536 18.6464L18.6464 17.9536C18.8417 17.7583 18.8417 17.4417 18.6464 17.2464L13.4 12L18.6464 6.75355Z"
      fill="#42413E"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
