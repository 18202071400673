<template>
  <div class="flex items-center rounded-b-3xl bg-main pt-2 pb-1 overflow-x-auto custom-scroll">
    <Chip
      v-for="(chip, index) in chips"
      ref="chipRef"
      :key="index"
      :data-test="`chain shelf ${index}`"
      class="last:mr-2 first:ml-2 ml-1.5 min-w-fit will-change-scroll"
      :bg-color="activeIndex === index ? 'bg-semantic-bg-progress' : 'bg-semantic-bg-control-minor'"
      @click="emits('choose-chip', index)"
    >
      <div class="flex flex-row">
        <template v-if="!chip.icon"><slot class="mr-1.5" /></template>
        <template v-if="chip.icon"><DefaultIcons class="mr-1.5" :name="chip.icon.name" /></template>
        <Body2 :color="activeIndex === index ? 'semantic-text-progress' : 'semantic-text-main'">{{ chip.text }}</Body2>
      </div>
    </Chip>
  </div>
</template>

<script setup lang="ts">
import Chip from '@/fsd/shared/ui/routeBar/chip/chip.vue';
import DefaultIcons from '@/fsd/shared/ui/routeBar/default-icons/default-icons.vue';
import { chipContent } from '@/fsd/shared/ui/routeBar/types';
import Body2 from '@/ui/common/typo/body-2.vue';
import { defineEmits, defineProps, ref, watch, withDefaults } from 'vue';

interface chipsContainerProps {
  activeIndex: number;
  chips: chipContent[];
}

const props = withDefaults(defineProps<chipsContainerProps>(), {
  activeIndex: 0,
  chips: () => [],
});

const chipRef = ref([]);

const emits = defineEmits<{
  (e: 'choose-chip', index: number): void;
}>();

watch(
  () => props.activeIndex,
  value => {
    const { $el } = chipRef.value[value];
    const chip = $el as Element;
    chip.scrollIntoView({ inline: 'center', behavior: 'smooth' });
  },
);
</script>

<style scoped>
.custom-scroll::-webkit-scrollbar {
  display: none;
}
</style>
