<template>
  <Layout>
    <template v-if="!loading" #header>
      <Bar @close-click="toHomePage" />
    </template>
    <template #default>
      <div v-if="!loading" class="flex flex-col gap-2 pb-4">
        <ProductTitle :product_id="product_id" class="mx-4" />
        <ProductMainInfo :product_id="product_id" class="mx-4" />
        <template v-if="available?.length">
          <Separator />
          <ProductAvailable :product_id="product_id" class="mx-4" />
          <Separator />
          <ProductAvailableTotal :product_id="product_id" class="mx-4" />
        </template>
      </div>
      <div v-else class="h-full flex flex-col items-center justify-center">
        <LoaderIndicator class="mb-4" />
        <body1 class="mb-2">
          {{ $gettext('Загрузка') }}
        </body1>
        <caption1 class="mb-2">
          {{ $gettext('Пожалуйста, подождите') }}
        </caption1>
      </div>
    </template>
    <template v-if="!loading" #footer>
      <LayoutFooter>
        <Hint class="w-full">
          {{ $gettext('Отсканируйте нужную полку') }}
        </Hint>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script lang="ts" setup>
import { useCheckProductResources } from '@/fsd/data/utils/checkResourse';
import { useSubscribeOnSuggests } from '@/fsd/data/utils/subscribeOnSuggests';
import { useRumPage } from '@/fsd/shared/metrics';
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import ProductAvailable from '@/fsd/widgets/productCard/ProductAvailable.vue';
import ProductAvailableTotal from '@/fsd/widgets/productCard/ProductAvailableTotal.vue';
import ProductMainInfo from '@/fsd/widgets/productCard/ProductMainInfo.vue';
import ProductTitle from '@/fsd/widgets/productCard/ProductTitle.vue';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import shelfQueue from '@/services/queue/shelf-queue';
import { OrderCheckRequest } from '@/services/requests';
import { OrderCheckResponse } from '@/services/response';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import LoaderIndicator from '@/ui/common/loader-indicator.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Separator from '@/ui/home/product-card/separator.vue';
import { AxiosResponse, isAxiosError } from 'axios';
import uuidv1 from 'uuid/v1';
import { computed, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<{
  product_id: Product['product_id'];
}>();
const { product_id } = toRefs(props);

const router = useRouter();
const { loading } = useCheckProductResources(product_id.value);
const { showLoader } = useLoader();

useRumPage(loading);

const getSrcShelf = () => {
  useRequestBarcode(async barcode => {
    const { closeLoader } = showLoader($gettext('Ищем полку по ШК'));
    try {
      await useShelves().getShelfByBarcode(barcode);
      closeLoader();
    } catch (error) {
      closeLoader();
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const shelf = useShelves().shelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Не найден штрихкод'));
      return true;
    }
    const valid = validateShelf(shelf);
    if (!valid) return true;

    const success = await createChangeValid(shelf);
    return !success;
  });
};
const checkAvailable = async () => {
  if (!available.value || !available.value.length) {
    //  ошибка, нельзя изменить СГ того, чего нет
    toHomePage();
    return;
  }
  if (available.value.length === 1) {
    //  Остатки есть только на одной полке.
    const shelf_id = available.value[0].shelf_id;
    const shelf = await shelfQueue.load(shelf_id);
    const valid = validateShelf(shelf);
    if (!valid) {
      toHomePage();
      return;
    }
    createChangeValid(shelf);
    return;
  }
  getSrcShelf();
};

const createChangeValid = async (shelf: Shelf) => {
  const hasOrder = await useOrders().checkExistRecount({
    product_id: product_id.value,
    shelf_id: shelf.shelf_id,
    mode: 'update_valids',
  });
  if (hasOrder) {
    toChangeValid(hasOrder);
    return false;
  }
  const order_id = await createCheckOrder({
    shelf_id: shelf.shelf_id,
    product_id: product_id.value,
  });
  if (!order_id) {
    toHomePage();
    return false;
  }
  toChangeValid(order_id);
  return false;
};

const toHomePage = () => {
  router.push({ name: 'home' });
};
const toChangeValid = (order_id: BaseOrder['order_id']) => {
  router.push({ name: 'check_product_on_shelf', params: { order_id } });
};

const available = computed(() => {
  return useProducts().availableByProductId(product_id.value);
});

const validateShelf = (shelf: Shelf): boolean => {
  const valid = ['store', 'markdown', 'kitchen_components', 'kitchen_on_demand', 'office'].includes(shelf.type);
  if (!valid) {
    Alerts.error($gettext('Неверная полка'));
    return false;
  }
  return true;
};
const createCheckOrder = async (option): Promise<false | BaseOrder['order_id']> => {
  const createPayload: OrderCheckRequest = {
    order_id: uuidv1(),
    check: {
      shelf_id: option.shelf_id,
      product_id: option.product_id,
    },
    mode: 'check_product_on_shelf',
    update_valids: true,
  };
  const { closeLoader, updateLoader } = showLoader($gettext('Отправляем запрос на создание пересчета'));
  try {
    const response: AxiosResponse<OrderCheckResponse> = await useOrders().createCheck(createPayload);
    const order_id = response.data.order.order_id;
    updateLoader($gettext('Резервируем товар для изменения СГ'), order_id);
    await useSubscribeOnSuggests(order_id)(s => {
      if (!s) return false;
      if (s.size >= 1) {
        return true;
      }
      return false;
    });
    closeLoader();
    return order_id;
  } catch (e: unknown) {
    closeLoader();
    logger.error(e, { method: 'createCheckOrder' });
    if (isAxiosError(e)) {
      const { response } = e;
      switch (true) {
        case response?.status === 400 && response.data.message === 'Product in kitchen assortment':
          Alerts.error($gettext('Нельзя пересчитывать готовую еду'));
          break;
        case response?.status === 400 && response?.data.code === 'NON_QUANTS_PRODUCT':
          await Notifications.modal({
            title: $gettext('Нельзя пересчитать непорционный товар'),
            text: $gettext('Переместите данный товар с кухонной полки и выполните пересчет') + '\nNON_QUANTS_PRODUCT',
          });
          break;
        default:
          Alerts.error($gettext('Не удалось создать задание на пересчет'));
          break;
      }
    }
    AudioService.playError();
    return false;
  }
};

if (loading.value) {
  const unWatchLoading = watch(loading, l => {
    if (l) return;
    unWatchLoading();
    //   ждем завершения загрузки
    checkAvailable();
  });
} else {
  checkAvailable();
}
</script>
