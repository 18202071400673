export const stub = {
  code: 'OK',
  stats: [
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602460800,
      orders_complete: 118,
      avg_order_time: 190.2908287472644,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602547200,
      orders_complete: 155,
      avg_order_time: 238.83507518768312,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602547200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602633600,
      orders_complete: 125,
      avg_order_time: 109.14481120491028,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602633600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602720000,
      orders_complete: 116,
      avg_order_time: 245.72553564762248,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602720000,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602806400,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602892800,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602979200,
      orders_complete: 165,
      avg_order_time: 190.12351137219053,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1602979200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603065600,
      orders_complete: 206,
      avg_order_time: 286.35709498229534,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603065600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603152000,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603238400,
      orders_complete: 118,
      avg_order_time: 206.18445244482007,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603238400,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603324800,
      orders_complete: 220,
      avg_order_time: 199.05739186026832,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603324800,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603411200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603497600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603584000,
      orders_complete: 33,
      avg_order_time: 112.63962916894393,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603584000,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603670400,
      orders_complete: 75,
      avg_order_time: 168.84269827842712,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603670400,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603756800,
      orders_complete: 128,
      avg_order_time: 156.26402289420366,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603756800,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603843200,
      orders_complete: 252,
      avg_order_time: 182.90871350538163,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603843200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603929600,
      orders_complete: 24,
      avg_order_time: 211.32705499728522,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1603929600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604016000,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604102400,
      orders_complete: 181,
      avg_order_time: 211.06814659102847,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604102400,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604188800,
      orders_complete: 151,
      avg_order_time: 176.01492991826393,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604188800,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604275200,
      orders_complete: 131,
      avg_order_time: 144.97992031628849,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604275200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604361600,
      orders_complete: 116,
      avg_order_time: 125.40428667027375,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604361600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604448000,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604534400,
      orders_complete: 86,
      avg_order_time: 192.71156626246696,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604534400,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604620800,
      orders_complete: 91,
      avg_order_time: 198.92357050193536,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604620800,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604707200,
      orders_complete: 122,
      avg_order_time: 177.80716552109016,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604707200,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604793600,
      orders_complete: 0,
      avg_order_time: 0.0,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604880000,
      orders_complete: 70,
      avg_order_time: 149.27045983586993,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604880000,
      orders_complete: 0,
      avg_order_time: 300,
    },
    {
      user_id: '1f82d2f1a7c84f0a92cde91a061dd4d0000300010000',
      interval: 86400,
      interval_start: 1604966400,
      orders_complete: 96,
      avg_order_time: 360.2257100145022,
    },
  ],
};
