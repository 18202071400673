import { api } from '@/fsd/data/api/api.service';
import User from '@/models/User';
import { logger } from '@/temp/plugins/logs';

const BARCODE_MISS_TYPE = new Error('Неверный qr_action');

export const QrActions = {
  async checkSign<T>(action: QrAction<any>): Promise<T> {
    // @ts-expect-error убить confirmed, когда бек перестанет его зачем-то требовать
    const res = await api.qr_action<T>({ sign: action.qr_data, params: { confirmed: true } });
    return res.data.result;
  },
  isAction(barcode: string) {
    let parsedBarcode, res;
    // Если прилетит НЕ объект, JSON.parse выкинет ошибку => это не action
    try {
      parsedBarcode = JSON.parse(barcode);
      res = typeof parsedBarcode === 'object' && 'qr_action_type' in parsedBarcode;
      logger('isAction', res);
      return res;
    } catch (e) {
      return false;
    }
  },
  async trustAcceptance(action: string): Promise<TrustCode> {
    const parsedBarcode: QrAction<'trusted_acceptance'> = JSON.parse(action);
    if (parsedBarcode.qr_action_type === 'trusted_acceptance') {
      const res = await QrActions.checkSign<{
        trust_code: TrustCode;
      }>(parsedBarcode);
      return res.trust_code;
    }
    throw BARCODE_MISS_TYPE;
  },
  async storeCheckin(action: string) {
    const parsedBarcode: QrAction<'store_checkin'> = JSON.parse(action);
    if (parsedBarcode.qr_action_type === 'store_checkin') {
      return await QrActions.checkSign<{
        store_id: User['store_id'];
        user_id: User['user_id'];
      }>(parsedBarcode);
    }
    throw BARCODE_MISS_TYPE;
  },
  async orderCheck(action: string) {
    const parsedBarcode: QrAction<'tsd_order_check'> = JSON.parse(action);
    if (parsedBarcode.qr_action_type === 'tsd_order_check') {
      return await QrActions.checkSign<{
        parent_id: string;
        action: QrActionTypes;
        mode: 'check_product_on_shelf';
      }>(parsedBarcode);
    }
    throw BARCODE_MISS_TYPE;
  },
};

type TrustCode = string;

export type QrActionTypes = 'trusted_acceptance' | 'tsd_order_check' | 'store_checkin';

interface QrAction<T extends QrActionTypes> {
  qr_action_type: T;
  qr_data: string;
}
