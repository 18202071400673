import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import { useLoader } from '@/ui/common/loader/useLoader';

export const resetSuggests = async (order: OrderOrderRetail, suggests: Suggest[]): Promise<boolean> => {
  const ordersStore = useOrders();
  const { showLoader } = useLoader();
  const { closeLoader } = showLoader();

  try {
    const suggest_ids: string[] = suggests.map(suggest => suggest.suggest_id);
    await ordersStore.signal({
      order_id: order.order_id,
      signal: 'complete_suggests',
      data: { suggest_ids },
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    closeLoader();
  }
};
