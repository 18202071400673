import type { TypePackageClass, TypePackageSize, TypePackageType } from '@/models/Item';
import { $gettext } from '@/temp/plugins/gettext';

export const PACKAGE_CLASSES: Record<TypePackageClass, string> = {
  box_l: $gettext('Коробка большая'),
  box_m: $gettext('Коробка средняя'),
  box_s: $gettext('Коробка маленькая'),
  packet_l: $gettext('Пакет большой'),
  packet_m: $gettext('Пакет средний'),
  packet_s: $gettext('Пакет маленький'),
  without_package: $gettext('Без упаковки'),
};

export const TYPE_PACKAGE: Record<TypePackageType, string> = {
  box: $gettext('Коробка'),
  packet: $gettext('Пакет'),
  without_package: $gettext('Без упаковки'),
};

export const SIZE_PACKAGE: Record<TypePackageSize, string> = {
  l: $gettext('Большой'),
  m: $gettext('Средний'),
  s: $gettext('Маленький'),
};

interface TypeAndSizeFromPackageClass {
  type: TypePackageType;
  size?: TypePackageSize;
}

export const getTypeAndSizeFromPackageClass = (value: TypePackageClass): TypeAndSizeFromPackageClass => {
  if (value === 'without_package') {
    return { type: 'without_package', size: undefined };
  }
  const [type, size] = value.split('_');

  return { type, size } as TypeAndSizeFromPackageClass;
};
