<template>
  <LayoutFooter v-if="!hasActiveConfirmSignal" class="flex-row">
    <UiButton
      v-if="needChange"
      data-test="return-btn"
      class="mr-2"
      background-color="secondary"
      @click="emits('changeProducts')"
    >
      <template #icon> <IconPlus /></template>
      {{ $gettext('Замена') }}
    </UiButton>
    <UiButton
      v-if="suggest.status === 'request' && collected"
      data-test="approve-btn"
      class="mr-2"
      background-color="secondary"
      @click="emits('suggestDone')"
    >
      <template #icon> <Check /></template>
      {{ $gettext('Собрано') }}
    </UiButton>
    <template v-if="suggest.status === 'done'">
      <UiButton
        v-if="!suggest.product?.isTrueWeight && suggest.isFull"
        class="mr-2"
        data-test="delete-1-btn"
        background-color="secondary"
        @click="emits('removeProducts')"
      >
        <template #icon> <IconReturn /></template>
        {{ $gettext('Уменьшить количество товара') }}
      </UiButton>
    </template>
  </LayoutFooter>
</template>

<script setup lang="ts">
import IconReturn from '@/fsd/shared/icons/icon-return.vue';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';
import Check from '@/temp/icons/check.vue';
import IconPlus from '@/temp/icons/icon-plus.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps, toRefs } from 'vue';

interface RetailFooterDetailsProps {
  suggest: Suggest;
  collected: number;
}

const props = defineProps<RetailFooterDetailsProps>();
const { suggest } = toRefs(props);
const order = computed<OrderOrderRetail>(() => {
  return useOrders().orderById(suggest.value.order_id) as OrderOrderRetail;
});
const emits = defineEmits<{
  (e: 'suggestDone'): void;
  (e: 'changeProducts'): void;
  (e: 'removeProducts'): void;
}>();

const hasActiveConfirmSignal = computed<boolean>(() => {
  return (
    order.value.vars.confirming_assembled_products || order.value.vars.fulfilled_conditions?.confirm_assembled_products
  );
});

const needChange = computed(() => {
  return !suggest.value.isFull && order.value.isReplaceItemsMethod;
});
</script>
