<template>
  <div class="product" :data-test="`suggest card`" :class="{ 'is-rlt': isRTL }">
    <div class="picture">
      <ImageWrapper :img-src="item?.type || product?.images[0]" />
    </div>
    <div class="info">
      <Typography color="primary-text" type="h4" data-test="suggest-card title">
        {{ title }}
      </Typography>
      <Row
        v-if="product && requiredItem?.count"
        class="text-primary"
        :label="$gettext('Количество')"
        :value="countToView"
        data-test="suggest card count row"
      />
      <Row v-if="shelf" :label="$gettext('Полка')" :value="shelf.title" data-test="suggest card shelf row" />
    </div>
  </div>
</template>

<script lang="ts">
import Item, { ItemInRequired } from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import itemQueue from '@/services/queue/item-queue';
import productQueue from '@/services/queue/product-queue';
import shelfQueue from '@/services/queue/shelf-queue';
import { useItems } from '@/store/modules/items';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { useUser } from '@/store/modules/user';
import { ProductInRequired } from '@/types/product';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Row from '@/ui/common/suggest-card/row.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Row, Typography, ImageWrapper },
  props: {
    requiredItem: {
      type: Object as PropType<ProductInRequired | ItemInRequired>,
      default: undefined,
    },
  },
  setup() {
    const itemsStore = useItems();
    const shelvesStore = useShelves();
    const productsStore = useProducts();
    const userStore = useUser();

    return { itemsStore, shelvesStore, productsStore, userStore };
  },
  computed: {
    isRTL(): boolean {
      return this.userStore.isRTL;
    },
    title(): string {
      if (this.product) {
        return this.product.title;
      }
      if (this.item) {
        return this.item.title;
      }
      return '-';
    },
    product(): Product | undefined {
      if (this.requiredItem && 'product_id' in this.requiredItem) {
        return this.productsStore.productById(this.requiredItem.product_id);
      }
      return undefined;
    },
    item(): Item | undefined {
      if (this.requiredItem && 'item_id' in this.requiredItem) {
        return this.itemsStore.itemById(this.requiredItem.item_id);
      }
      return undefined;
    },
    shelf(): Shelf | undefined {
      if (this.requiredItem && 'shelf_id' in this.requiredItem) {
        return this.shelvesStore.shelfById(this.requiredItem.shelf_id);
      }
      return undefined;
    },
    countToView(): string {
      if (!this.requiredItem?.count || !this.product) {
        return '';
      }
      return getCountToView({ count: this.requiredItem.count, type_accounting: this.product.type_accounting });
    },
  },
  async mounted(): Promise<void> {
    if (this.requiredItem && 'product_id' in this.requiredItem) {
      productQueue.load(this.requiredItem.product_id);
    }
    if (this.requiredItem && 'item_id' in this.requiredItem) {
      itemQueue.load(this.requiredItem.item_id);
    }
    if (this.requiredItem && 'shelf_id' in this.requiredItem && this.requiredItem.shelf_id) {
      shelfQueue.load(this.requiredItem.shelf_id);
    }
  },
});
</script>

<style lang="scss" scoped>
.product {
  background: var(--card-bg);
  display: flex;
  padding: 16px 16px 20px 0;
  overflow-x: hidden;
  transform: scale(1);
  transition: transform 0.3s ease 0s;
  border-radius: 20px;
  margin: 4px 16px;

  &.is-rtl {
    padding: 16px 0 20px 0;
  }
}

.picture {
  position: relative;
  box-sizing: border-box;
  margin: 0 16px;
  width: 52px;
  height: 52px;
}

.info {
  flex: 1 1 auto;
}

.product:last-child .info {
  border-bottom: none;
}
</style>
