<template>
  <svg class="svg-icon" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1.5L6 6L1.5 10.5" stroke-width="1.5" stroke-linecap="round" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
