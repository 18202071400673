<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details quants row" />
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import Row from '@/ui/common/suggest-details/row.vue';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  components: {
    Row,
  },
  props: {
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
  },
  computed: {
    needShow(): boolean {
      return Boolean(this.product?.quants && this.product.quants > 1);
    },
    label(): string {
      if (this.product?.quant_unit) {
        return this.$gettext('В одной упаковке');
      }
      return this.$gettext('Хранится в порциях');
    },
    value(): string {
      if (!this.product) {
        return '';
      }
      if (!this.product?.quant_unit) {
        return this.product?.quants.toString();
      }
      return `${this.product?.quants} ${getQuantUnit(this.product?.quant_unit)}`;
    },
  },
});
</script>
