import BaseOrder, { OrderBaseVars } from '@/models/orders/BaseOrder';

export interface VisualControlOrderVars extends OrderBaseVars {
  suggests_write_off?: boolean; //признак того, что саджесты на списание сгенерированы
}

export default class VisualControlOrder extends BaseOrder {
  public vars: VisualControlOrderVars = { suggests_write_off: false };

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }
}
