import IconBasketReturn from '@/temp/icons/icon-basket-return.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default () => {
  return Notifications.modal({
    title: $gettext('Требуется марка "честный знак"'),
    text: $gettext('Отсканируйте марку "честный знак"'),
    iconTop: {
      icon: IconBasketReturn,
      position: 'center',
    },
  });
};
