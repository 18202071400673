export const ModeService = {
  getModes() {
    return {
      production: 'production',
      device: 'device',
      development: 'development',
    };
  },
  getMode() {
    return process.env.VUE_APP_MODE;
  },
  isDevelopment() {
    return this.getMode() === this.getModes().development;
  },
  isDevice() {
    return this.getMode() === this.getModes().device;
  },
  isProduction() {
    return this.getMode() === this.getModes().production;
  },
};
