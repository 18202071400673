<template>
  <PageLayout :order_id="order_id">
    <template #header>
      <Bar
        :order="order"
        :text="controlBarText"
        :caption="captionText"
        need-progress
        :progress-config="progressConfig"
        :total="suggests.length"
        :icons="{ left: filter === 'all' ? 'close' : 'back', right: 'menu' }"
        :menu-config="menuConfig"
        @close-click="onBarLeftBtnClick"
      />
    </template>
    <template #default>
      <div class="flex flex-col h-full">
        <Hint v-if="filteredSuggests.length !== 0" class="my-2 mx-4">{{ $gettext('Отсканируйте товар') }}</Hint>
        <SuggestCardContainer doc-type="writeoff" :order="order" :suggests="filteredSuggests" />
      </div>
      <SuggestDetails
        v-if="suggestDetails.visible.value"
        :order-id="order.order_id"
        :suggest-id="suggestDetails.props.value.suggest_id"
        @finish="props => finishActiveSuggest(props, suggestDetails.props.value)"
        @cancel="suggestDetails.hide"
      />
      <ScanShelf v-if="scanShelf.visible.value" @scanned="scanShelf.hide" />
      <ShareOrder v-if="order && shareOrder.visible.value" :order="order" @close="shareOrder.hide" />
    </template>
    <template #footer>
      <LayoutFooter>
        <FilterMenu v-if="filter === 'all'" :menu-config="filterMenuConfig" />
        <UiButton v-if="filter === 'all'" data-test="writeoff footer finish-btn" @click="finishOrder">
          {{ $gettext('Завершить') }}
        </UiButton>
        <UiButton v-else @click="setFilter('all')">
          {{ $gettext('Назад') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import PageLayout from '@/fsd/entities/page/PageLayout.vue';
import { useCheck } from '@/fsd/entities/suggest/tools/useCheck';
import { useFilter } from '@/fsd/entities/suggest/tools/useFilter';
import { useDetachFromOrder } from '@/fsd/features/order/utils/useDetachFromOrder';
import { useEndOrder } from '@/fsd/features/order/utils/useEndOrder';
import { useComponent } from '@/hooks/useComponent';
import { useHandleOrderStatus } from '@/hooks/useHandleOrderStatus';
import requestBarcode from '@/mixins/requestBarcode';
import requestProductCode from '@/mixins/requestProductCode';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import WriteoffOrder from '@/models/orders/WriteoffOrder';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getQuantUnit } from '@/temp/constants/translations/quantUnits';
import Bar from '@/ui/common/bar/bar.vue';
import { ProgressConfig } from '@/ui/common/bar/types';
import FilterMenu from '@/ui/common/filter-menu/filter-menu.vue';
import { FilterMenuItemConfig } from '@/ui/common/filter-menu/types';
import Hint from '@/ui/common/hint/hint.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScanShelf from '@/ui/common/scan-shelf/scan-shelf.vue';
import ShareOrder from '@/ui/common/share-order/share-order.vue';
import SuggestDetails from '@/ui/common/suggest-details/suggest-details.vue';
import { Model } from '@/ui/common/suggest-details/types';
import UiButton from '@/ui/common/ui-button.vue';
import SuggestCardContainer from '@/views/common/suggest-card-container.vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  name: 'WriteOff',
  components: {
    PageLayout,
    LayoutFooter,
    SuggestCardContainer,
    Bar,
    UiButton,
    Hint,
    SuggestDetails,
    FilterMenu,
    ShareOrder,
    ScanShelf,
  },
  mixins: [requestBarcode, requestProductCode],
  props: {
    order_id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { showLoader } = useLoader();
    const productsStore = useProducts();
    const ordersStore = useOrders();
    const userStore = useUser();
    const shareOrder = useComponent();
    const suggestDetails = useComponent<Suggest>();
    const scanShelf = useComponent<void, Shelf>();
    const router = useRouter();
    const { detachBtn } = useDetachFromOrder(props.order_id);
    const {
      filter,
      setFilter,
      suggests,
      filteredSuggests,
      partiallyCompletedSuggests,
      fullCompletedSuggests,
      requestSuggests,
    } = useFilter(props.order_id);

    useHandleOrderStatus(props.order_id);

    return {
      showLoader,
      productsStore,
      userStore,
      ordersStore,
      shareOrder,
      suggestDetails,
      scanShelf,
      router,
      detachBtn,
      filter,
      setFilter,
      suggests,
      filteredSuggests,
      partiallyCompletedSuggests,
      fullCompletedSuggests,
      requestSuggests,
    };
  },
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  computed: {
    order(): WriteoffOrder | undefined {
      return this.ordersStore.orderById(this.order_id) as any as WriteoffOrder;
    },
    controlBarText(): string {
      if (!this.order?.suggests) {
        return '';
      }
      switch (this.filter) {
        case 'all':
          return this.$ngettext('%{n} товар', '%{n} товаров', this.order.suggests.length, {
            n: String(this.order.suggests.length),
          });
        case 'done':
        case 'request':
        case 'part':
        case 'noProduct':
          return this.$ngettext(
            '%{filtered} товар с таким статусом',
            '%{filtered} товаров с таким статусом',
            this.filteredSuggests.length,
            { filtered: String(this.filteredSuggests.length) },
          );
      }
      return '';
    },
    captionText(): string {
      switch (this.filter) {
        case 'all':
          return this.$gettext('Списание');
        case 'done':
          return this.$gettext('Товары со статусом “Готово”');
        case 'request':
          return this.$gettext('Не отсканированные товары');
        case 'part':
          return this.$gettext('Товары со статусом “Почти”');
        case 'noProduct':
          return this.$gettext('Товары со статусом “Нет”');
      }
      return this.$gettext('Списание');
    },
    filterMenuConfig(): FilterMenuItemConfig[] {
      return [
        {
          buttonText: this.$gettext('Не отсканированные'),
          color: 'gray',
          count: this.requestSuggests.length,
          onClick: () => this.setFilter('request'),
        },
        {
          buttonText: this.$gettext('Готово'),
          color: 'green',
          count: this.fullCompletedSuggests.length,
          onClick: () => {
            this.setFilter('done');
          },
        },
        {
          buttonText: this.$gettext('Почти'),
          color: 'orange',
          count: this.partiallyCompletedSuggests.length,
          onClick: () => {
            this.setFilter('part');
          },
        },
      ];
    },
    progressConfig(): ProgressConfig[] {
      return [
        {
          count: this.fullCompletedSuggests.length,
          color: 'green',
        },
        {
          count: this.partiallyCompletedSuggests.length,
          color: 'orange',
        },
      ];
    },
    menuConfig(): MenuItemConfig[] {
      const menuConfig: MenuItemConfig[] = [];
      menuConfig.push(this.detachBtn);

      const shareOrderBtn: MenuItemConfig = {
        buttonText: this.$gettext('Разделить задание'),
        dataTest: 'bar menu-item share-order-btn',
        onClick: () => this.showShareOrder(),
        condition: () => this.userStore.experimentByName(experiments.exp_tsd_companion),
      };
      menuConfig.push(shareOrderBtn);

      return menuConfig;
    },
  },
  methods: {
    async requestBarcode(): Promise<boolean> {
      const { product } = await this.requestProductCode({ checkSuggests: true });
      if (!product) {
        this.$alert.error(this.$gettext('Продукта нет в задании'));
        return true;
      }
      const checkSuggests = this.getCheckSuggests(product.product_id);
      if (checkSuggests.length === 1) {
        await this.suggestDetails.asyncShow(checkSuggests[0]);
        return true;
      }
      if (checkSuggests.length === 0) {
        this.$alert.error(this.$gettext('%{barcode} нет в задании', { barcode: product.barcode[0] }));
        return true;
      }
      const shelf = await this.scanShelf.asyncShow();
      if (!shelf) return true;
      const checkSuggest = checkSuggests.find(item => item.shelf_id === shelf.shelf_id);
      if (checkSuggest) {
        await this.suggestDetails.asyncShow(checkSuggest);
        return true;
      }
      return true;
    },
    getCheckSuggests(product_id: string): Suggest[] {
      return this.suggests.filter(suggest => suggest.product_id === product_id && suggest.type === 'check');
    },
    async finishActiveSuggest({ count = 0 }: Pick<Model, 'count'>, suggest: Suggest): Promise<void> {
      const activeProduct = suggest.product;
      const getTitle = () => {
        if (activeProduct?.quants && activeProduct?.quants > 1) {
          return this.$ngettext(
            'Вы уверены, что хотите списать %{count} закрытых упаковок товара (%{unit_count} %{unit})?',
            'Вы уверены, что хотите списать %{count} закрытых упаковок товара (%{unit_count} %{unit})?',
            ~~(count / activeProduct?.quants),
            {
              count: String(~~(count / activeProduct?.quants)),
              unit_count: String(count),
              unit: getQuantUnit(activeProduct?.quant_unit),
            },
          );
        }
        return this.$gettext('Вы уверены, что хотите списать %{count} шт. %{product}?', {
          count: count.toString(),
          product: String(activeProduct?.title),
        });
      };

      const confirmed = await this.$notification.confirmBottom({
        title: getTitle(),
      });
      if (!suggest || !confirmed) return;
      const result = await useCheck(this.order_id, {
        suggest_id: suggest.suggest_id,
        count,
      });
      if (!result) return;
      this.suggestDetails.hide();
    },
    async finishOrder(): Promise<void> {
      const confirm = await this.$notification.confirmBottom({
        title: this.$gettext('Завершить задание?'),
        ok: this.$gettext('Да, все готово'),
      });
      if (!confirm) return;
      this.uiStateNeedBarcodeRequest = false;
      const result = await useEndOrder(this.order_id);
      if (result) {
        this.router.push({ name: 'home' });
      } else {
        this.uiStateNeedBarcodeRequest = true;
      }
    },
    onBarLeftBtnClick(): void {
      if (this.filter === 'all') {
        this.router.push({ name: 'home' });
      } else {
        this.setFilter('all');
      }
    },
    async showShareOrder(): Promise<void> {
      this.uiStateNeedBarcodeRequest = false;
      await this.shareOrder.asyncShow();
      this.uiStateNeedBarcodeRequest = true;
    },
  },
});
</script>
