<template>
  <div>
    <Empty v-if="!(ordersForCarousel.length || failedOrders.length)" />
    <GroupContainer v-else :requested-orders="ordersForCarousel" :failed-orders="failedOrders" />
  </div>
</template>

<script lang="ts" setup>
import { ORDERS_WEIGHT } from '@/fsd/widgets/home/carousel/orders_weights';
import BaseOrder, { OrderTypeEnum, OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { orderSorter, queueSorter } from '@/store/utils/sorters';
import Empty from '@/ui/home/empty/empty.vue';
import GroupContainer from '@/ui/home/group-item/group-container.vue';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const ordersStore = useOrders();
const { requestedOrders, failedOrders } = storeToRefs(ordersStore);
const ordersForCarousel = computed(() => {
  const getWeight = order => {
    if (order.type === 'sale_stowage') {
      if (order.vars?.addition_tag) {
        return ORDERS_WEIGHT.sale_stowage[order.vars?.addition_tag];
      }
      if (order.vars.tag === 'parcel') {
        return ORDERS_WEIGHT.sale_stowage.parcel;
      }
    }
    return ORDERS_WEIGHT[order.type].default;
  };

  return (
    requestedOrders.value
      // оставляем только те типы, что есть в описании весов
      .filter(order => Object.keys(ORDERS_WEIGHT).includes(order.type))
      // выкидываем клиентские заказы в состоянии дозаказа
      .filter(order => {
        return !(
          [OrderTypeEnum.order, OrderTypeEnum.order_retail].includes(order.type) &&
          (order as OrderOrder).isOrderPaused &&
          order.status === OrderWorkStatusEnum.request
        );
      })
      .sort((a: BaseOrder, b: BaseOrder) => {
        const weightA = getWeight(a);
        const weightB = getWeight(b);
        // если оба документа - заказы, то сортируем опираясь на очередь
        if (weightA === weightB && [OrderTypeEnum.order, OrderTypeEnum.order_retail].includes(a.type)) {
          return queueSorter(a, b);
        }
        if (weightA === weightB) {
          // сортируем по статусу и дате создания
          return orderSorter(a, b);
        }
        // сортируем по весу
        return weightA - weightB;
      })
      // оставляем верхние 10
      .slice(0, 10)
  );
});
</script>
