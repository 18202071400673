import { api } from '@/fsd/data/api/api.service';
import BaseOrder, { OrderWorkStatusEnum } from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { subscribeOnOrder } from '@/utils/subscribeOnOrder';
import { AxiosError } from 'axios';

export interface EndOptions {
  onValidateError?: (e: string) => void;
  beforeRequest?: () => void;
  afterRequest?: () => void;
  onRequestError?: (e: AxiosError, retry: () => Promise<any>) => void | Promise<boolean> | boolean;
  beforeEventWaiting?: () => void;
  afterEventWaiting?: () => void;
  onEventWaitingError?: (e: string) => void;
  closeLoader?: () => void;
}

export const endOrder = async (order_id?: BaseOrder['order_id'], options: EndOptions = {}) => {
  if (!order_id) {
    options.onValidateError?.('Order not found');
    return false;
  }
  const order = useOrders().orderById(order_id);
  if (!order || order.target === order.status) {
    return true;
  }
  if (order.status !== OrderWorkStatusEnum.processing) {
    options.onValidateError?.('Order not in processing status');
    return false;
  }
  try {
    options.beforeRequest?.();
    // мб ретрай, а иногда даже можно и заверить
    await api.order.change_status({ order_id });
    options.afterRequest?.();
  } catch (e) {
    // обработка сетевых ошибок несколько сложнее, чем в остальных сценариях, нужно дать пользователю возможность сделать ретрай,
    // плюс некоторые ошибки это вовсе и не ошибки
    options.closeLoader?.();
    // сетевая ошибка, возвращаем результат обработчика ошибки(он проверит ошибка ли это) или false
    return options.onRequestError?.(e as AxiosError, () => endOrder(order_id, options)) || false;
  }
  try {
    options.beforeEventWaiting?.();

    await new Promise<void>((res, rej) => {
      const cb = (data, unSub) => {
        if (data.status === order.target) {
          unSub();
          res();
          return;
        }
        if (data.status === 'failed') {
          unSub();
          rej('Order status is failed');
          return;
        }
      };
      subscribeOnOrder({ order_id, cb });
    });
    options.afterEventWaiting?.();
  } catch (e) {
    options.onEventWaitingError?.(e as string);
    options.closeLoader?.();
    // ордер дотек до статуса failed, произошло что-то страшное, обязательно показываем пользователю
    return false;
  }
  options.closeLoader?.();
  return true;
};
