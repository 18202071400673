<template>
  <div v-if="activeStep === 'shelf'" class="p-4">
    <Hint class="mb-2">{{ hintText }}</Hint>
    <UiButton :disabled="true">
      {{ $gettext('Завершить') }}
    </UiButton>
  </div>
</template>
<script setup lang="ts">
import { Alerts } from '@/fsd/shared/tools/alertNotification';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Item from '@/models/Item';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Hint from '@/ui/common/hint/hint.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { computed, defineEmits, defineProps, ref } from 'vue';
import UiButton from '../../ui-button.vue';

const { showLoader } = useLoader();
const shelvesStore = useShelves();

interface ShelfFieldProps {
  activeStep: string;
  order: BaseOrder;
  product?: Product;
  item?: Item;
  suggest: Suggest;
}

const props = defineProps<ShelfFieldProps>();

const emits = defineEmits<{ (e: 'confirmShelf', shelf?: Shelf): void }>();

const shelfRef = ref<Shelf | undefined>(undefined);

const shelf = computed<Shelf>(() => shelvesStore.shelfById(props.suggest.shelf_id)!);

const hintText = computed<string>(() => {
  const hintTexts = {
    default: $gettext('Отсканируйте полку на которую кладете товар'),
    refund: $gettext('Отсканируйте указанную полку'),
    part_refund: $gettext('Отсканируйте указанную полку'),
    stowage: $gettext('Отсканируйте указанную полку'),
    sale_stowage: $gettext('Отсканируйте указанную полку'),
    stowage_market: $gettext('Отсканируйте подходящую полку'),
    hand_move: $gettext('Отсканируйте полку %{shelf}', { shelf: shelf.value?.title }),
    kitchen_provision: $gettext('Отсканируйте полку %{shelf}', { shelf: shelf.value?.title }),
  };
  if (['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(props.order.type)) {
    return hintTexts['control'];
  }
  return hintTexts[props.order.type] || hintTexts['default'];
});

const onCancelDisabledShelfConfirm = (): void => {
  shelfRef.value = undefined;
  needBarcodeRequest.value = true;
};
const onInputShelfConfirm = (shelf?: Shelf): void => {
  needBarcodeRequest.value = false;
  emits('confirmShelf', shelf);
};
const onOkDisabledShelfConfirm = (): void => {
  onInputShelfConfirm(shelfRef.value);
  shelfRef.value = undefined;
};

const checkValidShelf = (shelf: Shelf): boolean => {
  if (shelf.shelf_id === props.suggest.shelf_id) {
    return true;
  }
  if (!props.suggest.conditions.error) {
    Alerts.error($gettext('Отсканирована неверная полка'));
    return false;
  }
  if (['sale_stowage'].includes(props.order.type) && props.order.attr.returnable_supplier && shelf.type === 'trash') {
    Notifications.modal({
      title: $gettext('Нельзя размещать товар от возвратного поставщика на полку Списание'),
    });
    return false;
  }
  if (
    ['stowage_market', 'sale_stowage', 'weight_stowage', 'kitchen_provision'].includes(props.order.type) &&
    props.suggest.conditions.error
  ) {
    if (props.item) {
      if (['parcel', 'parcel_returned'].includes(shelf.type)) {
        return true;
      }
      Alerts.error($gettext(`Полка не соответствует зоне хранения`));
      return false;
    }
    const tags = props.product?.tags || [];
    switch (true) {
      case tags.length === 0 && shelf.tags.length === 0:
      case tags[0] && shelf.tags.includes(tags[0]):
        if (shelf.status === 'disabled') {
          shelfRef.value = shelf;
          needBarcodeRequest.value = false;
          Notifications.universalModal({
            title: $gettext('Полка отключена.'),
            titleAlign: 'center',
            text: $gettext('Размещение товара - включит ее на складе.'),
            position: 'center',
            isWrap: true,
            buttons: [
              {
                title: $gettext('Нет, выбрать другую'),
                color: 'primary',
                dataTest: 'shelf-field disabled-shelf-confirm no-btn',
                onClick: () => {
                  onCancelDisabledShelfConfirm();
                },
              },
              {
                title: $gettext('Да, разместить'),
                color: 'secondary',
                dataTest: 'shelf-field disabled-shelf-confirm yes-btn',
                onClick: () => {
                  onOkDisabledShelfConfirm();
                },
              },
            ],
          });
          return false;
        }
        return true;
      case shelf.type === 'trash':
        return true;
      default:
        Alerts.error($gettext(`Полка не соответствует зоне хранения`));
        return false;
    }
  }
  Alerts.error($gettext('Отсканирована неверная полка'));
  return false;
};

const { needBarcodeRequest } = useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const shelf = await shelvesStore.getShelfByBarcode(barcode); //getShelfByBarcode(barcode);
    if (!shelf) {
      Alerts.error($gettext('Произошла ошибка при загрузке полки'));
      return true;
    }
    if (checkValidShelf(shelf)) {
      onInputShelfConfirm(shelf);
      return false;
    }
    return true;
  } catch (error) {
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
