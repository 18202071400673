<template>
  <Layout>
    <template #header>
      <OrderRetailHeaderReset :order="order" @close="$emit('back')" />
    </template>
    <template #default>
      <div class="bg-element flex flex-col gap-2">
        <div class="bg-main p-4 rounded-b-3xl flex flex-col gap-2">
          <Title2 class="font-bold">{{ $gettext('Выложите эти товары из заказа') }}</Title2>
          <Body2>{{ $gettext('Эти товары не надо собирать в заказ.') }}</Body2>
        </div>
        <div class="bg-main p-4 rounded-t-3xl flex flex-col gap-4 h-full">
          <OrderRetailSuggestCard
            v-for="s in needResetSuggests"
            :key="s.suggest_id"
            :collected="s.result_count || 0"
            :suggest="s"
            :need-buttons="false"
            :need-right-buttons="false"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <OrderRetailFooterReset :suggest="suggest" @back="$emit('back')" @ready="toPackage" />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { useConfirmAssembledProducts } from '@/fsd/data/order/useConfirmAssembledProducts';
import { OrderRetailSuggestCard, resetSuggests } from '@/fsd/features/suggest';
import Suggest from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { computed, defineEmits, defineProps, watch } from 'vue';
import OrderRetailFooterReset from './order-retail-footer-reset.vue';
import OrderRetailHeaderReset from './order-retail-header-reset.vue';

const { showLoader } = useLoader();
const { confirmAssembledProducts } = useConfirmAssembledProducts();

interface ClearConfirmProps {
  order: OrderOrderRetail;
  suggest_id?: Suggest['suggest_id'] | void;
}

const props = defineProps<ClearConfirmProps>();

const emits = defineEmits<{
  (e: 'back'): void;
  (e: 'toFinish'): void;
}>();

const suggest = computed<Suggest | undefined>(() => {
  if (props.suggest_id) {
    return props.order.suggestById(props.suggest_id);
  }
  return undefined;
});

const needResetSuggests = computed<Suggest[]>(() => {
  if (suggest.value) {
    return suggest.value?.fullChildrenSuggest;
  }
  return props.order.needResetSuggests;
});

const hide = () => {
  if (props.suggest_id) {
    emits('back');
  } else if (props.order.forFinishPacking.length) {
    confirmAssembledProducts(props.order);
  } else {
    emits('toFinish');
  }
};

const toPackage = async () => {
  if (needResetSuggests.value.length) {
    let success = await Notifications.confirmCenter({
      title: $gettext('Все товары из списка выложены из заказа?'),
      decline: $gettext('Отмена'),
      ok: $gettext('Выложены'),
    });
    if (success) {
      success = await resetSuggests(props.order, needResetSuggests.value);
      const { closeLoader } = showLoader();
      await new Promise<void>(resolve => {
        const unWatch = watch(
          () => needResetSuggests.value.length,
          len => {
            if (!len) {
              resolve();
              unWatch();
              return;
            }
          },
        );
      });
      closeLoader();
    }
    if (success) hide();
  } else {
    hide();
  }
};
</script>
