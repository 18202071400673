import OrderEvent from '@/models/events/OrderEvent';
import EventService, { CB } from '@/services/event.service';
import { useUser } from '@/store/modules/user';
import { logger } from '@/temp/plugins/logs';

interface SubscribeOnOrderProps {
  order_id: string;
  cb: (ev: OrderEvent, unSub: () => void) => void;
}

export const subscribeOnOrder = ({ order_id, cb }: SubscribeOnOrderProps) => {
  const storeId = useUser().user!.store;

  const func: CB = (code, events = []) => {
    const orderEvents = events.filter(OrderEvent.isOrderEvent).filter(e => e.order_id === order_id);
    orderEvents.forEach(ev => {
      cb(ev, unSub);
    });
  };

  const unSub = EventService.subscribe(['order', 'store', storeId], func);
};

interface SubscribeOnOrderStatusProps {
  order_id: string;
  cbSuc: (data: OrderEvent, unSub: () => void) => void;
  cbFail: (data: OrderEvent, unSub: () => void) => void;
}

//TODO не ждать естатус done
export const subscribeOnOrderStatus = ({ order_id, cbSuc, cbFail }: SubscribeOnOrderStatusProps) => {
  logger('Оформлена подписка на ордер', order_id);
  const cb: SubscribeOnOrderProps['cb'] = (data, unSub) => {
    try {
      if (data.status === 'complete' && data.estatus === 'done') {
        cbSuc(data, unSub);
      } else if (data.status === 'failed') {
        cbFail(data, unSub);
      }
    } catch (error) {
      console.error(error);
    }
  };
  subscribeOnOrder({ order_id, cb });
};
