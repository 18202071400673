import BaseOrder from '@/models/orders/BaseOrder';
import { useUser } from '@/store/modules/user';

// обратная совместимость

export default class AcceptanceOrder extends BaseOrder {
  get isAvailableForJunior() {
    const maxWeight = useUser().getMaxWeightForOrder(this.type);
    if (!maxWeight) return false;
    if (this.maxWeightInOrder > maxWeight) {
      return false;
    }
    return super.isAvailableForJunior;
  }
}
