import { BarcodeHandler } from '@/services/scanner/handlers/BarcodeHandler';
import { logger } from '@/temp/plugins/logs';

export class SoftScanner extends BarcodeHandler {
  public barcode: string = '';
  public resolve;
  public reject;
  constructor() {
    super();
    this.mode = 'soft';
    this.barcode = '';
  }

  async init() {
    await super.init();
    document.addEventListener('keydown', this.onKeyDown.bind(this));
  }

  async destroy() {
    await super.destroy();
    document.removeEventListener('keydown', this.onKeyDown.bind(this));
  }

  async scan() {
    await super.scan();
    logger('Скан');
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  onKeyDown(e) {
    if (e.key === 'Enter') {
      this.resolve(this.barcode);
      this.barcode = '';
      // запретим отправку enter наружу
      e.stopPropagation();
      e.preventDefault();
      return false;
    } else {
      // Иначе будут лезть шифты
      if (e.key.length === 1) {
        this.barcode += e.key;
      }
    }
  }
}
