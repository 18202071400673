<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="11" width="14" height="2" />
    <rect x="11" y="19" width="14" height="2.001" transform="rotate(-90 11 19)" />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
