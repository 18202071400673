<template>
  <svg height="100%" class="inline -ml-0.5" viewBox="0 0 14 40" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 0H8.4V3.4885C8.4 7.4469 10.6366 10.9214 12.5045 14.1962C13.3559 15.6889 14 17.6085 14 20C14 22.3915 13.3559 24.3112 12.5045 25.8038C10.6366 29.0787 8.4 32.5532 8.4 36.5115V40H0C0 25.7323 0 15.9224 0 0Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
