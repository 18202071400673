import { ModeService } from '@/services/mode.service';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { modalNotifyId } from '@/temp/constants/common';
import iconSadBasket from '@/temp/icons/icon-sad-basket.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { notify } from '@kyvg/vue3-notification';
import { markRaw, onMounted, onUnmounted } from 'vue';

const LOGOUT_MODAL_DELAY = ModeService.isProduction()
  ? 3_600_000 // 1 час
  : 300_000; // 5 минут

const HARD_LOGOUT_DELAY = 30_000; // через сколько насильно разлогиним пользователя

// TODO написать unit тест на этот хук
export const useLogout = () => {
  const userStore = useUser();
  const ordersStore = useOrders();
  let modalTimer: number | undefined; // через сколько появится предупреждение о разлогине
  let hardLogoutTimer: number | undefined; // через сколько насильно разлогинят

  const startHardLogoutTimer = () => {
    hardLogoutTimer = setTimeout(() => {
      stopModalTimer();
      notify.close(modalNotifyId);
      userStore.logout();
    }, HARD_LOGOUT_DELAY);
  };

  const stopHardLogoutTimer = () => {
    clearTimeout(hardLogoutTimer);
  };

  // показываем модалку о том, что пользователь вот-вот будет разлогинен
  // в модалке надо ходить в ручку logout
  const showLogoutModal = () =>
    Notifications.modal({
      title: $gettext('Через 30 секунд вы будете отключены'),
      text: $gettext(
        'Кажется, вы слишком долго не выполняли задания. Если вы не возьмёте задание в работу, вас придётся отключить',
      ),
      buttonText: $gettext('Сейчас возьму'),
      iconTop: {
        icon: markRaw(iconSadBasket),
      },
    });

  // начинаем обратный отсчет до разлогина
  const startModalTimer = () => {
    modalTimer = setTimeout(() => {
      // ищем активные ордера, если не находим - разлогиниваем
      const activeOrder = ordersStore.ordersGetter.find(o => o.status !== 'request');
      if (!activeOrder) {
        startHardLogoutTimer(); // запускаем отсчет на принудительный разлогин
        showLogoutModal() // показываем модалку с предупреждением о разлогине
          .then(stopHardLogoutTimer); // если пользователь подтвердил, что он тут - не разлогиниваем его
      }
      startModalTimer(); // снова запускаем отсчет на предупреждение
    }, LOGOUT_MODAL_DELAY);
  };

  // сбрасываем таймер на разлогин
  const stopModalTimer = () => {
    clearTimeout(modalTimer);
    modalTimer = undefined;
  };

  onMounted(startModalTimer);
  onUnmounted(() => {
    stopModalTimer();
    stopHardLogoutTimer();
  });
};
