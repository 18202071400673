import { CB, waitDataChange } from '@/fsd/shared/utils/subscribe';
import BaseOrder from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import { useSuggests } from '@/store/modules/suggests';
import { computed } from 'vue';

export const useSubscribeOnSuggests = (order_id: BaseOrder['order_id']) => {
  const suggests = computed<Map<Suggest['suggest_id'], Suggest> | undefined>(() => {
    return useSuggests().suggestsByOrderId(order_id);
  });
  const subscribeOnSuggests = (cb: CB<Map<Suggest['suggest_id'], Suggest> | undefined>) => {
    return waitDataChange(suggests, cb);
  };
  return subscribeOnSuggests;
};

export const useSubscribeOnSuggest = (order_id: BaseOrder['order_id'], suggest_id: Suggest['suggest_id']) => {
  const suggest = computed<Suggest | undefined>(() => {
    return useSuggests().getSuggest(order_id, suggest_id);
  });
  const subscribeOnSuggest = (cb: CB<Suggest | undefined>) => {
    return waitDataChange(suggest, cb);
  };
  return subscribeOnSuggest;
};
