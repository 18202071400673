import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { AudioService } from '@/services/audio.service';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import { isAxiosError } from 'axios';
import { watch } from 'vue';

export const sendMoreProductSignal = async (
  order_id: BaseOrder['order_id'],
  product_id: Product['product_id'],
): Promise<Suggest | false> => {
  const order = useOrders().orderById(order_id);
  const product = useProducts().productById(product_id);
  if (!order || !product) return false;
  const getConfirmTitle = () => {
    if (order.vars.stage === 'stowage') {
      return $gettext('Этой позиции нет в списке. Все равно разместить?');
    }
    if (product) {
      const suggestsWithProduct = order.suggests?.filter(s => s.product_id === product.product_id) || [];
      const trashStageSuggests = suggestsWithProduct.find(s => s.vars.stage === 'trash');
      if (trashStageSuggests) {
        return $gettext('Вы уже списали этот товар - вы уверены, что хотите списать еще ?');
      }
    }
    return $gettext('Этой позиции нет в списке. Все равно списать?');
  };
  const getConfirmText = () => {
    if (order.vars.stage === 'stowage') {
      return $gettext('Кажется, что эту позицию никто не заказывал, но её все равно можно разместить');
    }
    return $gettext('Кажется, что эту позицию никто не заказывал, но её все равно можно списать');
  };
  const more_product = async () => {
    const product_id =
      product.parent_id && product.type_accounting === 'weight' ? product.parent_id : product.product_id;
    try {
      await useOrders().signal({
        order_id,
        signal: 'more_product',
        data: { product_id, count: 1 },
      });
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        if (e.response?.data.code === 'ER_CONTRACTOR_ASSORTMENT_CHECK') {
          AudioService.playError();
          Notifications.modal({
            title: $gettext('Данный товар отсутствует в ассортименте поставщика'),
          });
        }
        if (e.response?.data.code === 'ER_STOWAGE_PRECHECK') {
          AudioService.playError();
          Notifications.modal({
            title: $gettext('Данный товар уже добавлен в приемку'),
          });
        }
        if (
          e.response?.data.code === 'ER_PRODUCT_IN_SIBLING_STOWAGE' ||
          e.response?.data.code === 'ER_PRODUCT_IN_SIBLING_STOWAGE_SIGNAL'
        ) {
          AudioService.playError();
          Notifications.modal({
            title: $gettext('Товар уже есть в другом задании на размещение'),
            text: e.response.data.code,
          });
        }
      }
      throw e;
    }
  };
  const waitGenerateSuggestForProduct = async (): Promise<Suggest | undefined> => {
    //проверить есть ли саджесты на полку списания
    if (!order.suggests) return undefined;

    const isCreatedSuggest = s => {
      if (product.type_accounting === 'weight') {
        return s.product_id === product.parent_id && s.status === 'request';
      } else {
        return s.product_id === product.product_id && s.status === 'request';
      }
    };

    return new Promise<Suggest>(resolve => {
      let needWatch = false;
      let unWatch = () => {
        needWatch = true;
      };
      const handler = suggests => {
        for (const s of suggests) {
          if (isCreatedSuggest(s)) {
            resolve(s);
            unWatch();
            return;
          }
        }
      };
      handler(order.suggests);
      if (!needWatch) {
        unWatch = watch(() => order.suggests, handler);
      }
    });
  };

  const { showLoader } = useLoader();
  // проверка соответствия размещаемого продукта зоне размещения
  if (order.vars.tag !== 'mixed' && order.vars.tag != product.tags[0]) {
    const confirmed = await Notifications.confirmCenter({
      title: $gettext('Данный товар не соответствует зоне размещения'),
      text: $gettext('Вы точно хотите продолжить?'),
    });
    if (!confirmed) return false;
  } else {
    const confirmed = await Notifications.confirmCenter({
      title: getConfirmTitle(),
      text: getConfirmText(),
    });
    if (!confirmed) return false;
  }
  const { closeLoader, updateLoader } = showLoader();
  try {
    await more_product();
    updateLoader($gettext('Ожидаем создания задания на размещение'));
    const suggest = await waitGenerateSuggestForProduct();
    closeLoader();
    if (!suggest) return false;
    return suggest;
  } catch (e) {
    closeLoader();
    console.error(e);
    return false;
  }
};
