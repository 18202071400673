import BaseOrder, { OrderBaseVars, OrderTypeEnum } from '@/models/orders/BaseOrder';

export enum ControlModeEnum {
  // распродажа для своих. любая исходная полка. целевая полка распродажи. переносятся товары с 0 < СХ < списать за
  store2markdown = 'store2markdown',
  // Обычное КСГ. любая исходная полка. целевая полка списания. переносятся товары с 0 < СХ < списать за, для товаров, что не входят в ассортимент распродажи
  //  с полки распродажи переносятся товары с 0 < СХ < expired_count_sale
  store2trash = 'store2trash',
  // КСГ на полку разбор. любая исходная полка. целевая полка разбора. переносятся товары с 0 < СХ < списать за, если товары пришли от поставщика, что забирает просрочку.
  store2review = 'store2review',
  // Съешь сегодня. любая исходная полка. целевая полка распродажи. переносятся товары с 0 < СХ < expired_count_sale. продаются для внешних пользователей
  eatToday2markdown = 'eatToday2markdown',
  // списание с полки распродажи. исходная полка распродажи. целевая полка списания. переносятся товары с 0 < СХ < списать за
  markdown2trash = 'markdown2trash',
  review2trash = 'review2trash',
}

export interface ControlOrderVars extends OrderBaseVars {
  mode: ControlModeEnum;
  //признак того, что саджесты на списание или уценку сгенерированы
  suggests_write_off: boolean;
}

export default class ControlOrder extends BaseOrder {
  public vars: ControlOrderVars = { mode: ControlModeEnum.store2trash, suggests_write_off: false };

  constructor(data: any) {
    super(data);
    this.vars = data.vars;
  }
}

export const isControlOrder = (o: BaseOrder): o is ControlOrder => {
  return [
    OrderTypeEnum.writeoff_prepare_day,
    OrderTypeEnum.check_valid_regular,
    OrderTypeEnum.check_valid_short,
  ].includes(o.type);
};
