import IconBasket from '@/temp/icons/icon-basket.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default () => {
  return Notifications.modal({
    title: $gettext('Этот товар уже был собран в этом задании'),
    text: $gettext(
      'Положите его в корзину и возьмите следующий. У каждого продукта марка уникальна. Нужно сканировать каждый товар отдельно',
    ),
    iconTop: {
      icon: IconBasket,
      position: 'center',
    },
  });
};
