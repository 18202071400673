import { StatusBadgeConfig, StatusTypeEnum } from '@/fsd/entities/badges';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';

export const getImgBadge = (suggest: Suggest): StatusBadgeConfig[] => {
  const result: StatusBadgeConfig[] = [];
  if (suggest.status === SuggestStatusEnum.done) {
    if (suggest.isFull || suggest.isOver) {
      result.push({ type: StatusTypeEnum.complete, text: $gettext('Собран') });
    } else if (suggest.isPartial) {
      result.push({ type: StatusTypeEnum.waiting, text: $gettext('Мало') });
    } else {
      result.push({ type: StatusTypeEnum.error, text: $gettext('Нет') });
    }
  }
  return result;
};
