import {
  getEmptyDoneSuggests,
  getFullDoneSuggests,
  getPartDoneSuggests,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import Suggest from '@/models/Suggest';

export const getDefaultProgressConfig = (suggests: Suggest[]) => {
  if (!suggests) return;
  return [
    {
      count: getFullDoneSuggests(suggests).length,
      color: 'green',
    },
    {
      count: getPartDoneSuggests(suggests).length,
      color: 'orange',
    },
    {
      count: getEmptyDoneSuggests(suggests).length,
      color: 'red',
    },
  ];
};
