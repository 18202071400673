import { BarcodeHandler } from '@/services/scanner/handlers/BarcodeHandler';
import { NativeScanner } from '@/services/scanner/native-scanner';

export class DeviceHandler extends BarcodeHandler {
  mode: string;
  constructor() {
    super();
    this.mode = 'device';
  }

  async init() {
    await super.init();
    await NativeScanner.start();
  }

  async destroy() {
    await super.destroy();
    await NativeScanner.stop();
  }

  async scan(): Promise<any> {
    await super.scan();
    return await NativeScanner.barcode();
  }
}
