<template>
  <div v-if="needShow" class="m-4">
    <UiButton background-color="secondary" data-test="support-chat btn" @click="showChat">
      {{ $gettext('Чат поддержки') }}
    </UiButton>
  </div>
</template>

<script lang="ts">
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import UiButton from '../../common/ui-button.vue';

export default defineComponent({
  components: {
    UiButton,
  },
  setup() {
    const userStore = useUser();
    const router = useRouter();

    return { userStore, router };
  },
  computed: {
    needShow(): boolean {
      return this.userStore.experimentByName(experiments.exp_socrates);
    },
  },
  methods: {
    showChat(): void {
      this.router.push({ name: 'support' });
    },
  },
});
</script>
