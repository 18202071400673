<template>
  <p
    :class="{
      ['text']: true,
      [color]: color,
      [type]: type,
      [align]: align,
      ['bold']: isBold,
      [className]: className,
    }"
    :style="{ margin: margin }"
    :data-test="dataTest"
  >
    <slot />
  </p>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<
        | 'h1'
        | 'h2'
        | 'h3'
        | 'h4'
        | 'label1'
        | 'label2'
        | 'text1'
        | 'text2'
        | 'text3'
        | 'text4'
        | 'text5'
        | 'sub-title-italic'
        | 'subtitle'
      >,
      default: 'text1',
      validator: function (value: string) {
        return [
          'h1',
          'h2',
          'h3',
          'h4',
          'label1',
          'label2',
          'text1',
          'text2',
          'text3',
          'text4',
          'text5',
          'sub-title-italic',
          'subtitle',
        ].includes(value);
      },
    },
    align: {
      type: String,
      default: '',
      validator: function (value: string) {
        return ['center', 'left', ''].includes(value);
      },
    },
    color: {
      type: String,
      default: 'primary-text',
      validator: function (value: string) {
        return ['primary-text', 'secondary-text', 'title', 'secondary-title', 'sub-title', 'gray5', 'white'].includes(
          value,
        );
      },
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
      default: '',
    },
    margin: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
});
</script>

<style scoped>
.bold {
  font-weight: 500;
}

.primary-text {
  color: var(--text-primary-color);
}

.secondary-text {
  color: var(--text-secondary-color);
}

.title {
  color: var(--text-title-color);
}

.secondary-title {
  color: var(--text-secondary-title-color);
}

.sub-title {
  color: var(--text-sub-title-color);
}

.gray5 {
  color: #d2d0cc;
}

.white {
  color: #ffffff;
}

.h1 {
  font-size: 34px;
  font-weight: 500;
}

.h2 {
  font-size: 24px;
  font-weight: 500;
}

.h3 {
  font-size: 20px;
  font-weight: 500;
}

.h4 {
  font-size: 16px;
  font-weight: 500;
}

.label1 {
  font-size: 16px;
}

.label2 {
  font-size: 14px;
}

.text1 {
  font-size: 16px;
}

.text2 {
  font-size: 14px;
}

.text3 {
  font-size: 13px;
}

.text4 {
  font-size: 12px;
}

.text5 {
  font-size: 11px;
}

.sub-title-italic {
  font-style: italic;
  font-size: 13px;
  color: var(--text-sub-title-italic-color);
}

.center {
  text-align: center;
}

.left {
  text-align: start;
}
</style>

<style lang="scss">
.is-rtl-main {
  .text {
    direction: rtl;
  }
}

.center {
  text-align: center;
}

.left {
  text-align: start;
}
</style>
