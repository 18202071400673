<template>
  <Overlay>
    <div class="bg-main pointer-events-auto h-screen" data-test="suggest-details container">
      <Layout>
        <Bar
          :menu-config="[]"
          :icons="{ left: 'back', right: 'menu' }"
          :menu-id="'sideMenu'"
          data-test="suggest bar"
          is-fixed
          @close-click="$emit('cancel')"
        />
        <div class="flex flex-col">
          <div class="flex justify-center mb-3">
            <div class="flex" style="width: 174px; min-height: 174px">
              <ImageWrapper :img-src="product && product.images[0]" />
            </div>
          </div>
          <body-1 class="font-medium text-center mb-3">
            {{ product && product.title }}
          </body-1>
          <caption-1 class="text-center" color="day-textMinor" data-test="product-details count">{{
            productCount(stock.count)
          }}</caption-1>

          <!--        history-->
          <ProductHistoryItem
            v-for="historyItem in stock.history"
            :key="historyItem.time"
            :history-item="historyItem"
            @delete="deleteHistoryItem(historyItem)"
          />
        </div>

        <template #footer>
          <LayoutFooter class="flex-col">
            <Hint class="mb-2 flex-auto">{{ $gettext('Сколько всего товаров на палете?') }}</Hint>
            <Counter
              :product-id="productId"
              :value="model.product.count"
              icon-key="check"
              @confirm="value => saveCount({ value })"
            />
          </LayoutFooter>
        </template>
      </Layout>
    </div>
  </Overlay>
</template>

<script lang="ts">
import Counter from '@/fsd/entities/counter';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import Bar from '@/ui/common/bar/bar.vue';
import Hint from '@/ui/common/hint/hint.vue';
import ImageWrapper from '@/ui/common/image-wrapper.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Overlay from '@/ui/common/overlay/overlay.vue';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import ProductHistoryItem from '@/ui/control-check/product-history-item.vue';
import { HistoryItem, ProductStock } from '@/views/control_check/types';
import { PropType, defineComponent } from 'vue';

// показываем товары на палете

interface Data {
  model: {
    product: ProductStock;
  };
}

export default defineComponent({
  name: 'ProductDetails',
  components: {
    Overlay,
    ProductHistoryItem,
    Layout,
    Bar,
    Hint,
    ImageWrapper,
    Counter,
    LayoutFooter,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    stock: {
      type: Object as PropType<ProductStock>,
      required: true,
    },
  },
  emits: ['delete-history-item', 'input-count', 'cancel'],
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  data(): Data {
    return {
      model: {
        product: this.stock,
      },
    };
  },
  computed: {
    product(): Product | undefined {
      return this.productsStore.productById(this.productId);
    },
  },
  methods: {
    async saveCount({ value }: { value: number }): Promise<void> {
      const confirmed = await this.$notification.confirmCenter({
        title: this.$gettext('Вы уверены, что в палете %{count} товара?', {
          count: this.productCount(value),
        }),
      });
      if (!confirmed) return;

      this.$emit('input-count', value);
    },
    productCount(count: number): string {
      const data: any = { type_accounting: this.product?.type_accounting };
      if (this.product?.type_accounting === 'weight') {
        data.weight = count;
      } else {
        data.count = count;
      }
      return getCountToView(data);
    },
    deleteHistoryItem(item: HistoryItem): void {
      this.$emit('delete-history-item', item.time);
    },
  },
});
</script>
