<template>
  <div
    class="flex mx-1 px-4 py-2 rounded-2xl items-center cursor-pointer"
    :class="`bg-${bgColor}`"
    :data-test="dataTest"
  >
    <slot name="icon" />
    <Caption1 class="mx-2 font-medium" :color="textColor">
      <slot name="title" />
    </Caption1>
    <div class="flex-1" />
    <IconChevron v-if="$attrs.onClick" :color="textColor" />
  </div>
</template>

<script lang="ts">
import IconChevron from '@/temp/icons/icon-chevron.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OrderBadge',
  components: { IconChevron, Caption1 },
  props: {
    textColor: {
      type: String,
      required: true,
    },
    bgColor: {
      type: String,
      required: true,
    },
    dataTest: {
      type: String,
      default: 'order badge',
    },
  },
});
</script>
