import { $gettext, gettextWrapper } from '@/temp/plugins/gettext';

export const enum REPORT_ITEM_STATUS {
  ready = 'ready',
  error = 'error',
  waiting = 'waiting',
}

export const REPORT_ITEM_STATUS_TEXT: Record<REPORT_ITEM_STATUS, string> = gettextWrapper({
  [REPORT_ITEM_STATUS.ready]: $gettext('ОТСКАНИРОВАН'),
  [REPORT_ITEM_STATUS.error]: $gettext('НЕЛЬЗЯ ОТСКАНИРОВАТЬ'),
  [REPORT_ITEM_STATUS.waiting]: $gettext('ОТСКАНИРУЙТЕ ЧЕСТНЫЙ ЗНАК'),
});

export const ERROR_NO_SCAN_MARK = 'NO_SCAN';
