<template>
  <div class="flex flex-row gap-1">
    <Body2 class="font-medium">{{ countText }} </Body2>
    <Body2 v-if="suggest.allowableError" color="day-textMinor" class="font-medium">
      {{ $gettext('± %{g} г', { g: String(suggest.allowableError) }) }}
    </Body2>
  </div>
</template>
<script setup lang="ts">
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import { getCountTextFromAll } from '@/ui/common/suggest-card/formatters/count-formatter';
import Body2 from '@/ui/common/typo/body-2.vue';
import { computed, defineProps } from 'vue';

const props = defineProps<{ suggest: Suggest; collected: number }>();

const countText = computed(() => {
  const result_count = props.suggest.result_count || props.collected;
  const count = props.suggest.count || 0;
  return getCountTextFromAll(!!props.suggest.product?.isTrueWeight, result_count, count);
});
</script>
