import { logger } from '@/temp/plugins/logs';

export class BarcodeHandler {
  public mode = 'abstract';

  constructor() {}

  async init() {
    // почему то здесь нет логгера
    // eslint-disable-next-line no-console
    logger ? logger(`Запуск режима ${this.mode}`) : console.log(`Запуск режима ${this.mode}`);
  }

  async destroy() {
    logger(`Выключение режима ${this.mode}`);
  }

  async scan(): Promise<any> {}
}
