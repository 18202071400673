<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.16667 3V9.66667H2.5V11.3333H9.16667V18H10.8333V11.3333H17.5V9.66667H10.8333V3H9.16667Z"
      fill="#8A8784"
      fill-opacity="0.4"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
