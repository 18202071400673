<template>
  <div class="flex py-3 px-4 shadow-footer rounded-t-3xl">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
