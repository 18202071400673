<template>
  <Layout>
    <InputDateAndTime
      :name="`departure-time-${orderId}`"
      :title="$gettext('Когда уехала машина?')"
      :text="$gettext('Пожалуйста, укажите точные дату и время')"
      :max-date="new Date()"
      :min-date="arrivalDate"
      :btn-text="$gettext('Завершить')"
      @done="date => $emit('done', date)"
  /></Layout>
</template>
<script lang="ts">
import InputDateAndTime from '@/fsd/features/input/date-and-time/input-date-and-time.vue';
import Layout from '@/ui/common/layout.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'DepartureTime',
  components: {
    InputDateAndTime,
    Layout,
  },
  props: {
    arrivalDate: {
      type: Object as PropType<Date>,
      default: new Date(),
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  emits: ['done'],
});
</script>
