import { $gettext } from '@/temp/plugins/gettext';
import { ISuggestError } from '@/temp/plugins/notification';
import { AxiosError } from 'axios';

interface Payload {
  response?: AxiosError<any>['response'];
  onClose: () => void;
  onRepeat: () => void;
  operation: string;
  products: ISuggestError['products'];
}

export const createPayloadForSuggestError = ({ response, products, onClose, onRepeat, operation }: Payload) => {
  const majorErrorConfig: ISuggestError = {
    title: $gettext('Не удалось отправить информацию на сервер'),
    reason: '',
    body: '',
    onClose,
    onRepeat,
    operation,
    products,
  };
  if (!response) {
    majorErrorConfig.reason = 'Network error';
    majorErrorConfig.body = $gettext('Сервис недоступен, попробуйте завершить заказ позже');
  } else {
    majorErrorConfig.reason = response?.status?.toString();
    majorErrorConfig.body = response?.data?.message;
  }
  return majorErrorConfig;
};
