<template>
  <div class="h-14 flex px-2 rounded-2xl items-center" :class="bgColor">
    <div v-if="$slots.image">
      <slot name="image" />
    </div>
    <div class="flex flex-col">
      <Body2 v-if="$slots.text" data-test="hint text" class="font-medium whitespace-pre-wrap" :class="textColor">
        <slot name="text" />
      </Body2>
      <Caption1 v-if="$slots['sub-text']" data-test="hint sub-text" class="whitespace-pre-wrap" :class="textColor">
        <slot name="sub-text" />
      </Caption1>
    </div>
  </div>
</template>
<script lang="ts">
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { Body2, Caption1 },
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
});
</script>
