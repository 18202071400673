<template>
  <div v-if="available" class="flex flex-col gap-4" data-test="product info card">
    <Body1 data-test="product info card">
      {{ $gettext('Остаток по полкам') }}
    </Body1>
    <ProductAvailableItem
      v-for="(a, idx) in available"
      :key="a.shelf_id"
      :class="{ 'border-b': idx !== available.length - 1, 'pb-4': idx !== available.length - 1 }"
      :available="a"
    />
  </div>
</template>

<script lang="ts" setup>
import ProductAvailableItem from '@/fsd/widgets/productCard/ProductAvailableItem.vue';
import Product from '@/models/Product';
import { useProducts } from '@/store/modules/products';
import { computed, toRefs } from 'vue';

const props = defineProps<{ product_id: Product['product_id'] }>();
const { product_id } = toRefs(props);
const productsStore = useProducts();

const available = computed(() => {
  return productsStore.availableByProductId(product_id.value);
});
</script>
