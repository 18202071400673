import { ComputedRef, watch } from 'vue';

// если true - мы дождались и можно отписаться, иначе ждем дальше
export type CB<T> = (data: T) => boolean;
export const subscribe = <T>(data: ComputedRef<T>, cb: CB<T>) => {
  let needWatch: boolean = true;
  let unWatch = () => {
    needWatch = false;
  };

  const handler: CB<T> = val => {
    const result = cb(val);
    if (result) unWatch();
    return result;
  };

  handler(data.value);

  if (needWatch) {
    unWatch = watch(data, handler, { deep: true });
  }
};

// ждет пока cb не вернет true, если cb выбросил исключение, то ловим его и выбрасываем выше
export const waitDataChange = <T>(data: ComputedRef<T>, cb: CB<T>): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const handler: CB<T> = val => {
      try {
        const result = cb(val);
        if (result) resolve();
        return result;
      } catch (e) {
        reject(e);
        return true;
      }
    };
    subscribe(data, handler);
  });
};
