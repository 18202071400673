<template>
  <div>
    <div class="main-container">
      <Typography class="total-defects" type="h2" align="center" margin="16px 0 2px">
        {{ getCountToView({ count: totalCount, type_accounting: typeAccounting }) }}
      </Typography>
      <Typography class="defects-text" type="text3" color="primary-text" align="center" margin="2px 0 16px">
        {{ title }}
      </Typography>
      <div v-for="(config, index) in counterConfigs" :key="index">
        <ProductCard
          :defect="config.image"
          :title="config.title"
          :valid="checkValid(config, index)"
          :initial-value="uiState.counts[index] || config.initialValue"
          :type-accounting="typeAccounting"
          @change="value => onChangeCount(index, value)"
          @image-click="productImage.asyncShow(index)"
          @counter-click="getCountFromCalc(index)"
        />
      </div>
    </div>
    <ProductImage
      v-if="productImage.visible.value"
      :defect="counterConfigs[productImage.props.value].image"
      :title="counterConfigs[productImage.props.value].title"
      :description="counterConfigs[productImage.props.value].description || ''"
      @backdrop-click="productImage.hide"
    />
    <Calc
      v-if="calc.visible.value"
      :is-true-weight="true"
      :max="counterConfigs[calc.props.value].maxCount || maxCount - totalCount + uiState.counts[calc.props.value]"
      @close="calc.hide"
    />
  </div>
</template>

<script lang="ts">
import { useComponent } from '@/hooks/useComponent';
import { TypeAccountingEnum } from '@/models/Product';
import Calc from '@/ui/common/keyboard/calc.vue';
import ProductCard from '@/ui/common/multi-product-counter/product-card.vue';
import ProductImage from '@/ui/common/multi-product-counter/productImage.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';
import { getCountToView } from '../suggest-card/formatters/count-formatter';

interface Data {
  uiState: {
    counts: number[];
  };
}

export interface CounterConfig {
  image: string;
  initialValue: number;
  title: string;
  description?: string;
  maxCount?: number;
}

export default defineComponent({
  name: 'MultiProductCounter',
  components: {
    Typography,
    ProductCard,
    ProductImage,
    Calc,
  },
  props: {
    counterConfigs: {
      type: Array as PropType<CounterConfig[]>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    typeAccounting: {
      type: String as PropType<TypeAccountingEnum>,
      default: TypeAccountingEnum.unit,
    },
    maxCount: {
      type: Number,
      default: undefined,
    },
  },
  emits: ['change'],
  setup() {
    const calc = useComponent<number>();
    const productImage = useComponent<number>();

    return { calc, productImage };
  },
  data(): Data {
    return {
      uiState: {
        counts: [],
      },
    };
  },
  computed: {
    totalCount(): number {
      return this.uiState.counts.reduce((acc, value) => {
        return acc + value;
      }, 0);
    },
    checkValid(): (config: CounterConfig, index: number) => boolean {
      return (config, index) => {
        const countFromModel = this.uiState.counts[index];
        return (
          (!config.maxCount || countFromModel < config.maxCount) && (!this.maxCount || this.totalCount < this.maxCount)
        );
      };
    },
  },
  mounted() {
    this.uiState.counts = this.counterConfigs.map(c => c.initialValue || 0);
  },
  methods: {
    getCountToView,
    async getCountFromCalc(index: number): Promise<void> {
      if (this.typeAccounting !== TypeAccountingEnum.true_weight) return;
      const count = await this.calc.asyncShow(index);
      // uiState.counts - массив, где элемент - это число(кол-во) отдельного дефекта
      // т.е. общая сумма массива - общее число дефектов нашего продукта
      // калькулятор возвращает нецелое число в кг, поэтому умножаем на 1000, чтобы получить целое в граммах
      this.uiState.counts[index] = (count || 0) * 1000;
      this.$emit('change', this.uiState.counts);
    },
    onChangeCount(index, value): void {
      this.uiState.counts[index] = value;
      this.$emit('change', this.uiState.counts);
    },
  },
});
</script>

<style lang="scss" scoped>
.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
