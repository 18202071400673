<template>
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 .001v1.75c-.904.003-1.584.016-2.142.061-.725.06-1.087.166-1.334.292a3.25 3.25 0 0 0-1.42 1.42c-.126.247-.233.609-.292 1.334-.045.558-.058 1.238-.06 2.142H0c.008-2.15.068-3.336.544-4.27A5 5 0 0 1 2.73.545C3.664.069 4.85.009 7 0Zm6 1.75V.001c2.15.008 3.336.068 4.27.544a5 5 0 0 1 2.185 2.185c.476.934.536 2.12.544 4.27h-1.75c-.004-.904-.016-1.584-.061-2.142-.06-.725-.166-1.087-.292-1.334a3.25 3.25 0 0 0-1.42-1.42c-.248-.126-.61-.233-1.335-.292-.557-.045-1.237-.058-2.141-.06ZM4.858 18.188c.558.045 1.238.057 2.142.06v1.75c-2.15-.007-3.336-.067-4.27-.543A5 5 0 0 1 .545 17.27C.069 16.336.009 15.15 0 13h1.75c.003.904.016 1.584.061 2.141.06.726.166 1.087.292 1.334a3.25 3.25 0 0 0 1.42 1.42c.247.127.609.233 1.334.293Zm12.412 1.267c-.934.476-2.12.536-4.27.544v-1.75c.904-.004 1.584-.016 2.141-.061.726-.06 1.087-.166 1.334-.292a3.25 3.25 0 0 0 1.42-1.42c.127-.248.233-.61.293-1.335.045-.557.057-1.237.06-2.141h1.75c-.007 2.15-.067 3.336-.543 4.27a5 5 0 0 1-2.185 2.185Zm-7.243-4.706a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm3-4.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
