import { api } from '@/fsd/data/api/api.service';
import Shelf from '@/models/Shelf';
import ShelfByBarcode from '@/models/ShelfByBarcode';
import shelfQueue from '@/services/queue/shelf-queue';
import { ShelvesResponse } from '@/services/response';
import { logger } from '@/temp/plugins/logs';
import { AvailableProduct } from '@/types/product';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export const useShelves = defineStore('shelves', {
  state: () => ({
    shelves: new Map<Shelf['shelf_id'], Shelf>(),
    availableByKey: {} as { [key: string]: AvailableProduct[] },
  }),
  getters: {
    shelfById:
      state =>
      (id?: string): Shelf | undefined => {
        if (!id) return undefined;
        return state.shelves.get(id) as Shelf;
      },
    shelfByBarcode:
      state =>
      (barcode: string): Shelf | undefined => {
        for (const [_, item] of state.shelves) {
          if (item.barcode === barcode) {
            return item as Shelf;
          }
        }
        return undefined;
      },
    availableById: state => (id: string) => {
      return state.availableByKey[id] as AvailableProduct[];
    },
  },
  actions: {
    set(payload: Shelf[]) {
      payload.forEach(shelf => {
        this.shelves.set(shelf.shelf_id, shelf);
      });
    },
    addAvailable({ found, shelf_id }) {
      if (Array.isArray(shelf_id)) {
        shelf_id.forEach(item => {
          this.availableByKey[item] = found.filter(productAvailable => productAvailable.shelf_id === item);
        });
      } else {
        this.availableByKey[shelf_id] = found;
      }
    },
    async getShelvesByIds(ids: string[]): Promise<Shelf[]> {
      ids = ids.filter(id => !!id);
      ids = [...new Set(ids)];
      if (ids.length === 0) return [];
      const findShelves: Shelf[] = [];
      const shelvesToFetchIds: string[] = [];
      ids.forEach(id => {
        const shelf = this.shelfById(id);
        if (shelf) findShelves.push(shelf);
        else shelvesToFetchIds.push(id);
      });
      if (shelvesToFetchIds.length > 0) {
        try {
          const shelves = (await shelfQueue.loadMany(shelvesToFetchIds)).filter(Shelf.isShelf);
          if (shelves) {
            findShelves.push(...shelves);
          }
        } catch (e) {
          logger.error(e, { method: 'addToQueue', type: 'api', source: 'shelves.ts' });
        }
      }
      return findShelves;
    },
    async getShelfById(id: string): Promise<Shelf | undefined> {
      const shelf = this.shelfById(id);
      if (shelf) return shelf;
      try {
        return await shelfQueue.load(id);
      } catch (e) {
        logger.error(e, { method: 'addToQueue', type: 'api', source: 'shelves.ts' });
        return undefined;
      }
    },
    async getShelfByBarcode(barcode: string, needAvailable: boolean = false): Promise<Shelf | undefined> {
      let shelf = this.shelfByBarcode(barcode);
      if (!shelf || needAvailable) {
        const { data } = await api.barcode({ barcode });
        const shelfByBarcode = data.found[0];
        if (!shelfByBarcode || !ShelfByBarcode.isShelfByBarcode(shelfByBarcode)) throw new Error('wrong barcode');
        if (!shelf) await shelfQueue.load(shelfByBarcode.shelf_id);
        shelf = this.shelfById(shelfByBarcode.shelf_id)!;
      }
      return shelf;
    },
    async fetchAvailable(shelf_id: string | string[]) {
      try {
        const { data } = await api.stock.available({ shelf_id });
        this.addAvailable({ found: data.found, shelf_id });
        return data.found;
      } catch (e) {
        console.error(e);
      }
    },
    async loadShelves({
      ids,
      config,
    }: {
      ids: string[];
      config?: AxiosRequestConfig;
    }): Promise<AxiosResponse<ShelvesResponse>> {
      const response = await api.shelves({ ids }, config);
      this.set(response.data.shelves);
      return response;
    },
    clear() {
      this.shelves.clear();
      this.availableByKey = {};
    },
  },
});
