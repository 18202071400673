<template>
  <Row v-if="needShow" :label="label" :value="value" data-test="suggest-details product-on-shelf row" />
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useProducts } from '@/store/modules/products';
import { AvailableProduct } from '@/types/product';
import { getCountToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import Row from '@/ui/common/suggest-details/row.vue';
import { Model } from '@/ui/common/suggest-details/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {
    Row,
  },
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      required: true,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      default: undefined,
    },
  },
  setup() {
    const productsStore = useProducts();

    return { productsStore };
  },
  computed: {
    isControl(): boolean {
      return ['writeoff_prepare_day', 'check_valid_regular', 'check_valid_short'].includes(this.order.type);
    },
    available(): AvailableProduct[] {
      if (this.suggest) {
        return this.productsStore.availableByProductId(this.suggest.product_id) || [];
      }
      return [];
    },
    needShow(): boolean {
      if (['stowage'].includes(this.order.type) && this.suggest.vars.mode === 'product') {
        if (this.suggest.type === 'box2shelf') {
          return true;
        }
      }
      if (['sale_stowage'].includes(this.order.type) && this.suggest.vars.mode === 'product') {
        if (this.suggest.type === 'box2shelf' && this.order.vars.stage === 'stowage') {
          return true;
        }
      }
      return this.isControl && this.suggest.type === 'shelf2box';
    },
    label(): string {
      return this.$gettext('На полке');
    },
    value(): string {
      let productOnShelf;
      if (this.model.shelf) {
        productOnShelf = this.available.find(({ shelf_id }) => shelf_id === this.model.shelf!.shelf_id);
      } else if (this.available) {
        productOnShelf = this.available.find(({ shelf_id }) => shelf_id === this.suggest!.shelf_id);
      }
      if (productOnShelf) {
        let count = productOnShelf.count;
        if (this.isControl) {
          count = count - this.model.count!;
        }
        return getCountToView({ count, type_accounting: this.product?.type_accounting });
      }
      return '—';
    },
  },
  async mounted(): Promise<void> {
    if (this.needShow) {
      await this.productsStore.fetchAvailable(this.product?.product_id);
    }
  },
});
</script>
