<template>
  <OverlayScan :text="$gettext('Отсканируй продукт для перефасовки')" />
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import { useProducts } from '@/store/modules/products';
import { useShelves } from '@/store/modules/shelves';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { useLoader } from '@/ui/common/loader/useLoader';
import OverlayScan from '@/ui/common/overlay-scan/overlay-scan.vue';
import { computed, defineEmits, defineProps } from 'vue';

import { Alerts } from '@/fsd/shared/tools/alertNotification';
import uuidv1 from 'uuid/v1';
const { showLoader } = useLoader();
const shelvesStore = useShelves();
const productsStore = useProducts();

interface CreateRepackingProps {
  shelfId: string;
}

const props = defineProps<CreateRepackingProps>();

const emits = defineEmits<{ (e: 'close'): void }>();

const shelf = computed<Shelf>(() => shelvesStore.shelfById(props.shelfId)!);

const createRepacking = async (product: Product): Promise<boolean> => {
  const { closeLoader } = showLoader();
  try {
    await api.order.repacking({
      external_id: uuidv1(),
      repacking: { product_id: product.product_id, shelf_id: props.shelfId },
    });
    Alerts.success($gettext('Перефасовка успешно создана'));
    emits('close');
    return false;
  } catch (e) {
    console.error(e);
    return true;
  } finally {
    closeLoader();
  }
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    const available = await shelvesStore.fetchAvailable(props.shelfId);
    closeLoader();
    const productAvailable = available?.find(a => a.product_id === product.product_id);
    if (!productAvailable) {
      Alerts.error($gettext('Данный продукт отсутствует на полке'));
      return true;
    }
    if (productAvailable.count <= 1) {
      Alerts.error($gettext('На полке находится недостаточно продуктов для создания перефасовки'));
      return true;
    }
    // продукт есть на полке и его можно перефасовать. можно переспросить у пользователя и создать перефасовку
    const confirmed = await Notifications.confirmCenter({
      title: $gettext('Перефасовка'),
      text: $gettext('Вы хотите создать перефасовку товара %{product} на полке %{shelf}?', {
        product: product.title,
        shelf: shelf.value.title,
      }),
    });
    if (!confirmed) {
      return true;
    }

    return await createRepacking(product);
  } catch (e) {
    closeLoader();
    Alerts.error($gettext('Не найден штрихкод'));
    return true;
  }
});
</script>
