import IconBasketReturn from '@/temp/icons/icon-basket-return.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default () => {
  return Notifications.modal({
    title: $gettext('Отсканирована отсутствующая в заказе марка'),
    text: $gettext('Нужно отсканировать ту же марку, что и при сборке'),
    iconTop: {
      icon: IconBasketReturn,
      position: 'center',
    },
  });
};
