import IconBasketReturn from '@/temp/icons/icon-basket-return.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';

export default () => {
  return Notifications.modal({
    title: $gettext('Этот товар уже был собран в другом задании'),
    text: $gettext(
      'Положите его обратно на полку и возьмите следующий. У каждого продукта марка уникальна. Нужно сканировать каждый товар отдельно',
    ),
    iconTop: {
      icon: IconBasketReturn,
      position: 'center',
    },
  });
};
