<template>
  <Overlay>
    <div class="content-container">
      <div class="history-container" :class="{ openHistoryList: showAllHistory, closeHistoryList: !showAllHistory }">
        <div v-for="(item, i) in history" :key="i" class="history" @click.self="onEditHistory(i)">
          {{ i + 1 }}) {{ item }}
          <img src="./img/trash-solid.svg" alt="" width="16" @click="deleteItem(i)" />
        </div>
        <div v-if="total && total !== '0'" class="total-container" @click="toggleHistory(undefined)">
          <div class="total-count">{{ totalCount }}</div>
          <UiButton class="btn-toggle" is-icon data-test="toggle keyboard btn">
            <img v-if="!showAllHistory" src="./img/angle-double-down-solid.svg" alt="" />
            <img v-else src="./img/angle-double-up-solid.svg" alt="" />
          </UiButton>
        </div>
      </div>
      <div :class="{ openKeyboard: !showAllHistory, closeKeyboard: showAllHistory }">
        <div class="controls-container">
          <div class="control">
            {{ count }}
          </div>
          <UiButton
            is-icon
            class="ml-2"
            :is-disabled="!!$attrs.disabled || !valid"
            data-test="keyboard send btn"
            @click="onInputClick"
          >
            <img class="icon-check" src="@/assets/img/check.svg" alt="" />
          </UiButton>
        </div>
        <div class="keyboard" data-test="keyboard open" @click="onKeyClick">
          <div class="keyboard-row">
            <div class="keyboard-cell" value="1" data-test="keyboard key 1">1</div>
            <div class="keyboard-cell" value="2" data-test="keyboard key 2">2</div>
            <div class="keyboard-cell" value="3" data-test="keyboard key 3">3</div>
            <div class="keyboard-cell" value="*" data-test="keyboard key *">*</div>
          </div>
          <div class="keyboard-row">
            <div class="keyboard-cell" value="4" data-test="keyboard key 4">4</div>
            <div class="keyboard-cell" value="5" data-test="keyboard key 5">5</div>
            <div class="keyboard-cell" value="6" data-test="keyboard key 6">6</div>
            <div class="keyboard-cell" value="+" data-test="keyboard key +">+</div>
          </div>
          <div class="keyboard-row">
            <div class="keyboard-cell" value="7" data-test="keyboard key 7">7</div>
            <div class="keyboard-cell" value="8" data-test="keyboard key 8">8</div>
            <div class="keyboard-cell" value="9" data-test="keyboard key 9">9</div>
            <div class="keyboard-cell" value="=" data-test="keyboard key =">=</div>
          </div>
          <div class="keyboard-row">
            <div />
            <div class="keyboard-cell" value="0" data-test="keyboard key 0">0</div>
            <div class="keyboard-cell" value="D" data-test="keyboard key D">
              <img src="./img/delete.svg" alt="" />
            </div>
            <div class="keyboard-cell" value="-" data-test="keyboard key -">-</div>
          </div>
        </div>
      </div>
    </div>
  </Overlay>
</template>
<script lang="ts">
import Overlay from '@/ui/common/overlay/overlay.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

interface Data {
  count: string;
  hasError: boolean;
  showAllHistory: boolean;
  history: string[];
  editedIndex?: number;
}

export default defineComponent({
  components: { UiButton, Overlay },
  props: {
    mode: {
      type: String,
      default: 'simple',
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  emits: ['close'],
  data(): Data {
    return {
      count: '',
      hasError: false,
      history: [],
      showAllHistory: false,
      editedIndex: undefined,
    };
  },
  computed: {
    valid(): boolean {
      const inputCount = this.count ? +this.calc(this.count.toString()) : 0;
      const total = +this.total + inputCount;
      return this.min <= total && this.max >= total;
    },
    totalCount(): string {
      return this.$gettext('Итого: %{total}', { total: this.total });
    },
    total(): string {
      const resultsFromHistory = this.history.map(h => this.calc(h));
      const result = resultsFromHistory.reduce((acc, value) => {
        return (+acc + +value).toString();
      }, '0');
      return result;
    },
  },
  methods: {
    onKeyClick(mouseEvent) {
      let target = mouseEvent.target;
      while (target.className !== 'keyboard-cell') {
        target = target.parentNode;
      }

      const key = target.attributes.value.value;
      if (key === 'D' && !this.count) {
        return;
      }
      if (key === 'D' && this.count) {
        this.count = this.count.slice(0, -1);
        return;
      }
      if (key === '=' && !this.count) {
        return;
      }
      if (key === '=' && this.count) {
        if (this.editedIndex !== undefined) {
          this.history[this.editedIndex] = this.count;
          this.editedIndex = undefined;
        } else {
          this.history.push(this.count);
        }
        this.count = '';
        this.$nextTick(() => {
          const history = document.getElementsByClassName('history-container')[0];
          if (history) history.scrollTop = 9999;
        });
        return;
      }
      const newValue = this.count + key;
      this.count = newValue;
    },
    calc(value): string {
      const actions = {
        multiplication: {
          value: '*',
          label: 'multiplication',
          func: (a, b) => parseFloat(a) * parseFloat(b),
        },
        division: {
          value: '/',
          label: 'division',
          func: (a, b) => a / b,
        },
        addition: {
          value: '+',
          label: 'addintion',
          func: (a, b) => parseFloat(a) + parseFloat(b),
        },
        subtraction: {
          value: '-',
          label: 'subtraction',
          func: (a, b) => parseFloat(a) - parseFloat(b),
        },
      };

      function calcExpr(str) {
        let res;
        Object.keys(actions).map(function (type) {
          res = parseExpr(str, actions[type]);
          if (res) {
            str = str.replace(res.str, res.value.toString());
            str = calcExpr(str);
          }
        });
        return str;
      }

      function parseExpr(str, action) {
        const reg = new RegExp(`((\\d+)\\s*\\${action.value}\\s*(\\d+))`);
        const out = str.match(reg);
        if (!out) return false;
        return {
          str: out[1],
          value: action.func(out[2], out[3]),
        };
      }
      return calcExpr(value);
    },
    onInputClick(): void {
      if (this.count && this.valid) {
        this.history.push(this.count);
      }
      this.$emit('close', this.total);
    },
    toggleHistory(visible): void {
      if (visible === undefined) {
        this.showAllHistory = !this.showAllHistory;
      } else {
        this.showAllHistory = visible;
      }
      this.$nextTick(() => {
        const history = document.getElementsByClassName('history-container')[0];
        if (history) history.scrollTop = 9999;
      });
    },
    onEditHistory(index: number): void {
      this.editedIndex = index;
      this.count = this.history[index];
      this.toggleHistory(false);
    },
    deleteItem(index: number): void {
      this.history.splice(index, 1);
      if (this.history.length === 0) {
        this.toggleHistory(false);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.content-container {
  background: #fff;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  border-radius: 16px;
  margin: auto 16px;
  pointer-events: auto;
  overflow: hidden;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.controls-container {
  display: flex;
  align-items: center;
  padding: 0 16px 12px;
  background-color: #fff;
}

.keyboard {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background-color: #faf9f7;
}
.keyboard-row {
  display: flex;
}
.keyboard-cell {
  flex: 1 1 0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #21201f;
}

.control {
  padding-left: 16px;
  display: flex;
  border-radius: 16px;
  background: #faf9f7;
  align-items: center;
  flex-grow: 1;
  font-size: 16px;
  color: #21201f;
  height: 56px;
}

.history {
  padding: 16px;
  margin: 0 16px 12px;
  background: #faf9f7;
  border-radius: 16px;
  color: #21201f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-container {
  flex: 1 1 0;
  overflow: scroll;
  transition: transform 200ms ease 0ms;
  display: flex;
  flex-direction: column;
}

@keyframes show {
  0% {
    max-height: 45%;
  }
  100% {
    max-height: 100%;
  }
}

.openHistoryList {
  animation: show 0.3s linear;
  max-height: 100%;
  height: 100%;
  margin-top: 8px;
}

.closeHistoryList {
  justify-content: flex-end;
}

.btn-toggle {
  background: #faf9f7 !important;
  border-color: #faf9f7 !important;
}

.filler {
  flex: 1 1 0;
}

.total-container {
  display: flex;
  margin: 0 16px 12px;
}

.total-count {
  flex: 1 1 0;
  padding: 16px;
  background: #faf9f7;
  border-radius: 16px;
  color: #21201f;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.openKeyboard {
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
}
.closeKeyboard {
  transition: transform 200ms ease 0ms;
  transform: translateY(350px);
  max-height: 0px;
}
</style>
