import { ScannerService } from '@/services/scanner/scanner.service';
import { defineComponent } from 'vue';

interface Data {
  uiStateNeedBarcodeRequest: boolean;
}

export default defineComponent({
  data(): Data {
    return {
      uiStateNeedBarcodeRequest: true,
    };
  },
  watch: {
    uiStateNeedBarcodeRequest: {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.autoRequestBarcode();
        }
        if (!newValue && oldValue) {
          ScannerService.clearPromise(this.$options.name + this._uuid);
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    this.uiStateNeedBarcodeRequest = false;
    ScannerService.clearPromise(this.$options.name + this._uuid);
  },
  methods: {
    async autoRequestBarcode(): Promise<void> {
      if (!this.uiStateNeedBarcodeRequest) {
        return;
      }
      let needReRequest: boolean = true;
      try {
        needReRequest = await this.requestBarcode();
      } catch (error: any) {
        needReRequest = this.checkError(error);
      }

      // НЕ МЕНЯТЬ НИ В КОЕМ СЛУЧАЕ
      if (needReRequest === false) {
        this.uiStateNeedBarcodeRequest = false;
      } else {
        this.autoRequestBarcode();
      }
    },
    // метод должен быть реализован в компонентах, что используют этот миксин.
    // должен вернуть boolean, где true - озгачает,что нужен еще один запрос баркода. false - что ббольше не нужен
    async requestBarcode(): Promise<boolean> {
      return Promise.resolve(true);
    },
    checkError(error: string): boolean {
      return error !== 'Scanner rejected';
    },
  },
});
