import Suggest from '@/models/Suggest';
import { SuggestsByGroup } from '../types';

export const enum SUGGEST_GROUP_ID {
  OTHERS = 'others',
}

const SUGGEST_WITHOUT_GROUP: Omit<SuggestsByGroup, 'suggests'> = {
  group_id: SUGGEST_GROUP_ID.OTHERS,
  name: '',
};

export const getSuggestsByGroup = (suggests: Suggest[]): SuggestsByGroup[] => {
  const byGroup: Map<string, SuggestsByGroup> = new Map();

  suggests.forEach(suggest => {
    let group_id = SUGGEST_WITHOUT_GROUP.group_id;
    let name = SUGGEST_WITHOUT_GROUP.name;

    if (suggest.vars.product_group && suggest.vars.product_group.group_id && suggest.vars.product_group.name) {
      group_id = suggest.vars.product_group.group_id;
      name = suggest.vars.product_group.name;
    }

    if (!byGroup.has(group_id)) {
      byGroup.set(group_id, {
        group_id,
        name,
        suggests: [suggest],
      });
    } else {
      byGroup.get(group_id)!.suggests.push(suggest);
    }
  });

  return Array.from(byGroup.values());
};
