<template>
  <Layout>
    <template #header><OrderRetailHeaderClient :order="order" @close="$emit('back')" /></template>
    <template #default>
      <div class="bg-element flex flex-col gap-2">
        <div class="bg-main p-4 rounded-b-3xl flex flex-col gap-2">
          <Title2 class="font-bold">{{ $gettext('Позвоните клиенту') }}</Title2>
          <Body1>{{ $gettext('Чтобы согласовать изменения заказа.') }}</Body1>
        </div>
        <div class="bg-main p-4 rounded-3xl flex flex-col gap-4">
          <ClientPhone :order="order" />
        </div>
        <div class="bg-main rounded-t-3xl h-10" />
      </div>
    </template>
    <template #footer><OrderRetailFooterClient @no-replied="onNoReplied" @replied="$emit('toConfirm')" /></template>
  </Layout>
</template>

<script setup lang="ts">
import { ClientPhone } from '@/fsd/features/client';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Layout from '@/ui/common/layout.vue';
import Body1 from '@/ui/common/typo/body-1.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import { defineEmits, defineProps } from 'vue';
import OrderRetailFooterClient from './order-retail-footer-client.vue';
import OrderRetailHeaderClient from './order-retail-header-client.vue';

interface OrderRetailFinishProps {
  order: OrderOrderRetail;
}

defineProps<OrderRetailFinishProps>();

const emits = defineEmits<{
  (e: 'back'): void;
  (e: 'toConfirm'): void;
  (e: 'toPackage'): void;
}>();

const onNoReplied = async () => {
  const isNoReplied = await Notifications.confirmCenter({
    title: $gettext('Клиент не ответил?'),
    decline: $gettext('Отмена'),
    ok: $gettext('Не ответил'),
  });

  if (isNoReplied) emits('toPackage');
};
</script>
