<template>
  <svg width="190" height="171" viewBox="0 0 190 171" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 9.42578H96.2452V11.587H1"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 44.4355H96.2452V46.5968H1"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 79.4395H96.2452V81.6007H1"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M92.1843 116.572H1"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 114.408H91.1364"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.2441 116.572V140.205"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.2441 81.5996V93.1151"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.2441 46.5957V58.0458"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.2441 11.5879V28.3665"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.3277 44.2581V23.0312H31.4453V44.2581"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.9395 23.2988V44.3295"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.5449 33.8184V44.4318"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.50977 44.4318V33.8184H28.8075V44.2574"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.7721 33.7594L20.24 21.3066H8.19141L12.7251 33.7594"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5041 29.9136C12.6413 28.3593 12.0092 26.0489 13.0907 24.7511C14.1721 23.4551 16.5597 23.663 18.4225 25.2172C20.2853 26.7715 20.9174 29.082 19.8359 30.3798C18.7528 31.6758 16.3652 31.4679 14.5041 29.9136Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.77051 22.8887H6.3125L10.2913 33.819"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.88379 24.4707H4.42578L7.82943 33.8182"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.00098 26.0527H2.54297L5.36984 33.8191"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.3052 32.1227L45.9395 26.5176"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.3052 36.2243L45.9395 30.6191"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8111 32.1227L31.4453 26.5176"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8111 36.2243L31.4453 30.6191"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.6625 103.518L69.3379 105.857H85.0534L83.7288 103.518H70.6625Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M83.5381 105.857L82.5137 113.988"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.8535 105.857L71.8763 113.988"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.0375 103.518L53.7129 105.857H69.4284L68.1038 103.518H55.0375Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.917 105.857L66.8926 113.988"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.2871 105.857L56.3116 113.988"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.6625 93.0469L69.3379 95.3859H85.0534L83.7288 93.0469H70.6625Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M83.5381 95.3867L82.5137 103.517"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.8535 95.3867L71.8763 103.517"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.0375 93.0469L53.7129 95.3859H69.4284L68.1038 93.0469H55.0375Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.917 95.3867L66.8926 103.517"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.2871 95.3867L56.3116 103.517"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M83.1719 97.7188H77.7361C77.1945 97.7188 76.6764 97.935 76.2942 98.3173C75.9119 98.6996 75.6973 99.2194 75.6973 99.761C75.6973 100.303 75.9119 100.821 76.2942 101.203C76.6764 101.585 77.1962 101.8 77.7361 101.8H82.7192"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.4021 97.7188H61.968C61.4264 97.7188 60.9066 97.9334 60.5244 98.3173C60.1421 98.6996 59.9258 99.2194 59.9258 99.761C59.9258 100.029 59.9778 100.294 60.0817 100.542C60.184 100.79 60.3349 101.015 60.5244 101.205C60.7138 101.394 60.9385 101.545 61.1866 101.647C61.4348 101.749 61.6997 101.803 61.968 101.801H66.9494"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M71.4785 108.279H76.9143C77.1826 108.279 77.4475 108.331 77.6956 108.434C77.9438 108.536 78.1685 108.687 78.3579 108.876C78.5474 109.066 78.6983 109.29 78.8006 109.538C78.9028 109.787 78.9565 110.052 78.9548 110.32C78.9548 110.588 78.9028 110.853 78.8006 111.101C78.6983 111.349 78.5474 111.574 78.3579 111.763C78.1685 111.953 77.9438 112.104 77.6956 112.206C77.4475 112.308 77.1826 112.362 76.9143 112.362H71.9312"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M23.9815 100.549L22.7676 102.17H37.1719L35.958 100.549H23.9815Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.7885 102.172L34.8496 113.976"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.1582 102.172L25.0955 113.976"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.2803 100.549L51.4942 102.17H37.0898L38.3038 100.549H50.2803Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.4805 102.172L39.4194 113.976"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.0542 102.172L49.1152 113.976"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.9131 87.1367L46.127 88.7581H31.7227L32.9366 87.1367H44.9131Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.1133 88.7578L34.0522 100.562"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.6889 88.7578L43.75 100.562"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.404 66.834L32.4023 77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.1608 79.4141C25.926 78.9866 25.8053 78.4903 25.8087 77.9823V77.1875H32.6428V77.9823C32.6478 78.4886 32.5254 78.9866 32.2907 79.4141"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.0469 66.834L26.0485 77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.3301 56.1152H31.121V57.9445H27.3301V56.1152Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.8908 58.1152V58.417C27.8908 60.0183 27.1095 60.7074 26.3332 63.1084C25.8017 64.7583 25.8235 66.5422 25.8788 66.834H32.5637C32.6191 66.5406 32.6409 64.7583 32.1093 63.1084C31.3364 60.704 30.5517 60.0183 30.5517 58.417V58.1152"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24 66.834V77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.7565 79.4141C17.5217 78.9866 17.401 78.4903 17.4044 77.9823V77.1875H24.2385V77.9823C24.2435 78.4886 24.1211 78.9866 23.8864 79.4141"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.6426 66.834L17.6443 77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.9238 56.1152H22.7148V57.9445H18.9238V56.1152Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.4885 58.1152V58.417C19.4885 60.0183 18.7055 60.7074 17.9309 63.1084C17.3993 64.7583 17.4211 66.5422 17.4765 66.834H24.1597C24.215 66.5406 24.2368 64.7583 23.707 63.1084C22.9341 60.704 22.1494 60.0183 22.1494 58.417V58.1152"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.8161 66.834L40.8145 77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.5709 79.4141C34.3362 78.9866 34.2155 78.4903 34.2188 77.9823V77.1875H41.0529V77.9823C41.058 78.4886 40.9356 78.9866 40.7008 79.4141"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.457 66.834L34.4587 77.1909"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.7402 56.1152H39.5312V57.9445H35.7402V56.1152Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.3029 58.1152V58.417C36.3029 60.0183 35.5216 60.7074 34.7453 63.1084C34.2138 64.7583 34.2356 66.5422 34.2909 66.834H40.9758C41.0312 66.5406 41.053 64.7583 40.5215 63.1084C39.7485 60.704 38.9638 60.0183 38.9638 58.417V58.1152"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M160.845 39.6471C154.093 39.6471 148.619 34.1744 148.619 27.4207C148.686 24.2233 150.004 21.1785 152.289 18.9401C154.575 16.7017 157.646 15.4492 160.844 15.4492C164.043 15.4492 167.113 16.7034 169.398 18.9401C171.683 21.1785 173.001 24.2216 173.067 27.4207C173.07 34.1744 167.597 39.6471 160.845 39.6471Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M160.845 36.8939C158.973 36.8939 157.142 36.3389 155.584 35.2977C154.026 34.2565 152.813 32.7776 152.095 31.0473C151.377 29.3169 151.189 27.4122 151.555 25.5763C151.921 23.7386 152.823 22.0519 154.147 20.7273C155.472 19.4027 157.158 18.5007 158.996 18.1351C160.832 17.7696 162.737 17.9574 164.467 18.6734C166.197 19.3893 167.676 20.6032 168.717 22.1609C169.759 23.7185 170.314 25.5494 170.314 27.4223C170.314 29.934 169.316 32.3417 167.54 34.1173C165.765 35.8963 163.357 36.8939 160.845 36.8939Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M160.846 19.9863V27.4241L157.639 30.6316"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.4189 79.4348C50.4189 79.4348 50.6117 78.4003 50.6285 77.4228C50.6452 76.4453 50.6469 72.993 50.6368 72.2435C50.6268 71.494 50.5245 71.2777 50.5245 71.2777C50.5245 71.2777 50.5161 67.1614 50.5195 65.909C50.6469 65.4948 50.5933 64.9901 50.5933 64.9901C50.5933 63.0519 48.7975 62.5992 48.5427 61.9537C48.2878 61.3081 48.4387 59.9668 48.4387 59.9668H45.9941C45.9941 59.9668 46.145 61.3081 45.8902 61.9537C45.6353 62.5975 43.8396 63.0519 43.8396 64.9901C43.8396 64.9901 43.7859 65.4931 43.9134 65.909C43.9167 67.1614 43.9083 71.2777 43.9083 71.2777C43.9083 71.2777 43.8077 71.494 43.796 72.2435C43.7843 72.993 43.7876 76.4469 43.8044 77.4228C43.8212 78.4003 44.014 79.4348 44.014 79.4348H50.4189Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.114 61.4498H46.3266C45.9561 61.4498 45.6543 61.1497 45.6543 60.7775V60.4556C45.6543 60.085 45.9544 59.7832 46.3266 59.7832H48.114C48.4845 59.7832 48.7863 60.0833 48.7863 60.4556V60.7775C48.7863 61.1497 48.4862 61.4498 48.114 61.4498Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50.5137 65.9082H43.9395V71.2769H50.5137V65.9082Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.6591 79.4348C58.6591 79.4348 58.8519 78.4003 58.8687 77.4228C58.8855 76.4453 58.8871 72.993 58.8771 72.2435C58.867 71.494 58.7647 71.2777 58.7647 71.2777C58.7647 71.2777 58.7564 67.1614 58.7597 65.909C58.8871 65.4948 58.8335 64.9901 58.8335 64.9901C58.8335 63.0519 57.0378 62.5992 56.7829 61.9537C56.5281 61.3098 56.679 59.9668 56.679 59.9668H54.2344C54.2344 59.9668 54.3853 61.3081 54.1304 61.9537C53.8756 62.5975 52.0798 63.0519 52.0798 64.9901C52.0798 64.9901 52.0262 65.4931 52.1536 65.909C52.157 67.1614 52.1486 71.2777 52.1486 71.2777C52.1486 71.2777 52.048 71.494 52.0362 72.2435C52.0245 72.993 52.0279 76.4469 52.0446 77.4228C52.0614 78.3986 52.2542 79.4348 52.2542 79.4348H58.6591Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.3581 61.4498H54.5708C54.2002 61.4498 53.8984 61.1497 53.8984 60.7775V60.4556C53.8984 60.085 54.1986 59.7832 54.5708 59.7832H56.3581C56.7287 59.7832 57.0305 60.0833 57.0305 60.4556V60.7775C57.0305 61.1497 56.7303 61.4498 56.3581 61.4498Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.7559 65.9082H52.1816V71.2769H58.7559V65.9082Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M66.9033 79.4348C66.9033 79.4348 67.0961 78.4003 67.1128 77.4228C67.1296 76.4453 67.1313 72.993 67.1212 72.2435C67.1112 71.494 67.0089 71.2777 67.0089 71.2777C67.0089 71.2777 67.0005 67.1614 67.0039 65.909C67.1313 65.4948 67.0776 64.9901 67.0776 64.9901C67.0776 63.0519 65.2819 62.5992 65.0271 61.9537C64.7722 61.3098 64.9231 59.9668 64.9231 59.9668H62.4785C62.4785 59.9668 62.6294 61.3081 62.3746 61.9537C62.1197 62.5975 60.324 63.0519 60.324 64.9901C60.324 64.9901 60.2703 65.4931 60.3978 65.909C60.4011 67.1614 60.3927 71.2777 60.3927 71.2777C60.3927 71.2777 60.2921 71.494 60.2804 72.2435C60.2686 72.993 60.272 76.4469 60.2888 77.4228C60.3055 78.4003 60.4984 79.4348 60.4984 79.4348H66.9033Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.6003 61.4498H62.813C62.4424 61.4498 62.1406 61.1497 62.1406 60.7775V60.4556C62.1406 60.085 62.4407 59.7832 62.813 59.7832H64.6003C64.9709 59.7832 65.2727 60.0833 65.2727 60.4556V60.7775C65.271 61.1497 64.9709 61.4498 64.6003 61.4498Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M67.002 65.9082H60.4277V71.2769H67.002V65.9082Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.3759 79.2688C81.3759 79.2688 81.5687 78.2343 81.5855 77.2567C81.6023 76.2792 81.6039 72.8269 81.5939 72.0775C81.5838 71.328 81.4815 71.1117 81.4815 71.1117C81.4815 71.1117 81.4732 66.9954 81.4765 65.743C81.6039 65.3288 81.5503 64.8241 81.5503 64.8241C81.5503 62.8859 79.7546 62.4332 79.4997 61.7877C79.2449 61.1438 79.3957 59.8008 79.3957 59.8008H76.9512C76.9512 59.8008 77.1021 61.1421 76.8472 61.7877C76.5924 62.4332 74.7966 62.8859 74.7966 64.8241C74.7966 64.8241 74.743 65.3271 74.8704 65.743C74.8738 66.9954 74.8654 71.1117 74.8654 71.1117C74.8654 71.1117 74.7648 71.328 74.753 72.0775C74.7413 72.8269 74.7447 76.2809 74.7614 77.2567C74.7782 78.2326 74.971 79.2688 74.971 79.2688H81.3759Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M79.073 61.2799H77.2856C76.9151 61.2799 76.6133 60.9798 76.6133 60.6076V60.2856C76.6133 59.9151 76.9134 59.6133 77.2856 59.6133H79.073C79.4435 59.6133 79.7453 59.9134 79.7453 60.2856V60.6076C79.7436 60.9798 79.4435 61.2799 79.073 61.2799Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81.4727 65.7422H74.8984V71.1109H81.4727V65.7422Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.1289 8.58188V0.513672"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.9618 134.211C32.1353 134.211 27.4121 137.88 27.4121 143.897V145.418C27.4121 145.607 27.454 145.797 27.5328 145.968L28.564 146.58L27.501 147.036C27.4423 147.187 27.4121 147.348 27.4121 147.509V149.35C27.4121 149.531 27.444 149.71 27.5077 149.88L28.3997 150.674L27.8397 151.097C27.5714 151.3 27.4138 151.617 27.4138 151.952V153.208C27.4138 153.54 27.5496 153.857 27.7894 154.085L28.5439 154.806L27.9185 155.283C27.5999 155.527 27.4138 155.904 27.4138 156.305V157.421C27.4138 157.834 27.6083 158.221 27.9386 158.469L28.5439 158.92L28.0308 159.23C27.6468 159.462 27.4138 159.878 27.4138 160.327V161.135C27.4138 161.647 27.62 162.136 27.9872 162.492L28.5455 163.035L28.1197 163.293C27.6821 163.558 27.4155 164.032 27.4155 164.542V164.601C27.4155 167.79 30.1887 170.399 33.5789 170.399H42.2926C45.6828 170.399 48.4561 167.79 48.4561 164.601V164.542C48.4561 164.031 48.1895 163.556 47.7519 163.293L47.326 163.035L47.8843 162.492C48.2498 162.134 48.4577 161.647 48.4577 161.135V160.327C48.4577 159.879 48.223 159.463 47.8407 159.23L47.3277 158.92L47.9329 158.469C48.2633 158.223 48.4577 157.834 48.4577 157.421V156.305C48.4577 155.904 48.2716 155.527 47.9531 155.283L47.3277 154.806L48.0822 154.085C48.3219 153.855 48.4577 153.538 48.4577 153.208V151.952C48.4577 151.615 48.3001 151.298 48.0319 151.097L47.4719 150.674L48.3639 149.88C48.4276 149.71 48.4594 149.531 48.4594 149.35V147.509C48.4594 147.346 48.4292 147.187 48.3706 147.036L47.3075 146.58L48.3387 145.968C48.4192 145.795 48.4594 145.607 48.4594 145.418V143.897C48.4594 137.878 43.7362 134.211 37.9098 134.211H37.9618Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.3926 147.016C45.8412 146.913 46.916 146.773 47.305 146.59"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.5293 147.039C33.6117 147.178 36.5006 147.232 39.2487 147.202"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.707 151.288C43.8894 151.215 46.6257 151.018 47.3031 150.703"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.7227 150.754C29.0245 150.886 29.7152 150.995 30.6609 151.081"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.9219 155.272C45.6069 155.169 46.8745 155.018 47.3054 154.816"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.7227 154.869C29.3816 155.159 31.9033 155.337 34.8911 155.404"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.9512 159.492C35.202 159.532 36.5685 159.551 37.9383 159.548"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.7227 158.984C28.9742 159.095 29.499 159.189 30.2166 159.268"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.7227 163.098C29.3413 163.369 31.5965 163.542 34.3395 163.619"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.791 131.493C34.791 131.146 41.2613 131.146 41.2613 131.493V134.219C41.2613 134.566 34.791 134.566 34.791 134.219V131.493Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6512 134.211C7.82476 134.211 3.10156 137.88 3.10156 143.897V145.418C3.10156 145.607 3.14348 145.797 3.22228 145.968L4.25344 146.58L3.19043 147.036C3.13174 147.187 3.10156 147.348 3.10156 147.509V149.35C3.10156 149.531 3.13342 149.71 3.19713 149.88L4.08912 150.674L3.52912 151.097C3.26085 151.3 3.10324 151.617 3.10324 151.952V153.208C3.10324 153.54 3.23905 153.857 3.47881 154.085L4.23332 154.806L3.60792 155.283C3.28935 155.527 3.10324 155.904 3.10324 156.305V157.421C3.10324 157.834 3.29773 158.221 3.62804 158.469L4.23332 158.92L3.72026 159.23C3.3363 159.462 3.10324 159.878 3.10324 160.327V161.135C3.10324 161.647 3.30947 162.136 3.67666 162.492L4.235 163.035L3.80912 163.293C3.37151 163.558 3.10492 164.032 3.10492 164.542V164.601C3.10492 167.79 5.87814 170.399 9.26838 170.399H17.9821C21.3723 170.399 24.1455 167.79 24.1455 164.601V164.542C24.1455 164.031 23.8789 163.556 23.4413 163.293L23.0154 163.035L23.5738 162.492C23.9393 162.134 24.1472 161.647 24.1472 161.135V160.327C24.1472 159.879 23.9125 159.463 23.5302 159.23L23.0171 158.92L23.6224 158.469C23.9527 158.223 24.1472 157.834 24.1472 157.421V156.305C24.1472 155.904 23.9611 155.527 23.6425 155.283L23.0171 154.806L23.7716 154.085C24.0114 153.855 24.1472 153.538 24.1472 153.208V151.952C24.1472 151.615 23.9896 151.298 23.7213 151.097L23.1613 150.674L24.0533 149.88C24.117 149.71 24.1489 149.531 24.1489 149.35V147.509C24.1489 147.346 24.1187 147.187 24.06 147.036L22.997 146.58L24.0282 145.968C24.1086 145.795 24.1489 145.607 24.1489 145.418V143.897C24.1489 137.878 19.4257 134.211 13.5992 134.211H13.6512Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0801 147.016C21.5287 146.913 22.6035 146.773 22.9925 146.59"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.41016 146.641C5.37927 147.067 10.3556 147.251 14.938 147.197"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.3965 151.288C19.5788 151.215 22.3151 151.018 22.9925 150.703"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.41016 150.754C4.71196 150.886 5.40275 150.995 6.34839 151.081"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.41016 154.87C6.1757 155.645 21.2675 155.621 22.9928 154.816"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.41016 158.984C5.28706 159.368 9.44689 159.558 13.6252 159.551"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.9316 163.583C20.4282 163.488 22.4285 163.308 22.9935 163.045"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.41016 163.098C5.02885 163.369 7.28398 163.542 10.027 163.619"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4805 131.493C10.4805 131.146 16.9508 131.146 16.9508 131.493V134.219C16.9508 134.566 10.4805 134.566 10.4805 134.219V131.493Z"
      fill="#F8F8F6"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M89.3344 43.0361C89.3344 43.3412 89.0879 43.5877 88.7828 43.5877L88.719 44.4093H84.4083L84.3446 43.5877C84.0394 43.5877 83.793 43.3412 83.793 43.0361V32.7462C83.793 32.4411 84.0394 32.1946 84.3446 32.1946L84.4083 31.373H88.719L88.7828 32.1946C89.0879 32.1946 89.3344 32.4411 89.3344 32.7462V43.0361Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M89.3359 39.5089C88.9184 39.8158 88.4037 39.9969 87.847 39.9969C86.4588 39.9969 85.332 38.8718 85.332 37.4818C85.332 36.0935 86.4571 34.9668 87.847 34.9668C88.4037 34.9668 88.9184 35.1479 89.3359 35.4547"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.0586 43.0361C63.0586 43.3412 63.3051 43.5877 63.6102 43.5877L63.6739 44.4093H67.9847L68.0484 43.5877C68.3535 43.5877 68.6 43.3412 68.6 43.0361V32.7462C68.6 32.4411 68.3535 32.1946 68.0484 32.1946L67.9847 31.373H63.6739L63.6102 32.1946C63.3051 32.1946 63.0586 32.4411 63.0586 32.7462V43.0361Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.0586 39.5089C63.4761 39.8158 63.9908 39.9969 64.5475 39.9969C65.9358 39.9969 67.0625 38.8718 67.0625 37.4818C67.0625 36.0935 65.9374 34.9668 64.5475 34.9668C63.9908 34.9668 63.4761 35.1479 63.0586 35.4547"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M82.4572 43.0361C82.4572 43.3412 82.2107 43.5877 81.9055 43.5877L81.8418 44.4093H77.5294L77.4657 43.5877C77.1605 43.5877 76.9141 43.3412 76.9141 43.0361V32.7462C76.9141 32.4411 77.1605 32.1946 77.4657 32.1946L77.5294 31.373H81.8401L81.9039 32.1946C82.209 32.1946 82.4555 32.4411 82.4555 32.7462V43.0361H82.4572Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M79.6361 39.9969C81.0251 39.9969 82.1511 38.8708 82.1511 37.4818C82.1511 36.0928 81.0251 34.9668 79.6361 34.9668C78.2471 34.9668 77.1211 36.0928 77.1211 37.4818C77.1211 38.8708 78.2471 39.9969 79.6361 39.9969Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75.5805 43.0361C75.5805 43.3412 75.334 43.5877 75.0289 43.5877L74.9651 44.4093H70.6544L70.5907 43.5877C70.2855 43.5877 70.0391 43.3412 70.0391 43.0361V32.7462C70.0391 32.4411 70.2855 32.1946 70.5907 32.1946L70.6544 31.373H74.9651L75.0289 32.1946C75.334 32.1946 75.5805 32.4411 75.5805 32.7462V43.0361Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M75.5982 39.7631C74.7397 39.3624 74.1445 38.4922 74.1445 37.4828C74.1445 36.4802 74.7314 35.6133 75.5814 35.2109"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M70.0391 35.1641C70.9428 35.5447 71.5782 36.44 71.5782 37.4829C71.5782 38.5241 70.9445 39.4178 70.0424 39.8001"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.78363 79.3408H10.7597C12.6225 79.3408 14.1332 77.8301 14.1332 75.9673V70.6723C14.1332 68.8095 12.6225 67.2988 10.7597 67.2988H7.78363C5.92084 67.2988 4.41016 68.8095 4.41016 70.6723V75.9673C4.41016 77.8301 5.92084 79.3408 7.78363 79.3408Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9507 65.6777H7.42969V67.1834H10.9507V65.6777Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1356 70.8887H8.09961V74.3628H14.1356V70.8887Z"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.584 91.748H44.3147"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.5312 105.16H35.262"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.9492 105.16H49.6799"
      stroke="#C9C9C9"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M105.279 145.877H58.2363V170.464H105.279V145.877Z" fill="#E0CEB2" />
    <path
      d="M105.279 145.877H58.2363V170.464H105.279V145.877Z"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M77.6211 150.254V168.64"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.8675 158.17H81.4352C80.845 158.17 80.3672 158.644 80.3672 159.23V166.506C80.3672 167.092 80.845 167.566 81.4352 167.566H91.8675C92.4577 167.566 92.9355 167.092 92.9355 166.506V159.23C92.9339 158.644 92.456 158.17 91.8675 158.17Z"
      fill="#F8F8F6"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M96.7765 165.871H96.0488V169.112H96.7765V165.871Z" fill="#001D45" />
    <path d="M103.751 165.871H103.023V169.112H103.751V165.871Z" fill="#001D45" />
    <path d="M100.137 165.871H98.9102V169.112H100.137V165.871Z" fill="#001D45" />
    <path d="M101.761 165.871H100.533V169.112H101.761V165.871Z" fill="#001D45" />
    <path d="M97.6447 165.871H97.1719V169.112H97.6447V165.871Z" fill="#001D45" />
    <path d="M98.5138 165.871H98.041V169.112H98.5138V165.871Z" fill="#001D45" />
    <path d="M102.629 165.871H102.156V169.112H102.629V165.871Z" fill="#001D45" />
    <path
      d="M72.6706 142.152H53.3066L58.2394 145.886H77.6017L72.6706 142.152Z"
      fill="#E0CEB2"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.929 142.455H82.2535L77.5957 145.886H105.273L109.929 142.455Z"
      fill="#E0CEB2"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M84.063 165.825C84.281 165.801 84.4184 165.756 84.4956 165.675C84.5626 165.607 84.5526 165.374 84.5559 165.087C84.561 164.802 84.561 163.792 84.5576 163.573C84.5543 163.353 84.5258 163.291 84.5258 163.291C84.5258 163.291 84.5241 162.089 84.5241 161.722C84.561 161.601 84.5459 161.453 84.5459 161.453C84.5459 160.887 84.0211 160.754 83.9473 160.566C83.8735 160.379 83.9171 159.986 83.9171 159.986H83.2029C83.2029 159.986 83.2465 160.379 83.1727 160.566C83.0989 160.754 82.5741 160.887 82.5741 161.453C82.5741 161.453 82.559 161.601 82.5959 161.722C82.5976 162.087 82.5942 163.291 82.5942 163.291C82.5942 163.291 82.5641 163.355 82.5624 163.573C82.559 163.792 82.5607 164.8 82.5641 165.087C82.5691 165.372 82.559 165.607 82.6244 165.675C82.7015 165.754 82.839 165.801 83.057 165.825C83.057 165.825 83.2565 165.851 83.56 165.851C83.8635 165.851 84.063 165.825 84.063 165.825Z"
      fill="#FF9040"
    />
    <path d="M84.5233 161.723H82.6035V163.292H84.5233V161.723Z" fill="#C0E5B5" />
    <path
      d="M86.9751 165.825C87.1931 165.801 87.3306 165.756 87.4077 165.675C87.4748 165.607 87.4647 165.374 87.4681 165.087C87.4731 164.802 87.4731 163.792 87.4697 163.573C87.4664 163.353 87.4379 163.291 87.4379 163.291C87.4379 163.291 87.4362 162.089 87.4362 161.722C87.4731 161.601 87.458 161.453 87.458 161.453C87.458 160.887 86.9332 160.754 86.8594 160.566C86.7856 160.379 86.8292 159.986 86.8292 159.986H86.115C86.115 159.986 86.1586 160.379 86.0848 160.566C86.011 160.754 85.4862 160.887 85.4862 161.453C85.4862 161.453 85.4711 161.601 85.508 161.722C85.5097 162.087 85.5063 163.291 85.5063 163.291C85.5063 163.291 85.4762 163.355 85.4745 163.573C85.4711 163.792 85.4728 164.8 85.4762 165.087C85.4812 165.372 85.4711 165.607 85.5365 165.675C85.6136 165.754 85.7511 165.801 85.9691 165.825C85.9691 165.825 86.1686 165.851 86.4721 165.851C86.7756 165.851 86.9751 165.825 86.9751 165.825Z"
      fill="#FF9040"
    />
    <path d="M87.4393 161.723H85.5195V163.292H87.4393V161.723Z" fill="#C0E5B5" />
    <path
      d="M89.8872 165.825C90.1052 165.801 90.2427 165.756 90.3198 165.675C90.3869 165.607 90.3768 165.374 90.3802 165.087C90.3852 164.802 90.3852 163.792 90.3818 163.573C90.3785 163.353 90.35 163.291 90.35 163.291C90.35 163.291 90.3483 162.089 90.3483 161.722C90.3852 161.601 90.3701 161.453 90.3701 161.453C90.3701 160.887 89.8453 160.754 89.7715 160.566C89.6978 160.379 89.7414 159.986 89.7414 159.986H89.0271C89.0271 159.986 89.0707 160.379 88.9969 160.566C88.9231 160.754 88.3983 160.887 88.3983 161.453C88.3983 161.453 88.3832 161.601 88.4201 161.722C88.4218 162.087 88.4185 163.291 88.4185 163.291C88.4185 163.291 88.3883 163.355 88.3866 163.573C88.3832 163.792 88.3849 164.8 88.3883 165.087C88.3933 165.372 88.3832 165.607 88.4486 165.675C88.5258 165.754 88.6632 165.801 88.8812 165.825C88.8812 165.825 89.0807 165.851 89.3842 165.851C89.6877 165.851 89.8872 165.825 89.8872 165.825Z"
      fill="#FF9040"
    />
    <path d="M90.3495 161.723H88.4297V163.292H90.3495V161.723Z" fill="#C0E5B5" />
    <path
      d="M135.158 116.348C134.478 120.288 141.692 122.138 144.843 118.868C147.995 115.597 147.069 104.683 147.069 104.683C147.069 104.683 147.448 103.64 147.378 103.213C143.087 102.869 141.072 103.141 136.87 102.49C137.2 107.966 135.371 115.451 135.158 116.348Z"
      fill="#00ADFF"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.638 77.8633C133.634 82.1103 135.6 90.2054 135.676 93.039C135.808 98.0422 135.813 104.258 135.813 104.258C135.813 104.258 143.072 106.072 147.362 106.416C146.604 101.751 145.27 80.442 139.638 77.8633Z"
      fill="#00ADFF"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M137.362 119.881C133.174 117.829 129.941 111.793 122.337 105.555C122.337 105.555 119.014 102.92 121.452 100.388C121.881 100.225 121.45 97.4655 125.892 100.467C129.874 103.158 140.182 106.86 140.182 106.86"
      fill="#00ADFF"
    />
    <path
      d="M137.362 119.881C133.174 117.829 129.941 111.793 122.337 105.555C122.337 105.555 119.014 102.92 121.452 100.388C121.881 100.225 121.45 97.4655 125.892 100.467C129.874 103.158 140.182 106.86 140.182 106.86"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M123.931 99.1735C123.309 99.0242 123.114 98.4692 122.853 98.1322C122.591 97.7952 122.132 97.3911 121.647 96.8462C121.161 96.3013 119.449 95.0673 118.933 94.8811C118.416 94.695 116.312 93.7125 115.727 93.3889C115.142 93.067 114.188 92.1582 114.188 92.1582L113.626 92.4617L107.62 97.6443L106.656 97.6259C106.656 97.6259 105.912 97.698 105.621 98.0216C105.331 98.3469 105.561 98.7275 105.995 99.0578C106.43 99.3881 107.241 99.378 107.241 99.378C107.241 99.378 107.441 100.345 107.714 100.713C107.987 101.082 108.619 101.187 108.619 101.187C108.619 101.187 108.495 101.299 108.406 101.563C108.319 101.826 108.655 102.388 108.987 102.904C109.32 103.422 110.472 103.855 110.472 103.855C110.497 104.083 110.539 104.309 110.596 104.532C110.683 104.842 111.483 105.094 111.924 105.283C112.363 105.473 113.036 105.208 113.036 105.208L115.006 105.22C115.006 105.22 118.051 105.917 119.125 105.974C120.199 106.031 121.367 105.49 121.647 105.392C121.808 105.337 122.142 105.335 122.532 105.362C123.049 103.308 124.769 102.138 125.252 100.077C125.208 100.072 123.963 99.1802 123.931 99.1735Z"
      fill="#EFC9B4"
    />
    <path
      d="M109.356 100.199L108.959 99.6523"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M110.936 102.368L110.5 101.768"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M125.914 100.524C125.181 100.192 123.159 98.3657 122.44 97.6682C121.774 97.0244 119.715 95.284 118.919 94.9034C118.268 94.5915 117.011 94.1053 115.836 93.4564C114.918 92.9483 114.426 92.11 114.072 92.1821C113.564 92.2894 113.314 93.1462 113.968 94.1136C114.622 95.0811 115.603 95.185 116.131 95.9429"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M122.322 105.597C121.675 105.659 120.49 105.962 118.959 105.859C117.369 105.751 115.238 105.309 115.238 105.309"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.191 99.4058C107.191 99.4058 105.452 99.28 105.536 98.4081C105.63 97.4273 107.495 97.6821 107.495 97.6821"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M104.011 92.6246L107.48 88.614C107.48 88.614 107.734 88.3222 108.154 88.5603C108.575 88.7967 120.019 99.5308 120.237 99.955C120.456 100.381 120.55 100.745 120.389 101.043C120.228 101.342 116.856 105.061 116.856 105.061C116.856 105.061 116.586 105.27 116.258 105.176C115.929 105.084 115.461 104.683 115.461 104.683L104.146 93.9089C104.146 93.9089 103.936 93.6507 103.85 93.2399C103.827 93.1175 103.829 93.0001 103.855 92.8928C103.884 92.7889 103.936 92.6967 104.011 92.6246Z"
      fill="#001D45"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M102.922 92.4588C102.922 92.4588 105.274 89.657 106.194 88.7164C107.115 87.7758 107.678 88.3492 108.097 88.5856C108.517 88.8221 118.929 99.3634 119.147 99.7876C119.366 100.213 119.46 100.577 119.299 100.876C119.138 101.174 115.767 104.893 115.767 104.893C115.767 104.893 115.497 105.103 115.168 105.009C114.839 104.917 114.372 104.516 114.372 104.516C114.372 104.516 109.871 100.093 108.324 98.691C106.776 97.2893 105.767 97.5542 104.26 95.9647C101.798 93.3709 102.848 92.5292 102.922 92.4588Z"
      fill="#001D45"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M114.851 96.8298L112.376 99.6718C112.189 99.8864 111.815 99.8495 111.54 99.588L105.447 93.8084C105.172 93.5469 105.101 93.1629 105.287 92.9483L107.762 90.1063C107.95 89.8917 108.324 89.9286 108.599 90.1901L114.692 95.9697C114.967 96.2296 115.037 96.6152 114.851 96.8298Z"
      fill="white"
    />
    <path
      d="M106.791 93.5796L108.545 91.6816"
      stroke="#FF9040"
      stroke-width="0.774939"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.943 94.6343L109.699 92.7363"
      stroke="#A3E8FE"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.098 95.6871L110.853 93.7891"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M112.249 103.959C112.344 103.664 112.373 103.35 112.334 103.043C112.234 102.205 111.704 102.002 111.367 102.146C111.03 102.29 110.736 103.786 110.949 104.225C111.176 104.696 111.861 105 112.425 105.045C112.814 105.08 113.315 104.961 113.315 104.961L116.031 105.463"
      fill="#EFC9B4"
    />
    <path
      d="M112.249 103.959C112.344 103.664 112.373 103.35 112.334 103.043C112.234 102.205 111.704 102.002 111.367 102.146C111.03 102.29 110.736 103.786 110.949 104.225C111.176 104.696 111.861 105 112.425 105.045C112.814 105.08 113.315 104.961 113.315 104.961L116.031 105.463"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M110.792 103.623C110.792 103.623 109.773 103.26 109.324 102.729C108.856 102.172 108.787 101.515 108.908 101.238C109.069 100.866 109.85 99.6907 110.179 99.6639C110.507 99.6353 111.106 99.9539 111.079 100.797C111.054 101.587 110.422 102.244 110.422 102.244"
      fill="#EFC9B4"
    />
    <path
      d="M110.792 103.623C110.792 103.623 109.773 103.26 109.324 102.729C108.856 102.172 108.787 101.515 108.908 101.238C109.069 100.866 109.85 99.6907 110.179 99.6639C110.507 99.6353 111.106 99.9539 111.079 100.797C111.054 101.587 110.422 102.244 110.422 102.244"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.278 99.7937C109.278 99.7937 109.516 99.0895 109.013 98.5195C108.471 97.9091 107.66 98.273 107.574 98.5312C107.454 98.8967 107.67 100.23 108.071 100.575C108.302 100.775 108.699 100.909 109.041 100.993"
      fill="#EFC9B4"
    />
    <path
      d="M109.278 99.7937C109.278 99.7937 109.516 99.0895 109.013 98.5195C108.471 97.9091 107.66 98.273 107.574 98.5312C107.454 98.8967 107.67 100.23 108.071 100.575C108.302 100.775 108.699 100.909 109.041 100.993"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M178.872 161.314C178.872 161.314 179.711 162.573 180.091 163.2C180.22 163.393 180.296 163.614 180.314 163.842C180.331 164.07 180.288 164.296 180.189 164.499C179.935 165.068 179.468 165.509 178.886 165.73C176.83 166.795 175.672 167.353 176.113 169.982C178.586 170.177 180.381 170.058 182.573 170.076C186.134 170.103 189.048 168.079 189 165.517L188.998 165.442C188.946 163.373 188.051 160.692 188.034 158.359C188.014 156.166 188.559 150.796 187.603 149.342C187.05 148.5 184.965 148.338 182.932 148.464C181.947 148.521 177.618 148.962 176.879 149.024"
      fill="#BECBDA"
    />
    <path
      d="M178.872 161.314C178.872 161.314 179.711 162.573 180.091 163.2C180.22 163.393 180.296 163.614 180.314 163.842C180.331 164.07 180.288 164.296 180.189 164.499C179.935 165.068 179.468 165.509 178.886 165.73C176.83 166.795 175.672 167.353 176.113 169.982C178.586 170.177 180.381 170.058 182.573 170.076C186.134 170.103 189.048 168.079 189 165.517L188.998 165.442C188.946 163.373 188.051 160.692 188.034 158.359C188.014 156.166 188.559 150.796 187.603 149.342C187.05 148.5 184.965 148.338 182.932 148.464C181.947 148.521 177.618 148.962 176.879 149.024"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M182.861 168.465C185.941 167.961 186.459 166.277 186.414 164.423C186.366 162.406 185.951 160.342 185.936 158.06C185.921 156.236 186.312 151.782 185.846 150.023"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M178.743 160.144C178.683 159.789 178.911 159.478 179.474 159.366C179.743 159.301 179.979 159.145 180.143 158.925C180.308 158.705 180.39 158.434 180.378 158.154C180.202 155.6 178.973 151.627 178.462 151.316C177.097 150.488 176.879 149.018 176.879 149.018"
      fill="white"
    />
    <path
      d="M178.743 160.144C178.683 159.789 178.911 159.478 179.474 159.366C179.743 159.301 179.979 159.145 180.143 158.925C180.308 158.705 180.39 158.434 180.378 158.154C180.202 155.6 178.973 151.627 178.462 151.316C177.097 150.488 176.879 149.018 176.879 149.018"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M176.701 148.183C174.548 148.23 173.192 148.42 171.582 149.049C162.481 150.13 159.267 152.533 159.267 152.533C159.267 152.533 158.249 153.644 158.222 153.133C159.765 151.572 164.416 137.55 166.044 132.688C164.753 133.212 163.42 133.606 161.97 133.667C156.4 133.977 150.957 134.014 145.421 134.379L144.765 138.549C144.765 138.549 143.045 153.524 142.889 154.971C142.733 156.418 142.041 160.561 144.257 165.678C146.476 170.797 151.293 170.46 151.293 170.46L157.049 169.179L168.846 164.659L178.867 161.312C178.872 161.312 177.291 150.492 176.701 148.183Z"
      fill="#9A9A9A"
    />
    <path
      d="M166.316 132.234C162.404 143.466 160.954 149.218 157.933 153.572C161.264 150.495 167.833 149.6 171.723 148.785C174.205 148.265 176.703 148.186 176.703 148.186C176.703 148.186 179.278 161.275 178.737 161.578C177.685 162.135 176.527 162.472 175.328 162.564C168.769 164.061 159.282 169.187 153.164 170.012"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M145.438 134.266C145.438 134.266 142.339 153.992 142.64 159.877C142.906 165.127 145.317 171.329 153.162 170.013"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M151.412 158.58C154.879 157.723 158.233 153.225 158.233 153.225"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M128.512 161.316C128.512 161.316 126.141 162.48 125.401 162.825C121.825 164.48 117.848 165.287 116.885 165.968C115.69 166.814 115.936 169.448 117.186 170.015C117.9 170.352 120.458 170.488 123.478 170.463C125.945 170.443 128.46 170.096 131.248 170.107C133.867 170.116 138.523 170.692 140.252 169.846C141.253 169.354 141.08 167.773 140.781 165.946C140.656 165.166 140.433 162.041 140.352 161.37"
      fill="#BECBDA"
    />
    <path
      d="M128.512 161.316C128.512 161.316 126.141 162.48 125.401 162.825C121.825 164.48 117.848 165.287 116.885 165.968C115.69 166.814 115.936 169.448 117.186 170.015C117.9 170.352 120.458 170.488 123.478 170.463C125.945 170.443 128.46 170.096 131.248 170.107C133.867 170.116 138.523 170.692 140.252 169.846C141.253 169.354 141.08 167.773 140.781 165.946C140.656 165.166 140.433 162.041 140.352 161.37"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M116.836 167.737C117.998 167.913 120.001 167.987 122.268 167.97C125.929 167.942 128.442 167.611 130.041 167.611C132.273 167.62 136.076 168.042 138.22 167.64"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M168.892 112.724C168.865 112.511 168.826 112.288 168.783 112.059C163.483 113.861 157.965 115.233 152.16 116.433C149.717 116.899 147.274 117.053 144.934 116.75C144.898 116.748 132.397 115.891 125.382 117.719C122.9 118.363 121.896 119.751 120.306 121.917C118.65 124.178 119.433 129.323 119.433 129.323C119.433 129.323 125.895 150.498 126.805 158.307C125.865 161.809 126.683 162.064 126.683 162.064L134.017 163.288L140.804 163.598C140.804 163.598 141.017 160.895 140.033 158.753C139.836 156.613 139.347 152.42 139.347 152.42C139.347 152.42 139.052 145.679 138.326 141.672C137.601 137.665 135.096 133.23 135.096 133.23L145.698 133.926L145.613 134.379C151.161 134.013 156.603 133.976 162.185 133.666C163.635 133.606 164.982 133.213 166.289 132.687C166.407 132.368 166.509 132.088 166.596 131.855C171.97 129.422 171.425 122.159 171.425 122.159C171.115 118.894 170.26 115.704 168.892 112.724Z"
      fill="#9A9A9A"
    />
    <path
      d="M150.787 116.865C139.258 116.357 131.884 116.756 127.114 117.351C120.7 118.153 117.671 124.148 119.983 131.573C122.647 140.126 126.079 154.084 126.766 159.208C126.153 160.182 126.126 162.085 126.693 162.246C129.937 163.173 138.275 164.08 140.566 163.468C140.698 163.433 140.817 163.356 140.903 163.249C140.988 163.141 141.037 163.009 141.04 162.87C141.062 161.879 140.495 159.473 140.032 158.754C139.285 142.345 136.754 135.573 134.791 132.824C138.458 133.607 148.215 134.304 154.287 134.054C155.235 134.016 157.57 134.101 158.519 134.086C175.64 133.83 172.087 117.308 168.789 112.729"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M134.789 132.819C133.583 130.262 131.504 129.742 131.504 129.742"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M124.169 60.8469C124.33 60.7194 124.555 62.822 125.158 64.3662C125.76 65.9121 126.822 67.3021 126.728 68.1807C126.637 69.0593 126.278 70.5767 126.278 70.5767C126.278 70.5767 126.589 71.5894 127.299 71.7436C128.009 71.8996 128.374 71.6967 128.374 71.6967C128.374 71.6967 131.974 76.549 133.004 76.5373C134.467 76.5239 135.871 74.4431 135.946 74.3911C136.025 74.3391 136.907 75.2261 136.932 75.2512C136.932 75.2512 138.864 72.2567 141.105 71.3228C143.349 70.3889 145.508 69.289 145.508 69.289L142.988 66.053C142.988 66.053 139.977 59.4166 136.644 57.4817C133.31 55.5469 127.102 56.7373 127.102 56.7373C127.102 56.7373 124.055 60.9374 124.169 60.8469Z"
      fill="#EFC9B4"
    />
    <path
      d="M145.368 69.2463C144.429 68.0676 143.549 66.8604 143.214 66.4647C143.214 66.4647 144.111 65.3799 144.054 63.5255C143.992 61.4698 142.949 60.4437 142.949 60.4437C143.15 58.8307 142.979 57.1926 142.453 55.6534C141.759 53.6598 140.389 51.9161 138.479 51.3561C136.968 50.9151 135.555 51.6076 135.555 51.6076C135.555 51.6076 132.118 50.0885 127.706 52.6002C124.123 54.6407 123.821 57.5229 123.821 57.5229C123.821 57.5229 120.961 58.3378 121.726 61.7985C121.974 62.9252 122.297 63.9295 123.33 64.7008C124.324 65.2139 125.872 64.1106 126.308 63.4802C125.569 62.0097 125.325 60.3364 125.268 59.0303C126.581 60.4001 127.834 61.7951 128.644 62.3535C130.315 62.1103 131.437 62.2109 132.302 61.2971C133.477 61.993 133.102 62.8263 134.26 62.5479C135.117 62.3467 135.139 61.5704 136.192 61.5185C138.008 61.4095 138.781 63.9547 138.028 65.3279C137.302 66.6542 135.912 66.3758 135.912 66.3758"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M144.055 63.5213C144.112 65.3757 143.215 66.4605 143.215 66.4605L138.029 65.317C138.78 63.9438 138.009 61.3986 136.193 61.5076C135.14 61.5595 135.118 62.3358 134.26 62.537C133.103 62.8154 133.479 61.9821 132.303 61.2846C131.436 62.1983 130.69 62.0709 128.774 62.3224C127.962 61.7658 126.396 60.0203 125.083 58.6505C125.013 59.9751 125.57 61.9988 126.309 63.4676C125.875 64.098 124.326 65.2013 123.331 64.6882C122.299 63.9186 121.977 62.9126 121.727 61.7876C120.962 58.3286 122.882 56.3903 122.882 56.3903C122.882 56.3903 124.124 54.6331 127.707 52.5909C133.73 49.3767 138.48 51.3485 138.48 51.3485C140.39 51.9085 141.76 53.6523 142.454 55.6459C142.98 57.1834 143.151 58.8215 142.95 60.4362C142.95 60.4395 143.993 61.4656 144.055 63.5213Z"
      fill="#001D45"
    />
    <path
      d="M145.735 69.6279C144.8 68.4492 143.549 66.858 143.213 66.4623M138.029 65.3222C138.78 63.949 138.009 61.4037 136.193 61.5127C135.14 61.5647 135.12 62.341 134.26 62.5422C133.103 62.8189 133.479 61.9856 132.303 61.2897C131.438 62.2035 130.692 62.0744 128.774 62.3276C127.962 61.7709 126.396 60.0255 125.083 58.6557C125.013 59.9802 125.57 62.0023 126.309 63.4728C125.875 64.1032 124.326 65.2065 123.331 64.6934C122.299 63.9238 121.975 62.9195 121.727 61.7927C120.962 58.3337 122.882 56.3955 122.882 56.3955C122.882 56.3955 124.124 54.6366 127.707 52.5961C133.73 49.3836 138.48 51.3537 138.48 51.3537C140.39 51.9137 141.76 53.6575 142.454 55.651C142.98 57.1902 143.151 58.8284 142.95 60.4413C142.95 60.4413 143.993 61.4675 144.055 63.5231C144.112 65.3775 143.215 66.4623 143.215 66.4623L138.029 65.3188C137.303 66.6451 135.913 66.3667 135.913 66.3667"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M138.131 65.3105C138.131 65.3105 138.83 66.3752 140.859 66.6871C141.65 66.8112 142.46 66.734 143.215 66.4607"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M128.767 67.6083C129.057 67.5027 129.147 66.9929 128.959 66.4698C128.772 65.9484 128.376 65.613 128.083 65.7187C127.788 65.826 127.7 66.3357 127.888 66.8571C128.076 67.3803 128.472 67.7156 128.767 67.6083Z"
      fill="#001D45"
    />
    <path
      d="M126.823 65.708C126.771 65.7063 126.72 65.6929 126.677 65.6678C126.633 65.6426 126.595 65.6057 126.57 65.5621C126.451 65.3861 126.4 65.1178 126.598 64.9451C127.024 64.5628 127.566 64.3415 128.137 64.318C129.143 64.2526 129.811 65.1027 129.705 65.2821C129.599 65.4615 129.031 64.9669 128.162 65.0809C127.735 65.1363 127.339 65.3224 127.021 65.6124C126.967 65.6644 126.897 65.6979 126.823 65.708Z"
      fill="#001D45"
    />
    <path
      d="M131.042 73.6846C131.541 73.1649 131.598 72.4707 131.427 72.3802C131.253 72.2896 131.013 72.687 130.522 73.1129C130.128 73.4533 129.995 73.5019 129.853 73.5203C129.71 73.5388 129.672 73.5857 129.752 73.7752C129.831 73.9663 130.066 74.0619 130.247 74.0921C130.374 74.1038 130.668 74.072 131.042 73.6846Z"
      fill="#001D45"
    />
    <path
      d="M136.551 70.101C137.481 74.0747 134.142 76.8094 133.006 76.5344C131.289 76.1203 128.588 71.8045 128.588 71.8045C128.588 71.8045 126.903 72.0375 126.404 71.0449C126.006 70.2569 126.905 69.2777 126.705 67.7804C126.529 66.4525 125.046 65.527 124.824 63.2383"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M138.164 79.3383C137.306 77.5962 136.424 75.2975 136.424 75.2975C136.424 75.2975 136.726 73.8119 139.41 71.0286C142.094 68.2453 144.328 67.0398 145.148 67.6518C145.967 68.2638 148.892 72.043 148.892 72.043L138.164 79.3383Z"
      fill="#BECBDA"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
    />
    <path
      d="M135.973 81.7643C136.898 76.025 141.028 69.8045 146.948 69.5278C152.295 69.278 158.331 73.6374 160.075 78.7848C165.539 86.0448 165.224 92.8723 165.224 92.8723C170.402 99.4566 168.926 106.943 168.926 106.943C172.078 111.53 172.749 118.237 171.53 122.31C168.945 121.124 166.777 122.903 158.93 119.904C152.636 117.498 147.775 116.438 142.105 116.594C138.887 115.798 139.675 107.87 139.675 107.87C136.779 99.9865 135.047 87.5035 135.973 81.7643Z"
      fill="#BECBDA"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M152.606 86.1808C152.193 91.8011 148.644 96.2627 144.581 95.4361C140.519 94.6095 137.481 88.8049 137.893 83.1846C138.305 77.5644 141.935 73.6795 145.998 74.5078C150.061 75.3344 153.02 80.5623 152.606 86.1808Z"
      fill="#00ADFF"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M152.303 98.1504C152.303 98.1504 154.115 102.657 156.337 112.763L152.303 98.1504Z" fill="#BECBDA" />
    <path
      d="M152.303 98.1504C152.303 98.1504 154.115 102.657 156.337 112.763"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M109.034 123.207C109.577 122.632 109.847 122.186 109.875 121.787C109.899 121.445 109.324 120.827 108.672 120.032C108.019 119.237 105.665 116.469 105.147 115.875C104.629 115.282 104.399 115.178 104.399 115.178C104.399 115.178 101.586 111.885 100.734 110.879C100.553 110.46 100.167 110.092 100.167 110.092C98.8462 108.54 97.0974 109.4 96.4536 109.058C95.8097 108.716 95.015 107.537 95.015 107.537L93.0566 109.204C93.0566 109.204 94.0928 110.176 94.3276 110.867C94.5623 111.558 93.4322 113.146 94.7534 114.698C94.7534 114.698 95.0536 115.138 95.4392 115.384C96.296 116.387 99.096 119.691 99.096 119.691C99.096 119.691 99.1614 119.933 99.6644 120.541C100.167 121.15 102.523 123.915 103.202 124.688C103.881 125.461 104.401 126.127 104.741 126.158C105.14 126.195 105.623 125.999 106.277 125.556C106.277 125.556 106.891 125.164 107.719 124.46C108.547 123.756 109.034 123.207 109.034 123.207Z"
      fill="#FF9040"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M95.7951 108.918L94.3095 110.182C94.0262 110.422 93.6037 110.389 93.3622 110.105L93.0973 109.795C92.8575 109.512 92.8911 109.089 93.1744 108.848L94.66 107.584C94.9433 107.344 95.3658 107.377 95.6073 107.661L95.8722 107.971C96.112 108.254 96.0784 108.679 95.7951 108.918Z"
      fill="white"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.74 110.88L95.4707 115.363L99.1322 119.667L104.402 115.184L100.74 110.88Z"
      fill="#C0E5B5"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M107.122 114.487C105.849 113.274 104.873 112.406 102.395 112.069C102.102 112.029 102.56 112.111 102.31 112.262C102.935 112.81 103.562 113.356 103.7 113.905C103.822 114.889 102.335 115.477 101.481 115.925L101.037 123.626L101.649 124.008C102.063 124.413 102.685 124.516 103.207 124.268L104.659 123.723C106.329 123.075 107.581 121.604 109.434 120.652L114.957 118.707L112.735 112.783L107.122 114.487Z"
      fill="#EFC9B4"
    />
    <path
      d="M113.168 119.088L109.828 120.317C107.976 121.271 106.333 123.072 104.663 123.721L103.408 124.254C103.058 124.403 102.662 124.401 102.314 124.249L101.053 123.697"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M101.984 112.16C102.273 112.08 102.046 112.016 102.397 112.063C104.865 112.395 105.951 113.518 107.224 114.732C107.965 114.549 108.847 113.931 110.265 113.04"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M97.4495 117.798C98.8914 117.388 102.335 115.146 103.903 113.863L107.624 118.764C104.882 124.232 101.368 123.462 100.571 123.754C99.9561 123.979 99.2955 123.615 99.0993 122.991L98.8646 122.242C98.2409 122.277 97.6607 121.92 97.4126 121.346L97.0102 120.416L96.8744 120.389C96.4485 120.303 96.0595 120.074 95.8164 119.715C94.8104 118.216 95.1725 117.758 96.4753 116.59L97.4495 117.798Z"
      fill="#EFC9B4"
    />
    <path
      d="M103.19 113.533C103.279 113.751 103.375 114.427 102.233 115.295C100.622 116.524 99.6525 116.964 98.2105 117.376L97.498 117.797M97.5332 117.829L96.3662 116.457C95.0618 117.626 94.8103 118.215 95.8179 119.713C96.0594 120.074 96.45 120.304 96.8759 120.387L97.0117 120.414M99.9325 119.539C98.9902 119.948 98.3866 120.49 97.0101 120.414L97.5281 121.405C97.7897 121.905 98.3011 122.225 98.8661 122.24L99.5569 123.174C99.857 123.578 100.375 123.756 100.863 123.632C101.512 123.466 101.81 123.377 102.667 122.819M100.96 121.388C100.12 122 99.8285 122.014 98.8661 122.242"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M140.602 76.3984C138.196 79.3611 134.397 86.0997 133.211 88.4354C131.727 91.3612 130.002 94.0908 128.5 96.7215C126.716 99.1695 125.235 101.262 124.588 101.79C123.941 102.318 117.256 106.59 113.943 109.168C112.276 110.464 112.276 111.389 112.276 111.389L108.574 113.213L111.721 120.504L115.239 119.35C115.239 119.35 119.035 120.16 122.459 118.98C125.884 117.799 131.518 114.642 134.863 111.019C138.833 106.72 140.735 104.196 140.787 104.168C142.795 101.757 144.401 98.5022 146.07 95.8446C147.248 93.9651 147.822 92.8735 149.116 91.948"
      fill="#00ADFF"
    />
    <path
      d="M140.602 76.3984C138.196 79.3611 134.397 86.0997 133.211 88.4354C131.727 91.3612 130.002 94.0908 128.5 96.7215C126.716 99.1695 125.235 101.262 124.588 101.79C123.941 102.318 117.256 106.59 113.943 109.168C112.276 110.464 112.276 111.389 112.276 111.389L108.574 113.213L111.721 120.504L115.239 119.35C115.239 119.35 119.035 120.16 122.459 118.98C125.884 117.799 131.518 114.642 134.863 111.019C138.833 106.72 140.735 104.196 140.787 104.168C142.795 101.757 144.401 98.5022 146.07 95.8446C147.248 93.9651 147.822 92.8735 149.116 91.948"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M112.275 111.391L114.128 116.389"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.0957 170.486H188.09"
      stroke="#001D45"
      stroke-width="0.774939"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
