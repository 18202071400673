<template>
  <svg width="99" height="90" viewBox="0 0 99 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5209 5.76938C6.57306 4.70411 7.45196 3.86719 8.51851 3.86719H82.4395C83.4672 3.86719 84.3276 4.64603 84.4296 5.66863L92.8434 90H2.44405C1.1236 90 0.165559 88.743 0.515609 87.4698L2.8968 78.8089C2.93561 78.6677 2.95881 78.5227 2.96597 78.3765L6.5209 5.76938Z"
      fill="#EDECE8"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.66949 3.79534C9.67249 2.6929 10.567 1.80078 11.6695 1.80078H85.6281C86.6471 1.80078 87.5031 2.56681 87.6158 3.57949L95.8673 77.6964L97.8725 87.604C98.1232 88.8429 97.1762 90.0008 95.9122 90.0008H12.9917C11.9986 90.0008 11.1559 89.272 11.0126 88.2893L9.48979 77.8426C9.4756 77.7453 9.46861 77.6471 9.46888 77.5487L9.66949 3.79534Z"
      fill="#F5F4F2"
    />
    <path
      d="M43.25 59.0156C46.4086 57.126 50.1164 56.001 53.7211 56.001C57.3259 56.001 60.4712 57.126 63.4956 59.0156"
      stroke="#75736F"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M64.25 30C64.25 30 64.25 30 64.25 30C64.25 30 64.25 30 64.25 30C64.25 30.0001 64.25 30.0002 64.25 30.0004C64.2501 30.0007 64.2501 30.0015 64.2504 30.0028C64.2509 30.0053 64.2523 30.0111 64.256 30.0205C64.2636 30.0395 64.282 30.076 64.3246 30.1274C64.4136 30.2348 64.584 30.3774 64.8688 30.5198C65.1495 30.6602 65.5058 30.7823 65.9221 30.8685C66.3368 30.9544 66.7888 31 67.25 31C67.7112 31 68.1632 30.9544 68.5779 30.8685C68.9942 30.7823 69.3505 30.6602 69.6312 30.5198C69.916 30.3774 70.0864 30.2348 70.1754 30.1274C70.218 30.076 70.2364 30.0395 70.244 30.0205C70.2477 30.0111 70.2491 30.0053 70.2496 30.0028C70.2499 30.0015 70.2499 30.0007 70.25 30.0004C70.25 30.0002 70.25 30.0001 70.25 30C70.25 30 70.25 30 70.25 30C70.25 30 70.25 30 70.25 30"
      stroke="#75736F"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M32.25 30C32.25 30 32.25 30 32.25 30C32.25 30 32.25 30 32.25 30C32.25 30.0001 32.25 30.0002 32.25 30.0004C32.2501 30.0007 32.2501 30.0015 32.2504 30.0028C32.2509 30.0053 32.2523 30.0111 32.256 30.0205C32.2636 30.0395 32.282 30.076 32.3246 30.1274C32.4136 30.2348 32.584 30.3774 32.8688 30.5198C33.1495 30.6602 33.5058 30.7823 33.9221 30.8685C34.3368 30.9544 34.7888 31 35.25 31C35.7112 31 36.1632 30.9544 36.5779 30.8685C36.9942 30.7823 37.3505 30.6602 37.6312 30.5198C37.916 30.3774 38.0864 30.2348 38.1754 30.1274C38.218 30.076 38.2364 30.0395 38.244 30.0205C38.2477 30.0111 38.2491 30.0053 38.2496 30.0028C38.2499 30.0015 38.2499 30.0007 38.25 30.0004C38.25 30.0002 38.25 30.0001 38.25 30C38.25 30 38.25 30 38.25 30C38.25 30 38.25 30 38.25 30"
      stroke="#75736F"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle cx="13.7492" cy="1.8" r="1.8" fill="white" />
    <circle cx="19.1516" cy="1.8" r="1.8" fill="white" />
    <circle cx="24.55" cy="1.8" r="1.8" fill="white" />
    <circle cx="29.9484" cy="1.8" r="1.8" fill="white" />
    <circle cx="35.3508" cy="1.8" r="1.8" fill="white" />
    <circle cx="40.7492" cy="1.8" r="1.8" fill="white" />
    <circle cx="46.1516" cy="1.8" r="1.8" fill="white" />
    <circle cx="51.55" cy="1.8" r="1.8" fill="white" />
    <circle cx="56.9484" cy="1.8" r="1.8" fill="white" />
    <circle cx="62.3508" cy="1.8" r="1.8" fill="white" />
    <circle cx="67.7492" cy="1.8" r="1.8" fill="white" />
    <circle cx="73.1516" cy="1.8" r="1.8" fill="white" />
    <circle cx="78.55" cy="1.8" r="1.8" fill="white" />
    <circle cx="83.9484" cy="1.8" r="1.8" fill="white" />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
