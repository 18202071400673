<template>
  <svg
    class="bg-white rounded"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4H6V6H8V8H6V14H8V16H6V20H8V26H10V20H8V18H10H12V24H14V20H16H18V18H20V20H22H24V24H22H20V22H18V24H16V26H18V24H20V26H22H24V24H26V26H28V28H26H24H22H20H18H16H14H12H10H8H6H4V4ZM26 24V22H28V24H26ZM12 14V18H14V16H16V14H18H20V16H22H24V18H26V20H28V18H26V16H28V14H26V12H28V10H26H24V12H22V8H24V6H26V8H28V6H26V4H24V6H22V4H20V6H22V8H20V12H18V8H16V6H18V4H16V6H14V4H12V6H10V4H8V6H10V8H8V10H10V12H8V14H10H12ZM14 10H12V14H14V10ZM26 14H24V16H26V14Z"
      fill="#21201F"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
