<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.625 10.75H3C2.64844 10.75 2.375 11.043 2.375 11.375V12.625C2.375 12.9766 2.64844 13.25 3 13.25H3.625C3.95703 13.25 4.25 12.9766 4.25 12.625V11.375C4.25 11.043 3.95703 10.75 3.625 10.75ZM6.75 8.25H6.125C5.77344 8.25 5.5 8.54297 5.5 8.875V12.625C5.5 12.9766 5.77344 13.25 6.125 13.25H6.75C7.08203 13.25 7.375 12.9766 7.375 12.625V8.875C7.375 8.54297 7.08203 8.25 6.75 8.25ZM9.875 5.75H9.25C8.89844 5.75 8.625 6.04297 8.625 6.375V12.625C8.625 12.9766 8.89844 13.25 9.25 13.25H9.875C10.207 13.25 10.5 12.9766 10.5 12.625V6.375C10.5 6.04297 10.207 5.75 9.875 5.75ZM13 3.25H12.375C12.0234 3.25 11.75 3.54297 11.75 3.875V12.625C11.75 12.9766 12.0234 13.25 12.375 13.25H13C13.332 13.25 13.625 12.9766 13.625 12.625V3.875C13.625 3.54297 13.332 3.25 13 3.25Z"
      stroke="white"
      stroke-width="3"
    />
    <path
      d="M3.625 10.75H3C2.64844 10.75 2.375 11.043 2.375 11.375V12.625C2.375 12.9766 2.64844 13.25 3 13.25H3.625C3.95703 13.25 4.25 12.9766 4.25 12.625V11.375C4.25 11.043 3.95703 10.75 3.625 10.75ZM6.75 8.25H6.125C5.77344 8.25 5.5 8.54297 5.5 8.875V12.625C5.5 12.9766 5.77344 13.25 6.125 13.25H6.75C7.08203 13.25 7.375 12.9766 7.375 12.625V8.875C7.375 8.54297 7.08203 8.25 6.75 8.25ZM9.875 5.75H9.25C8.89844 5.75 8.625 6.04297 8.625 6.375V12.625C8.625 12.9766 8.89844 13.25 9.25 13.25H9.875C10.207 13.25 10.5 12.9766 10.5 12.625V6.375C10.5 6.04297 10.207 5.75 9.875 5.75ZM13 3.25H12.375C12.0234 3.25 11.75 3.54297 11.75 3.875V12.625C11.75 12.9766 12.0234 13.25 12.375 13.25H13C13.332 13.25 13.625 12.9766 13.625 12.625V3.875C13.625 3.54297 13.332 3.25 13 3.25Z"
      :fill="colorValue"
    />
  </svg>
</template>

<script lang="ts">
import withColor from '@/ui/common/typo/mixins/withColor';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [withColor],
});
</script>
