<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 11.5C4 11.2239 4.22386 11 4.5 11H16.2L10.9536 5.75355C10.7583 5.55829 10.7583 5.24171 10.9536 5.04645L11.6464 4.35355C11.8417 4.15829 12.1583 4.15829 12.3536 4.35355L20 12L12.3536 19.6464C12.1583 19.8417 11.8417 19.8417 11.6464 19.6464L10.9536 18.9536C10.7583 18.7583 10.7583 18.4417 10.9536 18.2464L16.2 13H4.5C4.22386 13 4 12.7761 4 12.5V11.5Z"
      fill="#21201F"
    />
  </svg>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
