<template>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      fill="#1C1D20"
      d="m2.25,12.03641c0,-5.38478 4.36522,-9.75 9.75,-9.75c5.3848,0 9.75,4.36522 9.75,9.75c0,4.9539 -3.6935,9.0433 -8.4774,9.6676c-0.6161,0.0804 -1.1807,-0.3539 -1.2611,-0.97c-0.08042,-0.6161 0.3538,-1.1807 0.9699,-1.2611c3.678,-0.48 6.5186,-3.6274 6.5186,-7.4365c0,-4.14214 -3.3579,-7.5 -7.5,-7.5c-4.14214,0 -7.5,3.35786 -7.5,7.5c0,2.0976 0.86016,3.9939 2.25,5.3561l0,-1.9811c0,-0.6213 0.50368,-1.125 1.125,-1.125c0.62132,0 1.125,0.5037 1.125,1.125l0,4.5c0,0.6213 -0.50368,1.125 -1.125,1.125l-4.5,0c-0.62132,0 -1.125,-0.5037 -1.125,-1.125c0,-0.6213 0.50368,-1.125 1.125,-1.125l1.58945,0c-1.68005,-1.7509 -2.71445,-4.1298 -2.71445,-6.75zm9.75,-5.625c0.6213,0 1.125,0.50368 1.125,1.125l0,3.9375l1.55,1.1625c0.4971,0.3728 0.5978,1.0779 0.225,1.575c-0.3728,0.4971 -1.0779,0.5978 -1.575,0.225l-2,-1.5c-0.28328,-0.2125 -0.45,-0.5459 -0.45,-0.9l0,-4.5c0,-0.62132 0.50368,-1.125 1.125,-1.125z"
      clip-rule="evenodd"
      fill-rule="evenodd"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
