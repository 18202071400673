<template>
  <div class="flex items-center">
    <StrokeText :text="String(errorCount)" />
    <ApiIcon :color="apiIndicatorColor" />
  </div>
</template>

<script setup lang="ts">
import ApiIcon from '@/fsd/features/indicators/img/api-icon.vue';
import StrokeText from '@/fsd/shared/ui/text/StrokeText.vue';
import { useIndicators } from '@/store/indicators';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { errorCount, hasRequest } = storeToRefs(useIndicators());

const apiIndicatorColor = computed<string>(() => {
  if (hasRequest.value) {
    return 'blue-normal';
  }
  return 'semantic-text-minor';
});
</script>
