<template>
  <div>
    <ShelfRow :doc-type="docType" :suggest="suggest" />
    <NewCountRow :order="order" :doc-type="docType" :suggest="suggest" />
    <AvailableRow :doc-type="docType" :available-product="availableProduct" />
    <ValidRow :doc-type="docType" :suggest="suggest" :available-product="availableProduct" />
    <ReservedCountRow :doc-type="docType" :available-product="availableProduct" />
    <CollectedRow :doc-type="docType" :suggest="suggest" :collected="collected" />
    <TrueMarkRow :suggest="suggest" />
  </div>
</template>

<script lang="ts">
import BaseOrder from '@/models/orders/BaseOrder';
import Suggest from '@/models/Suggest';
import { DocTypes } from '@/temp/constants/translations/types';
import { AvailableProduct } from '@/types/product';
import AvailableRow from '@/ui/common/suggest-card/data-rows/available-row.vue';
import NewCountRow from '@/ui/common/suggest-card/data-rows/new-count-row.vue';
import { defineComponent, PropType } from 'vue';
import CollectedRow from './collected-row.vue';
import ReservedCountRow from './reserved-count-row.vue';
import ShelfRow from './shelf-row.vue';
import TrueMarkRow from './true-mark-row.vue';
import ValidRow from './valid-row.vue';

export default defineComponent({
  components: {
    AvailableRow,
    NewCountRow,
    ValidRow,
    ShelfRow,
    ReservedCountRow,
    CollectedRow,
    TrueMarkRow,
  },
  props: {
    docType: {
      type: String as PropType<DocTypes>,
      required: true,
    },
    order: {
      type: Object as PropType<BaseOrder>,
      default: undefined,
    },
    suggest: {
      type: Object as PropType<Suggest>,
      default: undefined,
    },
    availableProduct: {
      type: Object as PropType<AvailableProduct>,
      default: undefined,
    },
    collected: {
      type: Number,
      required: true,
    },
  },
});
</script>
