<template>
  <div>
    <div v-for="option in options" :key="option.value" class="option-container" @click="$emit('change', option.value)">
      <div class="check-container">
        <IconCheck v-if="value === option.value" />
      </div>
      <Typography>
        {{ option.name }}
      </Typography>
    </div>
  </div>
</template>

<script lang="ts">
import IconCheck from '@/temp/icons/icon-check.vue';
import Typography from '@/ui/common/typography.vue';
import { defineComponent, PropType } from 'vue';

export interface SelectOption {
  name: string;
  value: string;
}

export default defineComponent({
  components: { IconCheck, Typography },
  props: {
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['change'],
  methods: {
    onMenuClick(): void {},
  },
});
</script>

<style lang="scss" scoped>
.option-container {
  padding: 16px 24px;
  display: flex;
  align-items: center;
}
.check-container {
  width: 40px;
  height: 28px;
}
</style>
