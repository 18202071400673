import BaseOrder from '@/models/orders/BaseOrder';
import { ScheduleTypeEnum } from '@/models/Product';
import Suggest from '@/models/Suggest';
import { useOrders } from '@/store/modules/orders';

export const orderSorter = (a, b) => {
  switch (true) {
    case Boolean(a.status === 'processing' && b.status === 'processing'):
      return a.created - b.created;
    case a.status === 'processing':
      return -1;
    case b.status === 'processing':
      return 1;
    default:
      return a.created - b.created;
  }
};

export const queueOrdersSorter = (a, b) => {
  const placeA = useOrders().ordersQueue.indexOf(a.external_id);
  const placeB = useOrders().ordersQueue.indexOf(b.external_id);
  if (placeA !== -1 && placeB !== -1) {
    return placeA - placeB;
  }
  return orderSorter(a, b);
};

export const queueSorter = (a: BaseOrder, b: BaseOrder) => {
  return waitingForCompleteOrderSorter(a, b) || queueOrdersSorter(a, b);
};

const isWaitingForCourierOrder = (order: BaseOrder): boolean => {
  const isDone = order.suggests.length && order.suggests.every(s => s.isDone || s.isPackaging);

  return !!order.attr.maybe_rover && !order.courier && !!isDone;
};

const isWaitingForKitchenOrder = (order: BaseOrder): boolean => {
  const productSuggests: Suggest[] = [];
  const kitchenSuggests: Suggest[] = [];

  order.suggests.filter(s => {
    if (
      s.product?.schedule_type === ScheduleTypeEnum.coffee ||
      s.product?.schedule_type === ScheduleTypeEnum.kitchens
    ) {
      kitchenSuggests.push(s);
    } else {
      productSuggests.push(s);
    }
  });

  const isProductDone = productSuggests.every(s => s.isDone || s.isPackaging);
  const isKitchenWaiting = kitchenSuggests.some(s => s.isBlocked);

  return isProductDone && isKitchenWaiting;
};

const isWaitingForCompleteOrder = (order: BaseOrder): boolean => {
  return isWaitingForCourierOrder(order) || isWaitingForKitchenOrder(order);
};

export const waitingForCompleteOrderSorter = (a: BaseOrder, b: BaseOrder) => {
  return Number(isWaitingForCompleteOrder(a)) - Number(isWaitingForCompleteOrder(b));
};
