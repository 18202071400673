<template>
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none">
    <path
      clip-rule="evenodd"
      fill-rule="evenodd"
      d="m5.4375,4.125c-0.72487,0 -1.3125,0.58763 -1.3125,1.3125l0,3.28125l-2.625,0l0,-3.28125c0,-2.17462 1.76288,-3.9375 3.9375,-3.9375l3.28125,0l0,2.625l-3.28125,0zm0,15.75c-0.72487,0 -1.3125,-0.5876 -1.3125,-1.3125l0,-3.2813l-2.625,0l0,3.2813c0,2.1747 1.76288,3.9375 3.9375,3.9375l3.28125,0l0,-2.625l-3.28125,0zm14.4375,-14.4375c0,-0.72487 -0.5876,-1.3125 -1.3125,-1.3125l-3.2813,0l0,-2.625l3.2813,0c2.1747,0 3.9375,1.76288 3.9375,3.9375l0,3.28125l-2.625,0l0,-3.28125zm-1.3125,14.4375c0.7249,0 1.3125,-0.5876 1.3125,-1.3125l0,-3.2813l2.625,0l0,3.2813c0,2.1747 -1.7628,3.9375 -3.9375,3.9375l-3.2813,0l0,-2.625l3.2813,0zm0.2718,-10.88442l-1.8561,-1.85616l-6.2907,6.29068l-3.00942,-3.00942l-1.85616,1.85612l4.86558,4.8656l8.1468,-8.14682z"
      fill="#1C1D20"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
