<template>
  <div class="mt-2 px-4">
    <UiButton
      class="w-full flex justify-center items-center mx-auto pointer-events-auto"
      :background-color="primary"
      @click="onDownloadNewApkVersion"
    >
      <img src="@/assets/img/alert.svg" alt="Alert icon" class="inline-block mr-2" />
      {{ $gettext('Доступна версия') }}
      {{ newVersion }}
    </UiButton>
  </div>
</template>

<script lang="ts">
import { DownloadManagerService } from '@/services/download-manager.service';
import { ModeService } from '@/services/mode.service';
import { useUser } from '@/store/modules/user';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

export interface Data {
  isCopied: boolean;
}

export default defineComponent({
  name: 'AppUpdater',
  components: {
    UiButton,
  },
  setup() {
    const userStore = useUser();

    return { userStore };
  },
  data(): Data {
    return {
      isCopied: false,
    };
  },
  computed: {
    appName(): string {
      return `${ModeService.isProduction() ? 'yandex.polka' : 'packman'}-${this.newVersion}.apk`;
    },
    newVersion() {
      return ModeService.isProduction() ? this.userStore.appVersion : this.userStore.packmanVersion;
    },
  },
  methods: {
    async onDownloadNewApkVersion(): Promise<void> {
      const url = `${window.location.origin}/permanent/app/${this.appName}`;
      try {
        await DownloadManagerService.download(url, this.appName);
        this.$alert.success(this.$gettext('Файл успешно скачан в папку "Загрузки"'));
      } catch (error) {
        this.$alert.error(this.$gettext('Не удалось загрузить файл'));
      }
    },
  },
});
</script>
