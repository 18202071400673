<template>
  <SuggestCard :collected="collected" :suggest="suggest">
    <template #right-button>
      <button class="flex" @click.stop="emits('remove')">
        <IconBasketRound />
      </button>
    </template>
  </SuggestCard>
</template>

<script setup lang="ts">
import { SuggestCard } from '@/fsd/entities/suggest';
import IconBasketRound from '@/fsd/shared/icons/icon-basket-round.vue';
import Suggest from '@/models/Suggest';
import { defineEmits, defineProps } from 'vue';

interface CardInListProps {
  suggest: Suggest;
  collected: number;
}

defineProps<CardInListProps>();

const emits = defineEmits<{ (e: 'remove'): void }>();
</script>
