<template>
  <!--  иконка активации/деактивации девтулзов  -->
  <DevtoolsActivator v-if="devtools.devtoolsEnabled" @click="toggle" />
  <!--  контент девтулзов  -->
  <DevtoolsSections v-if="visible" />
</template>

<script setup lang="ts">
import DevtoolsActivator from '@/devtools/activator/devtools-activator.vue';
import DevtoolsSections from '@/devtools/sections/devtools-sections.vue';
import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';
import { useComponent } from '@/hooks/useComponent';

const devtools = useDevtoolsStore();

const { toggle, visible } = useComponent();
</script>
