<template>
  <Overlay>
    <div class="count-window-container">
      <template v-if="uiState.step === 'start'">
        <Typography type="h2" class="count-window-title">
          {{ $gettext('Вы хотите произвести пересчет товара на основании прихода?') }}
        </Typography>
        <div class="buttons-wrapper">
          <UiButton data-test="cancel btn" background-color="secondary" @click="onCancel">
            {{ $gettext('Нет') }}
          </UiButton>
          <UiButton data-test="confirm ok-btn" @click="onConfirm">
            {{ $gettext('Да') }}
          </UiButton>
        </div>
      </template>
      <template v-if="uiState.step === 'scan'">
        <div class="barcode">
          <img src="@/assets/img/barcode.svg" alt="" />
        </div>
        <Typography type="h2" class="count-window-title">
          {{ $gettext('Отсканируйте продукт') }}
        </Typography>
        <div class="buttons-wrapper">
          <UiButton data-test="cancel btn" background-color="secondary" @click="onCancel">
            {{ $gettext('Отмена') }}
          </UiButton>
        </div>
      </template>
    </div>
  </Overlay>
</template>

<script lang="ts">
import Overlay from '@/ui/common/overlay/overlay.vue';
import Typography from '@/ui/common/typography.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { defineComponent } from 'vue';

interface Data {
  id: string;
  uiState: {
    step: 'start' | 'scan';
  };
}

export default defineComponent({
  components: {
    Typography,
    Overlay,
    UiButton,
  },
  emits: ['cancel'],
  data(): Data {
    return {
      id: 'recount-by-order',
      uiState: {
        step: 'start',
      },
    };
  },
  methods: {
    onCancel(): void {
      this.$emit('cancel');
      this.uiState.step = 'start';
    },
    onConfirm(): void {
      this.uiState.step = 'scan';
    },
  },
});
</script>

<style lang="scss" scoped>
.count-window-container {
  background: var(--main-bg);

  padding: 16px 8px 8px;
  transition: transform 200ms ease 0ms;
  transform: translateY(0);
  border-radius: 16px;
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

.count-window-title {
  margin: 0 8px 12px 8px !important;
}

.count-window-text {
  margin: 0 8px 20px 8px !important;
}

.buttons-wrapper {
  display: flex;
  padding: 4px 0 0;
  width: 100%;
  .button + .button {
    margin-left: 8px;
  }
}
</style>
