import { Canceler } from 'axios';
import DataLoader, { CacheMap } from 'dataloader';

const TIME_FOR_COLLECT_REQUESTS: number = 100;

export type QueueLoadFn<I> = (ids: readonly string[], setCancel: (c: Canceler) => void) => Promise<I[]>;
// Добавляем к обычной очереди функциональность по отмене запросов
export const createQueue = <I extends {}>(
  loadFn: QueueLoadFn<I>,
  cacheMap: CacheMap<string, Promise<I>> | null = null,
) => {
  let cancel: Canceler;
  const setCancel = (c: Canceler) => {
    cancel = c;
  };

  type WithStop<T extends {}> = T & { stop: () => void };
  const addStopToLoader = <T extends DataLoader<any, any>>(queue: T): WithStop<T> => {
    const result: WithStop<T> = queue as any;
    result.stop = () => {
      if (cancel) {
        cancel('stop');
      }
      result.clearAll();
    };
    return result;
  };

  const addCancelToLoadFn = (fn, cancel) => {
    return args => fn(args, cancel);
  };

  const dataLoader = new DataLoader<string, I>(addCancelToLoadFn(loadFn, setCancel), {
    batchScheduleFn: cb => setTimeout(cb, TIME_FOR_COLLECT_REQUESTS),
    maxBatchSize: 100,
    cacheMap,
  });
  return addStopToLoader(dataLoader);
};
