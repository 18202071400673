<template>
  <section class="layout pointer-events-auto">
    <header class="header" :class="{ isActive: !uiState.activeFooter }">
      <slot name="header" />
    </header>
    <main :id="mainId" class="main">
      <slot />
    </main>
    <footer class="footer" :class="{ isActive: uiState.activeFooter }">
      <slot name="footer" />
    </footer>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    mainId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      uiState: {
        activeFooter: false,
      },
    };
  },
  mounted() {
    this.$emitter.on('setActive', isActive => this.setActiveFooter(isActive));
  },
  methods: {
    setActiveFooter(isActive) {
      this.uiState.activeFooter = isActive;
    },
  },
});
</script>
<style scoped>
.layout {
  align-items: stretch;
  display: flex;
  height: 100%;
  flex-flow: column nowrap;
  min-height: 100%;
  overflow: hidden;
  flex: 1 1 auto;
  background-color: var(--main-bg);
}

.header,
.footer {
  flex: 0 0 auto;
  position: relative;
  z-index: 2;
  width: 100%;
}

.isActive {
  z-index: 3;
}

.main {
  flex: 1 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
</style>
