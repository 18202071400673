import { ScannerService } from '@/services/scanner/scanner.service';
import { v4 as uuid } from 'uuid';
import { onBeforeUnmount, ref, watch } from 'vue';

interface Options {
  immediate: boolean;
}

export const useRequestBarcode = (
  onBarcodeScan: (barcode: string) => Promise<boolean | void> | boolean | void,
  options: Options = { immediate: true },
) => {
  const handlerId = uuid();
  const needBarcodeRequest = ref<boolean>(options.immediate);
  const hasRequest = ref<boolean>(false);
  const autoRequestBarcode = async () => {
    if (!needBarcodeRequest.value) return;

    try {
      hasRequest.value = true;
      const barcode = await ScannerService.requestCode(handlerId);
      needBarcodeRequest.value = (await onBarcodeScan(barcode)) ?? true;
      hasRequest.value = false;
      autoRequestBarcode();
    } catch (e) {
      console.error(e);
      hasRequest.value = false;
      needBarcodeRequest.value = false;
      return;
    }
  };
  options.immediate && autoRequestBarcode();

  onBeforeUnmount(() => {
    ScannerService.clearPromise(handlerId);
  });

  // Если запрос стал не нужен - чистим его
  watch(needBarcodeRequest, val => {
    if (!val) ScannerService.clearPromise(handlerId);
    else if (!hasRequest.value) {
      autoRequestBarcode();
    }
  });

  return { needBarcodeRequest, autoRequestBarcode };
};
